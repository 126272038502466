import React, {Component} from 'react';
import swal from '@sweetalert/with-react';


class CancelTransaction extends Component {
    state = {
        reason: ''
    }
    handleChange = (e) => {
        const {name, value} = e.target
        this.setState({[name]: value})
    }
    cancelTransaction = () => {
        this.props.cancelTransaction({order_id: this.props.data.id, comment: this.state.reason})
    }

    render () {
        const { data, handleCancelView, history} = this.props
        if( data && data.status.title == 'Canceled') {
            swal(
                <div>
                    <h3>Transaction Canceled</h3>
                    <small class="text-left">Last Activity was {data.updated_at}</small>
                    <p>
                        Reasons:<br/>
                        {data.comment}
                    </p>
                </div>
            ).then(() => {
                history.push(`/app/transactions`);
            })
        }
        return (
            <div class="card">
                <div class=""><h5><strong>Cancel Transaction</strong></h5> </div>
                <br></br>
                <div class="container">
                    <ul>
                        <li>Please Provide a reason for cancelling this transaction and select Confirm button</li>
                        <li> The other party will be given the reason for cancelling this transaction</li>
                        <li> An asterisk (*) denotes a required field</li>
                    </ul>
                </div>
                <br></br>
                <div>
                    <div class="form-group">
                        <div class="col-sm-10">
                        <label>Reason for Cancellation * <small>(Up to 435 Characters)</small></label>
                            <textarea disabled={data.status.title =='Canceled'}  class="form-control" name="reason" onChange={this.handleChange} value={data.comment || this.state.reason}></textarea>
                        </div>
                    </div>
                    <div class="">
                        <button disabled={data.status.title =='Canceled'} onClick={this.cancelTransaction} class="btn btn-success btn-md">Confirm</button> &nbsp;
                        <button class="btn btn-md btn-default" onClick={handleCancelView}>Don't Cancel</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default CancelTransaction;