import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import  Dispatcher from '../views/addDispatcher';
import { logisticActions } from '../actions';
// import { payoutActions } from '../actions';


const mapStateToProps = (state) =>{
    const { loading,deliveryMen,deliveryManType,couriers,adding,deleting,updating,user} = state.users;
    

    const alert  = state.alert;
    
    return {
        alert,
        loading,
        deliveryMen,
        couriers,
        deliveryManType,
        user,
        adding,
        deleting,
        updating,
            
    };
     
     
 }
 const mapDispatchToProps = (dispatch) => ({
    viewType:(type,history)=>dispatch(logisticActions.delivery.viewDispatcherType(history)),
    add:(data,history)=>dispatch(logisticActions.delivery.createDispatcher(data,history)),
    update:(data,history)=>dispatch(logisticActions.delivery.editDispatcher(data,history)),
    delete:(id, history)=>dispatch(logisticActions.delivery.deleteDispatcher(id, history)),
    dispatch
}
)
 

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Dispatcher));