import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { orderActions } from "../actions";

class ProviderOrderAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idNumber: "",
            action: "",
        };
    }

    componentDidMount = () => {
        const {orderCode} = this.props.match.params;
        this.props.providerTriggerPickup({order: orderCode}, this.props.history);

    };

    render() {
        return (
            <div className="text-center">
                <ClipLoader loading={true} size={100}/>
                <div>Please wait. This will take a few seconds.</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {loading} = state.orders;
    return {
        loading,
    };
};

const mapDispatchToProps = (dispatch) => ({
    providerTriggerPickup: (data, history) => dispatch(orderActions.order.providerReadyForPickup(data, history)),
});


export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProviderOrderAction)
);


