import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";



import Transactions from '../views/Transactions';

const mapStateToProps = (state) => {
    const {alert, updating}  = state.alert;
    return {
        alert,
        updating,
        role: localStorage.getItem('trust_user_type')
    };
 }
 

export default withRouter(connect(mapStateToProps)(Transactions));