import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import CancelTransaction from './CancelTransaction';
import ModalComponent from '../../views/ModalComponent';
import InfoView from '../../views/InfoView';
import transactionHelper from '../../helpers/transaction';
import { formatPhoneNumber } from 'react-phone-number-input';
import swal from '@sweetalert/with-react';
import axios from 'axios';
import { BASE_URL } from '../../constants';

const summation = (ads) => {
  let _sum = 0;
  ads.map((item) => {
    if (item.selected) {
      _sum += item.cost;
    }
  });
  return _sum;
};
const formatToSixDigits = (number) => {
  var output = number + '';
  while (output.length < 6) {
    output = '0' + output;
  }
  return output;
};
const sentenceCase = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

const toLocaleDisplay = (quantity, curency = 'NGN') => {
  if (quantity === undefined) quantity = 0;

  return quantity.toLocaleString(undefined, {
    style: 'currency',
    currency: curency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const isStatusDone = (status, target, delivery = null) => {
  const statuses = [
    'Awaiting Agreement',
    'Agreed',
    'Pending',
    'Pending II',
    'Pending Buyer Response',
    'Accepted',
    'Rejected',
    'Complete',
  ];
  if (target === status) {
    if (delivery && delivery !== 'rejected') {
      return <span className='text-success'>(Done)</span>;
    }
  }
  if (
    target === 'Rejected' &&
    status === 'Pending' &&
    delivery === 'rejected'
  ) {
    return '';
  }

  if (status === 'Pending' && target === 'Pending II') {
    return '';
  }

  if (
    statuses.indexOf(status) < statuses.indexOf(target) &&
    status !== target
  ) {
    return <span className='text-success'>(Done)</span>;
  }
  return '';
};
const isPaymentDone = (status, target, paymentRef, delivery = null) => {
  const statuses = [
    'Awaiting Agreement',
    'Agreed',
    'Pending',
    'Pending II',
    'Pending Buyer Response',
    'Accepted',
    'Rejected',
    'Complete',
  ];
  if (target === status) {
    if (delivery && delivery !== 'rejected') {
      return <span className='text-success'>(Done)</span>;
    }
  }
  if (
    target === 'Rejected' &&
    status === 'Pending' &&
    delivery === 'rejected'
  ) {
    return '';
  }

  if (
    statuses.indexOf(status) < statuses.indexOf(target) &&
    status !== target
  ) {
    if (paymentRef > 0) {
      return <span className='text-success'>(Done)</span>;
    }
  }
  return '';
};
const displayCustomer = (text) => {
  if (text === 'customer') {
    return 'Buyer';
  }
  return text;
};

const subTotal = (data) => {
  let shippingCost = transactionHelper.calculateShipping(data, 0)['customer'];

  let netProductCost =
    data.category == 'Money Market'
      ? parseFloat(data.conversion_ratio * data.quantity)
      : parseFloat(data.amount_payed * data.quantity);
  let sub_total =
    data.category == 'Money Market'
      ? parseFloat(shippingCost + netProductCost)
      : parseFloat((shippingCost + netProductCost) / data.conversion_ratio);
  return sub_total;
};

class StageThree extends Component {
  state = {
    cancel: false,
    deposit: false,
    info: false,
    alt: '',
    editSeller: false,
    sellerEmail: null,
    sellerPhone: null,
    savedEmail: null,
    savedPhone: null,
  };

  handleCancelView = () => {
    this.setState((prevState) => ({ cancel: !prevState.cancel }));
  };
  handleDepositView = () => {
    this.setState((prevState) => ({ deposit: !prevState.deposit }));
  };
  handleInfoView = () => {
    this.setState((prevState) => ({ info: !prevState.info }));
  };

  handlePhone = (value) => {
    this.setState({ alt: value });
  };

  editSeller = (e) => {
    e.preventDefault();
    this.setState({ editSeller: true });
  };

  cancelEditSeller = (e) => {
    e.preventDefault();

    this.setState({
      editSeller: false,
      sellerEmail: null,
      sellerPhone: null,
    });
  };

  saveSeller = (e) => {
    e.preventDefault();

    const newState = {
      editSeller: false,
    };

    if (this.state.sellerPhone !== null && this.state.sellerPhone !== '') {
      newState['savedPhone'] = this.state.sellerPhone;
    }

    if (this.state.sellerEmail !== null && this.state.sellerEmail !== '') {
      newState['savedEmail'] = this.state.sellerEmail;
    }

    this.setState(newState, () => {
      const url = `${BASE_URL}customer/transaction/updateEasyP2P/${this.props.data.id}`;

      const { seller_phone, seller_email } = this.props.data;

      const data = {
        seller_phone: seller_phone.replace('+234', '+'),
        seller_email,
      };
      if (this.state.savedPhone) {
        data['seller_phone'] = this.state.savedPhone;
      }
      if (this.state.savedEmail) {
        data['seller_email'] = this.state.savedEmail;
      }

      axios({
        method: 'put',
        mode: 'no-cors',
        url,
        data,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem('user'),
          'Content-Type': 'application/json',
        },
      })
        .then(({ data }) => {
          if (data.status == 'success') {
            let successMsg = 'The seller has been notified.';
            if (!this.props.data.payment) {
              successMsg =
                'Seller information updated. The seller will be notified when you complete payment.';
            }
            swal('Success', successMsg, 'success');
            this.props.getTransactions(
              localStorage.getItem('trust_user_type'),
              this.props.history
            );
          } else if (data.status == 'error') {
            swal('Error', data.message, 'error');
          }
        })
        .catch((err) => {
          swal(
            'Error',
            `Something went wrong. ${err.response.data.message}`,
            'error'
          );
        });
    });
  };

  render() {
    const { data, role, terms, user, accounts, handlePhone, paymentInfo } =
      this.props;
    const { cancel, alt } = this.state;
    const { editTransaction } = this.props;
    if (data && data.status.title === 'Canceled') {
      swal(
        'This Transaction has been Canceled for the following Reason:',
        data.comment,
        'error'
      );
    }

    const sellerVerified =
      role == 'customer' &&
      data.sellers &&
      data.sellers.users &&
      data.sellers.users.status !== 0;
    const sellerExists =
      role == 'customer' && data.sellers && data.sellers.users;

    return (
      <>
        {paymentInfo && (
          <div className='content-wrapper' style={{ minHeight: 93 }}>
            <div className='container'>
              <br />
              <br />
              <ModalComponent
                pay={() => {
                  this.props.pay(role, data, alt, this.props.history);
                }}
                handlePhone={(val) => {
                  this.handlePhone(
                    (val &&
                      formatPhoneNumber(val, 'International').replace(
                        / /g,
                        ''
                      )) ||
                      ''
                  );
                }}
                role={role}
                seller_phone={null}
                user={user}
                openInfo={this.handleInfoView}
                open={this.state.deposit}
                onClose={this.handleDepositView}
                data={data}
                terms={
                  data.sellers &&
                  data.sellers.delivery_terms &&
                  data.sellers.delivery_terms
                }
              />

              <InfoView open={this.state.info} onClose={this.handleInfoView} />

              <div className='row'>
                <div className='col-sm-8'>
                  {data.started_by == role && (
                    <ul>
                      <li>You have provided the terms of this transaction.</li>
                      <li>
                        The other party must sign in to AtaraPay and agree to
                        these terms. An E-Mail reminder was sent to the other
                        party.
                      </li>
                    </ul>
                  )}
                  <div>
                    <div className='row text-center'>
                      <div className='btn-group'>
                        <div className='col-md-6 mb-2'>
                          <Link to='/app/seller/transactions'>
                            <button className='btn btn-sm btn-success'>
                              <i className='fa fa-list'></i> My Transactions
                            </button>
                          </Link>
                        </div>
                        <div className='col-md-6 mb-2'>
                          <button
                            onClick={this.handleCancelView}
                            disabled={
                              data.status.title === 'Accepted' ||
                              data.status.title === 'Rejected' ||
                              data.status.title === 'Canceled' ||
                              data.status.title === 'Complete'
                            }
                            className='btn btn-sm btn-dark'
                          >
                            <i className='fa fa-close'></i> Cancel Transaction
                          </button>
                        </div>
                      </div>
                      <div className='btn-group'>
                        <div className='col-md-6 mb-2'>
                          <button
                            disabled={
                              role != 'customer' ||
                              (data.easyp2p
                                ? data.payment != null ||
                                  data.payment != undefined
                                : data.status.title === 'Canceled' ||
                                  data.status.title !== 'Agreed')
                            }
                            onClick={this.handleDepositView}
                            className='btn btn-sm btn-warning'
                          >
                            <i className='fa fa-money'></i> Deposit in Escrow
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div>
                    {cancel ? (
                      <div>
                        <CancelTransaction
                          cancelTransaction={this.props.cancel}
                          history={this.props.history}
                          data={data}
                          handleCancelView={this.handleCancelView}
                        />
                      </div>
                    ) : (
                      <div>
                        <div className='row'>
                          <div
                            className='col-sm-12'
                            style={{ margin: '0 !important' }}
                          >
                            <div
                              className='bg-header bg-callout text-center'
                              style={{ padding: 10 }}
                            >
                              <strong>Transaction Details</strong>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-sm-12'>
                            <div
                              className=''
                              style={{
                                padding: '20px 12px',
                                background: 'white',
                                marginBottom: '10px',
                                borderRadius: 4,
                              }}
                            >
                              {/* <div className="card"> */}
                              <table className='table table-responsive'>
                                <thead>
                                  <tr>
                                    <th>No</th>
                                    <th>Item Description</th>

                                    <th className='text-center'>
                                      {data.category == 'Money Market'
                                        ? 'Currency Amount'
                                        : 'Quantity'}
                                    </th>
                                    <th
                                      className='text-right'
                                      style={{ width: 170 }}
                                    >
                                      {data.category == 'Money Market'
                                        ? 'Conversion Rate'
                                        : 'Unit Price'}
                                    </th>

                                    <th
                                      className='text-right'
                                      style={{ width: 120 }}
                                    >
                                      Total Price
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td className={'text-left'}>
                                      {data.product_name}
                                      <br />
                                      <small className={'text-left'}>
                                        {data.category}
                                      </small>
                                      <br />
                                      <small>{data.desc}</small>
                                    </td>
                                    <td className='text-center'>
                                      {data.category == 'Money Market'
                                        ? toLocaleDisplay(
                                            data.quantity,
                                            data.currency
                                          )
                                        : data.quantity}
                                    </td>

                                    <td className='text-right'>
                                      {data.category == 'Money Market'
                                        ? data.conversion_ratio
                                        : toLocaleDisplay(
                                            data.amount_payed /
                                              data.conversion_ratio,
                                            data.currency
                                          )}
                                    </td>
                                    <td className='text-right'>
                                      {data.category == 'Money Market'
                                        ? toLocaleDisplay(
                                            data.quantity *
                                              data.conversion_ratio,
                                            'NGN'
                                          )
                                        : toLocaleDisplay(
                                            (data.amount_payed *
                                              data.quantity) /
                                              data.conversion_ratio,
                                            data.currency
                                          )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Shipping Cost</strong>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-right'></td>
                                    <td className='text-right'>
                                      {data.category == 'Money Market'
                                        ? toLocaleDisplay(
                                            transactionHelper.calculateShipping(
                                              data,
                                              0
                                            )['customer'],
                                            'NGN'
                                          )
                                        : toLocaleDisplay(
                                            transactionHelper.calculateShipping(
                                              data,
                                              0
                                            )['customer'] /
                                              data.conversion_ratio,
                                            data.currency
                                          )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-right'>
                                      Sub Total
                                      <br />{' '}
                                      <small>
                                        Order No: {formatToSixDigits(data.id)}
                                      </small>
                                    </td>
                                    <td className='text-right'>
                                      {toLocaleDisplay(
                                        subTotal(data),
                                        data.category == 'Money Market'
                                          ? 'NGN'
                                          : data.currency
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-right'>
                                      <strong>Escrow Fee</strong>
                                    </td>
                                    <td className='text-right'>
                                      {data.category === 'Money Market'
                                        ? toLocaleDisplay(
                                            parseFloat(
                                              paymentInfo.buyerEscrowFees / 100
                                            ),
                                            'NGN'
                                          )
                                        : toLocaleDisplay(
                                            parseFloat(
                                              paymentInfo.buyerEscrowFees /
                                                data.conversion_ratio /
                                                100
                                            ),
                                            data.currency
                                          )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-right'>
                                      <strong>Payment Switch Fees</strong>
                                    </td>
                                    <td className='text-right'>
                                      {data.category == 'Money Market'
                                        ? toLocaleDisplay(
                                            data.tx_fee_bearer !== 'seller'
                                              ? paymentInfo.sellerTxFee / 100
                                              : 0,
                                            'NGN'
                                          )
                                        : toLocaleDisplay(
                                            data.tx_fee_bearer !== 'seller'
                                              ? paymentInfo.sellerTxFee /
                                                  data.conversion_ratio /
                                                  100
                                              : 0,
                                            data.currency
                                          )}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-right'>
                                      <strong>Total</strong>
                                    </td>
                                    <td className='text-right'>
                                      {data.category == 'Money Market'
                                        ? toLocaleDisplay(
                                            paymentInfo.totalPayable / 100,
                                            'NGN'
                                          )
                                        : toLocaleDisplay(
                                            paymentInfo.totalPayable /
                                              data.conversion_ratio /
                                              100,
                                            data.currency
                                          )}
                                    </td>
                                  </tr>

                                  {data.extra &&
                                    Object.keys(data.extra).map((item) => (
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                          <strong>
                                            {item === 'current_date'
                                              ? ``
                                              : item === 'validSec'
                                              ? ``
                                              : sentenceCase(
                                                  item.replace(/_/g, ' ')
                                                )}
                                          </strong>
                                        </td>
                                        <td className='text-right'>
                                          {item === 'current_date'
                                            ? ``
                                            : item === 'validSec'
                                            ? ``
                                            : data.extra[item]
                                                .toString()
                                                .toUpperCase()}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-sm-12'>
                            <div
                              className='bg-header text-center'
                              style={{ padding: 10 }}
                            >
                              <div className='row'>
                                <div className='col-sm-3' />
                                <div className='col-md-6'>
                                  <strong>Terms</strong>
                                </div>
                                <div className='col-sm-3 text-right'>
                                  {(!sellerExists || !sellerVerified) &&
                                    data.easyp2p &&
                                    role === 'customer' &&
                                    (this.state.editSeller ? (
                                      <>
                                        <a
                                          onClick={this.cancelEditSeller}
                                          href='#'
                                          style={{ color: '#fff' }}
                                        >
                                          <i className='fa fa-edit'></i> Cancel
                                        </a>
                                        &nbsp; &nbsp;
                                        <a
                                          onClick={this.saveSeller}
                                          href='#'
                                          style={{ color: '#fff' }}
                                        >
                                          <i className='fa fa-edit'></i> Save
                                        </a>
                                      </>
                                    ) : (
                                      <a
                                        onClick={this.editSeller}
                                        href='#'
                                        style={{ color: '#fff' }}
                                      >
                                        <i className='fa fa-edit'></i> Edit
                                        Seller
                                      </a>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {data.sellers &&
                        data.sellers.delivery_terms.refund_option === 0 ? (
                          <div className='row'>
                            <div className='col-sm-12'>
                              <div
                                className='text-center'
                                style={{
                                  padding: 10,
                                  backgroundColor: 'red',
                                  color: '#fff',
                                }}
                              >
                                <strong>
                                  Please note that the seller doesn't support
                                  refunds. Only REPLACEMENTS are supported.
                                </strong>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className='card' style={{ padding: 20 }}>
                          <div className='row'>
                            <div className='col-6 text-right'>
                              <strong>Order ID:</strong>
                            </div>
                            <div className='col-6'>
                              {formatToSixDigits(data.id)}
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-6 text-right'>
                              <strong>Transaction Title:</strong>
                            </div>
                            <div className='col-6 capitalize'>
                              {data.product_name}
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-6 text-right'>
                              <strong>Buyer Email:</strong>
                            </div>
                            <div className='col-6'>{data.buyer_email}</div>
                          </div>
                          <div className='row'>
                            <div className='col-6 text-right'>
                              <strong>Buyer Phone:</strong>
                            </div>
                            <div className='col-6'>
                              {data.buyer_phone.replace('+234', '+')}
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-6 text-right'>
                              <strong>Seller Email:</strong>
                            </div>
                            <div className='col-6'>
                              {this.state.editSeller ? (
                                <>
                                  <input
                                    type='text'
                                    className='form-control'
                                    value={this.state.sellerEmail}
                                    defaultValue={
                                      this.state.savedEmail || data.seller_email
                                    }
                                    onChange={(e) => {
                                      this.setState({
                                        sellerEmail: e.target.value,
                                      });
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  {this.state.savedEmail ||
                                    this.state.sellerEmail ||
                                    (data &&
                                    data.sellers &&
                                    data.sellers.individual !== 1
                                      ? data &&
                                        data.sellers &&
                                        data.sellers.business_email
                                      : data.seller_email)}
                                  {role === 'customer' &&
                                    (sellerExists ? (
                                      <span className='text-bold text-success'>
                                        {' '}
                                        (Verified)
                                      </span>
                                    ) : (
                                      <span className='text-bold text-danger'>
                                        {' '}
                                        (Unverified)
                                      </span>
                                    ))}
                                </>
                              )}
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-6 text-right'>
                              <strong>Seller Phone:</strong>
                            </div>
                            <div className='col-6'>
                              {this.state.editSeller ? (
                                <>
                                  <input
                                    type='text'
                                    className='form-control'
                                    value={this.state.sellerPhone}
                                    defaultValue={
                                      this.state.savedPhone ||
                                      data.seller_phone.replace('+234', '+')
                                    }
                                    onChange={(e) => {
                                      this.setState({
                                        sellerPhone: e.target.value,
                                      });
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  {this.state.savedPhone ||
                                    this.state.sellerPhone ||
                                    (data.sellers &&
                                    data.sellers.users &&
                                    data.sellers.users.phone_number
                                      ? data.sellers.users.phone_number
                                      : data.seller_phone.replace('+234', '+'))}
                                  {role === 'customer' &&
                                    (sellerVerified ? (
                                      <span className='text-bold text-success'>
                                        {' '}
                                        (Verified)
                                      </span>
                                    ) : (
                                      <span className='text-bold text-danger'>
                                        {' '}
                                        (Unverified)
                                      </span>
                                    ))}
                                </>
                              )}
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-6 text-right'>
                              <strong>Escrow Fee to be paid by:</strong>
                            </div>
                            <div className='col-6'>
                              <strong>
                                {displayCustomer(
                                  data.escrow_fee_bearer === 'both'
                                    ? 'Buyer and Seller'
                                    : data.escrow_fee_bearer === 'seller'
                                    ? 'Seller'
                                    : 'Buyer'
                                )}{' '}
                                (
                                {data.category == 'Money Market'
                                  ? toLocaleDisplay(
                                      parseFloat(
                                        data.escrow_fee_bearer === 'seller'
                                          ? paymentInfo.sellerEscrowFees / 100
                                          : paymentInfo.buyerEscrowFees / 100
                                      ),
                                      'NGN'
                                    )
                                  : toLocaleDisplay(
                                      parseFloat(
                                        data.escrow_fee_bearer === 'seller'
                                          ? paymentInfo.sellerEscrowFees /
                                              data.conversion_ratio /
                                              100
                                          : paymentInfo.buyerEscrowFees /
                                              data.conversion_ratio /
                                              100
                                      ),
                                      data.currency
                                    )}
                                )
                              </strong>
                              <br />
                              <small>
                                The buyer is responsible for 100% of the escrow
                                fee in the event the transaction is cancelled or
                                the merchandise is rejected.
                              </small>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-6 text-right'>
                              <strong>Shipping Fee to be paid by:</strong>
                            </div>
                            <div className='col-6'>
                              <strong>
                                {displayCustomer(
                                  !data.shipping_method ||
                                    transactionHelper.calculateShipping(
                                      data,
                                      1,
                                      1
                                    )[role] === 0
                                    ? 'None'
                                    : data.shipping_fee_bearer === 'both'
                                    ? 'Buyer and Seller'
                                    : data.shipping_fee_bearer === 'seller'
                                    ? 'Seller'
                                    : 'Buyer'
                                )}{' '}
                                (
                                {data.category == 'Money Market'
                                  ? toLocaleDisplay(
                                      parseFloat(
                                        data.shipping_fee_bearer === 'seller'
                                          ? paymentInfo.sellerShippingFees / 100
                                          : paymentInfo.buyerShippingFees / 100
                                      ),
                                      'NGN'
                                    )
                                  : toLocaleDisplay(
                                      data.shipping_fee_bearer === 'seller'
                                        ? paymentInfo.sellerShippingFees /
                                            data.conversion_ratio /
                                            100
                                        : paymentInfo.buyerShippingFees /
                                            data.conversion_ratio /
                                            100,
                                      data.currency
                                    )}
                                )
                              </strong>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-6 text-right'>
                              <strong>Payment Switch Fees:</strong>
                            </div>
                            <div className='col-6'>
                              <strong>
                                {data.tx_fee_bearer === 'seller'
                                  ? 'Seller'
                                  : 'Buyer'}{' '}
                                (
                                {data.category === 'Money Market'
                                  ? toLocaleDisplay(
                                      paymentInfo.sellerTxFee / 100,
                                      'NGN'
                                    )
                                  : toLocaleDisplay(
                                      paymentInfo.sellerTxFee /
                                        data.conversion_ratio /
                                        100,
                                      data.currency
                                    )}
                                )
                              </strong>
                              <br />{' '}
                              <small>
                                The buyer is responsible for 100% of the card
                                and transfer fee in the event the transaction is
                                cancelled or the merchandise is rejected.
                              </small>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-6 text-right'>
                              <strong>Payment Method:</strong>
                            </div>
                            <div className='col-6'>
                              {data.gateway_name === 'USSD' ? (
                                <strong>{'Monnify'} </strong>
                              ) : data.gateway_name === 'Paypal' ? (
                                <strong>{'PayPal'}</strong>
                              ) : (
                                <strong>{'Paystack'}</strong>
                              )}
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-sm-4'>
                  <div className=''>
                    <h4>
                      <strong>Status</strong>
                    </h4>
                    <p>
                      <small>Select any status for help.</small>
                    </p>
                    <div>
                      <Link
                        to='/app/p2p/help'
                        className={`black-text status-link ${
                          data.status.title === 'Awaiting Agreement'
                            ? 'text-black'
                            : ''
                        }`}
                      >
                        1. BUYER AND SELLER AGREE TO TERMS{' '}
                        {isStatusDone('Awaiting Agreement', data.status.title)}{' '}
                        {data.status.title === 'Awaiting Agreement'
                          ? '(Pending)'
                          : ''}
                      </Link>
                    </div>

                    <div>
                      <Link
                        to='/app/p2p/help'
                        className={`status-link ${
                          (data.status.title == 'Agreed' ||
                            data.status.title == 'Pending') &&
                          data.payment_number === 0
                            ? 'text-black'
                            : ''
                        }`}
                      >
                        2. BUYER MAKES PAYMENT TO ESCROW{' '}
                        {isPaymentDone(
                          'Agreed',
                          data.status.title,
                          data.payment_number
                        )}{' '}
                        {(data.status.title == 'Agreed' ||
                          data.status.title == 'Pending') &&
                        data.payment_number === 0
                          ? '(Pending)'
                          : ''}
                      </Link>
                    </div>

                    <div>
                      <Link
                        to='/app/p2p/help'
                        className={`status-link ${
                          (!data.delivery_man &&
                            data.status.title === 'Pending' &&
                            data.payment_number > 0) ||
                          data.status.title === 'Pending II'
                            ? 'text-black'
                            : ''
                        }`}
                      >
                        3. SELLER SHIPS SERVICE TO BUYER{' '}
                        {isStatusDone(
                          'Pending',
                          data.status.title,
                          data.delivery_man
                        )}{' '}
                        {(!data.delivery_man &&
                          data.status.title === 'Pending' &&
                          data.payment_number > 0) ||
                        data.status.title === 'Pending II'
                          ? '(Pending)'
                          : ''}
                      </Link>
                    </div>

                    {/*<div><Link to="/app/p2p/help" className={`status-link ${(data.delivery_man && data.status.title === 'Pending') || data.status.title === 'Rejected'|| data.status.title === 'Pending Buyer Response' ? 'text-black' : ''}`}>4. BUYER INSPECTS SERVICE {isStatusDone('Pending Buyer Response', data.status.title, 'rejected')} {(data.delivery_man && data.status.title === 'Pending') || data.status.title === 'Rejected' ||  data.status.title === 'Pending Buyer Response' ? '(Pending)': ''}</Link></div>*/}

                    <div>
                      <Link
                        to='/app/p2p/help'
                        className={`status-link ${
                          (data.delivery_man &&
                            data.status.title === 'Pending') ||
                          data.status.title === 'Pending Buyer Response'
                            ? 'text-black'
                            : ''
                        }`}
                      >
                        4. BUYER INSPECTS SERVICE{' '}
                        {isStatusDone(
                          'Pending Buyer Response',
                          data.status.title,
                          'rejected'
                        )}{' '}
                        {(data.delivery_man &&
                          data.status.title === 'Pending') ||
                        data.status.title === 'Pending Buyer Response'
                          ? '(Pending)'
                          : ''}
                      </Link>
                    </div>

                    <div>
                      <Link
                        to='/app/p2p/help'
                        className={`status-link ${
                          data.status.title === 'Accepted' && data.delivery_man
                            ? 'text-black'
                            : ''
                        }`}
                      >
                        5. ATARAPAY PAYS SELLER{' '}
                        {isStatusDone(
                          'Complete',
                          data.status.title,
                          data.delivery_man
                        )}{' '}
                        {data.status.title === 'Accepted' && data.delivery_man
                          ? '(Pending)'
                          : ''}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default StageThree;
