import { termConstants } from '../constants';

const term = (state = {}, action) => {
    switch (action.type) {
        case termConstants.term.CREATE_TERM:
            return {loading: true};
        case termConstants.term.CREATE_TERM_SUCCESS:
            return {...state, term: {...action.term}, success: true, loading: false};
        case termConstants.term.CREATE_TERM_FAILURE:
            return {...state, success: false};

        case termConstants.term.EDIT_TERM:
            return {loading: true};
        case termConstants.term.EDIT_TERM_SUCCESS:
            return {...state, term: {...state.term, ...action.term}, success: true};
        case termConstants.term.EDIT_TERM_FAILURE:
            return {...state, success: false};

        case termConstants.term.REQUEST_TERM:
            return {loading: true};
        case termConstants.term.REQUEST_TERM_SUCCESS:
            return {...state, term: {...action.term}, loading: false};
        case termConstants.term.REQUEST_TERM_FAILURE:
            return {error: action.error, loading: false};

        case termConstants.term.CREATE_ADDRESS:
            return {loading: true};
        case termConstants.term.CREATE_ADDRESS_SUCCESS:
            return {...state, term: {...action.term}, loading: false};
        case termConstants.term.CREATE_ADDRESS_FAILURE:
            return {error: action.error, loading: false};

        case termConstants.term.REQUEST_ADDRESSES:
            return {loading: true};
        case termConstants.term.REQUEST_ADDRESSES_SUCCESS:
            return {...state, addresses: [...action.addresses], loading: false};
        case termConstants.term.REQUEST_ADDRESSES_FAILURE:
            return {error: action.error, loading: false};

        case termConstants.term.DELETE_ADDRESS:
            return {...state, deleting: true, success: false};
        case termConstants.term.DELETE_ADDRESS_SUCCESS:
            return {...state, term: {...action.term}, deleting: false, success: true};
        case termConstants.term.DELETE_ADDRESS_FAILURE:
            return {error: action.error, deleting: false, success: false};

        case termConstants.term.DEFAULT_ADDRESS:
            return {...state, updating: true, success: false};
        case termConstants.term.DEFAULT_ADDRESS_SUCCESS:
            return {...state, term: {...action.term}, updating: false, success: true};
        case termConstants.term.DEFAULT_ADDRESS_FAILURE:
            return {error: action.error, updating: false, success: false};

        case termConstants.term.REQUEST_SERVICE_PROVIDERS:
            return {loading: true};
        case termConstants.term.REQUEST_SERVICE_PROVIDERS_SUCCESS:
            return {...state, service_providers: [...action.service_providers], loading: false};
        case termConstants.term.REQUEST_SERVICE_PROVIDERS_FAILURE:
            return {error: action.error, loading: false};

        default:
            return state
    }
}

export default term;
