import React, {Component} from 'react';
import { WEBSITE_URL } from '../constants';

class EasyP2PPayment extends Component {

    componentDidMount() {
        this.timer = setTimeout(() => {
            window.location.href = `${WEBSITE_URL}/completeEasyP2P`;
        }, 3000)
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {

        let buyer_email = this.props.match.params.buyer_email || "your email";

        return (

            <div style={{height: '100vh', backgroundColor: '#f7fcfc'}}>
                <nav className="navbar navbar-expand-lg fixed-top navbar-inverse">
                    <a className="navbar-brand" href="http://www.atarapay.com">
                        <img src="/dist/img/logo-header.png" alt="" className="logo-img img img-responsive"/>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
                            aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </nav>
                <section>
                        <div className="row row-margin top-margin-60">
                            <div className="col-sm-3 col-md-3"/>
                            <div className="col-sm-8 col-md-6 col-12">
                                <div className="card card-margin text-center">
                                    <p style={{ fontSize: 18, marginBottom: 28 }}>
                                        We have sent you details of this transaction to {buyer_email}
                                        <br/>
                                        Your will be redirected shortly.
                                    </p>
                                </div>

                                <div className="col-sm-3 col-md-3"/>

                            </div>
                        </div>
                    {/* </div> */}
                </section>


            </div>
        );
    }
}

export default EasyP2PPayment;