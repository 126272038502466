import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import qs from "query-string";

import { orderActions } from "../actions";

class FailedPayment extends Component {
  componentDidMount() {
    const { location, history } = this.props;
    let params = qs.parse(location.search);

    if (params.token) {
      this.props.cancelPayment({ token: params.token }, history, "PayPal");
    }
  }

  render() {
    const { alert } = this.props;
    return (
      <div className='failed-payment-container'>
        <nav class='navbar navbar-expand-lg fixed-top navbar-inverse'>
          <a class='navbar-brand' href='http://www.atarapay.com'>
            <img
              src='/dist/img/logo-header.png'
              alt=''
              className='logo-img img img-responsive'
            />
          </a>
          <button
            class='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarText'
            aria-controls='navbarText'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span class='navbar-toggler-icon'></span>
          </button>
        </nav>
        <section>
          <div class='row row-margin top-margin-60'>
            <div className='col-sm-3 col-md-3'></div>
            <div className='col-sm-8 col-md-6 col-12'>
              {alert && alert.message && alert.type === "alert-danger" ? (
                <div className='card card-margin text-center'>
                  <p className='top-text'>{alert.message}</p>
                </div>
              ) : (
                <div className='card card-margin text-center'>
                  <p className='top-text'>Your payment has been cancelled</p>
                  <p>You will be redirected in few seconds...</p>
                </div>
              )}
              <div class='col-sm-3 col-md-3'></div>
            </div>
          </div>
          {/* </div> */}
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, result } = state.orders;
  const alert = state.alert;

  return {
    loading,
    result,
    alert,
  };
};
const mapDispatchToProps = (dispatch) => ({
  cancelPayment: (data, history, paymentType) =>
    dispatch(orderActions.order.cancelPayment(data, history, paymentType)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FailedPayment)
);
