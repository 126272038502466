export const transaction = {
    EDIT_TRANSACTION: 'EDIT_TRANSACTION',
    CANCEL_EDIT_TRANSACTION: 'CANCEL_EDIT_TRANSACTION',

    CREATE_TRANSACTION_REQUEST: 'CREATE_TRANSACTION_REQUEST',
    CREATE_TRANSACTION_REQUEST_SUCCESS: 'CREATE_TRANSACTION_REQUEST_SUCCESS',
    CREATE_TRANSACTION_REQUEST_FAILURE: 'CREATE_TRANSACTION_REQUEST_FAILURE',

    GET_TRANSACTION: 'GET_TRANSACTION',
    GET_TRANSACTION_SUCCESS: 'GET_TRANSACTION_SUCCESS',
    GET_TRANSACTION_FAILURE: 'GET_TRANSACTION_FAILURE',

    UPDATE_TRANSACTION: 'UPDATE_TRANSACTION',
    UPDATE_TRANSACTION_SUCCESS: 'UPDATE_TRANSACTION_SUCCESS',
    UPDATE_TRANSACTION_FAILURE: 'UPDATE_TRANSACTION_FAILURE',

    SUBMIT_RESPONSE: 'SUBMIT_RESPONSE',
    SUBMIT_RESPONSE_SUCCESS: 'SUBMIT_RESPONSE_SUCCESS',
    SUBMIT_RESPONSE_FAILURE: 'SUBMIT_RESPONSE_FAILURE',

    CANCEL_TRANSACTION: 'CANCEL_TRANSACTION',
    CANCEL_TRANSACTION_SUCCESS: 'CANCEL_TRANSACTION_SUCCESS',
    CANCEL_TRANSACTION_FAILURE: 'CANCEL_TRANSACTION_FAILURE',

    MAKE_PAYMENT: 'MAKE_PAYMENT',
    MAKE_PAYMENT_SUCCESS: 'MAKE_PAYMENT_SUCCESS',
    MAKE_PAYMENT_FAILURE: 'MAKE_PAYMENT_FAILURE',

}