import React from 'react';

const Pending= ()=>{
   return(
    <div class="content-wrapper" style={{minHeight: 137}}>
<div style={{textAlign:"center", fontSize: '20px',paddingTop:'14%'}}> <p><b>Your registration is pending approval.</b></p> Expect our response within 24 hours from time of submission. Thank you</div>
   </div>
   );
}

export default Pending;