import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Courier from '../views/addCourier';
import { logisticActions } from '../actions';
// import { payoutActions } from '../actions';


const mapStateToProps = (state) =>{

    const { loading,couriers,adding, deleting, success, updating} = state.users;  
    const alert  = state.alert;
    
    return {
        alert,
        loading,
        adding,
        deleting,
        updating,
        couriers,
        success
    };
     
     
 }
 const mapDispatchToProps = (dispatch) => ({
     dispatch,
    add:(data,history)=>dispatch(logisticActions.delivery.createCourier(data,history)),  
    update:(data,id,history)=>dispatch(logisticActions.delivery.editCourier(data,id,history)),  
    delete:(id,history)=>dispatch(logisticActions.delivery.deleteCourier(id,history)),  
}
)
 

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Courier));