export const report = {
  GET_PAYOUT_REPORT_REQUEST: "GET_PAYOUT_REPORT",
  GET_PAYOUT_REPORT_SUCCESS: "GET_PAYOUT_REPORT_SUCCESS",
  GET_PAYOUT_REPORT_FAILURE: "GET_PAYOUT_REPORT_FAILURE",

  GET_MARKETPLACE_SELLERS_SUCCESS: " GET_MARKETPLACE_SELLERS_SUCCESS",

  GENERATE_REPORT_REQUEST: "GENERATE_REPORT_REQUEST",
  GENERATE_REPORT_SUCCESS: "GENERATE_REPORT_SUCCESS",
  GENERATE_REPORT_FAILURE: "GENERATE_REPORT_FAILURE",
};
