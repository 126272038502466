import React, {Component} from 'react';
import {APP_URL, USER_ROLE_SELLER,} from '../constants/index';
import moment from 'moment';

class ShareableLinks extends Component {

  render() {

    const {user} = this.props;

    let rcb = null;
    if (user) {
      rcb = user.rcb;
    }

    let links = [];

    const baseUrl = `${APP_URL}#/`;


    if (user && user.role?.name === USER_ROLE_SELLER) {
      links.push(
        {
          // link: `${baseUrl}easyp2p/seller/${user.email}/${user.phone_number}`,
          link: `${baseUrl}u/${user.short_code}`,
          description: <div>
            Share this link to your buyers who wish to make payment directly into escrow for your goods or
            service without having to register or signup on AtaraPay.
          </div>,
        })
    }


    if (user) {
      links.push(
        {
          link: `${baseUrl}refer/${user.referral_code}`,
          description: <>
            <div>
              Share this link to your {user.role?.name === USER_ROLE_SELLER ? "buyers" : "sellers"} or the referral
              code <strong>{user.referral_code}</strong> to enable you to enjoy discounts off escrow fees on your
              AtaraPay transactions when they sign up.
            </div>
            {
              rcb &&
              <div className="mt-3">
                If you share your referral code to <strong>{rcb.required_registrations}</strong> new users and they
                complete registration, you will receive a promo code that provides
                you <strong>{rcb.promo_percent}%</strong> discount off escrow fee on your
                next <strong>{rcb.promo_usage_freq}</strong> transaction(s). Valid
                until <strong>{moment(rcb.expiration_date).format('ll')}</strong>
              </div>
            }
          </>,
        }
      );
    }

    return (
      <div className="content-wrapper" style={{minHeight: 137}}>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Shareable Links</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card p-0">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                        <tr>

                          <th width="1%">
                            SN
                          </th>
                          <th>Link Name</th>
                          <th width={"40%"}>
                            Description
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          links.map((link, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td>
                                <code><a href={link.link}
                                         target="_blank">{link.link}</a></code>
                              </td>
                              <td>
                                {link.description}
                              </td>
                            </tr>
                          ))
                        }

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}


export default ShareableLinks;
