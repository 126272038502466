import React , {Component} from 'react';

import swal from '@sweetalert/with-react';
import { alertActions,userActions } from '../actions';
import Modal from 'react-responsive-modal';
import Tour from 'reactour';

class Api extends Component{
   
state={
        confirm:false,
        password:"",
        startTour:false,
    }
    
      onCloseModal = (name) => {
        this.setState({ [name]: false });
        this.setState({password:""});
      };
      onOpenModal = (name, value = null) => {
        this.setState({ [name]: true});
        this.setState({keyId: value});
       
      };
      componentDidMount = () => {

        if (localStorage.getItem("trust_user_type") === 'seller'||localStorage.getItem("trust_user_type") === 'marketplace'){
            if(this.props.user){
                this.setState({'startTour':this.props.user.api_tour_done?false:true});
            }
        }
        }
    closeTour =() =>{
        this.setState({startTour:false});
    }
    finishTour=()=>{
       this.props.dispatch(userActions.user.updateTour(localStorage.getItem("trust_user_type"),{
            firstname:this.props.user.firstname,
            lastname:this.props.user.lastname,
            phone_number:this.props.user.phone_number,
            email:this.props.user.email,
            api_tour_done:1,
        },this.props.history,'/app/dashboard'));
    }

      handleConfirm = (e) => {
          e.preventDefault();
          const data={"password":this.state.password};
          
          this.props.gen("seller",data,this.props.history);
          this.onCloseModal("confirm");
      }
      handleChange=(e)=>{
          const {value} = e.target;
          
          this.setState({password:value});
      }
    genKey=()=>{
        this.onOpenModal("confirm");
    }

    handleLive = (type) => {
        const { keyId } = this.state;
        this.props.goLive(localStorage.getItem("trust_user_type"), { key_id: keyId }, type, this.props.history);
    }

    componentWillReceiveProps(props){
        // console.log("props", props);
        if(props.alert && props.alert.message && props.alert.type==="alert-danger"){
            swal("Error",props.alert.message,"error").then(() => {
                props.dispatch(alertActions.alert.clear());
              });;
        }
        if(props.alert && props.alert.message && props.alert.type==="alert-success"){
            if (props.alert.message === "key generated"){
                swal("Success", "Don't forget to ensure the following are done BEFORE commencing your tests or sales;\n\n1. Mandatory: Create your bank account.At the left pane, go to Payout-- > Payout Details-- > Add Bank Account.Choose Own Account in the form and tick set as default.\n2. Optional: Set delivery terms.At the left pane, go to Delivery Terms and set it or leave as is.\n3. Optional: Set Frequency of Payment.Click on the person icon at the top right and Settings.\n4.	Optional: Set Card Fee.Click on the person icon at the top right and Settings.", "success").then(() => {
                    props.dispatch(alertActions.alert.clear());
                });
            }else{
                 swal("Success",props.alert.message,"success").then(() => {
                props.dispatch(alertActions.alert.clear());
              });
            }
           

             
        }
    }
    
    

    render(){
        const { users }= this.props;
        const steps = [
          {
            selector: '.active',
            content:({ goTo, inDOM }) => (<p>Click the <b>API</b> tab, navigate to <b>API Keys</b> and then click on the <b>Generate API Key</b> button.</p>),            
            position:'top',
            style:{
                maxWidth:'35%',
                borderRadius:'5px',
                backgroundColor:"#0e2433",
                color:'#ffffff',
            },
          },
          {
            selector: '.first-step',
            content:({ goTo, inDOM }) => (                 
                <p >Here’s where you generate the API keys that you require for integration. Click on the <b>Generate API Keys</b> button to enable you get your keys.</p>
                ),            
            position:'bottom',
            style:{
                maxWidth:'45%',
                borderRadius:'5px',
                backgroundColor:"#0e2433",
                color:'#ffffff',
            },
          },
        ]
        return(
            <div class="content-wrapper" style={{minHeight: 114}}>
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                                <h1 class="m-0 text-dark">{users && users.key ? "Recreate API Key" : "Create API Key"}</h1>
                                <div class="attop">
                                    <p>On this page, you are able to generate the API keys required for your developer to test and go-live with your integration of AtaraPay to your eCommerce website. You can generate API keys by clicking on the Generate API Keys button below. However, to commence integration to your website, first create a test seller account on http://staging.atarapay.com. On the test account, you can generate the API Keys to test your integration.</p>
                                    <p>After successful testing, create your live seller account on https://app.atarapay.com and generate the live API keys. Once your sanity tests are satisfactory and wish to go live, click on the Generate API Keys button again to regenerate new keys which you will use to update your app.</p>
                                    <p>Note that once you generate new live API keys, your sanity test data will disappear and your dashboard will begin to reflect brand new transactions created from your live eCommerce site. Also note that payments will be charged from customer’s live bank account</p>
                                </div>
                        </div>
                    </div>
                </div>
            </div> <br/>
            <section class="content">
            {(localStorage.getItem("trust_user_type") === 'seller'||localStorage.getItem("trust_user_type") === 'marketplace')?
            <Tour steps={steps} isOpen={this.state.startTour} onRequestClose={this.closeTour} disableKeyboardNavigation={['esc', 'right', 'left']} 
            lastStepNextButton={<button className="btn btn-danger" onClick={this.finishTour}>Got It!</button>} showNumber={false} /> :null}

                <div class="container-fluid" >
                    <div class="card">
                        <div class="row">
                            <div class="col-sm-1 col-md-2"></div>
                            <div class="col-sm-10 col-md-8 text-center"><br/><br/>
                                <br/>
                                <div class="form-group"><label>Public Key</label> <input type="text" value={users&&users.key&&users.key.public_key} readonly="readonly" class="form-control text-center"/></div>
                                <br/>
                                <div class="form-group "><label>Private Key</label> <input type="text" value={users&&users.key&&users.key.private_key} readonly="readonly" class="form-control text-center"/></div>
                                    <br /> <button className="btn btn-primary first-step" onClick={() => { this.genKey() }}>{users && users.key ? "Regenerate API Keys" : "Generate API Keys"}</button> 
                                     {/* <button class="btn btn-danger" onClick={() => this.onOpenModal("live", users.key.id)}>{users && users.key && users.key.is_live ? "Demo Mode" : "Go Live"}</button> <br/><br/> */}
                            </div>
                            <div class="col-sm-1 col-md-2"></div>
                        </div>
                    </div>
                </div>
            </section>
                <Modal open={this.state.confirm} onClose={() => { this.props.history.push("/") }} center>
                    <form data-type="seller" onSubmit={this.handleConfirm}>
                        <div class="row">
                            <div class="col-sm-12 col-md-12"><br /><br />
                                {/* {alert&&alert.message&&<div   class="alert buyer-alert alert-danger "><a  
                        aria-label="close" class="close alert-close">×</a> <span   class="alert-message-content">{alert.message}</span></div>} */}
                                <div class="form-group"><label >Confirm Password</label>
                                    <input type="password" onChange={this.handleChange} value={this.state.password} class="form-control" required /></div> <br />
                                <button type="submit" class="btn btn-primary">Confirm</button> <br /><br
                                />
                            </div>
                        </div>
                    </form>
                </Modal>
                <Modal open={this.state.live} onClose={() => this.onCloseModal("live")} center>

                    <div class="modal-header">
                        <h4 class="modal-title">{users && users.key && users.key.is_live ? "Demo Mode" : "Go Live"}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row form-group">
                            <div class="col-sm-12">
                                <p >{users && users.key && users.key.is_live ? "Enabling demo mode will put your plugin back to test, do you still want to continue?" : "Going live will wipe all demo transactions and records on your account, do you still want to continue?"}</p>
                            </div>
                        </div>
                    </div>
                    {users && users.key && users.key.is_live ?
                    <div class="modal-footer">
                    <button type="button" data-dismiss="modal"
                        class="btn btn-primary">Cancel</button>
                        &nbsp;&nbsp;
            <button onClick={() => this.handleLive(0)} disabled={users.goingLive} type="button" class="btn btn-danger">{users.goingLive ? 'Enabling Demo ...' : 'Switch to Demo'}</button></div>
                        : <div class="modal-footer"> <button type="button" data-dismiss="modal"
                            class="btn btn-primary">Cancel</button>
                        &nbsp;&nbsp;
            <button onClick={() => this.handleLive(1)} disabled={users.goingLive} type="button" class="btn btn-danger">{users.goingLive ? 'Going Live ...' : 'Go Live'}</button></div>}

                </Modal>
        </div>
        
        )
    }
}

export default Api;