import React, { Component } from "react";
import ReactModal from "react-responsive-modal";
import transactionHelpers from "../helpers/transaction";
import { toLocaleDisplay } from "../helpers/transaction";
import PhoneInput, { formatPhoneNumber } from "react-phone-number-input";
import { user } from "../actions/user";
// const ModalStyles = {
//     content : {
//       top                   : 'auto',
//       left                  : 'auto',
//       right                 : 'auto',
//       bottom                : 'auto',
//       marginRight           : '-50%',
//       transform             : 'translate(-50%, -50%)',
//       width                 : '70%'
//     }
//   };

let promoInput = React.createRef();

const USSDModalComponent = (props) => (

  <ReactModal open={props.open} onClose={props.onClose}>
    <div class="">
      <div class="row">
        <div class="col-10">
          <img
            src="/dist/img/logo.3fc64c3.png"
            alt="AtaraPay Logo"
            style={{ opacity: 1, width: "200px", paddingLeft: 15 }}
          />
        </div>
      </div>
      <br></br>
      <div className="container">
        <p>
          Your AtaraPay email is{" "}
          <strong>{props.user && props.user.email}</strong>
        </p>
        <p>
          Your AtaraPay phone number is{" "}
          <strong>{props.user && props.user.phone_number}</strong>
        </p>
        <p>Alternative Recipient Phone Number </p>
        <PhoneInput
          defaultCountry="NG"
          placeholder="080X XXX XXXX"
          value={
            (props.data && formatPhoneNumber(props.seller_phone, "National")) ||
            formatPhoneNumber(props.seller_phone, "International")
          }
          inputClassName={"text-alt"}
          onChange={(value) => props.handlePhone(value)}
        />
        <p>
          <small>
            [Please enter this number if you will not be able to receive the
            item(s) <br />
            in person. Ensure to update the international code accordingly]
          </small>
        </p>
        <p>
          You will be charged escrow fee of{" "}
          {toLocaleDisplay(transactionHelpers.escrowFees(props), transactionHelpers.transactionCurrency(props.data))}{' '}
          {props.data &&
          props.data.tx_fee_bearer &&
          props.data.tx_fee_bearer !== "seller"
            ? 'plus Switch fee of ' +
            toLocaleDisplay(transactionHelpers.cardFees(props), transactionHelpers.transactionCurrency(props.data))
            : ' '}{' '}
          and will be depositing{" "}
          {toLocaleDisplay(transactionHelpers.productAmount(props), transactionHelpers.transactionCurrency(props.data))}{" "} plus{' '}
          {toLocaleDisplay(transactionHelpers.escrowFees(props), transactionHelpers.transactionCurrency(props.data))}{' '}
          {props.data &&
          props.data.tx_fee_bearer &&
          props.data.tx_fee_bearer !== "seller"
            ? "plus " +
            toLocaleDisplay(transactionHelpers.cardFees(props), transactionHelpers.transactionCurrency(props.data))
            : " "}{" "}
          into escrow today. When you accept the item, we will credit the
          merchant with{" "}
          {toLocaleDisplay(transactionHelpers.payoutAmount(props), transactionHelpers.transactionCurrency(props.data))}
          . If you reject the item,{" "}

          {props.terms && props.terms.refund_option === 0
            ? "you shall get full replacement for them."
            : "we will refund you " +
            toLocaleDisplay(
              transactionHelpers.refundableAmount(props),
              transactionHelpers.transactionCurrency(props.data)
            ) +
              " within 24 hours."}
        </p>
        <blockquote>
          Seller's Delivery Terms:
          {props.terms && props.terms.refund_option === 0 ? (
            <ul>
              <li className="text-danger">
                Seller supports full replacements. No refund allowed
              </li>
            </ul>
          ) : (
            <div>
              <p>
                A logistics fee of{" "}
                <span class={"text-danger"}>
                  <b>
                    {props.data.sellers &&
                      props.terms &&
                      toLocaleDisplay(
                        parseFloat(
                          (props.terms.logistics_fee / 100) *
                            (props.data.amount_payed * props.data.quantity +
                              transactionHelpers.calculateShipping(
                                props.data,
                                props.data,
                                1,
                                0
                              )[props.role]) >
                            5000
                            ? 5000
                            : (props.terms.logistics_fee / 100) *
                                (props.data.amount_payed * props.data.quantity +
                                  transactionHelpers.calculateShipping(
                                    props.data,
                                    1,
                                    0
                                  )[props.role])
                        )
                      )}
                  </b>
                </span>{" "}
                will be charged from your escrow deposit, if you;
              </p>
              <ul>
                {/* <li>Buyer will pay shipping fee of NGN {props.data.sellers && props.data.sellers.delivery_terms && parseFloat((props.data.sellers.delivery_terms.logistics_fee / 100) * props.data.shipping_cost).toFixed(2)}</li> */}
                <li>
                  Cancel after{" "}
                  {(props.data.sellers &&
                    props.terms &&
                    props.terms.SLA === "0.5") ||
                  (props.data.sellers && props.terms && props.terms.SLA < 1.0)
                    ? props.data.sellers &&
                      props.terms &&
                      props.terms.SLA * 60 + " minutes"
                    : props.data.sellers &&
                      props.terms &&
                      props.terms.SLA + " hours"}
                </li>
                {/* <li>Reject at no fault of Seller</li> */}
                <li>Refund will be made less applicable fees</li>
              </ul>
            </div>
          )}
        </blockquote>

        <p>
          After making successful payment, please complete your AtaraPay
          registration from the link sent to your email address.
        </p>
        <p>
          Please note that maximum delivery days of the item(s) is{" "}
          {Number(props.data.max_delivery_days)} days after which if the order
          is not accepted or rejected, your funds will be refunded less escrow
          fees.
        </p>
        <p>
          For details on our escrow service click{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.atarapay.com/why_escrow"
          >
            here
          </a>
        </p>
        <p className={"text-danger"}>
          <small>
            Your funds are insured by a NAICOM-licensed insurance company.
          </small>
        </p>
        {/* <div class="card summary">
                    <small>Summary of the Transaction</small><br/>
                    <div class="row">
                        <div class="col-6 text-left">
                            Product Amount:
                        </div>
                        <div class="col-6 text-left">
                            {toLocaleDisplay((props.data.amount_payed))}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-left">
                        Quantity:
                        </div>
                        <div class="col-6 text-left">
                            {(props.data.quantity)}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-left">
                            Additional Services:
                        </div>
                        <div class="col-6 text-left">
                            <strong>{toLocaleDisplay((props.data.additional_service_amount))}</strong>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-left">
                            Escrow Fee:
                        </div>
                        <div class="col-6 text-left">
                            <strong>{toLocaleDisplay(transactionHelpers.calculatePayableEscrow(props.data))}</strong><br/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-left">
                            Shipping Fee:
                        </div>
                        <div class="col-6 text-left">
                            <strong>{toLocaleDisplay(transactionHelpers.calculatePayableShipping(props.data))}</strong>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-left">
                            VAT (5%):
                        </div>
                        <div class="col-6 text-left">
                            <strong>{toLocaleDisplay(parseFloat( transactionHelpers.calculateTransactionPayable(props.data) * (0.05/1.05) ))}</strong>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-left">
                           Total Order Amount:
                        </div>
                        <div class="col-6 text-left">
                            <strong>{toLocaleDisplay(parseFloat(transactionHelpers.calculateTransactionPayable(props.data)))}</strong>
                        </div>
                    </div>
                </div> */}
        {/* <p>For details on AtaraPay's Escrow Service, <a onClick={props.openInfo} class="text-info">click here.</a></p>
                <small>By Clicking pay, you have accepted all the terms of the transaction and ready to proceed.</small> */}
      </div>
      <div className="clearfix">
        <div className='form-inline float-left'>
          <div className='form-group'>
            <input
              type='text'
              ref={promoInput}
              disabled={
                (props.promo &&
                  props.promo.promo_code &&
                  promoInput &&
                  promoInput.current &&
                  promoInput.current.value) || props.promoID
              }
              name='promo'
              id='promo_code'
              placeholder='Promo code'
              className='form-control col-sm-3 col-md-6 ml-3 mb-2'
              style={{width: '125px', fontSize: '11px'}}
            />

            {(props.promo && props.promo.promo_code
              && promoInput && promoInput.current
              && promoInput.current.value) || props.promoID ? (
              <button
                className='btn btn-link col-md-3 col-sm-3 ml-1 mb-2 text-danger'
                style={{paddingLeft: 5, textDecoration: 'none'}}
                onClick={() => props.clearCoupon(promoInput)}
              >
                {props.showCouponLoader ? 'loading....' : 'Clear'}
              </button>
            ) : (
              <button
                type='button'
                className='btn btn-link col-md-3 col-sm-3 ml-1 mb-2'
                style={{
                  paddingLeft: 5,
                  color: '#007bff',
                  textDecoration: 'none',
                }}
                onClick={() => props.applyCoupon(promoInput)}
              >
                {props.showCouponLoader ? 'loading....' : 'Apply'}
              </button>
            )}
          </div>
        </div>
        <div className="float-right">
          <button onClick={props.onClose} class="btn btn-md btn-danger mb-2">
            {" "}
            Cancel
          </button>
          &nbsp;&nbsp;
          <button onClick={props.ussdpay} class="btn btn-md btn-info mb-2">
            {" "}
            <i class="fa fa-coins"></i>Yes, Desposit{" "}
            {props.data &&
              toLocaleDisplay(
                transactionHelpers.grandTotal(props, props.data),
                transactionHelpers.transactionCurrency(props.data)
              )}{' '}
            {transactionHelpers.transactionCurrency(props.data) === 'USD'
              ? `(${
                props.data && toLocaleDisplay(transactionHelpers.grandTotalUSD(props, props.data), 'NGN')
              })`
              : ''}
          </button>
        </div>
      </div>
    </div>
  </ReactModal>
);
export default USSDModalComponent;
