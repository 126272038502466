import { BASE_URL, LOCALSTORAGE_TOKEN_KEY } from '../constants';
import axios from 'axios';


const create = (data, type) => {
    console.log('user', localStorage.getItem(LOCALSTORAGE_TOKEN_KEY));
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL + type}/payout/create`,
        data: data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })

}
const createSp = (data, type) => {
    console.log('user', localStorage.getItem(LOCALSTORAGE_TOKEN_KEY));
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL + type}/payout/createSp`,
        data: data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })
}

const createPi = (data, type) => {
    console.log('user', localStorage.getItem(LOCALSTORAGE_TOKEN_KEY));
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL + type}/identification/create`,
        data: data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
        }
    })
}

const managePhotoId = (type, id, action) => {
    console.log(`${BASE_URL + type}/payouts`);
    return axios({
        method: 'get',
        mode: 'no-cors',
        url: `${BASE_URL + type}/admin/managePhotoId/${id}/${action}`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })
}
const publicManagePhotoId = (id, action) => {
    return axios({
        method: 'get',
        mode: 'no-cors',
        url: `${BASE_URL}admin/managePhotoId/${id}/${action}`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })
}
const all = (type) => {
    console.log(`${BASE_URL + type}/payouts`);
    return axios({
        method: 'get',
        mode: 'no-cors',
        url: `${BASE_URL + type}/payouts`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })
}

const get = (type) => {
    return axios({
        method: 'get',
        mode: 'no-cors',
        url: `${BASE_URL + type}/payout/view`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })
}

const edit = (data, type) => {
    return axios({
        method: 'put',
        mode: 'no-cors',
        url: `${BASE_URL + type}/payout/edit/${data.id}`,
        data: {type: data.type},
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })

}

const setDefault = (id, type) => {
    return axios({
        method: 'put',
        mode: 'no-cors',
        url: `${BASE_URL + type}/payout/setDefault/${id}`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })

}

const delDetail = (id, type) => {
    return axios({
        method: 'delete',
        mode: 'no-cors',
        url: `${BASE_URL + type}/payout/delete/${id}`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })
}
const delSp = (id, type) => {
    return axios({
        method: 'delete',
        mode: 'no-cors',
        url: `${BASE_URL + type}/payout/deleteSp/${id}`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })
}

const getBanks = (type) => {
    return axios({
        method: "get",
        mode: "no-cors",
        url: `${BASE_URL}${type}/banks`,
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            "Content-Type": "application/json",
        },
    });
};
export const payout = {
    create,
    all,
    get,
    edit,
    setDefault,
    delDetail,
    delSp,
    createSp,
    createPi,
    managePhotoId,
    publicManagePhotoId,
    getBanks
};


