import React, { Component } from 'react';
import termSchema from '../validation/terms';
import { termActions, alertActions, userActions } from '../actions'
import { Link } from 'react-router-dom';
import swal from '@sweetalert/with-react'
import { termConstants } from "../constants";
import Tour from 'reactour';

import Modal from 'react-responsive-modal';
// import DeleteAddress from "./pickupAddress/DeleteAddress";
import {CreateAddress, UpdateAddress} from "./modals/address";


class Delivery extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                days: 90,
                SLA: this.props.term && this.props.term.SLA || 0,
                logistics_fee: this.props.term && this.props.term.logistics_fee || 0,
                refund_option: this.props.term && this.props.term.logistics_fee || 1
            },
            edit: false,
            add: false,
            addSpAddress: false,
            addresses: [],
            delete: false,
            role: 'seller',
            view: false,
            set_default: false,
            submitted: false,
            startTour: false,
            error: this.props.error || null
        }
    }

    componentDidMount = () => {

        if (localStorage.getItem("trust_user_type") === 'seller') {
            if (this.props.user) {
                this.setState({'startTour': this.props.user.delivery_tour_done ? false : true});
            }
            this.props.dispatch(termActions.getTerm(this.props.history));
            this.props.dispatch(termActions.getAddresses("seller", this.props.history));
            this.props.dispatch(termActions.getServiceProviders("seller", this.props.history));
        } else
            this.setState({role: 'buyer'});

        if (this.props.addresses) {
            this.setState({addresses: [...this.props.addresses]});
        }

        if (this.props.service_providers) {
            this.setState({service_providers: [...this.props.service_providers]});
        }

    }
    onOpenModal = (name, detail = null) => {
        this.setState({[name]: true});
        if (detail) {
            this.setState({detail});
        }
    };

    onCloseModal = (name) => {
        this.setState({[name]: false, detail: ""});
    };


    handleChange = (e) => {
        const {name, value} = e.target;
        const fields = {...this.state.fields, [name]: value}
        this.setState({fields});
        if (name === 'refund_option') {
            let msg = (value === '1') ? "The buyer will be refunded when there is a rejection or cancellation of the order. The buyer will be able to cancel orders. This will impact all incomplete and future orders. Incomplete orders are those that have not been paid out or refunded. " : "The buyer will not be refunded when there is a rejection of the order. You will be required to offer replacements for the items purchased. The buyer will NOT be able to cancel orders after 2 hours of purchasing the items. This will impact all incomplete and future orders. Incomplete orders are those that have not been paid out or refunded. ";

            msg += "To save your changes, please click OK and then the Save button located at the bottom right corner.";

            swal("Caution !!", msg, "warning");
        }
    }

    componentWillReceiveProps = (props) => {

        if (props.term) {
            const fields = {...props.term}
            this.setState({fields})
        }
        if (props.alert && props.alert.message && props.alert.type == "alert-danger") {
            swal("Error", props.alert.message, "error").then(() => {
                this.props.dispatch(alertActions.alert.clear());
            });
            ;
        } else if (props.alert && props.alert.message && props.alert.type == "alert-success") {
            swal("Success", props.alert.message, "success").then(() => {
                this.props.dispatch(alertActions.alert.clear());
            });
            ;
        }
        if (this.props.addresses) {
            this.setState({addresses: [...this.props.addresses]});
        }
        if (this.props.service_providers) {
            this.setState({service_providers: [...this.props.service_providers]});
        }
    }

    handleSubmit = async (e) => {

        e.preventDefault();
        this.setState({submitted: true});
        // this.setState({ submitted: true, error: null });
        const term = this.state.fields;
        const validation = await termSchema.termSchema.validate(term).catch(
            error => {
                swal("Error", error.errors[0], "error");
                return this.setState({error: error.errors[0]})
            }
        )

        if (!validation) {
            return
        }

        const {dispatch, history} = this.props;
        dispatch(termActions.createTerm(term, history));

    }
    handleSubmitAddress = async (details, type) => {
        const {dispatch, history} = this.props;
        dispatch(termActions.createAddress(details, type, history));
    }

    handleEdit = async (e) => {
        e.preventDefault();
        const term = this.state.fields;
        const validation = await termSchema.termSchema.validate(term).catch(
            error => {
                swal("Error", error.errors[0], "error");
                return this.setState({error: error.errors[0]})
            }
        )

        if (!validation) {
            return
        }

        const {dispatch, history} = this.props;
        dispatch(termActions.editTerm(term, history));

    }

    handleDeleteAddress = (detail, type) => {
        this.props.dispatch(termActions.deleteAddress({address_id: detail.id}, type, this.props.history));
        if (this.props.success === true) {
            this.onCloseModal("delete")
        }
    }

    setDefaultAddress = (detail, type) => {
        this.props.dispatch(termActions.setAddressAsDefault({address_id: detail.id}, type, this.props.history));
        if (this.props.success === true) {
            this.onCloseModal("set_default")
        }

    }

    showDemo = () => {
        if (!this.props.loading) {
            this.onOpenModal("view");
        } else {
            swal("Loading", "Please wait...", "info").then(() => {
                this.props.dispatch(alertActions.alert.clear());
            });
        }

    }
    closeTour = () => {
        this.setState({startTour: false});
    }
    finishTour = () => {
        this.props.dispatch(userActions.user.updateTour(localStorage.getItem("trust_user_type"), {
            firstname: this.props.user.firstname,
            lastname: this.props.user.lastname,
            phone_number: this.props.user.phone_number,
            email: this.props.user.email,
            delivery_tour_done: 1,
        }, this.props.history, '/app/api'));
    }

    render() {

        const {loading, alert, user, term, deleting, updating} = this.props;
        const {fields, error, submitted, detail, addresses, service_providers} = this.state
        const edit = this.props.term && this.props.term.days || this.props.term && this.props.term.SLA ? true : false
        const steps = [
            {
                selector: '.active',
                content: ({goTo, inDOM}) => (
                    <p>Click the <b>Delivery Terms</b> tab and navigate to the <b>Delivery Terms</b> page</p>),
                position: 'bottom',
                style: {
                    maxWidth: '45%',
                    borderRadius: '5px',
                    backgroundColor: "#0e2433",
                    color: '#ffffff',
                },
            },
            {
                selector: '.first-step',
                content: ({goTo, inDOM}) => (
                    <p>To enable you charge cancellation fee to the buyer, please review this section and set your
                        preferred SLA value. Otherwise leave as is</p>),
                position: 'bottom',
                style: {
                    maxWidth: '45%',
                    borderRadius: '5px',
                    backgroundColor: "#0e2433",
                    color: '#ffffff',
                },
            },


            {
                selector: '.second-step',
                content: ({goTo, inDOM}) => (
                    <p> To enable you charge cancellation fee to the buyer, please review this section and set your
                        preferred percentage value. Otherwise leave as is</p>),
                position: 'bottom',
                style: {
                    maxWidth: '45%',
                    borderRadius: '5px',
                    backgroundColor: "#0e2433",
                    color: '#ffffff',
                },
            },
            {
                selector: '.fourth-step',
                content: ({goTo, inDOM}) => (
                    <p>Here, you can set if you want to allow refund or just replacements, when a buyer rejects your
                        service. Please review this section and set the value. Otherwise, leave as is.</p>),
                position: 'bottom',
                style: {
                    maxWidth: '45%',
                    borderRadius: '5px',
                    backgroundColor: "#0e2433",
                    color: '#ffffff',
                },
            },


            // ...
        ]
        return (
            <div className="content-wrapper" style={{minHeight: 342}}>
                {this.state.role === 'seller' ?
                    <Tour steps={steps} isOpen={this.state.startTour} onRequestClose={this.closeTour}
                          disableKeyboardNavigation={['esc', 'right', 'left']}
                          lastStepNextButton={<button className="btn btn-danger" onClick={this.finishTour}>Got
                              It!</button>} showNumber={false}
                    /> : null}

                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12 ">
                                <h1 className="m-0 text-dark">Delivery Terms</h1>
                                <div className="attop">
                                    {
                                        this.state.role !== 'seller' ?
                                            <p> In this section, Buyer is able to view the maximum allowable days set by
                                                AtaraPay for Seller to deliver orders placed via all eCommerce
                                                transactions.
                                                For P2P transaction, the maximum delivery days are varied and agreed by
                                                both Seller and Buyer

                                            </p>
                                            :
                                            <p>In this section, Seller is able to set terms of delivery to help protect
                                                against logistics
                                                challenges and attract serious buyers. Buyer will be informed of these
                                                terms well in
                                                advance before they make payment. The delivery terms are Cancellation
                                                SLA and Cancellation
                                                Fee.
                                                Please complete the Cancellation SLA and Cancellation Fee fields below
                                                then click <a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.showDemo()
                                                }}>here</a> to see
                                                how your delivery terms will be displayed to buyers.</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid"><br/>
                        <Modal open={this.state.view} onClose={() => {
                            this.onCloseModal("view")
                        }} right>
                            {
                                user && user ?
                                    <div>
                                        <br/>
                                        <p style={{color: "red"}}><b>Below is sample of what your buyers will see on
                                            your website based on a total order amount of N100,000.00</b></p>
                                        <p><img src="/dist/img/logo.3fc64c3.png" height="50px" alt=""
                                                className="img img-responsive"/></p>
                                        <p><h4><strong>Order Payment</strong></h4></p>
                                        <p style={{fontSize: '14px'}}><h6><strong>Your AtaraPay email
                                            is {user.email}</strong></h6></p>
                                        <p style={{fontSize: '14px'}}><h6><strong>Your AtaraPay phone number
                                            is {user.phone_number}</strong></h6></p>
                                        <p style={{fontSize: '14px'}}><h6><strong>Alternative Recipent Phone Number
                                            [+234]</strong><br/>
                                            <small>[Please enter this number if you will not be able to receieve the
                                                items(s) in person]</small>
                                        </h6></p>
                                        <p style={{fontSize: '14px'}}>You will be depositing NGN 100,000 into escrow
                                            today. When you accept the item, we will send the funds to the merchant.If
                                            you reject the item, we will refund your money within 24 hours.When payment
                                            is successful, we will send you an email with a 6 digit code which you will
                                            give to the delivery personnel to complete the payment process.</p>
                                        <p style={{fontSize: '14px'}}><h6><strong>Seller’s Delivery Terms</strong></h6>
                                        </p>
                                        <p style={{fontSize: '14px'}}>A cancellation fee of
                                            NGN {(((fields.logistics_fee) * 100000) / 100) > 5000 ? (5000).toLocaleString('en', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }) : (((fields.logistics_fee) * 100000) / 100).toLocaleString('en', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}, if you;</p>
                                        <ul style={{fontSize: '14px'}}>
                                            <li>Cancel after {fields.SLA < 1 ? (fields.SLA * 24) : (fields.SLA)} hours
                                                OR
                                            </li>
                                        </ul>
                                        <p style={{fontSize: '14px'}}>After making successful payment, please complete
                                            your AtaraPay registration from the link sent to your email address.</p>
                                        <p style={{fontSize: '14px'}}>Please note that maximum delivery days of the
                                            item(s) is 90 days after which if the order is not accepted or rejected,
                                            your funds will be refunded less escrow fees.</p>
                                        <p style={{fontSize: '14px'}}>For details on our escrow service click <a
                                            rel="noopener noreferrer" target="_blank"
                                            href="https://www.atarapay.com/why_escrow">here</a></p>
                                    </div>
                                    : null
                            }
                        </Modal>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className="callout callout-info"><br/>
                                    {this.state.role === 'seller' ?
                                        <span>
                                <div className="row">
                                    {error && <div className="col-sm-12">
                                        <div className="alert alert-danger">
                                            <button type="button" className="close">×</button>
                                            {error}
                                        </div>
                                    </div>}
                                </div>
                                <div className="row ">
                                    <div className="col-sm-12 first-step">
                                        <div className="form-group"><label htmlFor="cancellation_sla">Cancellation Service Level
                                                Agreement (SLA)</label>
                                            <p><small>Cancellation Service Level Agreement (SLA) – refers to the numbers of
                                                    hours that buyer is allowed to cancel an order after placing a successful
                                                    order on the website. Cancellation after this hours will lead to cancellation
                                                    fee charged to the buyer’s funds in escrow. Enter only number of hours
                                                    here. For example, if your Service Level Agreement (SLA) is 6 hours, then
                                                    enter the number "6" only. if your SLA is 30 minutes, enter 0.5 to
                                                    represent half an hour. Maximum allowable number of hours is 48.</small></p>
                                            <input type="number" id="cancellation_sla"
                                                   autoComplete="off" max="30" min="0"
                                                   className="form-control"
                                                   name="SLA"
                                                   onChange={this.handleChange.bind(this)}
                                                   value={fields.SLA}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="row ">
                                    <div className="col-sm-12 second-step">
                                        <div className="form-group"><label
                                            htmlFor="logistics_fee">Cancellation Fee</label>
                                                        <p><small>Cancellation Fee – The fee is a percentage of the product sum but up to a
                                                                maximum of 10% or N5,000.00. Enter only the percentage value. For example,
                                                    if you wish to charge 5% of the product sum, then enter the number “5“ only</small></p>
                                            <input type="number" id="logistics_fee" autoComplete="off" max="10" min="0"
                                                   className="form-control"
                                                   name="logistics_fee"
                                                   onChange={this.handleChange.bind(this)}
                                                   value={fields.logistics_fee}/>
                                        </div>
                                    </div>
                                </div></span> : null}
                                    <div className="row">


                                        <div className="col-sm-12 third-step">
                                            <div className="form-group"><label htmlFor="logistics_fee"> Maximum Delivery
                                                Days</label>
                                                <p><small>This is the number of days that merchant must deliver the
                                                    items by, failing which there shall be automatic refund from escrow
                                                    back to Buyer's account.Please note that this days are fixed
                                                    at {termConstants.term.MAX_DELIVERY_DAY} for escrow transactions
                                                    created via the Seller's eCommerce website.
                                                    However, for P2P escrow transactions, the Maximum Delivery Days are
                                                    settable during transaction creation based on mutual agreement by
                                                    both Seller and Buyer.</small></p>
                                                <input type="text" id="body" autoComplete="off" className="form-control"
                                                       name="days"
                                                       onChange={this.handleChange.bind(this)}

                                                       value={termConstants.term.MAX_DELIVERY_DAY}
                                                       readOnly
                                                       disabled/>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.role === 'seller' ?
                                        <div className="row">
                                            <div className="col-sm-12 fourth-step">
                                                <div className="form-group">
                                                    <label htmlFor="refund_option"> Refund Policy</label>
                                                    <p><small>Please choose a return policy you will like your customers
                                                        to see when they purchase from you. Full refund means the buyer
                                                        will be refunded in full, if they reject your item. No full or
                                                        partial refund means you do not support refund but replacements.
                                                        Default refund policy is set at full refund.</small></p>
                                                    <p><small>If you select the <b>No Refund </b> option, we shall
                                                        automatically set a minimum delivery SLA of 2 hours to give
                                                        buyer the time to cancel the order so they can receive their
                                                        full refund. After this SLA period, buyer will not be able to
                                                        receive a refund</small></p>

                                                    <select className="form-control"
                                                            name="refund_option"
                                                            onChange={this.handleChange.bind(this)}
                                                            value={fields.refund_option}>
                                                        <option disabled="disabled" value=""> Select Refund Policy
                                                        </option>
                                                        <option value={1}>Full refund available</option>
                                                        <option value={0}> No full or partial refund available, ONLY
                                                            REPLACEMENT
                                                        </option>

                                                    </select>

                                                </div>
                                            </div>
                                        </div> : null}
                                    <br/>
                                    {this.state.role === 'seller' ?
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label htmlFor="pickup_address">Delivery Service</label>
                                                <p><small>Please add an address that will serve as your pickup
                                                    location.</small></p>
                                                <div className="table-responsive">
                                                    <table className="table table table-hover table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th className="" style={{textAlign: "left"}}>
                                                                <span>Street</span>
                                                            </th>
                                                            <th className="" style={{textAlign: "left"}}>
                                                                <span>Apartment</span>
                                                            </th>
                                                            <th className="" style={{textAlign: "left"}}>
                                                                <span>City</span>
                                                            </th>
                                                            <th className="" style={{textAlign: "left"}}>
                                                                <span>State</span>
                                                            </th>
                                                            <th className="" style={{textAlign: "left"}}>
                                                                <span>Post Code</span>
                                                            </th>
                                                            <th className="" style={{textAlign: "left"}}>
                                                                <span>Country</span>
                                                            </th>
                                                            <th className="" style={{textAlign: "left"}}>
                                                                <span>Default</span>
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {addresses && addresses.length > 0 && addresses.map(address =>
                                                            !address.sp_id ? (<tr key={address.id.toString()}>
                                                                <td>{address.street}</td>
                                                                <td>{address.apartment}</td>
                                                                <td>{address.city}</td>
                                                                <td>{address.state}</td>
                                                                <td>{address.post_code}</td>
                                                                <td>{address.country}</td>
                                                                <td>{address.is_default ? 'YES' : "NO"}</td>
                                                                <td>
                                                            <span>
                                                                <a style={{cursor: "pointer"}} className="text-danger"
                                                                   onClick={() => this.onOpenModal("delete", address)}>
                                                                    <small>Delete</small>
                                                                </a></span>
                                                                    <br/>
                                                                    {!address.is_default ? <span>
                                                                <a style={{cursor: "pointer"}} className="text-success"
                                                                   onClick={() => this.onOpenModal("set_default", address)}>
                                                                    <small>Set As Default</small>
                                                                </a></span> : null}
                                                                </td>
                                                            </tr>) : null)
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {addresses && addresses.length === 0 ?
                                                    <button onClick={() => this.onOpenModal("add", "")}
                                                            className="btn btn-primary first-step"
                                                            disabled={loading}>{loading ? "..." : "Add Pickup Address"}
                                                    </button> : null}

                                            </div>
                                        </div> : null}
                                    <br/>
                                    {this.state.role === 'seller' ?
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label htmlFor="pickup_address">Service Provider Address</label>
                                                <p><small>Please add an address that will serve as your service provider
                                                    location.</small></p>
                                                <div className="table-responsive">
                                                <table className="table table table-hover table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th className="" style={{textAlign: "left"}}>
                                                            <span>Provider</span>
                                                        </th>
                                                        <th className="" style={{textAlign: "left"}}>
                                                            <span>Street</span>
                                                        </th>
                                                        <th className="" style={{textAlign: "left"}}>
                                                            <span>Apartment</span>
                                                        </th>
                                                        <th className="" style={{textAlign: "left"}}>
                                                            <span>City</span>
                                                        </th>
                                                        <th className="" style={{textAlign: "left"}}>
                                                            <span>State</span>
                                                        </th>
                                                        <th className="" style={{textAlign: "left"}}>
                                                            <span>Post Code</span>
                                                        </th>
                                                        <th className="" style={{textAlign: "left"}}>
                                                            <span>Country</span>
                                                        </th>
                                                        <th className="" style={{textAlign: "left"}}>
                                                            <span>Default</span>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {addresses && addresses.length > 0 && addresses.map(address =>
                                                        address.sp_id ? (<tr key={address.id.toString()}>
                                                            <td>{address.service_provider.firstname + " " || ""}
                                                                {address.service_provider.lastname + " " || ""}
                                                                ({address.service_provider.email || ""})</td>

                                                            <td>{address.street}</td>
                                                            <td>{address.apartment}</td>
                                                            <td>{address.city}</td>
                                                            <td>{address.state}</td>
                                                            <td>{address.post_code}</td>
                                                            <td>{address.country}</td>
                                                            <td>{address.is_default ? 'YES' : "NO"}</td>
                                                            <td>
                                                            <span>
                                                                <a style={{cursor: "pointer"}} className="text-danger"
                                                                   onClick={() => this.onOpenModal("delete", address)}>
                                                                    <small>Delete</small>
                                                                </a></span>
                                                                <br/>
                                                                {!address.is_default ? <span>
                                                                <a style={{cursor: "pointer"}} className="text-success"
                                                                   onClick={() => this.onOpenModal("set_default", address)}>
                                                                    <small>Set As Default</small>
                                                                </a></span> : null}
                                                            </td>
                                                        </tr>) : null)
                                                    }
                                                    </tbody>
                                                </table>
                                                </div>
                                                <button onClick={() => this.onOpenModal("addSpAddress", "")}
                                                        className="btn btn-primary first-step"
                                                        disabled={loading}>{loading ? "..." : "Add Service Provider Address"}
                                                </button>

                                            </div>
                                        </div> : null}

                                    <br/>
                                    {this.state.role === 'seller' ?

                                        <p className="text-right">
                                            {!edit ? <button className="btn btn-primary" style={{padding: "7 40"}}
                                                             onClick={this.handleSubmit.bind(this)}
                                                             disabled={loading}>{loading ? 'Loading' : 'Create'}</button> :
                                                <button className="btn btn-primary" style={{padding: "7 40"}}
                                                        onClick={this.handleEdit.bind(this)}
                                                        disabled={loading}>{loading ? 'Loading' : 'Save'}</button>}
                                        </p>
                                        : null}
                                    <br/><br/>
                                </div>
                            </div>
                            <div className="col-md-4"></div>
                        </div>
                        <br/>
                    </div>
                </section>


                {
                    this.state.role === 'seller' && this.state.add ?
                        <CreateAddress
                            role={this.state.role}
                            add={this.state.add}
                            adding={loading}
                            onCloseModal={this.onCloseModal}
                            handleSubmit={this.handleSubmitAddress}
                        ></CreateAddress> : null
                }
                {
                    this.state.role === 'seller' && this.state.addSpAddress ?
                        <CreateAddress
                            role={this.state.role}
                            addSpAddress={this.state.addSpAddress}
                            serviceProviders={service_providers}
                            adding={loading}
                            onCloseModal={this.onCloseModal}
                            handleSubmit={this.handleSubmitAddress}
                        ></CreateAddress> : null
                }
                {
                    this.state.role === 'seller' && this.state.delete ?
                        <UpdateAddress
                            role={this.state.role}
                            delete={this.state.delete}
                            deleting={deleting}
                            detail={detail}
                            onCloseModal={this.onCloseModal}
                            handleSubmit={this.handleDeleteAddress}
                        ></UpdateAddress> : null
                }
                {
                    this.state.role === 'seller' && this.state.set_default ?
                        <UpdateAddress
                            role={this.state.role}
                            set_default={this.state.set_default}
                            updating={updating}
                            detail={detail}
                            onCloseModal={this.onCloseModal}
                            handleSubmit={this.setDefaultAddress}
                        ></UpdateAddress> : null
                }

            </div>

        )
    }
}

export default Delivery;
