import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Login from '../views/Login';
import { userActions } from '../actions';

const mapStateToProps = (state) =>{
    
    const { loggingIn, user } = state.auth;
    const { loading, msg } = state.users;
    return {
        loggingIn,
        loading,
        user,
        msg,
        alert : state.alert
    };
 }

const mapDispatchToProps = (dispatch) => ({
    logout: (type, history) => dispatch(userActions.user.logout(type, history))
}
)
 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));