import React, { Component } from "react";
import swal from "@sweetalert/with-react";
import Modal from "react-responsive-modal";
import {ValidateSubmission} from '../../helpers/payoutValidator';

class CreateBankDetails extends Component {
  state = {
    details: {
      bank_name: "",
      bank_code: "",
      nip_bank_code: "",
      bank_account_name: "",
      bank_account_number: "",
      type: 0,
      default: 2,
    },
    usd_account_ids: { Wise: {}, Paypal: {}, CashApp: {} },
    is_terms_read: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const details = Object.assign({}, this.state.details);
    const { usd_account_ids } = this.state;
    const type = e.target.dataset.type;

    if (this.state.details.type === 1) {
      if (
        !(
          details.bank_name &&
          details.bank_account_name &&
          details.bank_account_number
        )
      ) {
        return swal({ text: "Please ensure your account details are entered correctly", icon: "warning" });
      }

      this.props.handleSubmit(details, type);
    }

    if (this.state.details.type === 2) {
      const validateUSDAccounts =
        (usd_account_ids.Wise.id ||
          usd_account_ids.Paypal.id ||
          usd_account_ids.CashApp.id) &&
        this.state.details.type === 2;

      if (!validateUSDAccounts) {
        return swal({
          text: "Please enter valid USD Payout detail",
          icon: "warning",
        });
      }

      if (!this.state.is_terms_read) {
        return swal({
          text: "Please agree terms and policies",
          icon: "warning",
        });
      }

      let allIds = Object.entries(this.state.usd_account_ids);
      allIds.forEach((el) => {
        let body = {
          account_id: el[1].id,
          account_type: el[1].name,
          default: el[1].default ? el[1].default : 2,
          type: 2,
        };
        if (el[1].id && el[1].id) {
          this.props.handleSubmit(body, type);
        }
      });
    }

    if (this.state.details.type === "both") {
      if (
        ValidateSubmission(
          this.state.details,
          this.state.usd_account_ids,
          this.state.is_terms_read
        )
      ) {
        details.type = 1;
        this.props.handleSubmit(details, type);
        let allIds = Object.entries(this.state.usd_account_ids);
        allIds.forEach((el) => {
          let body = {
            account_id: el[1].id,
            account_type: el[1].name,
            default: el[1].default ? el[1].default : 2,
            type: 2,
          };
          if (el[1].id && el[1].id) {
            this.props.handleSubmit(body, type);
          }
        });
      }
    }
  };



  handleBankChange = (e) => {
    const value = Number(e.target.value);

    console.log(this.props.banks.find((e) => e.id === value).name);

    const { details } = this.state;
    this.setState({
      details: {
        ...details,
        bank_name: this.props.banks.find((e) => e.id === value).name,
        bank_code: this.props.banks.find((e) => e.id === value).code,
        nip_bank_code: this.props.banks.find((e) => e.id === value)
          .nip_bank_code,
      },
    });
  };

  handleTypeSelect = (type) => {
    let details = this.state.details;
    details.type = type;
    this.setState({ details: details });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    let { type } =
      e.target.type === "checkbox"
        ? e.target.checked
          ? (value = 1)
          : (value = 0)
        : e.target.value;
    let { details } = this.state;
    this.setState({ details: { ...details, [name]: value } });
  };

  handleAccountType = (e) => {
    let name = e.target.name;
    let usd_account_ids = this.state.usd_account_ids;
    if (e.target.checked) {
      usd_account_ids[name].default = 1;
      this.setState({ usd_account_ids: usd_account_ids });
    } else {
      usd_account_ids[name].default = 2;
      this.setState({ usd_account_ids: usd_account_ids });
    }
  };
  handleAccountId = (e) => {
    let name = e.target.name,
      value = e.target.value;
    let usd_account_ids = this.state.usd_account_ids;
    usd_account_ids[name].id = value;
    usd_account_ids[name].name = name;
    this.setState({ usd_account_ids: usd_account_ids });
  };

  render() {
    const { details } = this.state;
    const { adding } = this.props;

    return (
      <>
        {/*Add Bank details modal start*/}
        <Modal
          open={this.props.add}
          onClose={() => this.props.onCloseModal("add")}
          center
        >
          <form data-type={this.props.role} onSubmit={this.handleSubmit}>
            <br />
            <br />
            <br />
            <div>
              <div>
                <label className="form-group">
                  {" "}
                  Please select payout type:{" "}
                </label>
              </div>

              <div style={{ paddingLeft: "10px" }} className="row">
                <div class="col-4 col-sm-4 col-md-4">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="typeOfCurrency"
                    onChange={() => this.handleTypeSelect(1)}
                    id="NGN"
                  />
                  &nbsp;<label for="NGN">NGN</label>
                </div>

                <div class="col-4 col-sm-4 col-md-4">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="typeOfCurrency"
                    onChange={() => this.handleTypeSelect(2)}
                    id="USD"
                  />
                  &nbsp;<label for="USD">USD</label>
                </div>

                <div class="col-4 col-sm-4 col-md-4">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="typeOfCurrency"
                    onChange={() => this.handleTypeSelect("both")}
                    id="Both"
                  />
                  &nbsp;<label for="Both">Both</label>
                </div>
              </div>

              <br />
            </div>
            <div id="accordion">
              {(this.state.details.type === 1 ||
                this.state.details.type === "both") && (
                <>
                  <div id="headingOne">
                    <h5>
                      <button
                        type="button"
                        className="accord"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Payout Currency: NGN
                        <i className="pull-right fa fa-angle-down"></i>
                      </button>
                    </h5>
                  </div>

                  <br />
                  <div class="col-12 col-sm-12">
                    <div
                      id="collapseOne"
                      className="collapse"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <label>Bank Name</label>
                            <select
                              className="form-control"
                              onChange={this.handleBankChange}
                            >
                              <option value="">Select Bank</option>
                              {this.props.banks.map((bank) => (
                                <option key={bank.id} value={bank.id}>
                                  {bank.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group">
                            <label>Account Name</label>
                            <input
                              type="text"
                              name="bank_account_name"
                              value={details.bank_account_name}
                              onChange={this.handleChange}
                              class="form-control"
                            />
                          </div>
                          <br />
                          <div className="form-group">
                            <label>Account Number</label>
                            <input
                              type="number"
                              name="bank_account_number"
                              value={details.bank_account_number}
                              onChange={this.handleChange}
                              className="form-control"
                            />
                          </div>{" "}
                          <br />
                          {this.props.role === "seller" ? (
                            <div class="form-check">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                name="default"
                                onChange={this.handleChange}
                                id="default"
                              />
                              <label className="form-group" for="default">
                                Set as Default
                              </label>
                              <br />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {(this.state.details.type === 2 ||
                this.state.details.type === "both") && (
                <>
                  <div id="headingTwo">
                    <h5>
                      <button
                        type="button"
                        className="accord"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        Payout Currency: USD
                        <i className="pull-right fa fa-angle-down"></i>
                      </button>
                    </h5>
                  </div>

                  <br />
                  <div class="col-12 col-sm-12">
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordion"
                    >
                      <div className="form-group">
                        <label>Wise ID: </label>
                        <input
                          type="text"
                          name="Wise"
                          className="form-control"
                          onChange={this.handleAccountId}
                        />
                      </div>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          onChange={this.handleAccountType}
                          class="form-check-input"
                          name="Wise"
                          id="Wise"
                        />
                        <label className="form-group" for="Wise">
                          Set as Default
                        </label>
                      </div>
                      <br />
                      <div className="form-group">
                        <label>Paypal ID: </label>
                        <input
                          type="text"
                          name="Paypal"
                          onChange={this.handleAccountId}
                          className="form-control"
                        />
                      </div>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          onChange={this.handleAccountType}
                          class="form-check-input"
                          name="Paypal"
                          id="Paypal"
                        />
                        <label className="form-group" for="Paypal">
                          Set as Default
                        </label>
                      </div>
                      <br />
                      <div className="form-group">
                        <label>CashApp ID: </label>
                        <input
                          type="text"
                          onChange={this.handleAccountId}
                          name="CashApp"
                          className="form-control"
                        />
                      </div>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          onChange={this.handleAccountType}
                          class="form-check-input"
                          name="CashApp"
                          id="CashApp"
                        />
                        <label className="form-group" for="bank_CashApp">
                          Set as Default
                        </label>
                      </div>
                      <br />
                    </div>
                  </div>
                  <div id="headingThree">
                    <h5>
                      <button
                        type="button"
                        className="accord"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                      >
                        USD Payout Terms:
                        <i className="pull-right fa fa-angle-down"></i>
                      </button>
                    </h5>
                  </div>

                  <br />
                  <div class="col-12 col-sm-12">
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordion"
                    >
                      <div className="form-check">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            this.setState({ is_terms_read: e.target.checked })
                          }
                        />
                        &nbsp;
                        <label> I've read and agree all the Terms: </label>
                      </div>
                      <ul>
                        <li>
                          {" "}
                          You will bear the Wise, PayPal or CashApp <br />{" "}
                          transfer fees.
                        </li>
                        <li>
                          {" "}
                          We would payout the USD value computed by <br /> our
                          platform to your default ID.
                        </li>
                        <li>
                          You may then choose to withdraw from your Wise, <br />{" "}
                          PayPal or CashApp account to your local currency.
                        </li>
                        <li>
                          Your website transactions using AtaraPay <br /> API or
                          plug-in must be in any non-NGN currency <br /> like
                          GBP, USD, etc.
                        </li>
                        <li>Your AtaraPay P2P transactions must be in USD.</li>
                        <li>
                          It is your responsibility to communicate <br /> the
                          terms above to your service provider <br /> (if they
                          choose to receive payout in USD)
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              className="row"
              style={{ paddingLeft: "10px", marginTop: "10px" }}
            >
              {(this.state.details.type === 1 ||
                this.state.details.type === 2 ||
                this.state.details.type === "both") && (
                <button type="submit" class="btn btn-primary">
                  {adding ? "Saving" : "Save"}
                </button>
              )}
              <br />
              <br />
            </div>
          </form>
        </Modal>
        {/*Add Bank details modal ends*/}
      </>
    );
  }
}

export default CreateBankDetails;
