import React , {Component} from 'react';
import {Link} from 'react-router-dom';
import { jsx, css } from '@emotion/core';
import swal from '@sweetalert/with-react';
import Modal from 'react-responsive-modal';
import {  alertActions } from '../actions';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import * as moment from 'moment';

const formatToSixDigits = (number)=>{
    var output = number + '';
    while (output.length < 6) {
        output = '0' + output;  
    }
    return output;
}

class Disputes extends Component{

    state={
        filer:" ",
        data:[],
        notCustom:true,
        period:"All",
        from_date:"",
        to_date:"",
        disputes:[],
        role: 'seller'
    }

    componentDidMount() {
        if (localStorage.getItem("trust_user_type") === 'seller')
            this.setState({ role: 'seller' });
        else
            this.setState({ role: 'buyer' });
        if (this.props.disputes) {
            this.setState({ data: [...this.props.disputes] });
            this.setState({ disputes: [...this.props.disputes] });
        }
    }

    handleSelect = (e)=>{
        const {name, value} = e.target;
        if(value === "Custom"){
            this.setState({notCustom:!this.state.notCustom})
        }
        this.filterPeriod(value);
        this.setState({period:value});
    }
    filterPeriod = (value)=>{
      const data = this.state.disputes;
      if(value === "All"){
          this.table_rows = data;
          this.notCustom = true;
          this.page = 1;
          this.setState({to_date:"",from_date:"",notCustom:true,data:[...this.props.disputes]});
      }

      if(value === "This Week"){
        var disputesThisWeek = [];
        for(var i = 0; i < data.length; i++){
          var now = moment();
          var input = moment(data[i].dispute[0].created_at);
          var isThisWeek = (now.isoWeek() == input.isoWeek())
          //console.log('This week:',now.isoWeek(),'input week:',input.isoWeek(),isThisWeek);
          if(isThisWeek){
              disputesThisWeek.push(data[i]);
          }
        }      
        this.table_rows = disputesThisWeek;
        this.notCustom = true;
        this.page = 1;
        //console.log("This Week",this.table_rows);
        this.setState({to_date:"",from_date:"",notCustom:true,data:[...this.table_rows]});
      }

      if(value === "This Month"){
        var disputesThisMonth = [];
        for(let i = 0; i < data.length; i++){
          let input = moment(data[i].dispute[0].created_at);
          var isThisMonth = input.isSame(new Date(), 'month');
          if(isThisMonth){
              disputesThisMonth.push(data[i]);
          }
        }      
        this.table_rows = disputesThisMonth;
        this.notCustom = true;
        this.page = 1;
        this.setState({to_date:"",from_date:"",notCustom:true,data:[...this.table_rows]});
     }
      
    if(value === "60 Days Ago"){
        var disputesSixtyDaysAgo = [];      
        for(let i = 0; i < data.length; i++){      
            var sixty_days_ago = moment().subtract(60, 'days');      
            var isBetweenSixtyDays = moment(data[i].dispute[0].created_at).isAfter(sixty_days_ago);

            if(isBetweenSixtyDays){
                disputesSixtyDaysAgo.push(data[i]);
            }
        }

        this.table_rows = disputesSixtyDaysAgo;
        this.notCustom = true;
        this.page = 1;
        //console.log("60 Days Ago",this.table_rows);
        this.setState({to_date:"",from_date:"",notCustom:true,data:[...this.table_rows]});
    }
      
  if(value == "90 Days Ago"){
      var disputesNinetyDaysAgo = [];

      for(let i = 0; i < data.length; i++){
          var ninety_days_ago = moment().subtract(90, 'days');
          var isBetweenNinetyDays = moment(data[i].dispute[0].created_at).isAfter(ninety_days_ago);

          if(isBetweenNinetyDays){
              disputesNinetyDaysAgo.push(data[i]);
          }
      }

      this.table_rows = disputesNinetyDaysAgo;
      this.notCustom = true;
      this.page = 1;
      //console.log("90 Days Ago",this.table_rows);
      this.setState({to_date:"",from_date:"",notCustom:true,data:[...this.table_rows]});
  }
      
  if(value === "Custom"){
      this.table_rows = data;
      this.setState({to_date:"",from_date:""});      
  }
}
filterDates = (data)=>{

    if(this.state.from_date === ""){
        swal("Error", "Please select a From date","error");
       return this.state.disputes;
    }
    if(this.state.to_date === ""){
        swal("Error","Please select a To date","error");
       return this.state.disputes;
    }

    if(moment(this.state.from_date).isAfter(this.state.to_date)){
        swal("Error","Please select a From date that is before your To date","error");
       return this.state.disputes;
    }
    var disputesBetweenTheTwoDates = [];
    for(var i = 0; i < data.length; i++){
        var isBetweenDates = moment(data[i].dispute[0].created_at).isBetween(this.state.from_date, this.state.to_date); // true
        if(isBetweenDates){
            disputesBetweenTheTwoDates.push(data[i]);
        }
    }      
    this.table_rows = disputesBetweenTheTwoDates;;
    this.page = 1;
    return this.table_rows;
}
    handleDate=(e)=>{
        const {name, value} = e.target;
        this.setState({[name]:value});
 
    }


    handleCancel = (e) => {
        e.preventDefault();
        const { order } = this.state;
        this.props.cancel(localStorage.getItem("trust_user_type"), { dispute_id: order }, this.props.history);

    }

    filterTable=(filter)=>{
        var data = this.state.disputes;
        var statusResolved='Resolved';
        var statusComplete='Complete';
        var filteredRows = [];
      if(filter != ""){
        for(var i = 0; i < data.length; i++){
            if(formatToSixDigits(data[i].id).match(new RegExp(filter,"gi")) ||
             (data[i].dispute[0].reason && data[i].dispute[0].reason.match(new RegExp(filter,"gi")))|| 
             (data[i].status&&data[i].status.title.toUpperCase().includes(filter.toUpperCase()))||(data[i].status&&data[i].status.title.toUpperCase().includes(statusComplete.toUpperCase())&&statusResolved.toUpperCase().includes(filter.toUpperCase()))||
             (data[i].dispute[0].created_at && data[i].dispute[0].created_at.match(new RegExp(filter,"gi")))
            ){
                filteredRows.push(data[i]); 
            }
        }

          this.setState({data:[...filteredRows]});
      }else{
        this.setState({data:[...this.state.disputes]});
      }
        
    }

    handleFilter = (e) => {
      const {value} = e.target;
      this.setState({filter:value},()=>this.filterTable(this.state.filter));
    }

    componentWillReceiveProps(props){
        if (props.disputes) {
            this.setState({ data: [...props.disputes] });
            this.setState({ disputes: [...props.disputes] })            
        }

        if(props.alert && props.alert.message && props.alert.type === "alert-danger"){
            swal("Error",props.alert.message,"error").then(() => {
                props.dispatch(alertActions.alert.clear());
              });
        }

        if(props.alert && props.alert.message && props.alert.type === "alert-success"){
            swal("Success",props.alert.message,"success").then(() => {
                props.dispatch(alertActions.alert.clear());
              });
        }
    }
    onOpenModal = (name, order) => {
        this.setState({ [name]: true });
        this.setState({ order: order });
    };

    onCloseModal = (name) => {
        this.setState({ [name]: false });
        this.setState({ order: "" });
    };
    render(){
        const { disputes, loading, alert, user, canceling} = this.props;       
        const { data, filterable, role} = this.state;
        var columns = [];
        { user && user.role && user.role.name == 'MarketPlace' ? 
         columns = [{
            Header: props => <span><b>SN</b></span>,
            id: "sn",
            filterable,
            maxWidth: 80,
            accessor: d => d,// String-based value accessors!
            Cell: (row) => {
                return <span>{row.index + 1}</span>;
            }
        }, 
          {
            Header: props => <span><b>Order Number</b></span>,
            id: "order_id",
            accessor: d => d,
            maxWidth: 140,
              Cell: props => <span className='number'><a href="#/app/disputes/all" onClick={() => this.onOpenModal("fullModal", props.value)}>{formatToSixDigits(props.value.dispute[0].transaction_id)}</a></span> // Custom cell components!
          },
          {
            Header: props => <span><b>Seller </b></span>,
            id: "firstname",
            filterable,
            maxWidth: 100,
            accessor: d => d,// String-based value accessors!
            Cell: props => <span>{props.value.sellers.users && props.value.sellers.users.firstname} <br/> {props.value.sellers.users && props.value.sellers.users.lastname} </span>
        },
        {
            Header: props => <span><b>Buyer</b></span>,
            id: "firstname",
            filterable,
            maxWidth: 100,
            accessor: d => d,// String-based value accessors!
                Cell: props => <span>{props.value.customers && props.value.customers.firstname} <br/> {props.value.customers && props.value.customers.lastname} </span>
        },
          {
            id: 'issue', // Required because our accessor is not a string
            Header: props => <span><b>Issue</b></span>,
            accessor: d => d.dispute[0].reason, // Custom value accessors!
            
          },{
            id:"date_created",
              maxWidth: 170,
            Header: props => <span><b>Date Created</b></span>,
              accessor: d => d.dispute[0].created_at // String-based value accessors!
          },{
            id:'status',
            Header:props=><span><b>Dispute Status</b></span>,
              maxWidth: 120,
              accessor: d => d.dispute[0], // String-based value accessors!
            className: 'text-center',
            Cell: props => <div>
                {props.value && props.value.status === 0 ? <span class="badge badge-success">Resolved</span> : props.value.status === 1 ? <span class="badge badge-warning">Dispute</span> : props.value.status === 2 ? <span class="badge badge-info">Disputed</span> : <span class="badge badge-danger">Canceled</span>}
            </div>
            }
    ]

    :
    columns = [{
        Header: props => <span><b>SN</b></span>,
        id: "sn",
        filterable,
        maxWidth: 80,
        accessor: d => d,// String-based value accessors!
        Cell: (row) => {
            return <span>{row.index + 1}</span>;
        }
    }, 
      {
        Header: props => <span><b>Order Number</b></span>,
        id: "order_id",
        accessor: d => d,
        maxWidth: 140,
          Cell: props => <span className='number'><a href="#/app/disputes/all" onClick={() => this.onOpenModal("fullModal", props.value)}>{formatToSixDigits(props.value.dispute[0].transaction_id)}</a></span> // Custom cell components!
      },
      {
        id: 'issue', // Required because our accessor is not a string
        Header: props => <span><b>Issue</b></span>,
        accessor: d => d.dispute[0].reason, // Custom value accessors!
        
      },{
        id:"date_created",
          maxWidth: 170,
        Header: props => <span><b>Date Created</b></span>,
          accessor: d => d.dispute[0].created_at // String-based value accessors!
      },{
        id:'status',
        Header:props=><span><b>Dispute Status</b></span>,
          maxWidth: 120,
          accessor: d => d.dispute[0], // String-based value accessors!
        className: 'text-center',
        Cell: props => <div>
            {props.value && props.value.status === 0 ? <span class="badge badge-success">Resolved</span> : props.value.status === 1 ? <span class="badge badge-warning">Dispute</span> : props.value.status === 2 ? <span class="badge badge-info">Disputed</span> : <span class="badge badge-danger">Canceled</span>}
        </div>
        }, {
            id: "actions",
            sortable: false,
            filterable: false,
            maxWidth: 110,
            Header: props => <span><b>Actions</b></span>,
            accessor: d => d,
            Cell: props => <span>
                {/* {props.value.status.title == 'Disputed' &&<Link to={`/app/seller/dispute/raise/${props.value.id}`} class="text-danger" v-if="order.status.title == 'Disputed'">
                <small>Cancel Dispute</small>
                &nbsp;&nbsp;
                </Link>} */}
                {props.value.dispute[0].status === 1 && this.state.role === 'seller' &&<Link to={`/app/seller/dispute/raise/${props.value.id}`} class="text-danger">
                    <small>Raise Dispute</small><br /></Link> }
                  
                {(props.value.dispute[0].status === 1 || props.value.dispute[0].status === 2) && <a style={{ cursor: "pointer" }} class="text-dark" onClick={() => this.onOpenModal("cancel", props.value.id)}>
                    <small>Cancel Dispute</small></a>
                
                }
            </span> // String-based value accessors!
        }
]

}

        return(
            <div class="content-wrapper" style={{minHeight: 311}}>
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <h1 class="m-0 text-dark">All Disputes</h1>
                                <div class="attop">
                                <a href="https://www.youtube.com/watch?v=N57OfviJcys." className="btn btn-primary" target="_blank">Watch: How To Resolve A Dispute</a><br/><br/>
                                    <p>The dispute section allows Buyer and Seller to raise a dispute using the Raise Dispute link or to cancel a dispute using the Cancel Dispute link.<br/><br/> Dispute will be deemed resolved in the following situations;</p>   
                                    <ol>
                                            <li>If a dispute is not raised by either party 36 hours after the dispute is created, the Buyer will be automatically refunded. </li>
                                            <li>Should a dispute be cancelled by Seller, the Buyer will be automatically refunded with the full amount of the order. </li>
                                            <li>Should a dispute be cancelled by Buyer, the Seller will be automatically credited with the full amount of the order.</li>
                                            <li>If either party raises dispute, AtaraPay Dispute manager shall mediate. If a resolution is reached, the beneficial party shall be credited and dispute set to resolved. </li>
                                            <li>If a resolution is not reached through mediation, both parties shall be advised to settle the dispute with a public arbitration agency such as the Police, courts, etc. The dispute resolution letter from the agency will be used to credit the beneficial party and dispute set to resolved.</li>
                                    </ol> 
                                </div>
                            {/* <div class="row">
                                <div class="col-sm-4"><label for="">Filter By</label> <input type="text" placeholder="Name"
                                        class="form-control" /></div>
                                <div class="col-sm-2"><label for=""></label> <input type="text" placeholder="Order Id" class="form-control"
                                        style={{marginTop: 5}} /></div>
                                <div class="col-sm-4"><label for=""></label> <input type="date" placeholder="Name" class="form-control" /></div>
                                <div class="col-sm-2"><label for=""></label> <button class="btn btn-info btn-block">Filter</button></div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <section class="content">
                <div class="container-fluid"><br/>
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                        {/* {alert && alert.message && alert.type!="alert-danger"&&<div class={`alert buyer-alert ${alert.type}`} ><a  
                                aria-label="close" class="close alert-close">×</a> <span   class="alert-message-content">{alert.message}</span></div>} */}
                            <div class="card">
                            
                                <div class="card-body">
                               
                                <div class="row">
                                            <div class="col-12 col-sm-2">
                                                <div class="form-group"><label for="period" >Period</label>
                                <select class="form-control" name="filter" onChange={this.handleSelect}>
                                                        <option value="All"  >All</option>
                                                        <option value="This Week">This Week</option>
                                                        <option value="This Month">This Month</option>
                                                        <option value="60 Days Ago">60 Days Ago</option>
                                                        <option value="90 Days Ago">90 Days Ago</option>
                                                        <option value="Custom">Custom</option>
                                                    </select></div>
                                            </div>
                                            <div class="col-12 col-sm-2">
                                                    <div class="form-group"><label for="filter">Filter</label><input type="text" placeholder="Filter" class="form-control" value={this.state.filter} onChange={this.handleFilter} /></div>
                                             </div>    
                                            <div class="col-12 col-sm-3">
                                                <div class="form-group"><label for="from">Date From</label> <input type="date"
                                                    name="from_date" value={this.state.from_date} disabled={this.state.notCustom} onChange={this.handleDate} class="form-control" /></div>
                                            </div>
                                            <div class="col-12 col-sm-3">
                                                <div class="form-group"><label for="from" >Date To</label> <input type="date"
                                                    name="to_date"  value={this.state.to_date} disabled={this.state.notCustom} onChange={this.handleDate} class="form-control" /></div>
                                            </div>
                                            <div class="col-12 col-sm-2">
                                                <div class="form-group"> <label>&nbsp;</label>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <button disabled={this.state.notCustom} onClick={()=>{this.setState({data:[...this.filterDates(this.state.disputes)]})}} class="btn btn-info btn-block form-control"><center>Search</center></button>
                                                        </div>
                                                        {/* <div class="col-4">
                                                            <button onClick={()=>{this.setState({filterable: !filterable})}} class="btn btn-info btn-md">
                                                                <i class="fa fa-search"></i>
                                                            </button>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> <br/>
                               
                                        <div class="table-responsive">
                                        <ReactTable
                                        defaultPageSize={5}
                                                data={data}
                                            columns={columns}/>
                                            
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div> <br/>
                </div>
            </section>
             <div>
                <Modal open={this.state.fullModal} onClose={() => this.onCloseModal("fullModal")} center>
                    <form>
                        <div class="modal-header">
                            <h4 class="modal-title"><small></small></h4>
                        </div>
                        <div class="modal-body">
                            <div class="row"></div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group"><label>Order Number</label> <br /> <input type="text" readonly="readonly"
                                        value={formatToSixDigits(this.state.order && this.state.order.id)} class="form-control" /></div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group"><label>Amount</label> <br /> <input type="text" readonly="readonly"
                                            value={this.state.order && '₦' + (this.state.order.amount_payed / 100).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} class="form-control" /></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group"><label>Order Date</label> <br /> <input type="text" readonly="readonly"
                                            value={this.state.order && this.state.order.transaction_date} class="form-control" /></div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group"><label>Order Status</label> <br /> <input type="text" readonly="readonly"
                                        value={this.state.order && this.state.order.status && this.state.order.status.title} class="form-control" /></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group"><label>Buyer Name</label> <br /> <input type="text" readonly="readonly"
                                            defaultValue={this.state.order && this.state.order.customers && this.state.order.customers.firstname + " " + this.state.order.customers.lastname} class="form-control" /></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group"><label>Delivery Location</label> <br /> <input type="text" readonly="readonly"
                                        value={this.state.order && this.state.order.delivery_location} class="form-control" /></div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group"><label>Delivery Date</label> <br /> <input type="text" readonly="readonly"
                                        value={this.state.order && this.state.order.delivery_date} class="form-control" /></div>
                                </div>
                            </div> <br /><br />
                        </div>
                    </form>
                </Modal>
                <Modal open={this.state.cancel} onClose={() => this.onCloseModal("cancel")} center>

                    <div class="modal-header">
                        <h4 class="modal-title">Cancel Dispute</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row form-group">
                            <div class="col-sm-12">
                                    {role === 'seller' ? <p >If you cancel, the buyer will be refunded in full. Are you sure you want to cancel this dispute?</p> 
                                        : <p >If you cancel, the seller will be credited in full. Are you sure you want to cancel this dispute?</p>}
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer"><button type="button" data-dismiss="modal"
                            class="btn btn-primary" onClick={() => this.onCloseModal("cancel")}>Don't Cancel</button>
                        &nbsp;&nbsp;
                <button onClick={this.handleCancel} disabled={canceling} type="button" class="btn btn-danger">
                {canceling ? 'Canceling ...' : 'Cancel'}</button>
                </div>

                </Modal>
            </div>
        </div>
       
        )
    }
}

export default Disputes;