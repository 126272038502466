import React, {Component} from 'react';
import ReactModal from 'react-responsive-modal';
import transactionHelpers from '../helpers/transaction';
import { user } from '../actions/user';
// const ModalStyles = {
//     content : {
//       top                   : 'auto',
//       left                  : 'auto',
//       right                 : 'auto',
//       bottom                : 'auto',
//       marginRight           : '-50%',
//       transform             : 'translate(-50%, -50%)',
//       width                 : '70%'
//     }
//   };


const InfoView = (props) => (
    <ReactModal
            open={props.open}
            onClose={props.onClose}
        >

        <div class="" style={{ padding: 20}}>
            <div class="row">
                <div class="col-10">
					<img src="/dist/img/logo.3fc64c3.png" alt="AtaraPay Logo" style={{opacity: 1 ,width: "200px", paddingLeft: 15}} />
                </div>
            </div>
            <div class="">
				<h2><strong>Why Escrow? How Does Escrow Work?</strong></h2>
				<h3><strong>Why Escrow?</strong></h3>
				<p><font size="4px" face="Alegreya Sans">‘Escrow’ refers to money held by a trusted third-party on behalf of the buyer and seller. The payment is released to the seller only after the buyer is satisfied all the terms of the agreement have been met. So, by entrusting your funds with a credible and trusted 3rd party as the Bank, you protect your funds from loss, theft or fraud. This is the AtaraPay promise to buyers and sellers.</font></p>

				<h4 id="how-does-escrow-work"><strong>How does Escrow Work?</strong></h4>
				<p><font size="4px" face="Alegreya Sans">AtaraPay eliminates your risk and increases your value by acting as a neutral third- party to secure the transaction for everyone involved. This means you have the confidence to buy and sell with anyone, anywhere – with virtually zero risk.</font></p>
				<ol>
					<li><strong>AGREE on a price</strong></li>
					<li><strong>SECURE your online escrow deposit</strong></li>
					<li><strong>DELIVERY of product or service</strong></li>
					<li><strong>PAY after inspecting your purchase</strong></li>
					<li><strong>ENJOY a safe and worry-free transaction</strong></li>
				</ol>

				<h4 id="stay-informed"><strong>Stay Informed Always</strong></h4>
				<p><font size="4px" face="Alegreya Sans">Both seller or buyer, you are kept in the know of every stage of your transaction, whether the order was rejected or accepted or cancelled by the buyer. Seller is also informed on the security of their goods at point of delivery.</font></p>

				<h4 id="trust-and-accountability"><strong>Trust and Accountability</strong></h4>
				<p><font size="4px" face="Alegreya Sans">When you pay into the escrow account, your funds are held securely with no charges until the end of the transaction. As the buyer, you do not experience any loss or risk to your money. Your funds are safe and protected by the credibility of our partner Bank.</font></p>

				<h4 id="sellers-are-protected"><strong>Sellers are protected too</strong></h4>
				<p><font size="4px" face="Alegreya Sans">When a purchase is made online, an unserious buyer may decide to cancel the order much later after the order may have been packaged by the seller and ready for dispatch. With AtaraPay, sellers can now enforce a cancellation fee to weed out unserious buyers, enabling to make logistics plans only for well-meaning buyers.</font></p>

				<h3 id="payment"><strong>Online Payment VS Other payment options</strong></h3>
				<p><font size="4px" face="Alegreya Sans">Cash on delivery (COD), cheques and bank transfers are all payment options that slow down the delivery process. They are all prone to delays and paper documentations which easily expose the transaction to fraud.</font></p>
				<p><font size="4px" face="Alegreya Sans">Online payment on the other hand is made securely at the comfort of your home or office or while on holiday anywhere anytime without bothering about delays. It is fast and all stages of the transactions are documented making it super easy for you to organize all your purchases. What’s more, you can reference the status of your orders from anywhere and in real time.</font></p>
				<p><font size="4px" face="Alegreya Sans">AtaraPay supports online bank transfer, debit and credit payment methods which can be used to pay into escrow for small transactions like buying a toy for your child or big-ticket transactions like buying a parcel of land for your dream home. Either way, AtaraPay protects you from financial loss and fraud while online or even offline.</font></p>

				<h3 id="benefits"><strong>Who is Escrow For?</strong></h3>
				<ol>
    				<li>For Seller that want to reduce fulfilment risk by ensuring funds are available for the product in advance.</li>
    				<li>For Sellers that prefer cash in advance for their service.</li>
    				<li>For first time business transactions with the attendant uncertainty of service delivery and payment fulfilment.</li>
    				<li>For short term transactions.</li>
    				<li>For high value transactions.</li>
    				<li>For parties unfamiliar in a transaction.</li>
    				<li>For serious buyers with high liquidity.</li>
    				<li>For funds protection without any underwriting required.</li>
				</ol>
				
				<h3 id="advantages"><strong>Advantages of Using AtaraPay</strong></h3>
				<ul>
					<li>Enjoy the protection of an international bank while you buy and sell online</li>
					<li>Be informed in every step of the transaction through real-time and online notifications at point of delivery</li>
					<li>Sellers can leverage our Sub-Sahara Africa presence to conduct their eCommerce business providing wider visibility to their products and services</li>
					<li>Offer true sales protection for sellers through flexible merchant API</li>
					<li>Full protection from ills introduced by Cash on Delivery payment method such as theft, impersonation and fraud</li>
				</ul>
			
				<h3 id="ecommerce"><strong>Supported eCommerce Items</strong></h3>
				<p>
					<font size="4px" face="Alegreya Sans">The following goods and services can be safely purchased online with AtaraPay with zero risk;</font>
				</p>
				<ul>
					<li>OEM packed goods from e-Commerce websites</li>
					<li>Online Contract Services such as freelance work providers</li>
					<li>Motor Vehicles and Airline tickets (direct/ non-agency sales)</li>
					<li>Property rental payments, Land and house purchase</li>
					<li>Luxury goods like jewelry, designer watches</li>
					<li>Logistics and Bidding marketplaces</li>
				</ul>
				<p>Enjoy the protection of an international bank while you buy and sell online</p>
				<p>Be informed in every step of the transaction through real-time and online notifications at point of delivery</p>
				<p>Sellers can leverage our Sub-Sahara Africa presence to conduct their eCommerce business providing wider visibility to their products and services</p>
				<p>Offer true sales protection for sellers through flexible merchant API</p>
				<p>Full protection from ills introduced by Cash on Delivery payment method such as theft, impersonation and fraud</p>

			</div>
            <div className="clearfix">
                <div className="float-right">
                    <button onClick={props.onClose} class="btn btn-md btn-info">Agree</button>
                </div>
            </div>
        </div>
    </ReactModal>
);
export default InfoView;