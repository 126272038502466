export const term = {
    CREATE_TERM: 'CREATE_TERM',
    CREATE_TERM_SUCCESS: 'CREATE_TERM_SUCCESS',
    CREATE_TERM_FAILURE: 'CREATE_TERM_FAILURE',

    EDIT_TERM: 'EDIT_TERM',
    EDIT_TERM_SUCCESS: 'EDIT_TERM_SUCCESS',
    EDIT_TERM_FAILURE: 'EDIT_TERM_FAILURE',

    REQUEST_TERM: 'REQUEST_TERM',
    REQUEST_TERM_SUCCESS: 'REQUEST_TERM_SUCCESS',
    REQUEST_TERM_FAILURE: 'REQUEST_TERM_FAILURE',

    CREATE_ADDRESS: 'CREATE_ADDRESS',
    CREATE_ADDRESS_SUCCESS: 'CREATE_ADDRESS_SUCCESS',
    CREATE_ADDRESS_FAILURE: 'CREATE_ADDRESS_FAILURE',

    REQUEST_ADDRESSES: 'REQUEST_ADDRESSES',
    REQUEST_ADDRESSES_SUCCESS: 'REQUEST_ADDRESSES_SUCCESS',
    REQUEST_ADDRESSES_FAILURE: 'REQUEST_ADDRESSES_FAILURE',

    DELETE_ADDRESS: 'DELETE_ADDRESS',
    DELETE_ADDRESS_SUCCESS: 'DELETE_ADDRESS_SUCCESS',
    DELETE_ADDRESS_FAILURE: 'DELETE_ADDRESS_FAILURE',

    DEFAULT_ADDRESS: 'DEFAULT_ADDRESS',
    DEFAULT_ADDRESS_SUCCESS: 'DEFAULT_ADDRESS_SUCCESS',
    DEFAULT_ADDRESS_FAILURE: 'DEFAULT_ADDRESS_FAILURE',

    REQUEST_SERVICE_PROVIDERS: 'REQUEST_SERVICE_PROVIDERS',
    REQUEST_SERVICE_PROVIDERS_SUCCESS: 'REQUEST_SERVICE_PROVIDERS_SUCCESS',
    REQUEST_SERVICE_PROVIDERS_FAILURE: 'REQUEST_SERVICE_PROVIDERS_FAILURE',


    MAX_DELIVERY_DAY:'7'
}
