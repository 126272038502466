import { settingConstants } from "../constants";

const settings = (state = {}, action) => {
  switch (action.type) {
    case settingConstants.settings.SET_REFUNDABLE:
      return { loading: true };
    case settingConstants.settings.SET_REFUNDABLE_SUCCESS:
      return { loading: false };
    case settingConstants.settings.SET_REFUNDABLE_FAILURE:
      return { loading: false };

    case settingConstants.settings.SET_SP_TRANSFER_FEE:
      return { loading: true };
    case settingConstants.settings.SET_SP_TRANSFER_FEE_SUCCESS:
      return { loading: false };
    case settingConstants.settings.SET_SP_TRANSFER_FEE_FAILURE:

    case settingConstants.settings.SET_ESCROW_BEARER:
      return { loading: true };
    case settingConstants.settings.SET_ESCROW_BEARER_SUCCESS:
      return { loading: false };
    case settingConstants.settings.SET_ESCROW_BEARER_FAILURE:
      return { loading: false };

    case settingConstants.settings.SET_PAYMENT:
      return { loading: true };
    case settingConstants.settings.SET_PAYMENT_SUCCESS:
      return { loading: false };
    case settingConstants.settings.SET_PAYMENT_FAILURE:
      return { loading: false };

    case settingConstants.settings.SET_BVN_VERIFICATION:
      return { loading: true };
    case settingConstants.settings.SET_BVN_VERIFICATION_SUCCESS:
      return { loading: false };
    case settingConstants.settings.SET_BVN_VERIFICATION_FAILURE:
      return { loading: false };
    case settingConstants.settings.GET_BANKS:
      return { loading: true };
    case settingConstants.settings.GET_BANKS_SUCCESS:
      return { banks: [...action.banks] , loading: false};
    case settingConstants.settings.GET_BANKS_FAILURE:
      return { loading: false };
    default:
      return state;
  }
};

export default settings;
