import React, { Component } from "react";
import Header from "./components/_header";
import SideBar from "./components/_sidebar";
import Main from "./components/Main";
import Footer from "./components/Footer";
import { Switch, Route } from "react-router-dom";
import Login from "./components/_login";
import { connect } from "react-redux";
import Register from "./components/_register";
import ForgotPass from "./components/_forgotPass";
import ResetPass from "./components/_resetPass";
import Confirmation from "./components/_confirm";
import POD from "./components/_pod";
import Identification from "./views/payout/Identification";
import ProviderOrder from "./views/ProviderOrder";
import SupportVerification from "./components/SupportVerification";
import PaymentGateway from "./components/PaymentGateway";
import FailedPayment from "./components/FailedPayment";
import "./App.css";
import { withRouter } from "react-router-dom";
import { alertActions } from "./actions";
import Auth from "./helpers/auths";
import EasyP2P from "./views/EasyP2P";
import EasyP2PPayment from "./views/EasyP2PPayment";
import ShortcodeRedirect from "./views/ShortcodeRedirect";
import ReferralCodeRedirect from "./views/ReferralCodeRedirect";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuth: false,
      isLoading: true,
    };

    this.props.history.listen((location, action) => {
      // clear alert on location change
      const { dispatch } = this.props;
      dispatch(alertActions.alert.clear());
    });
  }

  // }
  render() {
    return (
      <div>
        <Switch>
          {/* <Route exact redirect="/app" path="/" component={Dasboard} /> */}
          <Route path='/login/:type?' component={Auth(Login)} />
          <Route
            path='/mail/:mailType?/:name/:amount/:code'
            component={Login}
          />
          <Route exact path='/forgot/:type' component={ForgotPass} />
          <Route path='/pod/:type?/:orderNo?/:phone?' component={POD} />
          <Route
            path='/seller/identification/:idNumber?/:action?'
            component={Identification}
          />

          <Route
            path='/delivery/orderpickup/:orderCode'
            component={ProviderOrder}
          />

          <Route path='/reset/:type/:email?/:token?' component={ResetPass} />

          <Route
            path='/seller/business/:token/:answer'
            component={SupportVerification}
          />
          <Route
            path='/signup/:type/:email/:phone/:ref?'
            component={Register}
          />
          <Route
            path='/register/:type?/:getstarted?/:email?/:phone?/:subrole?'
            component={Register}
          />

          <Route path='/confirmation/:type/:token' component={Confirmation} />
          <Route
            path='/easyp2p/seller/:email/:phone'
            render={(props) => <EasyP2P {...props} sellerLink={true} />}
          />
          <Route
            path='/easyp2p/:email?/:phone?'
            render={(props) => <EasyP2P {...props} sellerLink={false} />}
          />

          <Route exact path={"/payment/failed"} component={FailedPayment} />

          <Route
            exact
            path={"/payment/success/:ref?"}
            component={PaymentGateway}
          />
          <Route
            exact
            path={"/payment/engine/:ref?"}
            component={PaymentGateway}
          />

          <Route
            exact
            path='/app/easyp2p-payment/:buyer_email?'
            component={EasyP2PPayment}
          />
          <Route exact path='/u/:short_code?' component={ShortcodeRedirect} />
          <Route
            exact
            path='/refer/:referral_code?'
            component={ReferralCodeRedirect}
          />
          <Route
            redirect='/app/dashboard'
            path='/'
            component={() => (
              // (user&&user?
              <div>
                <Header />
                <SideBar />
                <Main />
                <Footer />
              </div>
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(connect()(App));
