import { BASE_URL, LOCALSTORAGE_TOKEN_KEY } from '../constants';
import axios from 'axios';
import { headers } from '../constants';

const getTransactions = (type) => {
  return axios({
    method: 'get',
    mode: 'no-cors',
    url: `${BASE_URL}${type}/transaction/view`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      'Content-Type': 'application/json',
    },
  });
};

const createTransaction = (type, transaction) => {
  return axios({
    method: 'post',
    mode: 'no-cors',
    url: `${BASE_URL}${type}/transaction/create`,
    data: transaction,
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      'Content-Type': 'application/json',
    },
  });
};

const updateTransaction = (type, transaction) => {
  console.log(transaction);
  return axios({
    method: 'put',
    mode: 'no-cors',
    url: `${BASE_URL}${type}/transaction/update/${transaction.id}`,
    data: transaction,
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      'Content-Type': 'application/json',
    },
  });
};
const cancelTransaction = (type, response) => {
  return axios({
    method: 'put',
    mode: 'no-cors',
    url: `${BASE_URL}${type}/transaction/cancel/${response.order_id}`,
    data: response,
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      'Content-Type': 'application/json',
    },
  });
};
const submitResponse = (type, response) => {
  return axios({
    method: 'post',
    mode: 'no-cors',
    url: `${BASE_URL}${type}/transaction/respond`,
    data: response,
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      'Content-Type': 'application/json',
    },
  });
};

const makePayment = (type, transaction, alt, paymentType) => {
  const { id, promo_code } = transaction;
  return axios({
    method: 'post',
    mode: 'no-cors',
    url: `${BASE_URL}customer/transaction/${
      paymentType == 'PayPal' ? 'paypalPayment' : 'payment'
    }`,
    data: { order_id: id, promo_code: promo_code, alt: alt },
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      'Content-Type': 'application/json',
    },
  });
};

const makePaymentUSSD = (type, transaction, alt) => {
  const { id, promo_code } = transaction;
  return axios({
    method: 'post',
    mode: 'no-cors',
    url: `${BASE_URL}customer/transaction/ussdpayment`,
    data: { order_id: id, promo_code: promo_code, alt: alt },
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      'Content-Type': 'application/json',
    },
  });
};
const checkUser = (role, data) => {
  return axios({
    method: 'post',
    mode: 'no-cors',
    url: `${BASE_URL}${role}/check`,
    data: data,
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      'Content-Type': 'application/json',
    },
  });
};

export const transaction = {
  getTransactions,
  createTransaction,
  cancelTransaction,
  updateTransaction,
  submitResponse,
  makePayment,
  makePaymentUSSD,
  checkUser,
};
