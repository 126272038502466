import * as yup from 'yup';

const adServiceSchema = yup.object().shape({
  service: yup.string(),
  cost: yup
    .number('Please enter a Valid Cost')
    .typeError('Please enter a Valid Cost')
    .positive('Please Enter a valid cost')
    .required(),
  selected: yup.boolean(true).required(),
  payment_recipient: yup
    .number('Please Select a Valid Payment Recipient')
    .typeError('Please Select a Valid Payment Recipient'),
});
export const transactionSchema = yup.object().shape({
  role: yup.string(),
  currency: yup.string().required('Please select valid currency'),

  payment_method: yup
    .string()
    .oneOf(['USSD', 'Paystack', 'Paypal'], 'Please select a Payment method')
    .required('Please select a Payment method'),

  category: yup.string().required('Please select a transaction category'),

  buyer_email: yup.string().when('role', {
    is: (val) => val == 'seller',
    then: yup
      .string()
      .email('Please provide a valid email')
      .required("Please provide buyer's email address"),
  }),
  buyer_phone: yup.string().when('role', {
    is: (val) => val == 'seller',
    then: yup.string().required("Please provide buyer's phone number"),
  }),

  // buyer_phone: yup.string().required("The Buyer's Phone Number is required"),

  seller_email: yup.string().when('role', {
    is: (val) => val == 'customer',
    then: yup
      .string()
      .email('Please provide a valid email')
      .required("Please provide seller's email address"),
  }),
  seller_phone: yup.string().when('role', {
    is: (val) => val == 'customer',
    then: yup
      .string('Please provide a valid phone number')
      .required("Please provide seller's phone number"),
  }),

  // seller_phone: yup.string("Phone is required").required("The Seller's Phone Number is required"),

  //amount_payed: yup.number().min((200/conversion_ratio).toFixed(2), "Price must be at least "+currency+ " "+ (200/conversion_ratio).toFixed(2)).required("Please provide a valid amount"),
  amount_payed: yup
    .number()
    .min(1000, 'Price must be at least NGN 1000')
    .required('Please provide a valid amount'),
  quantity: yup.number().when('category', {
    is: (val) => val == 'Money Market',
    then: yup
      .number()
      .typeError('Please provide a valid Currency Amount')
      .min(1, 'Currency Amount must be greator than equal to 1')
      .integer()
      .required('Please provide Currency Amount'),
    otherwise: yup
      .number()
      .typeError('Please provide a valid quantity')
      .min(1, 'Quantity must be greator than equal to 1')
      .integer()
      .required('Please provide quantity'),
  }),

  escrow_fee_bearer: yup
    .string()
    .oneOf(
      ['customer', 'seller', 'both'],
      'Please select who pays the escrow fee'
    )
    .required('Please select who pays the escrow fee'),

  shipping_method: yup
    .number()
    .oneOf([0, 1], 'Please select a shipping method'),

  shipping_fee_bearer: yup.string().when('shipping_method', {
    is: (val) => val == 1,
    then: yup
      .string()
      .required('Please select who pays the shipping fee')
      .oneOf(
        ['customer', 'seller', 'both'],
        'Please select who pays the shipping fee'
      ),
  }),

  shipping_cost: yup.string().when('shipping_method', {
    is: (val) => val == 1,
    then: yup.string().required('Please provide the shipping cost'),
  }),

  extra: yup.object().when('category', {
    is: (val) => val == 'automobile',
    then: yup.object().shape({
      vin: yup
        .string()
        .min(17, 'Vehicle Inspection Number - VIN is a 17 character number')
        .max(17, 'Vehicle Inspection Number - VIN is a 17 character number')
        .required('Please Enter a Vehicle Inspection Number'),
      make: yup.string().required('Please select the Make of the vehicle'),
      model: yup.string().required('Please select the Model of the vehicle'),
      year: yup
        .string()
        .required('Please select the Year the vehicle was manufactured'),
      registration_expiry: yup
        .date()
        .required('Please Enter Vehicle Expiry Date'),
      odometer: yup.string().required('Enter Odometer Readings'),
    }),
  }),
  conversion_ratio: yup.number().when('category', {
    is: (val) => val == 'Money Market',
    then: yup.number().required('conversion_ratio is required'),
  }),
  recipient: yup.object().when('category', {
    is: (val) => val == 'Money Market',
    then: yup.object().shape({
      recipient_name: yup.string().required('Please Enter Recipient Name'),
      recipient_address: yup
        .string()
        .required('Please Enter Recipient Address'),
      recipient_bank_name: yup
        .string()
        .required('Please Enter Recipient Bank Name'),
      recipient_bank_address: yup
        .string()
        .required('Please Enter Recipient Bank Address'),
      recipient_account_number: yup
        .string()
        .required('Please Enter Recipient Bank Account number'),
      recipient_account_type: yup
        .string()
        .required('Please Select Account type'),
      recipient_bank_swift: yup
        .string()
        .required('Please Enter Recipient Bank Swift'),
    }),
  }),

  max_delivery_days: yup.string().notRequired(),
  ///.min(1, "Maximum Delivery Days must be at least 1 day.").required("Fix a maximum number of delivery days"),
  product_desc: yup
    .string('Please provide a valid description')
    .required('Please provide a description'),
  delivery_location: yup
    .string('Please provide a valid Shipping Address')
    .required('Please provide a shipping address'),

  is_additional_services: yup
    .number('The Additional Services field is required')
    .typeError('Do you require an Additional Service')
    .oneOf([0, 1], 'The Additional Services field is required'),

  additional_services: yup.array().when('is_additional_services', {
    is: (val) => val == 1,
    then: yup.array().of(adServiceSchema).required(),
    otherwise: yup.array(),
  }),
});

export default transactionSchema;
