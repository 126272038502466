import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";


import Register from '../views/Register';
import {userActions} from '../actions';

const mapStateToProps = (state) => {
  const {registering} = state.register;
  const {msg} = state.users;


  const alert = state.alert;
  return {
    alert,
    registering,
    msg,
    referral: state.referral,
  };


}
const mapDispatchToProps = (dispatch) => ({
    dispatch,
    checkVerified: (data, history) => dispatch(userActions.user.checkVerified(data, history)),
  }
)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));