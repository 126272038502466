import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import PayoutReport from "../views/payoutReport";
import { reportActions } from "../actions";

const mapStateToProps = (state) => {
  const { loading, report, marketPlaceSellers } = state.reports;
  const { accounts } = state.payout;
  const { user } = state.users;
  const alert = state.alert;
  return {
    alert,
    loading,
    report,
    accounts,
    marketPlaceSellers,
    user,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getPayoutReport: (type, history) =>
    dispatch(reportActions.report.getPayoutReport(type, history)),
  generateReport: (type, data, report_type, history) =>
    dispatch(
      reportActions.report.generateReport(type, data, report_type, history)
    ),
  getMarketPlaceSellers: (type, history) =>
    dispatch(reportActions.report.getMarketPlaceSellers(type, history)),
  dispatch,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PayoutReport)
);
