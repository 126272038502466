import React , {Component} from 'react';
import {Switch,Route} from 'react-router-dom';
import {BrowserRouter as Router} from 'react-router-dom'; 
import Create from './Create';
import Help from './Help';


class P2P extends Component{
    view
    render(){

        return(
                <Route>
                    <Switch>
                        <Route exact path="/app/transaction" component={Create} />
                        <Route path="/app/transactions/help" component={Help} />
                     </Switch>
                </Route>
                    
            
        )
    }
}
export default P2P;