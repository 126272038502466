import React , {Component} from 'react';
import termSchema from '../validation/terms';
import { termActions, alertActions } from '../actions'
import {Link} from 'react-router-dom';
import Modal from 'react-responsive-modal';
import swal from '@sweetalert/with-react'

const intialState ={
    open:false,
    fields:{
     current_password: "",
     new_password: "",
     new_password_confirmation: ""
    }
}
class Profile extends Component{
    constructor(props){
      super(props);
      this.state={
        open:false,
        fields:{
         current_password: "",
         new_password: "",
         new_password_confirmation: ""
        },
        profile:{
            firstname: this.props.user && this.props.user.firstname || "",
            lastname:this.props.user && this.props.user.lastname || "",
            business_name:this.props.user && this.props.user.seller && this.props.user.seller.business_name && this.props.user.seller.business_name,
            phone_number:this.props.user && this.props.user.phone_number || "",
            email:this.props.user && this.props.user.email,
            business_email: this.props.user && (this.props.user.seller && this.props.user.seller.business_email ? this.props.user.seller.business_email : ""),
        },
        role: localStorage.getItem("trust_user_type"),
        business_email: null
        }
    }
    

    handleChange=(e)=>{

        const { name, value } = e.target;
        const { fields } = this.state;
        this.setState({   
            fields: {
                ...fields,
                [name]: value
            }
            
        })
    }

    handleUpdate=(e)=>{

        const { name, value } = e.target;
        const { profile } = this.state;
        this.setState({   
            profile: {
                ...profile,
                [name]: value
            }
        })
    }

    handleSubmit=(e)=> {
        
        e.preventDefault();
     
   
        const {fields} = this.state;
        this.setState({ submitted: true });
 
        if (fields.current_password && fields.new_password) {
            
           this.props.update(localStorage.getItem("trust_user_type"),fields,this.props.history);
        }
    }

    updateProfile = (e)=>{
        e.preventDefault();
        let { profile } = this.state;
        if(this.state.role === 'seller' || this.state.role === 'marketplace'){
            profile = {
                ...profile,
                business_email: profile.business_email
            }
        }
        
        this.props.updateProfile(localStorage.getItem("trust_user_type"),profile,this.props.history);
    }
    onOpenModal = () => {
        this.setState({ open: !this.state.open });
       
      };
    
      onCloseModal = () => {
        this.setState({ open: false });
      };

      componentWillReceiveProps(props){
          console.log("pp: ",props);
          if(props.user){
              const profile = {...props.user}
              this.setState({profile});
          }
          
        if(props.alert && props.alert.message && props.alert.type === "alert-danger"){
            swal("Error",props.alert.message,"error").then(() => {
                props.dispatch(alertActions.alert.clear());
              });
        }else if(props.alert && props.alert.message && props.alert.type=="alert-success"){
            swal("Success",props.alert.message,"success").then(() => {
                props.dispatch(alertActions.alert.clear());
              });
            this.setState({open:intialState.open,fields:intialState.fields})
        }
          
    }
   render(){
      
       const { user, updating,alert,loading} = this.props;
       const {fields,profile} = this.state;
       return(
        <div>
            <Modal open={this.state.open} onClose={()=>{this.onCloseModal()}} center>
      
       
                  
                   <div class="modal-header">
                       <h4 class="modal-title"><small>Update Password</small></h4>
                     
                   </div>
       
                   
                   <div class="modal-body">
       
                       <div class="alert update-password-alert alert-danger d-none">
                           <a class="close alert-close pointer" aria-label="close"  onClick={this.hideAlert}>&times;</a>
                           <span class="update-password-alert-message-content"></span>
                       </div>
                       <form onSubmit={this.handleSubmit} style={{width:"400px"}}>
                       {/* {alert && alert.message && <div class={`alert buyer-alert ${alert.type}`} ><a  
                                aria-label="close" class="close alert-close">×</a> <span class="alert-message-content">{alert.message}</span></div>} */}
                       <div class="form-group">
                       <label for="cur_pwd"><small>Please enter your current password*</small></label>
       
                       <input type="password" name="current_password" width="150" value={fields.current_password} onChange={this.handleChange} v-model="cur_pwd" id="cur_pwd" class="form-control" required="required" />
                       </div>
       
                       <div class="form-group">
                       <label for="new_pwd"><small>Please enter your new password*</small></label>
       
                       <input type="password"  name="new_password" value={fields.new_password} onChange={this.handleChange} v-model="new_pwd" id="new_pwd" class="form-control" required="required" />
                       </div>
       
                       <div class="form-group">
                       <label for="pwd_confirmation"><small>Please confirm your new password*</small></label>
       
                       <input type="password"  name="new_password_confirmation" value={fields.new_password_confirmation} onChange={this.handleChange} v-model="pwd_confirmation" id="pwd_confirmation" class="form-control" required="required" />
                       </div>
       
                       <button class="btn btn-info" onClick={this.updatePassword}>Submit</button>
                        </form>
                   </div>
       
       
               
           </Modal>
       
           <div class="content-wrapper">
              
               <div class="content-header">
               <div class="container-fluid">
                   <div class="row mb-2">
                   <div class="col-sm-12">
                       <h1 class="m-0 text-dark">Update your account</h1>
                   </div>
                   </div>
               </div>
               </div>
           
               <section class="content">
               <div class="container-fluid">
               
                   <button type="button" class="btn btn-primary" onClick={()=>{this.onOpenModal()}}>
                   <i class="fa fa-lock"></i>&nbsp;&nbsp;&nbsp;Update Password
                   </button>
                   <br /><br />
                   <div class="row">
                   <div class="col-12 col-sm-12 col-md-12">
                       <div class="alert update-alert alert-danger d-none">
                           <a class="close alert-close pointer" aria-label="close" onClick={this.hideAlert}>&times;</a>
                           <span class="update-alert-message-content"></span>
                       </div>
                       <div class="callout callout-info">
                       <br />
                    <form onSubmit={this.updateProfile}>

                      
                    {(this.state.role !== 'marketplace' && this.state.role !== 'seller') || (this.props.user && this.props.user.seller && !this.props.user.seller.business_name) ?
                       <div class="row">
                           <div class="col-sm-6">
                           <div class="form-group">
                               <label for="customer_first_name">First Name</label>
                               <input type="text" value={profile.firstname} v-model="first_name" id="customer_first_name" class="form-control" autocomplete="off" disabled readonly />
                           </div>
                           </div>
                           <div class="col-sm-6">
                           <div class="form-group">
                               <label for="customer_last_name">Last Name</label>
                               <input type="text" value={profile.lastname} v-model="last_name" id="customer_last_name" class="form-control" autocomplete="off" disabled readonly />
                           </div>
                           </div>
                       </div> :
                       <div class="row">
                           <div class="col-sm-6">
                           <div class="form-group">
                               <label for="phone">Business Name</label>
                               <input type="text" value={profile.business_name} v-model="phone" id="phone" class="form-control" autocomplete="off" disabled readonly/>
                           </div>
                           </div>
                           <div class="col-sm-6">
                           <div class="form-group">
                               <label for="email">Business Email Address</label>
                               <input type="text" value={profile.business_email}  name="business_email" id="email" onChange={this.handleUpdate} class="form-control" autocomplete="off"  required/>
                                <small id="passwordHelpBlock" class="form-text text-muted">
                                   This email address is used to receive AtaraPay service messages
                                </small>
                           </div>
                           </div>
                       </div>
                       }
                       <div class="row">
                           <div class="col-sm-6">
                            <div class="form-group">
                                <label for="phone">Phone Number</label>
                                <input type="text" value={profile.phone_number} v-model="phone" id="phone" class="form-control" autocomplete="off" disabled readonly/>
                            </div>
                           </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="email">Contact Email Address</label>
                                    <input type="text" value={profile.email} name="email" id="email" onChange={this.handleUpdate} class="form-control" autocomplete="off" required />
                                    <small id="passwordHelpBlock" class="form-text text-muted">
                                       {this.state.role !== 'seller' ||  (this.props.user && this.props.user.seller && this.props.user.seller.business_name === null)  ?
                                       "This email address is used as username to login and receive AtaraPay service messages" :
                                       "This email address is used as username to login"
                    }
                                    </small>
                            </div>
                            </div>
                       </div>
                       <div class="row" >
                           <div class="col-sm-6">
                           <div class="form-group">
                                <button disabled={updating} class="btn btn-sm btn-info" onClick={this.updateProfile} >{updating ? 'Updating . . .' : 'Update'}</button>
                           </div>
                           </div>
                       </div>
                                       </form>
                       </div>
                       
                   </div>
                   </div>
                   <br />
       
               </div>
               </section>
               
           </div>
       </div>
       )
   }
}

export default Profile;
