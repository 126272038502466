import React , {Component} from 'react';
import {Link} from 'react-router-dom';
import { payoutActions,alertActions,userActions } from '../actions';
import { ClipLoader } from 'react-spinners';
import swal from '@sweetalert/with-react';
import Modal from 'react-responsive-modal';
import Tour from 'reactour';
import CreateServiceProvider from './payout/CreateServiceProvider';
import CreateBankDetails from './payout/CreateBankDetails';
import { loaderOverride } from "../helpers/styleHelper";

const cardTypes =  [
    "International Passport",
    "National ID Card",
    "Voter's Card"
]


const  initialState = {
    details:{
        bank_name: "",
        bank_code: "",
        nip_bank_code: "",
        bank_account_name: "",
        bank_account_number: ""
    },
    account_id: "",
    account_type: null,

    submitted: false
};

class PayOutdetail extends Component{
    constructor(props) {
        super(props);

        // reset login status
        // dispatch(userActions.user.logout());

        this.state = {
            details:{
                bank_name: "",
                bank_code: "",
                nip_bank_code: "",
                bank_account_name: "",
                bank_account_number: ""
            },
            account_id: "",
            account_type: null,
            data:"",
            banks:[],
            accounts:"",
            detail:"",
            add:false,
            addRecipent:false,
            addSP:false,
            edit:false,
            checked: 0,
            delete:false,
            default:false,
            filter:"",
            role: 'seller',
            submitted: false,
            startTour:false,
            serviceProviders:[],
            addPI:false
        };

    }

    componentDidMount= ()=>{
      if (localStorage.getItem("trust_user_type") === 'seller' || localStorage.getItem("trust_user_type") === 'marketplace'){
          this.props.view("seller", this.props.history);
      }else{
          this.props.view("customer", this.props.history);
          this.setState({role: 'customer'});
      }
      if (this.props.user && (localStorage.getItem("trust_user_type") === 'seller'|| localStorage.getItem("trust_user_type") === 'marketplace')){
            this.setState({'startTour':this.props.user.payment_tour_done?false:true});
        }
      this.props.getBanks(localStorage.getItem("trust_user_type"))
    }
    componentWillReceiveProps=(props)=>{
        if(props.accounts){
          /* console.log(props.accounts); */
          this.setState({data:props.accounts});
          this.setState({accounts:props.accounts});
          const sp=props.accounts[0];
          if(sp){
              this.setState({serviceProviders:sp['service_providers']});
          }
        }
        if(props.alert && props.alert.message && props.alert.type==="alert-danger"){
            console.log("err", props.alert.message);
            swal("Error",props.alert.message,"error").then(() => {
                this.props.dispatch(alertActions.alert.clear());
              });;
        }
        if(props.alert && props.alert.message && props.alert.type==="alert-success"){
            swal("Success",props.alert.message,"success").then(() => {
                this.onCloseModal("add")
                this.onCloseModal("addSP")
                this.onCloseModal("default")
                this.onCloseModal("edit")
                this.onCloseModal("delete")
                this.props.dispatch(alertActions.alert.clear());
              });;
              this.setState({initialState});
        }
    }
    handleEditChange=(e)=>{
       let {name,value} = e.target;
       let {detail}=this.state;
       this.setState({
           detail:{
               ...detail,
               [name]:value
           }
       })
    }
    handleChange=(e)=>{
        let { name, value } = e.target;
        let { type } = e.target.type === 'checkbox' ?  e.target.checked ? value = 1 : value = 0 : e.target.value;
        let { details } = this.state;
        this.setState({ details: {...details, [name]: value }
        })
    }


    filterTable=(filter)=>{
        console.log(filter);
        var data = this.state.data;
        var filteredRows = [];
        // this.setState({data:[...this.state.deliveryMen]});
      if(filter !== ""){
        for(var i = 0; i < data.length; i++){

            if(data[i].bank_name.toUpperCase().includes(filter.toUpperCase()) || data[i].bank_account_name.toUpperCase().includes(filter.toUpperCase())||
                (data[i].bank_account_number&&data[i].bank_account_number.toUpperCase().includes(filter.toUpperCase()))
            ){
                filteredRows.push(data[i]);
            }
        }

          this.setState({accounts:[...filteredRows]});
      }else{
        this.setState({accounts:[...this.state.data]});
      }

    }
    handleBankChange=(e)=>{
        const { name, value } = e.target;

        const banks = this.props.banks
        console.log(
         banks.find(e=>e.id===value).name);

        const { details } = this.state;
        this.setState({
            details: {
                ...details,
                bank_name: banks.find(e=>e.id===value).name,
                bank_code: banks.find(e=>e.id===value).code,
                nip_bank_code: banks.find(e=>e.id===value).nip_bank_code
            }
        })
    }

    handleImageUpload = async (e) => {
        let file = e.target.files[0];
        let type = file.type;
        if (type === "image/jpeg" || type === "image/png" || type === "image/jpg") {
            if (file.size <= 2 * 1024 * 1024) {
                this.setState({ image: file });
                const {details} = this.state;
                this.setState({
                    details: {
                        ...details,
                        identification_card: file
                    }
                })
            } else
                swal("Error",("File size must be smaller than 2 MB").replace(/_/g, " "),"error").then(() => {
                    alertActions.alert.clear();
                });
        } else {
            swal("Error",("File type must be png, jpg, jpeg").replace(/_/g, " "),"error").then(() => {
                alertActions.alert.clear();
            });
        }
    }

    handleCardTypeChange=(e)=>{
        const { name, value } = e.target;

        const { details } = this.state;
        this.setState({
            details: {
                ...details,
                card_type: value
            }
        })
    }
    onOpenModal = (name,detail) => {
        this.setState({ [name]: true });
        this.setState({detail});

      };

      onCloseModal = (name) => {
        this.setState({ [name]: false, detail:""});
      };

      handleFilter = (e) => {
        const {value} = e.target;
        this.setState({filter:value},()=>this.filterTable(this.state.filter));
        // this.filterTable();

    }

    handleSubmit=(details, type)=> {
        this.setState({ submitted: true });
        this.props.dispatch(payoutActions.payout.create(details,type,this.props.history));
    }

    handleSPSubmit=(details, type)=> {
        this.setState({ submitted: true });
        this.props.dispatch(payoutActions.payout.createSp(details,type,this.props.history));
    }

    handlePISubmit=(e)=> {
        e.preventDefault();

        const { details } = this.state;
        this.setState({ submitted: true });
        const type= e.target.dataset.type;
        if (details.card_type && details.identification_card && details.expiry_at) {
            let formData = new FormData();
            formData.append("identification_card", details.identification_card);
            formData.append("card_type", details.card_type);
            formData.append("expiry_at", details.expiry_at);
            this.props.dispatch(payoutActions.payout.createPi(formData,type,this.props.history));

        }
    }
    handleEdit=(e)=>{
        e.preventDefault();
        const {detail} = this.state;
        let data= {id:detail.id,type:detail.type};
        this.props.edit(data,localStorage.getItem("trust_user_type"),this.props.history);
        if(this.props.success === true)
            this.onCloseModal("edit")
    }
    setDefault=(e)=>{
        e.preventDefault();
        const {detail} = this.state;
        this.props.setDefault(detail.id,localStorage.getItem("trust_user_type"),this.props.history);
        this.onCloseModal("default")
    }
    handleDelete=(e)=>{
        e.preventDefault();
        const {detail} = this.state;
        // console.log(detail);
        this.props.delDetail(detail,localStorage.getItem("trust_user_type"),this.props.history);
        if(this.props.success === true)
            this.onCloseModal("delete")
    }
     handleSPDelete=(e,spId)=>{
        let prop=this.props;

        console.log('spId',prop);
        swal({
          title: "Are you sure?",
          text:"By clicking Ok you will proceed to Delete Service Provider.",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(sendToClient => {
            if(sendToClient){
                this.props.delSp(spId,localStorage.getItem("trust_user_type"),this.props.history);
               if(this.props.success === true)
                    setTimeout(function(){window.location.reload()},3000);

          }
        });
    }
    closeTour =() =>{
        this.setState({startTour:false});
    }
    finishTour=()=>{
       this.props.dispatch(userActions.user.updateTour(localStorage.getItem("trust_user_type"),{
            firstname:this.props.user.firstname,
            lastname:this.props.user.lastname,
            phone_number:this.props.user.phone_number,
            email:this.props.user.email,
            payment_tour_done:1,
        },this.props.history,localStorage.getItem("trust_user_type")==='marketplace'?'/app/api':'/app/delivery'));
    }


    render(){
        const { adding,alert,deleting,editing,updating,loading } = this.props;
        const { details,detail,accounts,submitted,serviceProviders } = this.state;
        const steps = [
          {
            selector: '.active',
            content:({ goTo, inDOM }) => (
                <p>Click the <b>Payout</b> tab and navigate to the <b>Payout Details</b> page</p>
                ),
            position:'bottom',
            style:{
                maxWidth:'35%',
                borderRadius:'5px',
                backgroundColor:"#0e2433",
                color:'#ffffff',
            },
          },
          {
            selector: '.first-step',
            content:({ goTo, inDOM }) => (
                <p>To enable you receive payment automatically to your account, click the <b>Add Bank Details</b> button. On the form that pops up, select the <b>Own Account</b> option under the <b>Account Type</b> dropdown field while providing your bank account details</p>
                ),
            position:'bottom',
            style:{
                maxWidth:'45%',
                borderRadius:'5px',
                backgroundColor:"#0e2433",
                color:'#ffffff',
            },
          }
        ]

        return(
            !loading && !loading ?
            <div className="content-wrapper" style={{minHeight: 152}}>
                <div className="content-header">
                {/*Tour section starts*/}
                    {
                        (localStorage.getItem("trust_user_type") === 'seller'||localStorage.getItem("trust_user_type") === 'marketplace')?
                        <Tour steps={steps} isOpen={this.state.startTour} onRequestClose={this.closeTour} disableKeyboardNavigation={['esc', 'right', 'left']}
                        lastStepNextButton={<button className="btn btn-danger" onClick={this.finishTour}>Got It!</button>} showNumber={false} />
                        :null
                    }
                {/*Tour section ends*/}
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <h1 className="m-0 text-dark">Payout detail</h1>
                            <div className="attop">
                                {
                                    this.state.role === 'seller' ?
                                    <p>Here, click the Add Bank Details button below and select the <b>Own Account</b> option under the Account Type dropdown field in the form to enter your bank details. This will enable you receive payment to your account when Buyer accepts your order or when logistics payment is due to you on all transactions. You can add as many bank accounts you like but ensure you set one of them as the default account to receive payments during settlement. Kindly ensure your bank details is same as that registered to your BVN.<br /><br /> While creating a Person to Person (P2P) escrow transaction, you can add the bank details of other service providers to enable them automatically receive payment to their account when the Buyer accepts your order. To add bank details for service providers, click Add Bank Details and select the Service Provider option under the Account Type dropdown field in the form.<br/><br/>Also, you can opt to receive payment settlement 24 hours, weekly, bi-weekly or monthly once payment is due to you. Go to the Settings section to set the Frequency of Settlement by clicking <Link to='/app/settings/frequency'>here.</Link><br /><br /></p>
                                    :
                                         <p>While creating a Person to Person (P2P) escrow transaction, you can add the bank details of other service providers to enable them automatically receive payment to their account when you accept Seller's order. To add bank details for service providers, click Add Bank Details and select the Service Provider option under the Account Type dropdown field in the form.</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div> <br/>
            {/*Add Bank details modal start*/}
                {this.state.add &&
                    <CreateBankDetails
                    role={this.state.role}
                    add={this.state.add}
                    banks={this.props.banks}
                    adding={adding}
                    onCloseModal={this.onCloseModal}
                    handleSubmit={this.handleSubmit}
                    ></CreateBankDetails>
                }
            {/*Add Bank details modal ends*/}
            {/*Set Owner Account as Defualt starts here*/}
                <Modal open={this.state.default} onClose={()=>this.onCloseModal("default")} center>
                    <div className="modal-header">
                        <h4 className="modal-title">Set as Default</h4>
                    </div>
                    <div className="modal-body">
                        <div className="row form-group">
                            <div className="col-sm-12">
                                <p >Are you sure you want to set this account as the default account to receive payments?</p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" onClick={() => this.onCloseModal("default")} data-dismiss="modal" class="btn btn-primary">Cancel</button>
                        &nbsp;&nbsp;
                        <button   type="button" disabled={updating} onClick={this.setDefault} class="btn btn-danger">{updating?"Updating":"Set as Default"}</button>
                    </div>
                </Modal>
            {/*Set Owner Account as Defualt starts here*/}
            {/*Edit Bank details modal start*/}
                <Modal open={this.state.edit} onClose={()=>this.onCloseModal("edit")} center>
                <form  onSubmit={this.handleEdit}>
                    <div class="row">
                        <div className="col-sm-12 col-md-12"><br/><br/>
                        {
                            (this.state.detail && this.state.detail.type === 1 || this.state.detail.type === "1") &&

                            <div className="form-group">
                                <label >Bank Name</label>
                                <select disabled class="form-control" onChange={this.handleBankChange} required >
                                    <option disabled selected value={detail.bank_name}>{detail.bank_name}</option>
                                    {this.props.banks.map((bank) => <option key={bank.id} value={bank.id}>{bank.name}</option>)}
                                </select>
                            </div>
                        }
                        {/* <br/>
                            {this.state.role === 'seller' ?
                                <div class="form-group">
                                    <label >Account type</label>
                                    <select name="type" value={detail.type} class="form-control" onChange={this.handleEditChange} >
                                        <option value='1' >Own Account</option>
                                        <option value='2' >Service Provider</option>
                                    </select>
                                </div>
                            :
                                <div class="form-group">
                                    <label >Account type</label>
                                    <select name="type" value={detail.type}
                                       className="form-control" onChange={this.handleEditChange} >
                                        <option value='2' >Service Provider</option>
                                    </select>
                                </div>
                            }<br/> */}
                            {
                                (this.state.detail && this.state.detail.type === 1 || this.state.detail.type === "1") &&
                                <>
                                <div className="form-group">
                                    <label >Account Name</label>
                                    <input disabled type="text" name="bank_account_name" value={detail.bank_account_name} onChange={this.handleChange} class="form-control" required/>
                                </div> <br/>
                                <div className="form-group">
                                    <label >Account Number</label>
                                    <input  type="number" name="bank_account_number" value={detail.bank_account_number} onChange={this.handleChange}
                                className="form-control" disabled/>
                                </div> <br/>
                                </>
                            }
                            {
                                (this.state.detail && this.state.detail.type === 2 || this.state.detail.type === "2") &&
                                <>
                                    <div className="form-group">
                                        <label >Account Id</label>
                                        <input disabled type="text" name="account_id" value={detail.account_id} onChange={this.handleChange} class="form-control" required/>
                                    </div> <br/>
                                    <div className="form-group">
                                        <label >Payout Provider</label>
                                        <select className="form-control" disabled value={detail.account_type} onChange={this.handleEditChange} >
                                            <option value="Wise"> Wise </option>
                                            <option value="Paypal"> Paypal </option>
                                            <option value="CashApp"> CashApp </option>
                                        </select>
                                    </div>
                                    <br/>
                                </>
                            }
                            <button  type="submit" value="edit" class="btn btn-primary">{updating?"Saving...":"Save"}</button> <br/><br
                                />
                        </div>
                    </div>
                </form>
                </Modal>
            {/*Edit Bank details modal ends*/}
            {/*Delete Bank details modal start*/}
                <Modal open={this.state.delete} onClose={()=>this.onCloseModal("delete")} center>
                    <div class="modal-header">
                        <h4 className="modal-title">Delete Bank Detail</h4>
                    </div>
                    <div class="modal-body">
                        <div className="row form-group">
                            <div className="col-sm-12">
                                <p >Are you sure you want to delete ? This process cannot be reversed.</p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" onClick={() => this.onCloseModal("delete")} data-dismiss="modal" class="btn btn-primary">Cancel</button>
                        &nbsp;&nbsp;
                        <button onClick={this.handleDelete} disabled={deleting} type="button" class="btn btn-danger">{deleting ? 'Deleting ...' : 'Delete'}</button>
                    </div>
                </Modal>
            {/*Delete Bank details modal ends*/}
            {/*Add SP details modal start*/}
                {this.state.addSP &&
                    <CreateServiceProvider
                    role={this.state.role}
                    addSP={this.state.addSP}
                    banks={this.props.banks}
                    adding={adding}
                    onCloseModal={this.onCloseModal}
                    handleSPSubmit={this.handleSPSubmit}
                    ></CreateServiceProvider>
                }
            {/*Add sp details modal ends*/}
            {/*Add Photo ID modal start*/}
                <Modal open={this.state.addPI} onClose={()=>this.onCloseModal("addPI")} center>
                    <form data-type={this.state.role} onSubmit={this.handlePISubmit}>
                        <div class="row">
                            <div className="col-sm-12 col-md-12">
                                <br/><br/>
                                <div className="form-group">
                                    <label >Identification Card</label>
                                    <input type="file" name="card_type" onChange={this.handleImageUpload} class="form-control" required/>
                                    <span>*File Type must be png, jpg or jpeg. Max size is 2mb</span>
                                </div><br/>
                                <div className="form-group">
                                    <label >Card Type</label>
                                    <select className="form-control" onChange={this.handleCardTypeChange} required >
                                        <option value=''>Select Card Type</option>
                                        {cardTypes.map((cardType) =>
                                            <option key={cardType} value={cardType}>{cardType}</option>)
                                        }
                                    </select>
                                </div><br/>
                                <div className="form-group">
                                    <label>Expiry Date</label>
                                    <input type="date" name="expiry_at" onChange={this.handleChange} class="form-control" required/>
                                </div><br/>
                                <button  type="submit" class="btn btn-primary">{adding?"Saving":"Save"}</button>
                                <br/><br/>
                            </div>
                        </div>
                    </form>
                </Modal>
            {/*Add Photo ID modal ends*/}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <br /><br />
                        {this.state.role === 'seller' ?
                            <div className="d-flex flex-column flex-md-row col-12 mb-4">
                                <button  onClick={()=>this.onOpenModal("add","")} className="btn btn-outline-primary btn-block">Add Bank Details</button>
                                <button  onClick={()=>this.onOpenModal("addSP","")} className="btn btn-outline-primary btn-block ml-0 ml-md-3 mt-3 mt-md-0">Add New Service Provider</button>
                                <button  onClick={()=>this.onOpenModal("addPI","")} className="btn btn-outline-primary btn-block ml-0 ml-md-3 mt-3 mt-md-0">Add Photo ID</button>
                            </div>
                        :
                            <div className="d-flex flex-column flex-md-row">
                                <button onClick={() => this.onOpenModal("add", "")}
                                        className="btn btn-outline-primary btn-block">Add Bank Details
                                </button>
                            </div>
                        }
                        {/*Bank details Display Starts here*/}
                            <br /><br />
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className="card">
                                    <div className="card-body table-responsive">
                                        <div className="row">
                                            <div className="col-12 col-sm-5 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="filter">Filter</label>
                                                    <input type="text"  value={this.state.filter} onChange={this.handleFilter}  placeholder="Filter" class="form-control"/>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                        <table className="table table table-hover table-striped">
                                            <thead>
                                                <tr>
                                                    <th class="" style={{textAlign: "left"}}>
                                                        <span>Account Name</span>
                                                    </th>
                                                    <th class="" style={{textAlign: "left"}}>
                                                        <span>Account Number</span>
                                                    </th>
                                                    <th class="" style={{textAlign: "left"}}>
                                                        <span>Account Type</span>
                                                     </th>
                                                    <th class="" style={{textAlign: "left"}}>
                                                        <span>Bank</span>
                                                     </th>
                                                     <th class="" style={{textAlign: "left"}}>
                                                        <span>Actions</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {accounts && accounts.length > 0 && accounts.map( account => account.type ===1 && (
                                                    <tr key={account.id.toString()}>
                                                        <td >{account.bank_account_name}</td>
                                                        <td >{account.bank_account_number}</td>
                                                        <td>{account.type ==="1" || account.type ===1 ?"Owner Account":"Service Provider"}</td>
                                                        <td >{account.bank_name}</td>
                                                        <td >
                                                            <span>
                                                                {/* <a style={{cursor : "pointer"}} class="text-warning" onClick={()=>this.onOpenModal("edit",account)}>
                                                                    <small>Edit</small>
                                                                </a> */}
                                                                <br/>
                                                                <a style={{cursor : "pointer"}} className="text-danger" onClick={()=>this.onOpenModal("delete",account.id)}>
                                                                    <small>Delete</small>
                                                                </a>
                                                                <br />
                                                                {(account.type === 1 || account.type === "1")&&account.default!==1 ?
                                                                    <a style={{cursor : "pointer"}} class="text-info" onClick={()=>this.onOpenModal("default",account)}>
                                                                        <small>Set as Default</small>
                                                                    </a>
                                                                : null}
                                                            </span>
                                                        </td>
                                                    </tr>))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        {/*Bank details Display Ends here*/}
                        {/*Bank details USD Display Starts here*/}
                            {/* {(serviceProviders && serviceProviders.length > 0 )? */}
                            {this.props.user && (
                                <div className="col-12 col-sm-12 col-md-12">
                                    <div className="card">
                                        <div className="card-body table-responsive">
                                            <div className="row">
                                                <div className="col-12 col-sm-5 col-md-4">
                                                    <div className="form-group">
                                                        <h5>USD Payout Details (Own Account)</h5>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <table className="table table table-hover table-striped">
                                                <thead>
                                                    <tr>
                                                        <th class="" style={{textAlign: "left"}}>
                                                            <span>Full Name</span>
                                                        </th>
                                                        <th class="" style={{textAlign: "left"}}>
                                                            <span>Phone</span>
                                                        </th>

                                                        <th class="" style={{textAlign: "left"}}>
                                                            <span>Email</span>
                                                        </th>

                                                        <th class="" style={{textAlign: "left"}}>
                                                            <span>Payout Provider</span>
                                                        </th>
                                                        <th class="" style={{textAlign: "left"}}>
                                                            <span>Payout ID</span>
                                                        </th>
                                                         <th class="" style={{textAlign: "left"}}>
                                                            <span>Actions</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                    <tbody>
                                    {accounts && accounts.length > 0 && accounts.map( account => account.type ===2 && (
                                                    <tr key={account.id.toString()}>

                                                        <td>{this.props.user.firstname} {this.props.user.lastname}</td>
                                                        <td>{this.props.user.phone_number}</td>
                                                        <td>{this.props.user.email}</td>
                                                        <td >{account.account_type}</td>
                                                        <td >{account.account_id}</td>
                                                        <td >
                                                            <span>
                                                                {/* <a style={{cursor : "pointer"}} class="text-warning" onClick={()=>this.onOpenModal("edit",account)}>
                                                                    <small>Edit</small>
                                                                </a> */}
                                                                {/* <br/> */}
                                                                <a style={{cursor : "pointer"}} className="text-danger" onClick={()=>this.onOpenModal("delete",account.id)}>
                                                                    <small>Delete</small>
                                                                </a>
                                                                <br />
                                                                {(account.type === 2 || account.type === "2")&&account.default!==1 ?
                                                                    <a style={{cursor : "pointer"}} class="text-info" onClick={()=>this.onOpenModal("default",account)}>
                                                                        <small>Set as Default</small>
                                                                    </a>
                                                                 : null}
                                                            </span>
                                                        </td>
                                                    </tr>))
                                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>)}
                {/* :null} */}
                        {/*Bank details USD Display Ends here*/}
                        {/*Service Provider Display Starts here*/}
                            {(serviceProviders && serviceProviders.length > 0 )?
                                <div className="col-12 col-sm-12 col-md-12">
                                    <div className="card">
                                        <div className="card-body table-responsive">
                                            <div className="row">
                                                <div className="col-12 col-sm-5 col-md-4">
                                                    <div className="form-group">
                                                        <h5>Service Providers</h5>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <table className="table table table-hover table-striped">
                                                <thead>
                                                    <tr>
                                                        <th class="" style={{textAlign: "left"}}>
                                                            <span>Full Name</span>
                                                        </th>
                                                        <th class="" style={{textAlign: "left"}}>
                                                            <span>Phone</span>
                                                        </th>

                                                        <th class="" style={{textAlign: "left"}}>
                                                            <span>Email</span>
                                                        </th>

                                                        <th class="" style={{textAlign: "left"}}>
                                                            <span>Account Name</span>
                                                        </th>
                                                        <th class="" style={{textAlign: "left"}}>
                                                            <span>Account Number</span>
                                                        </th>
                                                        <th class="" style={{textAlign: "left"}}>
                                                            <span>Bank</span>
                                                         </th>
                                                         <th class="" style={{textAlign: "left"}}>
                                                            <span>Actions</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    serviceProviders.map(sp => !sp.account_type &&(
                                                    <tr>
                                                        <td>{sp.firstname} {sp.lastname}</td>
                                                        <td>{sp.phone}</td>
                                                        <td>{sp.email}</td>
                                                        <td >{sp.bank_account_name}</td>
                                                        <td >{sp.bank_account_number}</td>
                                                        <td >{sp.bank_name}</td>
                                                        <td >
                                                            <span>
                                                                <a style={{cursor : "pointer"}} className="text-danger" onClick={(e)=>this.handleSPDelete(this,sp.id)}>
                                                                    <small>Delete</small>
                                                                </a>
                                                            </span>
                                                        </td>
                                                    </tr>))
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>:null}
            {/*Service Provider Display Ends here*/}
            {/*Service Provider Display USD Starts here*/}
            {(serviceProviders && serviceProviders.length > 0 )?
                                <div className="col-12 col-sm-12 col-md-12">
                                    <div className="card">
                                        <div className="card-body table-responsive">
                                            <div className="row">
                                                <div className="col-12 col-sm-5 col-md-4">
                                                    <div className="form-group">
                                                        <h5>USD Payout Details (Service Provider)</h5>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <table className="table table table-hover table-striped">
                                                <thead>
                                                    <tr>
                                                        <th class="" style={{textAlign: "left"}}>
                                                            <span>Full Name</span>
                                                        </th>
                                                        <th class="" style={{textAlign: "left"}}>
                                                            <span>Phone</span>
                                                        </th>

                                                        <th class="" style={{textAlign: "left"}}>
                                                            <span>Email</span>
                                                        </th>
                                                        <th class="" style={{textAlign: "left"}}>
                                                            <span>Payout Provider</span>
                                                        </th>
                                                        <th class="" style={{textAlign: "left"}}>
                                                            <span>Payout Id</span>
                                                        </th>
                                                         <th class="" style={{textAlign: "left"}}>
                                                            <span>Actions</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    serviceProviders.map(sp => sp.account_type &&(
                                                    <tr>
                                                        <td>{sp.firstname} {sp.lastname}</td>
                                                        <td>{sp.phone}</td>
                                                        <td>{sp.email}</td>
                                                        <td>{sp.account_type}</td>
                                                        <td>{sp.account_id}</td>
                                                        <td >
                                                            <span>
                                                                <a style={{cursor : "pointer"}} className="text-danger" onClick={(e)=>this.handleSPDelete(this,sp.id)}>
                                                                    <small>Delete</small>
                                                                </a>
                                                                {/* <br /> */}
                                                                {/* {account.type === 1 | account.type === "1"&&account.default!==1 ?  */}
                                                                    {/* <a style={{cursor : "pointer"}} class="text-info" onClick={()=>this.onOpenModal("default",sp)}>
                                                                        <small>Set as Default</small>
                                                                    </a>  */}
                                                                {/* : null}  */}
                                                            </span>
                                                        </td>
                                                    </tr>))
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>:null}
                                {/*Service Provider Display Ends here*/}
            </div> <br />
                <br />
                <br />
        </div>
    </section>
</div>
        :
        <div className='sweet-loading'>
        <ClipLoader
          cssOverride={loaderOverride}
          size={70}
          color={'blue'}
          loading={loading}
        />
        </div>
        )
    }
}

export default PayOutdetail;
