import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Modal from "react-responsive-modal";
import * as moment from "moment";
import { alertActions } from "../actions";
import swal from "@sweetalert/with-react";
import transactionHelper from "../helpers/transaction";

import { jsx, css } from "@emotion/core";
import { ClipLoader } from "react-spinners";

const override = css`
  display: block;
  margin-top: 10%;
  margin-left: 50%;
  margin-right: 40%;
  border-color: red;
`;

const formatToSixDigits = (number) => {
  var output = number + "";
  while (output.length < 6) {
    output = "0" + output;
  }
  return output;
};

const FilterForm = (props) => (
  <div className="row">
    <div className="col-12 col-sm-2">
      <div className="form-group">
        <label htmlFor="period">Period</label>
        <select
          className="form-control"
          name="filter"
          onChange={props.periodChange}
        >
          <option value="All">All</option>
          <option value="This Week">This Week</option>
          <option value="This Month">This Month</option>
          <option value="60 Days Ago">60 Days Ago</option>
          <option value="90 Days Ago">90 Days Ago</option>
          <option value="Custom">Custom</option>
        </select>
      </div>
    </div>
    <div className="col-12 col-sm-2">
      <div className="form-group">
        <label htmlFor="filter">Filter</label>
        <input
          type="text"
          placeholder="Filter"
          className="form-control"
          value={props.filtered}
          onChange={props.filterData}
        />
      </div>
    </div>
    <div className="col-12 col-sm-3">
      <div className="form-group">
        <label htmlFor="from">Date From</label>
        <input
          type="date"
          name="from_date"
          value={props.fromDate}
          disabled={props.customOff}
          onChange={props.dateChange}
          className="form-control"
        />
      </div>
    </div>
    <div className="col-12 col-sm-3">
      <div className="form-group">
        <label htmlFor="from">Date To</label>
        <input
          type="date"
          name="to_date"
          value={props.toDate}
          disabled={props.customOff}
          onChange={props.dateChange}
          className="form-control"
        />
      </div>
    </div>
    <div className="col-12 col-sm-2">
      <div className="form-group">
        <label>&nbsp;</label>
        <div className="row">
          <div className="col-12">
            <button
              disabled={props.customOff}
              onClick={props.dateSearch}
              className="btn btn-info btn-block form-control"
            >
              <center>Search</center>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

class payoutHistory extends Component {
  state = {
    filterable: false,
    notCustom: true,
    period: "All",
    assignModal: false,
    fullModal: false,
    from_date: "",
    filter: "",
    to_date: "",
    data: [],
    order: "",
    fields: {
      order_id: 0,
      delivery_man_id: 0,
    },
    role: "seller",
    payments: [],
    transaction: [],
    serviceProvider: "",
    seller: "",
    pay: [],
  };

  componentDidMount() {
    if (
      localStorage.getItem("trust_user_type") === "seller" ||
      localStorage.getItem("trust_user_type") === "marketplace"
    )
      this.setState({ role: "seller" });
    else this.setState({ role: "buyer" });

    if (this.props.payouts) {
      console.log("payouts", this.props.payouts);
      this.setState({ data: [...this.props.payouts] });
    }
  }

  componentWillReceiveProps(props) {
    if (
      props.alert &&
      props.alert.message &&
      props.alert.type === "alert-danger"
    ) {
      swal("Error", props.alert.message, "error").then(() => {
        props.dispatch(alertActions.alert.clear());
      });
    }

    if (
      props.alert &&
      props.alert.message &&
      props.alert.type === "alert-success"
    ) {
      swal("Success", props.alert.message, "success").then(() => {
        props.dispatch(alertActions.alert.clear());
      });
      this.onCloseModal("assignModal");
      // this.onCloseModal('edit');
      // this.onCloseModal('delete');
    }
    if (props.payouts) {
      this.setState({ data: [...props.payouts] });
      this.setState({ payments: props.payouts });
    }
  }
  calculateCardFee = (data, role) => {
    let cost =
      parseFloat(data.product_cost / 100) * data.quantity +
      parseFloat(transactionHelper.calculateShipping(data, 1, 1)[role]) / 100;
    let fee = (1.5 / 100) * cost;

    if (cost >= 2500) fee = fee + 100;
    if (fee >= 2000) fee = 2000;
    return fee + 50;
  };
  handleSelect = (e) => {
    const { value } = e.target;
    if (value === "Custom") {
      this.setState({ notCustom: !this.state.notCustom });
    }
    this.filterPeriod(value);
    this.setState({ period: value });
  };
  filterPeriod = (value) => {
    const data = this.state.payments;
    if (value === "All") {
      /* console.log(data); */
      this.table_rows = data;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({ notCustom: true, data: [...this.table_rows] });
    }

    if (value === "This Week") {
      var ordersThisWeek = [];

      for (var i = 0; i < data.length; i++) {
        var now = moment();
        var input = moment(data[i].created_at);
        var isThisWeek = now.isoWeek() === input.isoWeek();

        if (isThisWeek) {
          ordersThisWeek.push(data[i]);
        }
      }

      this.table_rows = ordersThisWeek;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      /* console.log(this.table_rows); */
      this.setState({ notCustom: true, data: [...this.table_rows] });
    }

    if (value === "This Month") {
      var ordersThisMonth = [];

      for (let i = 0; i < data.length; i++) {
        //var now = moment();
        let input = moment(data[i].created_at);
        var isThisMonth = input.isSame(new Date(), "month");

        if (isThisMonth) {
          ordersThisMonth.push(data[i]);
        }
      }

      this.table_rows = ordersThisMonth;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({ notCustom: true, data: [...this.table_rows] });
    }

    if (value === "60 Days Ago") {
      var ordersSixtyDaysAgo = [];

      for (let i = 0; i < data.length; i++) {
        var sixty_days_ago = moment().subtract(60, "days");

        var isBetweenSixtyDays = moment(data[i].created_at).isAfter(
          sixty_days_ago
        );

        if (isBetweenSixtyDays) {
          ordersSixtyDaysAgo.push(data[i]);
        }
      }

      this.table_rows = ordersSixtyDaysAgo;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({ notCustom: true, data: [...this.table_rows] });
    }

    if (value === "90 Days Ago") {
      var ordersNinetyDaysAgo = [];

      for (let i = 0; i < data.length; i++) {
        var ninety_days_ago = moment().subtract(90, "days");

        var isBetweenNinetyDays = moment(data[i].created_at).isAfter(
          ninety_days_ago
        );

        if (isBetweenNinetyDays) {
          ordersNinetyDaysAgo.push(data[i]);
        }
      }

      this.table_rows = ordersNinetyDaysAgo;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({ notCustom: true, data: [...this.table_rows] });
    }

    if (value === "Custom") {
      this.table_rows = data;
      this.to_date = null;
      this.from_date = null;
    }
  };

  filterDates = () => {
    const data = this.state.payments;
    var ordersBetweenTheTwoDates = [];
    for (var i = 0; i < data.length; i++) {
      var isBetweenDates = moment(data[i].created_at).isBetween(
        this.state.from_date,
        this.state.to_date
      ); // true
      console.log(
        "from_date:",
        this.state.from_date,
        "to_date:",
        this.state.to_date,
        "created_at:",
        data[i].created_at,
        "isBetweenDates:",
        isBetweenDates
      );
      if (isBetweenDates) {
        ordersBetweenTheTwoDates.push(data[i]);
      }
    }

    this.table_rows = ordersBetweenTheTwoDates;
    this.page = 1;
    return this.table_rows;
  };
  handleDate = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  returnStatus(status) {
    switch (status) {
      case 1:
        return "completed";
        break;
      case 2:
        return "gw-pending";
        break;
      case 3:
        return "gw-failed";
        break;
      default:
        return "pending";
        break;
    }
  }

  filterTable = (filter) => {
    var data = this.state.payments;
    var p2p = "P2P";
    var ecommerce = "eCommerce";
    var ownAccount = "Own Account";
    var filteredRows = [];
    if (filter !== "") {
      for (var i = 0; i < data.length; i++) {
        if (
          (data[i]?.transaction &&
            formatToSixDigits(data[i]?.transaction?.id)?.match(
              new RegExp(filter, "gi")
            )) ||
          (data[i].created_at &&
            data[i].created_at?.match(new RegExp(filter, "gi"))) ||
          (data[i]?.transaction &&
            data[i]?.transaction?.payment?.payment_ref &&
            data[i]?.transaction?.payment?.payment_ref
              ?.toUpperCase()
              .includes(filter.toUpperCase())) ||
          (data[i]?.amount &&
            data[i]?.amount?.match(new RegExp(filter, "gi"))) ||
          (data[i]?.service_provider &&
            data[i]?.service_provider?.firstname &&
            data[i]?.service_provider?.firstname?.match(
              new RegExp(filter, "gi")
            )) ||
          (data[i]?.service_provider &&
            data[i]?.service_provider?.lastname &&
            data[i]?.service_provider?.lastname?.match(
              new RegExp(filter, "gi")
            )) ||
          (data[i].type !== 10 && ownAccount.match(new RegExp(filter, "gi"))) ||
          (data[i]?.seller?.firstname &&
            data[i]?.seller?.firstname !==
              data[i]?.service_provider?.firstname &&
            data[i]?.seller?.firstname?.match(new RegExp(filter, "gi"))) ||
          (data[i]?.seller?.lastname &&
            // data[i]?.seller?.lastname !== data[i]?.service_provider?.lastname &&
            data[i]?.service_provider &&
            data[i]?.seller?.lastname?.match(new RegExp(filter, "gi"))) ||
          (data[i]?.transaction?.customers?.firstname &&
            // data[i]?.transaction?.customers?.firstname !==
            //   data[i]?.service_provider?.firstname &&
            data[i]?.service_provider &&
            data[i]?.transaction?.customers?.firstname?.match(
              new RegExp(filter, "gi")
            )) ||
          (data[i]?.transaction?.customers?.lastname &&
            // data[i]?.transaction?.customers?.lastname !==
            //   data[i]?.service_provider?.lastname &&
            !data[i]?.service_provider &&
            data[i]?.transaction?.customers?.lastname?.match(
              new RegExp(filter, "gi")
            )) ||
          this.returnStatus(data[i]?.status)
            .toLowerCase()
            .includes(filter.trim().toLowerCase()) ||
          (data[i]?.transaction &&
            data[i]?.transaction?.type === 1 &&
            ecommerce.match(new RegExp(filter, "gi"))) ||
          (data[i]?.transaction &&
            data[i]?.transaction?.type === 2 &&
            p2p.match(new RegExp(filter, "gi")))
        ) {
          filteredRows.push(data[i]);
        }
      }

      this.setState({ data: [...filteredRows] });
    } else {
      this.setState({ data: [...this.state.payments] });
    }
  };

  handleFilter = (e) => {
    const { value } = e.target;
    this.setState({ filter: value }, () => this.filterTable(this.state.filter));
    // this.filterTable();
  };
  onOpenModal = (name, value, value2) => {
    if (name === "serviceProviderModal") {
      this.setState({ [name]: true });
      this.setState({ serviceProvider: value });
    } else if (name == "sellerModal") {
      this.setState({ [name]: true });
      this.setState({ seller: value });
    } else {
      this.setState({ [name]: true });
      this.setState({ transaction: value, pay: value2 });
    }
  };

  onCloseModal = (name) => {
    this.setState({ [name]: false });
    this.setState({ transaction: "" });
    this.setState({ serviceProvider: "" });
    this.setState({ pay: "" });
  };

  formatAmount = (amount) => {
    let a = amount + "";
    let naira = a.slice(0, -3);
    let kobo = a.substr(a.length - 2);
    return naira + "." + kobo;
  };

  render() {
    const { loading, user } = this.props;
    const { filterable, role } = this.state;
    var columns = [];
    {
      user && user.role && user.role.name == "MarketPlace"
        ? (columns = [
            {
              Header: (props) => (
                <span>
                  <b>SN</b>
                </span>
              ),
              id: "sn",
              filterable,
              maxWidth: 80,
              accessor: (d) => d, // String-based value accessors!
              Cell: (row) => {
                return <span>{row.index + 1}</span>;
              },
            },
            {
              Header: (props) => (
                <span>
                  <b>Order ID</b>
                </span>
              ),
              id: "id",
              filterable,
              maxWidth: 90,
              accessor: (d) => d, // String-based value accessors!
              Cell: (props) => (
                <a
                  href="#/app/payout/history"
                  onClick={() =>
                    this.onOpenModal(
                      "fullModal",
                      props.value.transaction,
                      props.value
                    )
                  }
                >
                  {formatToSixDigits(
                    props.value.transaction && props.value.transaction.id
                  )}
                </a>
              ),
            },
            {
              Header: (props) => (
                <span>
                  <b>Seller </b>
                </span>
              ),
              id: "firstname",
              filterable,
              maxWidth: 100,
              accessor: (d) => d, // String-based value accessors!
              Cell: (props) => (
                <span>
                  <a
                    href="#/app/payout/history"
                    onClick={() =>
                      this.onOpenModal(
                        "sellerModal",
                        props.value.seller,
                        props.value
                      )
                    }
                  >
                    {props.value.seller && props.value.seller.firstname} <br />{" "}
                    {props.value.seller && props.value.seller.lastname}{" "}
                  </a>
                </span>
              ),
            },
            {
              Header: (props) => (
                <span>
                  <b>Buyer </b>
                </span>
              ),
              id: "firstname",
              filterable,
              maxWidth: 100,
              accessor: (d) => d, // String-based value accessors!
              Cell: (props) => (
                <span>
                  {props.value.transaction.customers &&
                    props.value.transaction.customers.firstname}{" "}
                  <br />{" "}
                  {props.value.transaction.customers &&
                    props.value.transaction.customers.lastname}{" "}
                </span>
              ),
            },
            {
              Header: (props) => (
                <span>
                  <b>Service</b>
                </span>
              ),
              id: "Service",
              filterable,
              maxWidth: 100,
              accessor: (d) => d, // String-based value accessors!
              Cell: (props) => (
                <span>
                  {props.value.transaction.type === 1 ? "eCommerce" : "P2P"}
                </span>
              ),
            },

            {
              id: "amount", // Required because our accessor is not a string
              Header: (props) => (
                <span>
                  <b>Amount Paid</b>
                </span>
              ),
              filterable,
              maxWidth: 140,
              accessor: (d) => d, // Custom value accessors!
              Cell: (props) => (
                <span className="number">
                  {props.value === null
                    ? ""
                    : "₦" +
                      (props.value.amount / 100).toLocaleString("en", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </span>
              ), // Custom cell components!
            },
            // {
            //   id: "recipient", // Required because our accessor is not a string
            //   Header: (props) => (
            //     <span>
            //       <b>Recipient</b>
            //     </span>
            //   ),
            //   filterable,
            //   accessor: (d) => d, // Custom value accessors!
            //   Cell: (props) => (
            //     <span>
            //       {props.value.type === 10
            //         ? `${props.value?.service_provider?.firstname} ${props.value?.service_provider?.firstname}`
            //         : props.value.account && props.value.account.type === 1
            //         ? "Own Account"
            //         : props.value.type === 1 ||
            //           props.value.type === 2 ||
            //           props.value.type === 4 ||
            //           props.value.type === 5
            //         ? "Own Account"
            //         : "Buyer's Account"}
            //     </span>
            //   ), // Custom cell components!
            // },
            {
              id: "datePaid",
              filterable,
              maxWidth: 170,
              Header: (props) => (
                <span>
                  <b>Date Paid</b>
                </span>
              ),
              accessor: (d) => d, // String-based value accessors!
              Cell: (props) => (
                <span>
                  {props.value === null ? "" : props.value.created_at}
                </span>
              ),
            },
            {
              Header: (props) => (
                <span>
                  <b>Transaction No.</b>
                </span>
              ),
              id: "transactionNo",
              filterable,
              accessor: (d) => d.transaction,
              Cell: (props) => (
                <span className="number">
                  {props.value === null
                    ? ""
                    : props.value.payment && props.value.payment.payment_ref}
                </span>
              ), // Custom cell components!
            },
            {
              id: "status",
              filterable,
              maxWidth: 100,
              Header: (props) => (
                <span>
                  <b>Status</b>
                </span>
              ),
              accessor: (d) => d, // String-based value accessors!
              className: "text-center",
              Cell: (props) => (
                <div>
                  {props.value.status == 1 ? (
                    <span className="badge badge-success">Completed </span>
                  ) : props.value.status == 2 ? (
                    <span className="badge badge-info">GW-Pending</span>
                  ) : props.value.status == 3 ? (
                    <span className="badge badge-danger">GW-Failed</span>
                  ) : (
                    <span className="badge badge-warning">Pending</span>
                  )}
                </div>
              ),
            },
          ])
        : (columns = [
            {
              Header: (props) => (
                <span>
                  <b>SN</b>
                </span>
              ),
              id: "sn",
              filterable,
              maxWidth: 80,
              accessor: (d) => d, // String-based value accessors!
              Cell: (row) => {
                return <span>{row.index + 1}</span>;
              },
            },
            {
              Header: (props) => (
                <span>
                  <b>Order ID</b>
                </span>
              ),
              id: "id",
              filterable,
              maxWidth: 90,
              accessor: (d) => d, // String-based value accessors!
              Cell: (props) => (
                <a
                  href="#/app/payout/history"
                  onClick={() =>
                    this.onOpenModal(
                      "fullModal",
                      props.value.transaction,
                      props.value
                    )
                  }
                >
                  {formatToSixDigits(
                    props.value.transaction && props.value.transaction.id
                  )}
                </a>
              ),
            },
            {
              Header: (props) => (
                <span>
                  <b>Service</b>
                </span>
              ),
              id: "Service",
              filterable,
              maxWidth: 100,
              accessor: (d) => d, // String-based value accessors!
              Cell: (props) => (
                <span>
                  {props.value.transaction && props.value.transaction.type === 1
                    ? "eCommerce"
                    : "P2P"}
                </span>
              ),
            },

            {
              id: "amount", // Required because our accessor is not a string
              Header: (props) => (
                <span>
                  <b>Amount Paid</b>
                </span>
              ),
              filterable,
              maxWidth: 140,
              accessor: (d) => d, // Custom value accessors!
              Cell: (props) => (
                <span className="number">
                  {props.value === null
                    ? ""
                    : "₦" +
                      (props.value.amount / 100).toLocaleString("en", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </span>
              ), // Custom cell components!
            },
            {
              id: "recipient", // Required because our accessor is not a string
              Header: (props) => (
                <span>
                  <b>Recipient</b>
                </span>
              ),
              filterable,
              accessor: (d) => d, // Custom value accessors!
              Cell: (props) => (
                <span>
                  {props.value.type === 10 ? (
                    <a
                      href="#/app/payout/history"
                      onClick={() =>
                        this.onOpenModal(
                          "serviceProviderModal",
                          props.value.service_provider,
                          props.value
                        )
                      }
                    >
                      {props.value?.service_provider?.firstname}{" "}
                      {props.value?.service_provider?.lastname}
                    </a>
                  ) : props.value.account && props.value.account.type === 1 ? (
                    "Own Account"
                  ) : props.value.type === 1 ||
                    props.value.type === 2 ||
                    props.value.type === 4 ||
                    props.value.type === 5 ? (
                    "Own Account"
                  ) : (
                    "Buyer's Account"
                  )}
                </span>
              ), // Custom cell components!
            },
            {
              id: "datePaid",
              filterable,
              maxWidth: 170,
              Header: (props) => (
                <span>
                  <b>Date Paid</b>
                </span>
              ),
              accessor: (d) => d, // String-based value accessors!
              Cell: (props) => (
                <span>
                  {props.value === null ? "" : props.value.created_at}
                </span>
              ),
            },
            {
              Header: (props) => (
                <span>
                  <b>Transaction No.</b>
                </span>
              ),
              id: "transactionNo",
              filterable,
              accessor: (d) => d.transaction,
              Cell: (props) => (
                <span className="number">
                  {props.value === null
                    ? ""
                    : props.value.payment && props.value.payment.payment_ref}
                </span>
              ), // Custom cell components!
            },
            {
              id: "status",
              filterable,
              maxWidth: 100,
              Header: (props) => (
                <span>
                  <b>Status</b>
                </span>
              ),
              accessor: (d) => d, // String-based value accessors!
              className: "text-center",
              Cell: (props) => (
                <div>
                  {props.value.status == 1 ? (
                    <span className="badge badge-success">Completed </span>
                  ) : props.value.status == 2 ? (
                    <span className="badge badge-info">GW-Pending</span>
                  ) : props.value.status == 3 ? (
                    <span className="badge badge-danger">GW-Failed</span>
                  ) : (
                    <span className="badge badge-warning">Pending</span>
                  )}
                </div>
              ),
            },
          ]);
    }
    return (
      <div>
        <Modal
          open={this.state.fullModal}
          onClose={() => this.onCloseModal("fullModal")}
          center
        >
          <form>
            <div className="modal-header">
              <h4 className="modal-title">
                <small>
                  Transaction{" "}
                  {formatToSixDigits(
                    this.state.transaction && this.state.transaction.id
                  )}
                </small>
              </h4>
            </div>
            <div className="modal-body">
              <div className="row"></div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Order ID</label> <br />
                    <input
                      type="text"
                      readonly="readonly"
                      value={formatToSixDigits(
                        this.state.transaction && this.state.transaction.id
                      )}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Order Value</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={
                        this.state.transaction &&
                        "₦" +
                          (
                            this.state.transaction.amount_payed / 100
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                      }
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                {role === "seller" ? (
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Buyer Name</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          (this.state.transaction &&
                            this.state.transaction.customers &&
                            this.state.transaction.customers.firstname) +
                          " " +
                          (this.state.transaction &&
                            this.state.transaction.customers &&
                            this.state.transaction.customers.lastname)
                        }
                        className="form-control"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Seller Name</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          (this.state.transaction &&
                            this.state.transaction.user &&
                            this.state.transaction.user.firstname) +
                          " " +
                          (this.state.transaction &&
                            this.state.transaction.sellers &&
                            this.state.transaction.user &&
                            this.state.transaction.user.lastname)
                        }
                        className="form-control"
                      />
                    </div>
                  </div>
                )}
                <div className="col-sm-6">
                  {role === "seller" ? (
                    <div className="form-group">
                      <label>
                        <small>
                          <b>Marketplace Business Name</b>
                        </small>
                      </label>{" "}
                      <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.pay &&
                          this.state.pay.marketplace &&
                          this.state.pay.marketplace.marketplaceuser &&
                          this.state.pay.marketplace.marketplaceuser.seller
                            ? this.state.pay.marketplace.marketplaceuser.seller
                                .business_name
                            : this.props.user &&
                              this.props.user.seller &&
                              this.props.user.seller.individual
                            ? this.props.user.firstname +
                              " " +
                              this.props.user.lastname
                            : this.props.user &&
                              this.props.user.seller &&
                              this.props.user.seller.business_name
                        }
                        className="form-control"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label>
                        <small>
                          <b>Marketplace Business Name</b>
                        </small>
                      </label>{" "}
                      <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.transaction &&
                          this.state.transaction.sellers &&
                          this.state.transaction.sellers.business_name
                            ? this.state.transaction &&
                              this.state.transaction.sellers &&
                              this.state.transaction.sellers.business_name
                            : "N/A"
                        }
                        className="form-control"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Escrow Fee Paid By</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={
                        this.state.transaction &&
                        this.state.transaction.escrow_fee_bearer === "seller"
                          ? "Seller"
                          : this.state.transaction &&
                            this.state.transaction.escrow_fee_bearer === "both"
                          ? "Both"
                          : "Buyer"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Escrow Fee</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={
                        this.state.transaction &&
                        "(₦" +
                          (
                            transactionHelper.calculateTransactionEscrow(
                              (this.state.transaction.amount_net +
                                this.state.transaction.shipping_cost *
                                  (this.state.transaction &&
                                  this.state.transaction.shipping_fee_bearer ===
                                    "both"
                                    ? 0.5
                                    : this.state.transaction &&
                                      this.state.transaction
                                        .shipping_fee_bearer === "seller"
                                    ? 0
                                    : 1)) /
                                100
                            ) *
                            (this.state.transaction &&
                            this.state.transaction.escrow_fee_bearer === "both"
                              ? 0.5
                              : 1)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) +
                          ")"
                      }
                      className="form-control text-danger"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Shipping Fee Paid By</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={
                        this.state.transaction &&
                        this.state.transaction.shipping_fee_bearer === "seller"
                          ? "Seller"
                          : this.state.transaction &&
                            this.state.transaction.shipping_fee_bearer ===
                              "both"
                          ? "Both"
                          : "Buyer"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  {role === "seller" ? (
                    <div className="form-group">
                      <label>Shipping Fee</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.transaction &&
                          "₦" +
                            (
                              this.state.transaction.shipping_cost / 100
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        className="form-control"
                      />
                    </div>
                  ) : this.state.transaction &&
                    this.state.transaction.shipping_fee_bearer === "seller" ? (
                    <div className="form-group">
                      <label>Shipping Fee</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.transaction &&
                          "(₦" +
                            (
                              this.state.transaction.shipping_cost / 100
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) +
                            ")"
                        }
                        className="form-control text-danger"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label>Shipping Fee</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.transaction &&
                          "₦" +
                            (
                              this.state.transaction.shipping_cost / 100
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        className="form-control"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label style={{ "font-size": "12px" }}>
                      Card & Transfer Fee Paid By
                    </label>{" "}
                    <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={
                        this.state.transaction &&
                        this.state.transaction.tx_fee_bearer === "seller"
                          ? "Seller"
                          : this.state.transaction.sla === 1
                          ? "Seller"
                          : "Buyer"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  {this.state.transaction &&
                  this.state.transaction.status === 9 &&
                  this.state.transaction.tx_fee_bearer === "seller" &&
                  this.state.transaction.sla === 1 ? (
                    <div className="form-group">
                      <label>Card & Transfer Fee</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.transaction &&
                          "₦" +
                            this.calculateCardFee(
                              this.state.transaction,
                              "customer"
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        className="form-control"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label>Card & Transfer Fee</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.transaction &&
                          "(₦" +
                            this.calculateCardFee(
                              this.state.transaction,
                              "customer"
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) +
                            ")"
                        }
                        className="form-control text-danger"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Product Amount</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={
                        this.state.transaction &&
                        "₦" +
                          (
                            this.state.transaction.product_cost / 100
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  {role === "seller" ? (
                    <div className="form-group">
                      <label>Total Payout</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.pay &&
                          "₦" +
                            (this.state.pay.amount / 100).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        className="form-control"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label>Total Refund</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.pay &&
                          "₦" +
                            (this.state.pay.amount / 100).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        className="form-control"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Payment Channel</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={
                        this.state.transaction && this.state.transaction.payment
                          ? this.state.transaction &&
                            this.state.transaction.payment &&
                            this.state.transaction.payment.method
                          : "Card"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  {role === "seller" ? (
                    <div className="form-group">
                      <label>Payout Status</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.pay && this.state.pay.status
                            ? "Paid"
                            : "Pending"
                        }
                        className="form-control"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label>Refund Status</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.pay && this.state.pay.status
                            ? "Paid"
                            : "Pending"
                        }
                        className="form-control"
                      />
                    </div>
                  )}
                </div>
              </div>
              {this.state.transaction &&
              this.state.transaction.status === 9 &&
              this.state.transaction.tx_fee_bearer === "seller" &&
              this.state.transaction.sla === 1 ? (
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Cancellation Fee</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.transaction &&
                          "₦" +
                            (
                              this.state.transaction.cancellation_fee / 100
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <br />
              <br />
            </div>
          </form>
        </Modal>

        <Modal
          open={this.state.serviceProviderModal}
          onClose={() => this.onCloseModal("serviceProviderModal")}
          center
        >
          <form>
            <div className="modal-header">
              <h4 className="modal-title">
                <small>Service Provider</small>
              </h4>
            </div>
            <div className="modal-body">
              <div className="row"></div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Firstname</label> <br />
                    <input
                      type="text"
                      readonly="readonly"
                      value={this.state.serviceProvider.firstname}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Lastname</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={this.state.serviceProvider.lastname}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Email</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={this.state.serviceProvider.email}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Phone</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={this.state.serviceProvider.phone}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Bank Name</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={this.state.serviceProvider.bank_name}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Bank Account No</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={this.state.serviceProvider.bank_account_number}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <br />
            </div>
          </form>
        </Modal>
        <Modal
          open={this.state.sellerModal}
          onClose={() => this.onCloseModal("sellerModal")}
          center
        >
          <form>
            <div className="modal-header">
              <h4 className="modal-title">
                <small>Seller Details</small>
              </h4>
            </div>
            <div className="modal-body">
              <div className="row"></div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Firstname</label> <br />
                    <input
                      type="text"
                      readonly="readonly"
                      value={this.state.seller.firstname}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Lastname</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={this.state.seller.lastname}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Email</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={this.state.seller.email}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Phone</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={this.state.seller.phone_number}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Bank Name</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={this.state.seller.bvn_bank_name}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Bank Account No</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={this.state.seller.bvn_account_number}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <br />
            </div>
          </form>
        </Modal>

        <div className="content-wrapper" style={{ minHeight: 136 }}>
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                {role === "seller" ? (
                  <div className="col-sm-12">
                    <h1 className="m-0 text-dark">Payout History</h1>
                    <div className="attop">
                      <p>
                        On this page, you will find details of payments made by
                        AtaraPay to the Seller and Service Providers on orders
                        placed from the Seller’s website or P2P escrow
                        transaction and accepted by the Buyer.
                        <br />
                        <br />
                        You can filter by payout to Seller or Service Provider.
                      </p>
                    </div>{" "}
                    <br />
                  </div>
                ) : (
                  <div className="col-sm-12">
                    <h1 className="m-0 text-dark">Refund History</h1>
                    <div className="attop">
                      <p>
                        On this page, you will find details of refunds or
                        payouts made by AtaraPay to you or your Service Provider
                        respectively, on orders placed from the Seller’s website
                        or P2P escrow transaction you created and accepted or
                        rejected the service.
                        <br />
                        <br />
                        You can filter by refund made to Buyer, in cases of
                        rejected service or payout to your Service Provider, in
                        cases of accepted service.
                      </p>
                    </div>{" "}
                    <br />
                  </div>
                )}
              </div>
            </div>
          </div>
          {!loading ? (
            <section className="content">
              <div className="container-fluid">
                <br />
                <div className="row ">
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="alert animated bounceIn alert-danger d-none search-alert">
                      <a aria-label="close" className="close alert-close">
                        ×
                      </a>
                      <span></span>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <FilterForm
                          filtered={this.state.filter}
                          filterData={this.handleFilter}
                          fromDate={this.state.from_date}
                          toDate={this.state.to_date}
                          customOff={this.state.notCustom}
                          dateChange={this.handleDate}
                          dateSearch={() => {
                            this.setState({
                              data: [...this.filterDates(this.state.data)],
                            });
                          }}
                          periodChange={this.handleSelect}
                        />
                        <br />
                        <div className="table-responsive">
                          <ReactTable
                            defaultPageSize={5}
                            filterable
                            data={this.state.data}
                            columns={columns}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <br />
              </div>
            </section>
          ) : (
            <div className="sweet-loading">
              <ClipLoader
                class={override}
                sizeUnit={"px"}
                size={70}
                color={"blue"}
                loading={loading}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default payoutHistory;
