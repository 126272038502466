import { transactionConstants } from "../constants";
import { alertActions, userActions } from "./";
import { transactionService } from "../service.js";
import React from "react";
import swal from "@sweetalert/with-react";

//dummy data
/**
 * @todo Please remove in Production
 *
 */

const editTransaction = (transaction, history) => {
  return (dispatch) => {
    dispatch(edit(transaction));
    // history('/app/seller/transaction')
  };
  function edit(transaction) {
    return {
      type: transactionConstants.transaction.EDIT_TRANSACTION,
      transaction,
    };
  }
};

const cancelEditTransaction = () => {
  return (dispatch) => {
    dispatch(cancel());
  };
  function cancel() {
    return { type: transactionConstants.transaction.CANCEL_EDIT_TRANSACTION };
  }
};

const createTransactionRequest = () => ({
  type: transactionConstants.transaction.CREATE_TRANSACTION_REQUEST,
});
const createTransactionRequestSuccess = (transaction) => ({
  type: transactionConstants.transaction.CREATE_TRANSACTION_REQUEST_SUCCESS,
  transaction,
});
const createTransactionRequestFailure = (error) => ({
  type: transactionConstants.transaction.CREATE_TRANSACTION_REQUEST_FAILURE,
  error,
});
const createTransaction = (type, transaction, history) => (dispatch) => {
  dispatch(createTransactionRequest());
  transactionService.transaction
    .createTransaction(type, transaction)
    .then((res) => res.data)
    .then((res) => {
      if (res.status == "success") {
        dispatch(createTransactionRequestSuccess(res.data));
        dispatch(
          alertActions.alert.success(
            "An email has been sent to your recipient requesting to review and agree to the transaction. Kindly inform the other party to check their spam folder, if the email sent isn't found in the inbox."
          )
        );
      } else {
        dispatch(
          createTransactionRequestFailure(res.message?.replace(/_/g, " "))
        );
        if (res.message === "enter_buyer_email") {
          dispatch(
            alertActions.alert.error("Please provide the buyer's email address")
          );
        } else if (res.message === "enter_seller_email") {
          dispatch(
            alertActions.alert.error(
              "Please provide the seller's email address"
            )
          );
        } else if (res.message === "enter_shipping_cost") {
          dispatch(
            alertActions.alert.error("Please provide the shipping cost")
          );
        } else if (res.message === "select_shipping_fee_bearer") {
          dispatch(
            alertActions.alert.error("Please select who pays the shipping fee")
          );
        } else {
          dispatch(alertActions.alert.error(res.message?.replace(/_/g, " ")));
        }
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status == 401) {
          dispatch(userActions.user.logout(type, history));
          dispatch(createTransactionRequestFailure(err.response.data.message));
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/_/g, " ")
            )
          );
        } else {
          dispatch(createTransactionRequestFailure(err.response.data.message));
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/_/g, " ")
            )
          );
        }
      } else if (err.request) {
        dispatch(createTransactionRequestFailure(err.message));
        dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
      } else {
        dispatch(createTransactionRequestFailure(err.message));
        dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
      }
    });
};

const getTransactionRequest = () => ({
  type: transactionConstants.transaction.GET_TRANSACTION,
});
const getTransactionRequestSuccess = (transaction) => ({
  type: transactionConstants.transaction.GET_TRANSACTION_SUCCESS,
  transaction,
});
const getTransactionRequestFailure = (error) => ({
  type: transactionConstants.transaction.GET_TRANSACTION_FAILURE,
  error,
});
const getTransactions = (type, history) => (dispatch) => {
  dispatch(getTransactionRequest());
  transactionService.transaction
    .getTransactions(type)
    .then((res) => res.data)
    .then((res) => {
      if (res.status == "success") {
        dispatch(
          getTransactionRequestSuccess(
            res.data.orders.map((data) => ({ ...data, extra: res.data.config }))
          )
        );
        // dispatch(alertActions.alert.success(res.message));
      } else {
        dispatch(getTransactionRequestFailure(res.message));
        // dispatch(alertActions.alert.error(res.message));
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 401) {
          if (window.location.href.includes("transaction/pay")) {
            dispatch(userActions.user.logout("buyer", history));
          } else if (window.location.href.includes("transaction/seller")) {
            dispatch(userActions.user.logout("seller", history));
          } else if (window.location.href.includes("transaction/buyer")) {
            dispatch(userActions.user.logout("seller", history));
          } else {
            // dispatch(userActions.user.logout('seller', history))
          }
          dispatch(getTransactionRequestFailure(err.response.data.message));
          dispatch(
            alertActions.alert.error(
              "You have to login first to review transaction"?.replace(
                /_/g,
                " "
              )
            )
          );
        } else {
          dispatch(getTransactionRequestFailure(err.response.data.message));
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/_/g, " ")
            )
          );
        }
      } else if (err.request) {
        dispatch(getTransactionRequestFailure(err.message));
        dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
      } else {
        dispatch(getTransactionRequestFailure(err.message));
        dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
      }
    });
};
const updateTransactionRequest = () => ({
  type: transactionConstants.transaction.UPDATE_TRANSACTION,
});
const updateTransactionRequestSuccess = (transaction) => ({
  type: transactionConstants.transaction.UPDATE_TRANSACTION_SUCCESS,
  transaction,
});
const updateTransactionRequestFailure = (error) => ({
  type: transactionConstants.transaction.UPDATE_TRANSACTION_FAILURE,
  error,
});
const updateTransaction = (type, transaction, history) => (dispatch) => {
  dispatch(updateTransactionRequest());
  transactionService.transaction
    .updateTransaction(type, transaction)
    .then((res) => res.data)
    .then((res) => {
      if (res.status == "success") {
        dispatch(
          updateTransactionRequestSuccess({
            ...res.data,
            extra: JSON.parse(res.data.extra),
          })
        );
        dispatch(alertActions.alert.success("Escrow Transaction Updated"));
      } else {
        dispatch(updateTransactionRequestFailure(res.message));
        dispatch(alertActions.alert.error(res.message));
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status == 401) {
          dispatch(userActions.user.logout(type, history));
          dispatch(updateTransactionRequestFailure(err.response.data.message));
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/_/g, " ")
            )
          );
        } else {
          dispatch(updateTransactionRequestFailure(err.response.data.message));
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/_/g, " ")
            )
          );
        }
      } else if (err.request) {
        dispatch(updateTransactionRequestFailure(err.message));
        dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
      } else {
        dispatch(updateTransactionRequestFailure(err.message));
        dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
      }
    });
};
const submitResponseRequest = () => ({
  type: transactionConstants.transaction.SUBMIT_RESPONSE,
});
const submitResponseRequestSuccess = (response) => ({
  type: transactionConstants.transaction.SUBMIT_RESPONSE_SUCCESS,
  response,
});
const submitResponseRequestFailure = (error) => ({
  type: transactionConstants.transaction.UPDATE_TRANSACTION_FAILURE,
  error,
});
const submitResponse = (type, response, history) => (dispatch) => {
  dispatch(submitResponseRequest());
  transactionService.transaction
    .submitResponse(type, response)
    .then((res) => res.data)
    .then((res) => {
      if (res.status === "success") {
        dispatch(
          submitResponseRequestSuccess({
            ...res.data,
            extra: JSON.parse(res.data.extra),
          })
        );
        if (res.message === "response_sent") {
          if (response.response === "agree") {
            if (type === "seller")
              dispatch(
                alertActions.alert.success(
                  `Based on your approval, buyer has been informed to make payment.`
                )
              );
            else dispatch(alertActions.alert.success(`response_sent`));
          } else {
            dispatch(
              alertActions.alert.success(
                `Based on your rejection, ${
                  type === "seller" ? "buyer" : "seller"
                } has been informed to review transaction.`
              )
            );
          }
        }
      } else {
        dispatch(submitResponseRequestFailure(res.message));
        dispatch(alertActions.alert.error(res.message));
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status == 401) {
          dispatch(userActions.user.logout(type, history));
          dispatch(submitResponseRequestFailure(err.response.data.message));
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/_/g, " ")
            )
          );
        } else {
          dispatch(submitResponseRequestFailure(err.response.data.message));
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/_/g, " ")
            )
          );
        }
      } else if (err.request) {
        dispatch(submitResponseRequestFailure(err.message));
        dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
      } else {
        dispatch(submitResponseRequestFailure(err.message));
        dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
      }
    });
};
const cancelTransactionRequest = () => ({
  type: transactionConstants.transaction.CANCEL_TRANSACTION,
});
const cancelTransactionRequestSuccess = (transaction) => ({
  type: transactionConstants.transaction.CANCEL_TRANSACTION_SUCCESS,
  transaction,
});
const cancelTransactionRequestFailure = (error) => ({
  type: transactionConstants.transaction.CANCEL_TRANSACTION_FAILURE,
  error,
});
const cancelTransaction = (type, response, history) => (dispatch) => {
  dispatch(cancelTransactionRequest());
  transactionService.transaction
    .cancelTransaction(type, response)
    .then((res) => res.data)
    .then((res) => {
      if (res.status === "success") {
        dispatch(
          cancelTransactionRequestSuccess({
            ...res.data,
            extra: JSON.parse(res.data.extra),
          })
        );
        dispatch(alertActions.alert.success(res.message));
      } else {
        dispatch(cancelTransactionRequestFailure(res.message));
        dispatch(alertActions.alert.error(res.message));
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 401) {
          dispatch(userActions.user.logout(type, history));
          dispatch(cancelTransactionRequestFailure(err.response.data.message));
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/_/g, " ")
            )
          );
        } else {
          dispatch(cancelTransactionRequestFailure(err.response.data.message));
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/_/g, " ")
            )
          );
        }
      } else if (err.request) {
        dispatch(cancelTransactionRequestFailure(err.message));
        dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
      } else {
        dispatch(cancelTransactionRequestFailure(err.message));
        dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
      }
    });
};

const makePaymentRequest = () => ({
  type: transactionConstants.transaction.MAKE_PAYMENT,
});
const makePaymentRequestSuccess = (authorization) => ({
  type: transactionConstants.transaction.MAKE_PAYMENT_SUCCESS,
  authorization,
});
const makePaymentRequestFailure = (error) => ({
  type: transactionConstants.transaction.MAKE_PAYMENT_FAILURE,
  error,
});
const makePayment =
  (type, transaction, alt, history, paymentType) => (dispatch) => {
    dispatch(makePaymentRequest());
    transactionService.transaction
      .makePayment(type, transaction, alt, paymentType)
      .then((res) => res.data)
      .then((res) => {
        if (res.status === "success") {
          window.location.replace(
            paymentType == "PayPal"
              ? res.data.authorization_url
              : res.data.auth.authorization_url
          );
          return;
          dispatch(makePaymentRequestSuccess(res.data));
          dispatch(alertActions.alert.success(res.message));
        } else {
          dispatch(makePaymentRequestFailure(res.message));
          dispatch(alertActions.alert.error(res.message));
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout(type, history));
            dispatch(makePaymentRequestFailure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(makePaymentRequestFailure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          dispatch(makePaymentRequestFailure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        } else {
          dispatch(makePaymentRequestFailure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }
      });
  };

const makePaymentUSSDRequest = () => ({
  type: transactionConstants.transaction.MAKE_PAYMENT,
});
const makePaymentUSSDRequestSuccess = (invoice) => ({
  type: transactionConstants.transaction.MAKE_PAYMENT_SUCCESS,
  invoice,
});
const makePaymentUSSD = (type, transaction, alt, history) => (dispatch) => {
  dispatch(makePaymentUSSDRequest());
  transactionService.transaction
    .makePaymentUSSD(type, transaction, alt)
    .then((res) => res.data)
    .then((res) => {
      if (res.status === "success") {
        dispatch(makePaymentUSSDRequestSuccess(res.data.auth.responseBody));

        var div = document.createElement("div");
        var p = document.createElement("p"); // Create a <h1> element
        p.classList.add("pclass");
        var t = document.createTextNode(
          "Thank you for choosing AtaraPay. An email has been sent to your registered AtaraPay email address with the details below."
        ); // Create a text node
        var t_b = document.createTextNode(
          "The order will be cancelled if payment made is less than the expected total amount."
        );
        var span = document.createElement("span");
        span.appendChild(
          document.createTextNode(
            "This order will be valid for 48 hours from when you placed the order until " +
              res.data.auth.responseBody.expiryDate +
              ". "
          )
        );
        span.style.color = "maroon";
        p.appendChild(t);
        p.appendChild(span);
        p.appendChild(t_b);
        var p1 = document.createElement("p"); // Create a <h1> element
        p1.classList.add("pclass");
        var t_i = document.createTextNode(
          "To complete your purchase, please make payment via USSD or Bank Transfer."
        );
        p1.appendChild(t_i);
        div.appendChild(p);
        div.appendChild(p1);

        swal({
          title: "Success",
          icon: "success",
          content: div,
          buttons: {
            Paylater: {
              text: "Pay later",
              value: "paylater",
              className: "btn-primary",
            },
            Confirm: {
              text: "Click to Pay Now",
              value: "catch",
              className: "btn-success",
            },
          },
        }).then((value) => {
          switch (value) {
            case "catch":
              window.location.href = res.data.auth.responseBody.checkoutUrl;
              break;

            default:
              window.location.reload();
          }
        });

        // dispatch(alertActions.alert.success(thankyoumsg));
      } else {
        dispatch(makePaymentRequestFailure(res.message));
        dispatch(alertActions.alert.error(res.message));
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 401) {
          dispatch(userActions.user.logout(type, history));
          dispatch(makePaymentRequestFailure(err.response.data.message));
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/_/g, " ")
            )
          );
        } else {
          dispatch(makePaymentRequestFailure(err.response.data.message));
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/_/g, " ")
            )
          );
        }
      } else if (err.request) {
        dispatch(makePaymentRequestFailure(err.message));
        dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
      } else {
        dispatch(makePaymentRequestFailure(err.message));
        dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
      }
    });
};

export const transaction = {
  editTransaction,
  cancelEditTransaction,
  createTransaction,
  getTransactions,
  updateTransaction,
  submitResponse,
  cancelTransaction,
  makePayment,
  makePaymentUSSD,
};
