import { BASE_URL, LOCALSTORAGE_TOKEN_KEY } from '../constants';
import axios from 'axios';

const get = (type) => {
    return axios({
        method: 'get',
        mode: 'no-cors',
        url: `${BASE_URL + type}/orders`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        },
    });
};
const getPaymentHistory = (type) => {
    return axios({
        method: 'get',
        mode: 'no-cors',
        url: `${BASE_URL + type}/payments  `,
        headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        },
    });
};
const getDelivery = (type) => {
    return axios({
        method: 'get',
        mode: 'no-cors',
        url: `${BASE_URL + type}/deliverymen  `,
        headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        },
    });
};

const assignDispatcher = (type, data) => {
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL + type}/order/assign `,
        data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        },
    });
};

const getDisputes = (type) => {
    return axios({
        method: 'get',
        mode: 'no-cors',
        url: `${BASE_URL + type}/order/disputes  `,
        headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        },
    });
};

const raiseDispute = (type, data) => {
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL + type}/order/dispute/raise `,
        data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        },
    });
};

const cancelDispute = (type, data) => {
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL + type}/order/dispute/cancel `,
        data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        },
    });
};

const cancelOrder = (type, response) => {
    return axios({
        method: 'put',
        mode: 'no-cors',
        url: `${BASE_URL}${type}/order/cancel/${response.order_id}`,
        data: response,
        headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        },
    });
};

const applyCoupon = (data) => {
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL}promo/appendPromo`,
        data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        },
    });
};
const clearCoupon = (data) => {
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL}promo/unAppendPromo`,
        data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        },
    });
};

const processPayment = (data, paymentType) => {
    if (paymentType === 'PayPal') {
        return axios({
            method: 'post',
            mode: 'no-cors',
            url: `${BASE_URL}external/createPaypalOrder`,
            data,
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
                'Content-Type': 'application/json',
            },
        });
    }
    return axios({
        method: 'get',
        mode: 'no-cors',
        url: `${BASE_URL}external/create/${data} `,
        headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        },
    });
};

const cancelPayment = (data, paymentType) => {
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL}external/${
            paymentType === 'PayPal' ? 'paypalPaymentFail' : ''
        }`,
        data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        },
    });
};

const processPod = (path, data) => {
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL}order/pod/${path} `,
        data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    });
};

const completeOrder = (data) => {
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL}order/pod/complete `,
        data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    });
};
const addServiceProvider = (data) => {
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL}order/pod/assignSp `,
        data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    });
};

const getTransactionPaymentInformation = (type, data) => {
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL}${type}/order/transactionPaymentInformation`,
        data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        },
    });
};

const readyForPickup = (data) => {
  return axios({
    method: 'post',
    mode: 'no-cors',
    url: `${BASE_URL}order/pod/orderDeliveryPickup`,
    data,
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      'Content-Type': 'application/json',
    },
  });
};

const externalReadyForPickup = (data) => {
  return axios({
    method: 'post',
    mode: 'no-cors',
    url: `${BASE_URL}order/pod/external/orderDeliveryPickup`,
    data,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });
};
const sendDetailsToRedirect = (url, data) => {
  console.log(data)
  return axios({
    method: 'post',
    mode: 'no-cors',
    url: url,
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  });
};

export const order = {
    get,
    getDelivery,
    getPaymentHistory,
    assignDispatcher,
    getDisputes,
    cancelDispute,
    cancelOrder,
    raiseDispute,
    applyCoupon,
    clearCoupon,
    processPayment,
    cancelPayment,
    processPod,
    completeOrder,
    addServiceProvider,
    getTransactionPaymentInformation,
    readyForPickup,
    externalReadyForPickup,
    sendDetailsToRedirect
};
