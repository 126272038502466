import React , {Component} from 'react';
import {Link} from 'react-router-dom';



class Footer extends Component{

    render(){

        return (
          <div style={{ position: "fixed", bottom: "0", width: "100%" }}>
            <footer className="main-footer">
              {/* <div class="float-right d-sm-none d-md-block"></div> */}
              {/* <!-- To the right --> */}
              {/* <div class="float-right d-sm-none d-md-block">
              <i class="fa fa-twitter"></i>
              &nbsp;&nbsp;&nbsp;
              <i class="fa fa-facebook"></i>
              &nbsp;&nbsp;&nbsp;
              <i class="fa fa-instagram"></i>
              &nbsp;&nbsp;&nbsp;
            </div> */}
              {/* <!-- Default to the left --> */}
              <strong>
                Copyright &copy; {new Date().getFullYear()}{" "}
                <a href="https://www.atarapay.com">AtaraPay</a>.
              </strong>{" "}
              All rights reserved.
              <div className="pull-right">
                <b>
                  <a href="https://twitter.com/atarapayonline">
                    <i className="fa fa-twitter"></i>&nbsp;&nbsp;&nbsp;
                  </a>
                  <a href="https://www.facebook.com/atarapay/">
                    <i className="fa fa-facebook"></i>&nbsp;&nbsp;&nbsp;
                  </a>
                  <i className="fa fa-instagram"></i>&nbsp;&nbsp;&nbsp;
                </b>
              </div>
            </footer>
          </div>
        );
    }
}

export default Footer;