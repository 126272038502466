import { alertConstants } from '../constants';

export  const alertReducer = (state = {}, action)=> {
  switch (action.type) {
    case alertConstants.alert.SUCCESS:
      return {
        type: 'alert-success',
        message: action.message
      };
    case alertConstants.alert.ERROR:
      return {
        type: 'alert-danger',
        message: action.message
      };
    case alertConstants.alert.CLEAR:
      return {};
    default:
      return state
  }
}