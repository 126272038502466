import React, {Component} from 'react';
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

class ReferralCodeRedirect extends Component {

    state = {
        error: false,
        loading: true,
    }

    constructor(props) {
        super(props);

        const { referral_code } = this.props.match.params;
        this.props.setReferralCode(referral_code);
    }

    render() {
        const redirect = `/register/buyer`;

        return <Redirect to={redirect}/>;
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch,
    setReferralCode: referral_code => dispatch({type: 'SET_REFERRAL_CODE', payload: referral_code})
})

export default connect(null, mapDispatchToProps)(ReferralCodeRedirect);