import { payoutService } from "../service.js";
import { payoutConstants } from "../constants";
import { alertActions, userActions } from "./";

const create = (details, type, history) => {
    return (dispatch) => {
        dispatch(request(details));
        payoutService.payout
            .create(details, type)
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch(success(res.data.data));
                    dispatch(
                        alertActions.alert.success(
                            "Bank account details successfully added."?.replace(/_/g, " ")
                        )
                    );
                } else {
                    dispatch(failure(res.data.message));
                    dispatch(
                        alertActions.alert.error(res.data.message?.replace(/_/g, " "))
                    );
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout(type, history));
                        // history.push('/login');
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request(details) {
        return {type: payoutConstants.payout.PAYOUT_DETAIL_ADD_REQUEST, details};
    }

    function success(account) {
        return {type: payoutConstants.payout.PAYOUT_DETAIL_ADD_SUCCESS, account};
    }

    function failure(error) {
        return {type: payoutConstants.payout.PAYOUT_DETAIL_ADD_FAILURE, error};
    }
};
const createSp = (details, type, history) => {
    return (dispatch) => {
        dispatch(request(details));
        payoutService.payout
            .createSp(details, type)
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch(success(res.data.data));
                    dispatch(
                        alertActions.alert.success(
                            "Service Provider successfully added."?.replace(/_/g, " ")
                        )
                    );
                    history.push("/app/payout/details");
                } else {
                    dispatch(failure(res.data.message));
                    dispatch(
                        alertActions.alert.error(res.data.message?.replace(/_/g, " "))
                    );
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout(type, history));

                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request(details) {
        return {type: payoutConstants.payout.PAYOUT_DETAIL_ADD_REQUEST, details};
    }

    function success(account) {
        return {type: payoutConstants.payout.PAYOUT_DETAIL_ADD_SUCCESS, account};
    }

    function failure(error) {
        return {type: payoutConstants.payout.PAYOUT_DETAIL_ADD_FAILURE, error};
    }
};

const createPi = (details, type, history) => {
    return (dispatch) => {
        dispatch(request(details));
        payoutService.payout
            .createPi(details, type)
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch(success(res.data.data));
                    dispatch(
                        alertActions.alert.success(
                            "Photo Id successfully added."?.replace(/_/g, " ")
                        )
                    );
                    history.push("/app/payout/details");
                } else {
                    dispatch(failure(res.data.message));
                    dispatch(
                        alertActions.alert.error(res.data.message?.replace(/_/g, " "))
                    );
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout(type, history));

                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request(details) {
        return {type: payoutConstants.payout.PHOTO_ID_ADD_REQUEST, details};
    }

    function success(account) {
        return {type: payoutConstants.payout.PHOTO_ID_ADD_SUCCESS, account};
    }

    function failure(error) {
        return {type: payoutConstants.payout.PHOTO_ID_ADD_FAILURE, error};
    }
};

const managePhotoId = (type, id, action, history) => {
    return (dispatch) => {
        payoutService.payout
            .managePhotoId(type, id, action)
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch(success(res.data.data));
                    dispatch(alertActions.alert.success(res.data.message));
                    history.push("/app/payout/details");
                } else {
                    dispatch(failure(res.data.message));
                    dispatch(
                        alertActions.alert.error(res.data.message?.replace(/_/g, " "))
                    );
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout(type, history));

                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request(details) {
        return {type: payoutConstants.payout.MANAGE_PHOTO_ID_REQUEST, details};
    }

    function success(account) {
        return {type: payoutConstants.payout.MANAGE_PHOTO_ID_SUCCESS, account};
    }

    function failure(error) {
        return {type: payoutConstants.payout.MANAGE_PHOTO_ID_FAILURE, error};
    }
};

const publicManagePhotoId = (id, action, history) => {
    return (dispatch) => {
        payoutService.payout
            .publicManagePhotoId(id, action)
            .then((res) => {
                if (res.data.status === "success") {
                    console.log('in success', res.data.message?.replace(/_/g, " "))
                    history.push("/login");
                    dispatch(alertActions.alert.success(res.data.message?.replace(/_/g, " ")))
                } else {
                    dispatch(failure(res.data.message));
                    dispatch(
                        alertActions.alert.error(res.data.message?.replace(/_/g, " "))
                    );
                }
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response);

                    dispatch(failure(err.response.data.message));
                    dispatch(
                        alertActions.alert.error(
                            err.response.data.message?.replace(/_/g, " ")
                        )
                    );
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request(details) {
        return {type: payoutConstants.payout.MANAGE_PHOTO_ID_REQUEST, details};
    }

    function success(account) {
        return {type: payoutConstants.payout.MANAGE_PHOTO_ID_SUCCESS, account};
    }

    function failure(error) {
        return {type: payoutConstants.payout.MANAGE_PHOTO_ID_FAILURE, error};
    }
};

const all = (type, history) => {
    return (dispatch) => {
        dispatch(request());
        payoutService.payout
            .all(type)
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch(success(res.data.data));
                } else {
                    dispatch(failure(res.data.message));

                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout(type, history));
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request() {
        return {type: payoutConstants.payout.ALL_PAYOUTS_REQUEST};
    }

    function success(payouts) {
        return {type: payoutConstants.payout.ALL_PAYOUTS_SUCCESS, payouts};
    }

    function failure(error) {
        return {type: payoutConstants.payout.ALL_PAYOUTS_FAILURE, error};
    }
};

const view = (type, history) => {
    return (dispatch) => {
        dispatch(request());
        payoutService.payout
            .get(type)
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch(success(res.data.data));
                } else {
                    dispatch(failure(res.data.message));
                    // dispatch(alertActions.alert.error((res.data.message)?.replace(/_/g, " ")));
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 401) {
                        dispatch(userActions.user.logout(type, history));
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request() {
        return {type: payoutConstants.payout.PAYOUT_DETAILS_REQUEST};
    }

    function success(details) {
        return {type: payoutConstants.payout.PAYOUT_DETAILS_SUCCESS, details};
    }

    function failure(error) {
        return {type: payoutConstants.payout.PAYOUT_DETAILS_FAILURE, error};
    }
};

const edit = (data, type, history) => {
    return (dispatch) => {
        dispatch(request());
        payoutService.payout
            .edit(data, type)
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch(success(res.data.data));
                    dispatch(
                        alertActions.alert.success(
                            "Account type has been successfully updated"?.replace(/_/g, " ")
                        )
                    );
                    history.push("/app/payout/details");
                } else {
                    dispatch(failure(res.data.message));
                    dispatch(
                        alertActions.alert.error(res.data.message?.replace(/_/g, " "))
                    );
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout(type, history));
                        // history.push('/login');
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request() {
        return {type: payoutConstants.payout.PAYOUT_DETAIL_EDIT_REQUEST};
    }

    function success(account) {
        return {type: payoutConstants.payout.PAYOUT_DETAIL_EDIT_SUCCESS, account};
    }

    function failure(error) {
        return {type: payoutConstants.payout.PAYOUT_DETAIL_EDIT_FAILURE, error};
    }
};

const setDefault = (id, type, history) => {
    return (dispatch) => {
        dispatch(request());
        payoutService.payout
            .setDefault(id, type)
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch(success(res.data.data));
                    dispatch(
                        alertActions.alert.success(
                            "Bank account is successfully set as Default"?.replace(/_/g, " ")
                        )
                    );
                    history.push("/app/payout/details");
                } else {
                    dispatch(failure(res.data.message));
                    dispatch(
                        alertActions.alert.error(res.data.message?.replace(/_/g, " "))
                    );
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout(type, history));
                        // history.push('/login');
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request() {
        return {type: payoutConstants.payout.PAYOUT_DETAIL_SET_DEFAULT_REQUEST};
    }

    function success(account) {
        return {
            type: payoutConstants.payout.PAYOUT_DETAIL_SET_DEFAULT_SUCCESS,
            account,
        };
    }

    function failure(error) {
        return {
            type: payoutConstants.payout.PAYOUT_DETAIL_SET_DEFAULT_FAILURE,
            error,
        };
    }
};

const delDetail = (id, type, history) => {
    return (dispatch) => {
        dispatch(request());
        payoutService.payout
            .delDetail(id, type)
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch(success(res.data.data));
                    dispatch(
                        alertActions.alert.success(
                            "Bank account details deleted successfully"?.replace(/_/g, " ")
                        )
                    );
                    //  history.push('/app/payout/details');
                } else {
                    dispatch(failure(res.data.message));
                    dispatch(
                        alertActions.alert.error(res.data.message?.replace(/_/g, " "))
                    );
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout(type, history));
                        // history.push('/login');
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                // console.log(err.config)
            });
    };

    function request() {
        return {type: payoutConstants.payout.PAYOUT_DETAIL_DELETE_REQUEST};
    }

    function success(accounts) {
        return {
            type: payoutConstants.payout.PAYOUT_DETAIL_DELETE_SUCCESS,
            accounts,
        };
    }

    function failure(error) {
        return {type: payoutConstants.payout.PAYOUT_DETAIL_DELETE_FAILURE, error};
    }
};
const delSp = (id, type, history) => {
    return (dispatch) => {
        dispatch(request());
        payoutService.payout
            .delSp(id, type)
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch(success(res.data.data));
                    dispatch(
                        alertActions.alert.success(
                            "Service Provider deleted successfully"?.replace(/_/g, " ")
                        )
                    );
                    history.push("/app/payout/details");
                } else {
                    dispatch(failure(res.data.message));
                    dispatch(
                        alertActions.alert.error(res.data.message?.replace(/_/g, " "))
                    );
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout(type, history));
                        // history.push('/login');
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                // console.log(err.config)
            });
    };

    function request() {
        return {type: payoutConstants.payout.PAYOUT_SP_DELETE_REQUEST};
    }

    function success(accounts) {
        return {type: payoutConstants.payout.PAYOUT_SP_DELETE_SUCCESS, accounts};
    }

    function failure(error) {
        return {type: payoutConstants.payout.PAYOUT_DETAIL_DELETE_FAILURE, error};
    }
};
const getBanks = (type, history) => {
    const request = () => {
        return {type: payoutConstants.payout.GET_BANKS};
    };
    const success = (banks) => {
        return {type: payoutConstants.payout.GET_BANKS_SUCCESS, banks};
    };
    const failure = (error) => {
        return {type: payoutConstants.payout.GET_BANKS_FAILURE, error};
    };

    return (dispatch) => {
        dispatch(request(type));
        payoutService.payout
            .getBanks(type)
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch(success(res.data.data));
                    // dispatch(alertActions.alert.success((res.data.message)?.replace(/_/g, " ")));
                } else {
                    dispatch(failure(res.data.message));
                    // dispatch(alertActions.alert.error((res.data.message)?.replace(/_/g, " ")));
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout("seller", history));
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }
                console.log(err.config);
            });
    };
};

export const payout = {
    create,
    all,
    view,
    edit,
    setDefault,
    delDetail,
    delSp,
    createSp,
    createPi,
    managePhotoId,
    getBanks,
    publicManagePhotoId
};
