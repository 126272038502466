import React, { Component } from "react";
import { userActions, alertActions } from "../actions";
import { Link, withRouter } from "react-router-dom";
import PhoneInput, { formatPhoneNumber } from "react-phone-number-input";
// import "react-phone-number-input/style.css";
import swal from "@sweetalert/with-react";
import Modal from "react-responsive-modal";

import { OrderEmail, OrderDeliveryEmail } from "./ModalViews";
import axios from "axios";
import { BASE_URL } from "../constants";

const initialState = {
  email: "",
  password: "",
  submitted: false,
};
var count = 0;

class Login extends Component {
  // componentDidMount = () => {
  //   if(localStorage.getItem("user")!=null){
  //   this.props.history.push("/app");
  //   }
  // }

  constructor(props) {
    super(props);

    // reset login status
    // dispatch(userActions.user.logout());

    this.state = {
      show: true,
      email: "",
      phone: "",
      password: "",
      password_confirmation: "",
      submitted: false,
      type: this.props.match.params.type,
      orderEmail: false,
      orderDeliveryEmail: false,
      default: false,
      staticEmail: "",
      buyerStep: 1,
    };
  }

  componentDidMount = () => {
    if (this.props.match.params.mailType !== "delivery") {
      if (this.props.match.params.code) {
        this.setState({ orderEmail: true });
      }
    } else {
      this.setState({ orderDeliveryEmail: true });
    }
  };
  componentWillReceiveProps = (props) => {
    if (
      props.alert &&
      props.alert.message &&
      props.alert.type === "alert-danger"
    ) {
      if (props.alert.message === "unverified") {
        count++;
        if (count < 2) {
          //This account is yet to be verified. To verify your account, please check your email for your verification link
          swal({
            title: "Error",
            text: "Your phone number and email address is yet to be verified. To verify, please check your email for your verification link",
            type: "error",
            buttons: ["Resend email", "Close"],
          }).then((val) => {
            if (!val) {
              if (props && props.user) {
                let data = { email: props.user && props.user.email };
                this.props.dispatch(
                  userActions.user.resendEmail(
                    data,
                    this.props.match.params.type,
                    this.props.history
                  )
                );
                this.props.dispatch(alertActions.alert.clear());
              }
              this.props.dispatch(alertActions.alert.clear());
              return;
            }
            this.props.dispatch(alertActions.alert.clear());
          });
          return;
        }
        return;
      }
      swal("Error", props.alert.message, "error").then(() => {
        this.props.dispatch(alertActions.alert.clear());
      });
    }
    if (
      props.alert &&
      props.alert.message &&
      props.alert.type === "alert-success"
    ) {
      if (props.alert.message === "Resent email") {
        swal({
          title: "Success",
          content: (
            <div>
              A verification email has been sent to <b>{this.props.msg}</b>.
              Please access the email to verify your phone number
            </div>
          ),
          icon: "success",
        }).then(() => {
          this.props.dispatch(alertActions.alert.clear());
        });
        return;
      }
      swal("Success", props.alert.message, "success").then(() => {
        this.props.dispatch(alertActions.alert.clear());
      });
    }
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handlePhone = (value, name) => {
    // value = value.replace(/[^a-zA-Z ]/g, "");
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  handleTabClick = (type) => {
    const { dispatch } = this.props;
    this.setState(initialState);
    dispatch(alertActions.alert.clear());
    // this.props.history.push(`/login/${type}`);
    this.setState({ type: type });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password } = this.state;
    const credentials = { email, password };
    const type = e.target.dataset.type;
    const { dispatch } = this.props;
    if (email && password) {
      this.setState({ staticEmail: email });
      dispatch(userActions.user.login(credentials, type, this.props.history));
    }
  };
  toggleModal = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  handleBuyerLogin = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    const {
      firstname,
      lastname,
      email,
      phone,
      password,
      password_confirmation,
      buyerStep,
    } = this.state;
    const { dispatch } = this.props;

    if (buyerStep === 1) {
      const credentials = { email: phone };

      axios({
        method: "post",
        mode: "no-cors",
        url: `${BASE_URL}customer/checkBuyer `,
        data: credentials,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
        .then(({ data }) => {
          console.log(data);
          if (data.status == "error") {
            swal(
              "Error",
              "Account does not exist. Please register first",
              "error"
            );
          } else {
            const buyerData = data.data;

            if (buyerData.firstTime == true) {
              this.setState({
                buyerStep: 2,
                email: buyerData.email,
              });
            } else {
              this.setState({ buyerStep: 3 });
            }
          }
        })
        .catch((err) => {
          swal(
            "Error",
            err.response ? err.response.message : "Something went wrong",
            "error"
          );
        })
        .finally(() => {
          this.setState({
            submitted: false,
          });
        });
    } else if (buyerStep === 2) {
      const data = {
        firstname,
        lastname,
        email,
        phone_number: phone,
        password,
        password_confirmation,
      };
      console.log(data);

      axios({
        method: "post",
        mode: "no-cors",
        url: `${BASE_URL}customer/completeEasyRegistration `,
        data: data,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
        .then(({ data }) => {
          console.log(data);
          if (data.status == "success") {
            if (data.message == "customer_updated") {
              swal(
                "Success",
                "Your account information has been updated. You are being logged in.",
                "success"
              );

              const credentials = { email: phone, password };
              dispatch(
                userActions.user.login(
                  credentials,
                  "customer",
                  this.props.history
                )
              );
            }
          } else {
            swal("Error", data.message || "Something went wrong", "error");
          }
        })
        .catch((err) => {
          swal(
            "Error",
            err.response ? err.response.message : "Something went wrong",
            "error"
          );
        })
        .finally(() => {
          this.setState({
            submitted: false,
          });
        });
    } else if (buyerStep === 3) {
      const credentials = { email: phone, password };
      dispatch(
        userActions.user.login(credentials, "customer", this.props.history)
      );
    }
  };

  render() {
    const { loggingIn, alert, loading } = this.props;
    const {
      email,
      phone,
      firstname,
      lastname,
      password,
      password_confirmation,
      buyerStep,
    } = this.state;
    let { type, name, amount, code } = this.props.match.params;
    if (type === "customer" || code) {
      type = "buyer";
    }
    return (
      <div className="background-gradient" style={{ height: "100vh" }}>
        <nav className="navbar navbar-expand-lg fixed-top navbar-inverse">
          <a className="navbar-brand" href="https://www.atarapay.com">
            <img
              src="/dist/img/logo-header.png"
              alt=""
              className="logo-img img img-responsive"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </nav>
        <OrderEmail
          open={this.state.orderEmail}
          onClose={() => {
            this.toggleModal("orderEmail");
          }}
          name={this.props.match.params.name}
          amount={this.props.match.params.amount}
          code={this.props.match.params.code}
        />

        <OrderDeliveryEmail
          open={this.state.orderDeliveryEmail}
          onClose={() => {
            this.toggleModal("orderDeliveryEmail");
          }}
          name={this.props.match.params.name}
          phone={this.props.match.params.amount}
          code={this.props.match.params.code}
        />

        <div className="row row-margin top-margin-60">
          <div className="col-sm-3 col-md-3"></div>
          <div className="col-sm-8 col-md-6 col-12">
            <div className="card card-margin">
              {/* <img src="/dist/img/logo-white.png" alt="" className="logo-img img img-responsive" />
                             <ul role="tablist" className="nav nav-tabs nav-justified ">
                                {type&&type==`buyer`&&<li className="nav-item"><a  href="#buyer" onClick={()=>{this.handleTabClick("buyer")}} role="tab" data-toggle="tab"
                                        className={`nav-link  ${type&&type?(type&&type==`buyer`?`active`:``):`active`}`}>Buyer</a></li>}
                                {type&&type==`seller`&&<li className="nav-item"><a  href="#seller" onClick={()=>{this.handleTabClick("seller")}} role="tab" data-toggle="tab"
                                    className={`nav-link ${type&&type?(type&&type==`seller`?`active`:``):``}`}>Seller</a></li>}
                                <li className="nav-item"><a  href="#marketplace" onClick={()=>{this.handleTabClick("market")}} role="tab"
                                    data-toggle="tab" className={`nav-link ${type&&type?(type&&type==`market`?`active`:`disabled`):``}`}>Marketplace Admin</a></li>
                            </ul><br /> */}
              <div className="tab-content">
                <div
                  role="tabpanel"
                  id="buyer"
                  className={`tab-pane left-margin-35 ${
                    type && type
                      ? type && type === `buyer`
                        ? `active`
                        : ``
                      : ``
                  }`}
                >
                  <br />
                  {
                    <>
                      <form
                        data-type="customer"
                        onSubmit={this.handleBuyerLogin}
                      >
                        <h4>
                          <strong>Buyer Login</strong>
                        </h4>

                        <p>
                          <span className="text-danger">*</span> Indicates
                          mandatory fields
                        </p>
                        <div className="custom-label form-group ">
                          <label className="small" htmlFor="buyer_email">
                            <small>
                              Please Enter Your Phone Number
                              <span className="text-danger">*</span>
                            </small>
                          </label>
                          <div className="col-sm-11">
                            <PhoneInput
                              defaultCountry="NG"
                              placeholder="08XX XXX XXXX"
                              limitMaxLength={true}
                              value={
                                phone &&
                                formatPhoneNumber(phone, "International")
                              }
                              onChange={(value) =>
                                this.handlePhone(
                                  (value &&
                                    formatPhoneNumber(
                                      value,
                                      "International"
                                    ).replace(/ /g, "")) ||
                                    "",
                                  "phone"
                                )
                              }
                              inputClassName={"custom-input max-custom-input"}
                              disabled={buyerStep === 2 || buyerStep === 3}
                            />
                          </div>
                        </div>
                        {buyerStep === 2 && (
                          <>
                            <div className="text-sm">
                              You are logging in for the first time. Please
                              complete your registration to login
                            </div>
                            <div className="custom-label form-group">
                              <label className="small" htmlFor="buyer_password">
                                <small className="small">
                                  First name
                                  <span className="text-danger">*</span>
                                </small>
                              </label>
                              <input
                                type="text"
                                name="firstname"
                                value={firstname}
                                onChange={this.handleChange}
                                required="required"
                                placeholder=""
                                className="custom-input"
                              />
                            </div>
                            <div className="custom-label form-group">
                              <label className="small" htmlFor="buyer_password">
                                <small className="small">
                                  Last name
                                  <span className="text-danger">*</span>
                                </small>
                              </label>
                              <input
                                type="text"
                                name="lastname"
                                value={lastname}
                                onChange={this.handleChange}
                                required="required"
                                placeholder=""
                                className="custom-input"
                              />
                            </div>
                            <div className="custom-label form-group">
                              <label className="small" htmlFor="buyer_password">
                                <small className="small">
                                  Your Email
                                  <span className="text-danger">*</span>
                                </small>
                              </label>
                              <input
                                type="email"
                                name="email"
                                value={email}
                                disabled={true}
                                required="required"
                                placeholder=""
                                className="custom-input"
                              />
                            </div>
                          </>
                        )}
                        {(buyerStep === 2 || buyerStep === 3) && (
                          <div className="custom-label form-group">
                            <label className="small" htmlFor="buyer_password">
                              <small className="small">
                                Please Enter Your Password
                                <span className="text-danger">*</span>
                              </small>
                            </label>
                            <input
                              type="password"
                              name="password"
                              value={password}
                              onChange={this.handleChange}
                              id="buyer_password"
                              required="required"
                              placeholder="********"
                              className="custom-input"
                            />
                          </div>
                        )}
                        {buyerStep === 2 && (
                          <div className="custom-label form-group">
                            <label className="small" htmlFor="buyer_password">
                              <small className="small">
                                Please confirm your password
                                <span className="text-danger">*</span>
                              </small>
                            </label>
                            <input
                              type="password"
                              name="password_confirmation"
                              value={password_confirmation}
                              onChange={this.handleChange}
                              id="buyer_password"
                              required="required"
                              placeholder="********"
                              className="custom-input"
                            />
                          </div>
                        )}
                        {buyerStep === 3 && (
                          <div>
                            <Link to="/forgot/buyer" className="">
                              <p className="float-left">
                                <em>
                                  <small>Forgot password ?</small>
                                </em>
                              </p>
                            </Link>
                          </div>
                        )}
                        <div className="custom-label form-group">
                          <button
                            name="button"
                            className="btn btn-dark-blue btn-login"
                            disabled={loggingIn || loading}
                            value="Login"
                          >
                            {loggingIn || loading ? "Sending..." : "Login"}
                          </button>
                          <hr />
                          <p className="text-center">
                            Login as{" "}
                            <Link to="/login/seller" className="">
                              Seller
                            </Link>
                          </p>
                          <p className="text-center">
                            Don't have an account? Register as{" "}
                            <Link to="/register/buyer" className="">
                              Buyer
                            </Link>{" "}
                            or{" "}
                            <Link to="/register/seller" className="">
                              Seller
                            </Link>
                          </p>
                          {/* <hr />
                                            <Link to="/register" className="">
                                                <p  className="text-center  ">Register
                                            an account</p>
                                            </Link> */}
                        </div>
                      </form>
                    </>
                  }
                </div>
                <div
                  role="tabpanel"
                  id="seller"
                  className={`tab-pane left-margin-35 ${
                    type && type
                      ? type && type === `seller`
                        ? `active`
                        : ``
                      : `active`
                  }`}
                >
                  <br />
                  <form data-type="seller" onSubmit={this.handleSubmit}>
                    <h4>
                      <strong>Seller Login</strong>
                    </h4>
                    <p>
                      <span className="text-danger">*</span> Indicates mandatory
                      fields
                    </p>
                    <div className="custom-label form-group ">
                      <label className="small" htmlFor="buyer_email">
                        <small>
                          Please Enter Your Email Address
                          <span className="text-danger">*</span>
                        </small>
                      </label>
                      <input
                        type="text"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                        id="buyer_email"
                        required="required"
                        placeholder="Email Address"
                        className="custom-input"
                      />
                    </div>
                    <div className="custom-label form-group">
                      <label className="small" htmlFor="buyer_password">
                        <small className="small">
                          Please Enter Your Password
                          <span className="text-danger">*</span>
                        </small>
                      </label>{" "}
                      <input
                        type="password"
                        name="password"
                        value={password}
                        onChange={this.handleChange}
                        id="buyer_password"
                        required="required"
                        placeholder="********"
                        className="custom-input"
                      />
                      <Link to="/forgot/seller" className="">
                        <p className="float-left">
                          <em>
                            <small>Forgot password ?</small>
                          </em>
                        </p>
                      </Link>
                    </div>
                    <div className="custom-label form-group">
                      <button
                        name="button"
                        className="btn btn-dark-blue btn-login"
                        disabled={loggingIn}
                        value="Login"
                      >
                        {loggingIn ? "Sending..." : "Login"}
                      </button>
                      <hr />
                      <p className="text-center">
                        Login as{" "}
                        <Link to="/login/buyer" className="">
                          Buyer
                        </Link>
                      </p>
                      <p className="text-center">
                        Don't have an account? Register as{" "}
                        <Link to="/register/buyer" className="">
                          Buyer
                        </Link>{" "}
                        or{" "}
                        <Link to="/register/seller" className="">
                          Seller
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
                <div
                  role="tabpanel"
                  id="marketplace"
                  className={`tab-pane left-margin-35 ${
                    type && type
                      ? type && type === `market`
                        ? `active`
                        : ``
                      : ``
                  }`}
                >
                  <br />
                  <h4>
                    <strong>MarketPlace Login</strong>
                  </h4>
                  <div className="alert animated bounceIn buyer-alert alert-danger d-none">
                    <a aria-label="close" className="close alert-close">
                      ×
                    </a>{" "}
                    <span className="alert-message-content"></span>
                  </div>
                  <p>* Indicates mandatory fields</p>
                  <div className="custom-label form-group ">
                    <label className="small" htmlFor="buyer_email">
                      <small>Please Enter Your Email Address*</small>
                    </label>
                    <input
                      type="email"
                      id="buyer_email"
                      required="required"
                      placeholder="Email Address"
                      className="custom-input"
                    />
                  </div>
                  <div className="custom-label form-group">
                    <label className="small" htmlFor="buyer_password">
                      <small className="small">
                        Please Enter Your Password*
                      </small>
                    </label>{" "}
                    <input
                      type="password"
                      id="buyer_password"
                      required="required"
                      placeholder="********"
                      className="custom-input"
                    />
                    <Link to="/forgot/market" className="">
                      <p className="float-left">
                        <em>
                          <small>Forgot password ?</small>
                        </em>
                      </p>
                    </Link>
                  </div>
                  <div className="custom-label form-group">
                    <button
                      name="button"
                      className="btn btn-dark-blue btn-login"
                      disabled={loggingIn || loading}
                      value="Login"
                    >
                      {loggingIn || loading ? "Sending..." : "Login"}
                    </button>
                    <hr />
                    <Link to="/register" className="">
                      <p className="text-center">Register an account</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-md-3"></div>
        </div>
        {/* <Modal open={this.state.default} onClose={() => this.toggleModal("default")} center>

                    <div className="modal-header">
                        <h4 className="modal-title">Error</h4>
                    </div>
                    <div className="modal-body">
                        <div className="row form-group">
                            <div className="col-sm-12">
                                <p >This account is yet to be verified. To verify your account,<br/> please check your email for your verification link</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer"><button type="button" onClick={() => this.toggleModal("default")} data-dismiss="modal"
                        className="btn btn-primary">Close</button>
                        &nbsp;&nbsp;
            <button type="button" disabled={loading} onClick={() => this.props.dispatch(userActions.user.resendEmail( staticEmail, this.props.match.params.type, this.props.history))} className="btn btn-danger">{loading ? "Sending..." : "Resend Email"}</button></div>

                </Modal> */}
      </div>
    );
  }
}

export default withRouter(Login);
