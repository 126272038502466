import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Disputes from '../views/Disputes';
import { orderActions } from '../actions';
// import { payoutActions } from '../actions';


const mapStateToProps = (state) =>{
    const { loading, disputes, canceling} = state.orders;
    

    const alert  = state.alert;
    
    return {
        alert,
        loading,
        canceling,
        disputes
       

       
    };
     
     
 }
 const mapDispatchToProps = (dispatch) => ({
     dispatch,
   
    view:(type,history)=>dispatch(orderActions.order.viewDisputes(type,history)),
     cancel: (type, data, history) => dispatch(orderActions.order.cancelDispute(type, data, history)),
    
}
)
 

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Disputes));