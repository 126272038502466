const initialState = {
  code: null,
}

const referralReducer = function(state = initialState, action){
  switch(action.type){
    case 'SET_REFERRAL_CODE':
      const newState = {
        ...state,
        code: action.payload
      };

      return newState;
    default:
      return state;
  }
}

export default referralReducer;