import React , {Component} from 'react';
import supportSchema from '../validation/support';
import { supportActions, alertActions } from '../actions'
import {withRouter} from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import swal from '@sweetalert/with-react'
import $ from 'jquery'; 

import { loaderOverride } from "../helpers/styleHelper";
const intialState =  {
    subject: '',
    description: '',
    attachment: '',
    
}
class Support extends Component{
    state = {
        fields : {
            subject: '',
            description: '',
            attachment: '',
            email:this.props.user&&this.props.user.email||''
        },
        error: null,
        submitted: false
    }
    // constructor (props) {
    //     super(props)
     
    // }

    handleChange = (e) => {
        const {name, value} = e.target;
        const fields = { ...this.state.fields, [name]: value}

        this.setState({fields});
    }

    handleFileChange = (e) => {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.files[0];
        this.setState({ fields })
    }

    handleSubmit= async(e) => {
        this.setState({ submitted: true, error: null });

        const support = this.state.fields;
        const validation = await supportSchema.validate(support).catch(
                error => {
                    swal("Error", error.errors[0], "error");
                    return this.setState({error: error.errors[0]})
                }
            )
        if (!validation) {
            return
        }

        const { dispatch} = this.props;
        var role=$('.d-block small').html();
        console.log('role',role);    
        dispatch(supportActions.createSupportMail(support, (role==='<br>(Buyer)')?'buyer': (role=='(MarketPlace)')? 'seller' : 'seller', this.props.history));
       
        
    }
    componentWillReceiveProps(props){
        this.setState({fields:{...this.state.fields,email:this.props.user&&this.props.user.email}})
        if(props.alert && props.alert.message && props.alert.type=="alert-danger"){
            swal("Error",props.alert.message,"error").then(() => {
                props.dispatch(alertActions.alert.clear());
              });
        }else if(props.alert && props.alert.message && props.alert.type!="alert-danger"){
            //  ${support.email}
        swal({title:"Success",content:<div>Thank you for your message. Our support team will respond via email to  <b>{props.alert.message}</b> within 1 business day</div>,icon:"success"}).then(() => {
                props.dispatch(alertActions.alert.clear());
              });
            this.setState({fields:intialState,email:this.props.user&&this.props.user.email})
        }
    }
render(){
    const {fields, error } = this.state
    const {loading,alert,success,user} = this.props
    //console.log(success);
    
return(
 alert ? <div class="content-wrapper" style={{minHeight: 103 ,padding: '2%'}}>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Support</h1>
                    </div>
                </div>
            </div>
        </div> <br />
<section class="content">
    <div class="container-fluid">
        <div class="callout callout-info">
            <div class="row">
                <div class="col-sm-12 col-md-12"><br /><br />
                {/* {error && <div class="alert animated bounceIn support-alert alert-danger"><a aria-label="close" class="close alert-close">×</a>
                        <span class="alert-message-content">{error}</span></div>} */}
                    <div>
                    <div class="form-group"><label>Subject *</label> 
                    <input type="text" class="form-control" name="subject" onChange={this.handleChange.bind(this)} value={fields.subject}/></div>
                    <br />
                    <div class="form-group"><label>Contact Email *</label> 
                    <input type="text" class="form-control" name="email"  onChange={this.handleChange.bind(this)}  value={fields.email}/></div>
                    <br />
                    <div class="form-group"><label>Description *</label> 
                    <textarea class="form-control" name="description" minLength="5" onChange={this.handleChange.bind(this)} value={fields.description}></textarea></div>
                    <br />
                    <div class="form-group"><label>Attachment </label><br /> 
                    <input type="file" name="attachment" 
                        onChange={this.handleFileChange.bind(this)}
                    /></div>
                    <br /> 
                    <button class="btn btn-primary" 
                    onClick={this.handleSubmit.bind(this)}
                    disabled={loading}>{loading ? 'Loading ...' : 'Send Email'}</button> <br /><br />
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</section>
</div>:<div className='sweet-loading'>
        <ClipLoader
          cssOverride={loaderOverride}
          size={70}
          color={'blue'}
          loading={loading}
        />
        </div>
)
}
}

export default  withRouter(Support);