import { orderService } from "../service.js/index.js";
import {
  orderConstants,
  disputeConstants,
  transactionConstants,
} from "../constants";
import { alertActions, userActions } from ".";

function failure(error) {
  return { type: orderConstants.order.ORDER_DETAILS_FAILURE, error };
}

const rejectOrder = (type, data, history) => {
  return (dispatch) => {
    // dispatch({ type: "SET_LOADING", loading: true });
    orderService.order
      .processPod(type, data)
      .then((res) => {
        console.log("response", res);
        dispatch(alertActions.alert.success("Thank you for your response."));
        // dispatch({ type: "SET_LOADING", loading: false });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          }
        }
      });
  };
};
const acceptOrder = (type, data, history) => {
  return (dispatch) => {
    orderService.order
      .processPod(type, data)
      .then((res) => {
        dispatch(alertActions.alert.success("Order accepted successfully!"));
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          }
        }
      });
  };
};
const confirmImpediment = (type, data, history) => {
  return (dispatch) => {
    orderService.order
      .processPod(type, data)
      .then((res) => {
        if (res.data.message === "pod_updated" && data.type === "inspection") {
          if (data.answer === "A")
            dispatch(
              alertActions.alert.success(
                "Thank you for your response. Buyer will be notified of the new delivery attempt"
              )
            );
          else if (data.answer === "Y")
            dispatch(
              alertActions.alert.success(
                data?.refundType == 1
                  ? "Thank you for your response. Buyer will be refunded"
                  : "Thank you for your response. Please arrange replacement for the buyer"
              )
            );
          else if (data.answer === "N")
            dispatch(
              alertActions.alert.success(
                "Thank you for your response. Buyer will be notified"
              )
            );
          else
            dispatch(
              alertActions.alert.success(
                // "Point of Delivery transaction completed"
                "Thank you for your response. Buyer will be notified of the new delivery attempt"
              )
            );
        } else if (
          res.data.message === "pod_updated" &&
          data.type === "inspections"
        ) {
          if (data.answer === "Y")
            dispatch(
              alertActions.alert.success(
                data?.refundType == 1
                  ? "Thank you for your response. Buyer will be refunded"
                  : "Thank you for your response. Please arrange replacement for the buyer"
              )
            );
          else if (data.answer === "N")
            dispatch(
              alertActions.alert.success(
                "Thank you for your response. Buyer will be notified"
              )
            );
          else
            dispatch(
              alertActions.alert.success(
                // "Point of Delivery transaction completed"
                "Thank you for your response. Buyer will be notified of the new delivery attempt"
              )
            );
        } else {
          dispatch(
            alertActions.alert.success(
              "Thank you for your response. Buyer will be notified of the new delivery attempt"
            )
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          }
        }
      });
  };
};

export const PODOrder = {
  rejectOrder,
  acceptOrder,
  confirmImpediment,
};
