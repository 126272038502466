export const user = {
    REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USER_REGISTER_FAILURE',

    RESEND_EMAIL_REQUEST: 'RESEND_EMAIL_REQUEST',
    RESEND_EMAIL_SUCCESS: 'RESEND_EMAIL_SUCCESS',
    RESEND_EMAIL_FAILURE: 'RESEND_EMAIL_FAILURE',
    
    GET_VERIFY_REQUEST: 'GET_VERIFY_REQUEST',
    GET_VERIFY_SUCCESS: 'GET_VERIFY_SUCCESS',
    GET_VERIFY_FAILURE: 'GET_VERIFY_FAILURE',

    LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
    LOGIN_UNVERIFIED: 'USER_LOGIN_UNVERIFIED',
   
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

    PASSWORD_CHANGE_REQUEST: 'PASSWORD_CHANGE_REQUEST',
    PASSWORD_CHANGE_SUCCESS: 'PASSWORD_CHANGE_SUCCESS',
    PASSWORD_CHANGE_FAILURE: 'PASSWORD_CHANGE_FAILURE',

    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',


    LOGOUT: 'USER_LOGOUT',

    GO_LIVE_REQUEST: 'GO_LIVE_REQUEST',
    GO_LIVE_SUCCESS: 'GO_LIVE_SUCCESS',
    GO_LIVE_FAILURE: 'GO_LIVE_FAILURE',
    
    GET_KEY_REQUEST: 'GET_KEY_REQUEST',
    GET_KEY_SUCCESS: 'GET_KEY_SUCCESS',
    GET_KEY_FAILURE: 'GET_KEY_FAILURE',

    GET_OTP_REQUEST: 'GET_OTP_REQUEST',
    GET_OTP_SUCCESS: 'GET_OTP_SUCCESS',
    GET_OTP_FAILURE: 'GET_OTP_FAILURE',
    
    SUBMIT_OTP_REQUEST: 'SUBMIT_OTP_REQUEST',
    SUBMIT_OTP_SUCCESS: 'SUBMIT_OTP_SUCCESS',
    SUBMIT_OTP_FAILURE: 'SUBMIT_OTP_FAILURE',

    SUBMIT_BUSINESS_REQUEST: 'SUBMIT_BUSINESS_REQUEST',
    SUBMIT_BUSINESS_SUCCESS: 'SUBMIT_BUSINESS_SUCCESS',
    SUBMIT_BUSINESS_FAILURE: 'SUBMIT_BUSINESS_FAILURE',

    DASHBOARD_DETAILS_REQUEST: 'DASHBOARD_DETAILS_REQUEST',
    DASHBOARD_DETAILS_SUCCESS: 'DASHBOARD_DETAILS_SUCCESS',
    DASHBOARD_DETAILS_FAILURE: 'DASHBOARD_DETAILS_FAILURE',

    CONFIRM_BUYER_REQUEST: 'CONFIRM_BUYER_REQUEST',
    CONFIRM_BUYER_SUCCESS: 'CONFIRM_BUYER_SUCCESS',
    CONFIRM_BUYER_FAILURE: 'CONFIRM_BUYER_FAILURE',

    GEN_KEY_REQUEST: 'GEN_KEY_REQUEST',
    GEN_KEY_SUCCESS: 'GEN_KEY_SUCCESS',
    GEN_KEY_FAILURE: 'GEN_KEY_FAILURE',

    GETALL_REQUEST: 'USER_DETAIL_REQUEST',
    GETALL_SUCCESS: 'USER_DETAIL_SUCCESS',
    GETALL_FAILURE: 'USER_DETAIL_FAILURE',

    BVN_PAY_REQUEST: 'BVN_PAY_REQUEST',
    BVN_PAY_SUCCESS: 'BVN_PAY_SUCCESS',
    BVN_PAY_FAILURE: 'BVN_PAY_FAILURE',
}