import * as yup from 'yup';

const stageTwoSchema = yup.object().shape({
    // BVN: yup.string().max(11).min(11).required("Your BVN is required"),
    is_marketplace: yup.number().default(0),
    marketplace_child: yup.number().default(0),
    business_name: yup.string().when('individual',{
        is: (val) => val === 0,
        then: yup.string().required("What name is your Business called ?"),
    }),
    business_address: yup.string().when('individual',{
        is: (val) => val === 0,
        then: yup.string().required("Enter a Business Address"),
    }),
    business_address_2: yup.string().when('individual',{
        is: (val) => val === 0,
        then: yup.string(),
    }),
    business_country: yup.string().when('individual',{
        is: (val) => val === 0,
        then: yup.string().required("Enter a Country of Business"),
    }),
    business_city: yup.string().when('individual',{
        is: (val) => val === 0,
        then: yup.string().required("Enter City of Business"),
    }),
    business_state: yup.string().when('individual',{
        is: (val) => val === 0,
        then: yup.string().required("Enter State"),
    }),
    business_email: yup.string().when('individual',{
        is: (val) => val === 0,
        then: yup.string().email().required("Business Email is Required"),
    }),
    siteURL: yup.string().when('individual',{
        is: (val) => val === 0,
        then: yup.string(),
    }),
    year_of_inc: yup.string().when('individual',{
        is: (val) => val === 0,
        then: yup.string().required("Year of Incorporation is Required for Business"),
    }),
    memorandum_of_article: yup.mixed().when('individual',{
        is: (val) => val === 0,
        then: yup.mixed().required("Memorandum of Article is Required for Business"),
    })
})

export default {
    stageTwoSchema
}