import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  USER_ROLE_SELLER,
  USER_ROLE_MARKET,
  USER_ROLE_BUYER,
} from "../constants";
import Avatar from "react-avatar";
import $ from "jquery";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLogic: false,
      openPayout: false,
      openReports: false,
      openAPI: false,
    };
  }

  getVerifiedStyle = () => {
    const { user } = this.props;
    if (
      user &&
      user.role &&
      (user.role.name === USER_ROLE_SELLER ||
        user.role.name === USER_ROLE_MARKET) &&
      (user.verified !== 1 ||
        user.support_verified !== 1 ||
        user.seller_id == null)
    ) {
      return {
        color: "darkgray",
        fontWeight: "400",
      };
    } else {
      return {
        // color: '#343a40'
      };
    }
  };

  CloseMenu = () => {
    if ($(window).width() >= 768) {
      $("body").addClass("sidebar-open").removeClass("sidebar-collapse");
    } else {
      $("body").addClass("sidebar-collapse").removeClass("sidebar-open");
    }
  };

  render() {
    const { openLogic, openPayout, openAPI, openReports } = this.state;
    const { user } = this.props;
    const { pathname } = this.props.location;
    //console.log(user);
    return (
      <div className="wrapper">
        <aside className="main-sidebar sidebar-light-primary elevation-4">
          {/* <!-- Brand Logo --> */}
          <a href="https://www.atarapay.com" className="brand-link">
            <span className="brand-text">
              {" "}
              <img
                src="/dist/img/logo.3fc64c3.png"
                alt="AtaraPay"
                style={{ opacity: 0.8, width: "140px", paddingLeft: 15 }}
              />
            </span>
          </a>

          {/* <!-- Sidebar --> */}
          <div className="sidebar" style={{ position: "relative" }}>
            {/* <!-- Sidebar user panel (optional) --> */}
            <Link
              onClick={(e) => {
                if (
                  user &&
                  user.role &&
                  (user.role.name === USER_ROLE_SELLER ||
                    user.role.name === USER_ROLE_MARKET) &&
                  (user.verified !== 1 ||
                    user.support_verified !== 1 ||
                    user.seller_id === null)
                ) {
                  e.preventDefault();
                }
              }}
              style={this.getVerifiedStyle()}
              to="/app/seller/profile/view"
            >
              <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                  <Avatar
                    color={"rgb(52, 120, 246)"}
                    name={
                      user &&
                      user.role &&
                      (user.role.name === USER_ROLE_SELLER ||
                        user.role.name === USER_ROLE_MARKET) &&
                      user.seller &&
                      user.seller.business_name
                        ? user && user.seller && user.seller.business_name
                        : user && user.firstname + " " + user.lastname
                    }
                    maxInitials={2}
                    size={30.23}
                    className={"img-circle elevation-2"}
                  />
                </div>
                <div className="info">
                  {
                    <span href="#" className={"d-block"}>
                      {user &&
                      user.role &&
                      (user.role.name === USER_ROLE_SELLER ||
                        user.role.name === USER_ROLE_MARKET) &&
                      user.seller &&
                      user.seller.business_name
                        ? user && user.seller && user.seller.business_name
                        : user && user.firstname + " " + user.lastname}
                      <small>
                        <br />
                        {user &&
                        user.role &&
                        user.role.name === USER_ROLE_SELLER
                          ? "(Seller)"
                          : user &&
                            user.role &&
                            user.role.name === USER_ROLE_MARKET
                          ? "(MarketPlace)"
                          : "(Buyer)"}
                      </small>
                    </span>
                  }
                </div>
              </div>
            </Link>

            {/* <!-- Sidebar Menu --> */}
            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                {/* <!-- Add icons to the links using the .nav-icon className
                       with font-awesome or any other icon font library --> */}
                {user &&
                  user.role &&
                  (user.role.name === USER_ROLE_SELLER ||
                    user.role.name === USER_ROLE_MARKET) &&
                  user.verified !== 1 && (
                    <div>
                      <li className="nav-item">
                        <Link
                          to="/app/business"
                          className={`nav-link ${
                            pathname === "/app/business" ||
                            pathname ===
                              "/app/business/bvn_payment_successful/0" ||
                            pathname ===
                              "/app/business/bvn_payment_successful/1"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="nav-icon fa fa-check"></i>
                          <p>
                            <strong>
                              {" "}
                              &nbsp; <b>Get Verified</b>
                            </strong>
                          </p>
                        </Link>
                      </li>
                    </div>
                  )}
                {user &&
                  user.role &&
                  (user.role.name === USER_ROLE_SELLER ||
                    user.role.name === USER_ROLE_MARKET) &&
                  (user.verified !== 1 ||
                    user.support_verified !== 1 ||
                    user.seller_id === null) && (
                    <li className="nav-item">
                      <Link
                        to="/app/support"
                        className={`nav-link ${
                          pathname === "/app/support" ? "active" : ""
                        }`}
                      >
                        <i className="nav-icon fa fa-life-ring"></i>
                        <p>
                          <strong>
                            &nbsp; <b>Support </b>
                          </strong>
                        </p>
                      </Link>
                    </li>
                  )}

                <li className="nav-item">
                  <Link
                    onClick={(e) => {
                      if (
                        user &&
                        user.role &&
                        (user.role.name === USER_ROLE_SELLER ||
                          user.role.name === USER_ROLE_MARKET) &&
                        (user.verified !== 1 ||
                          user.support_verified !== 1 ||
                          user.seller_id === null)
                      ) {
                        e.preventDefault();
                      }
                      this.CloseMenu();
                    }}
                    // onClick={this.CloseMenu()}
                    to="/"
                    style={this.getVerifiedStyle()}
                    className={`nav-link ${
                      pathname === "/" || pathname === "/app/dashboard"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="nav-icon fa fa-dashboard"></i>
                    <p>Dashboard</p>
                  </Link>
                </li>
                {user &&
                  user.role &&
                  (user.role.name === USER_ROLE_SELLER ||
                    user.role.name === USER_ROLE_MARKET) && (
                    <div>
                      <li className="nav-item">
                        <a
                          style={this.getVerifiedStyle()}
                          className={`nav-link ${
                            pathname === "/app/payout/details" ||
                            pathname === "/app/payout/history"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            this.setState({ openPayout: !openPayout });
                          }}
                        >
                          &nbsp;<i className="nav-icon fa fa-money"></i>
                          &nbsp;&nbsp;
                          <p>
                            Payout
                            <i className="right fa fa-angle-left"></i>
                          </p>
                        </a>
                        {openPayout && (
                          <ul className="nav ">
                            <li className="nav-item">
                              <Link
                                onClick={(e) => {
                                  if (
                                    user &&
                                    user.role &&
                                    (user.role.name === USER_ROLE_SELLER ||
                                      user.role.name === USER_ROLE_MARKET) &&
                                    (user.verified != 1 ||
                                      user.support_verified != 1 ||
                                      user.seller_id == null)
                                  ) {
                                    e.preventDefault();
                                  }
                                  this.CloseMenu();
                                }}
                                style={this.getVerifiedStyle()}
                                to="/app/payout/details"
                                className="nav-link"
                              >
                                <span className="nav-icon">&nbsp;&nbsp;</span>
                                <p>&nbsp;Payout Details</p>
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                onClick={(e) => {
                                  if (
                                    user &&
                                    user.role &&
                                    (user.role.name === USER_ROLE_SELLER ||
                                      user.role.name === USER_ROLE_MARKET) &&
                                    (user.verified != 1 ||
                                      user.support_verified != 1 ||
                                      user.seller_id == null)
                                  ) {
                                    e.preventDefault();
                                  }
                                  this.CloseMenu();
                                }}
                                style={this.getVerifiedStyle()}
                                to="/app/payout/history"
                                className="nav-link"
                              >
                                <span className="nav-icon">&nbsp;&nbsp;</span>
                                <p>&nbsp;Payout History</p>
                              </Link>
                            </li>
                          </ul>
                        )}
                      </li>
                      {user &&
                        user.role &&
                        user.role.name === USER_ROLE_SELLER && (
                          <li className="nav-item">
                            <Link
                              onClick={(e) => {
                                if (
                                  user &&
                                  user.role &&
                                  user.role.name === USER_ROLE_SELLER &&
                                  (user.verified !== 1 ||
                                    user.support_verified !== 1 ||
                                    user.seller_id === null)
                                ) {
                                  e.preventDefault();
                                }
                                this.CloseMenu();
                              }}
                              style={this.getVerifiedStyle()}
                              to="/app/delivery"
                              className={`nav-link ${
                                pathname == "/app/delivery" ? "active" : ""
                              }`}
                            >
                              &nbsp;
                              <i className="nav-icon fa fa-info-circle"></i>
                              &nbsp;
                              <p>
                                &nbsp; Delivery Terms
                                {/* <span className="right badge badge-danger">New</span> */}
                              </p>
                            </Link>
                          </li>
                        )}
                    </div>
                  )}

                {user &&
                user.role &&
                (user.role.name === USER_ROLE_SELLER ||
                  user.role.name === USER_ROLE_MARKET) ? null : (
                  <span>
                    <li className="nav-item">
                      <a
                        style={this.getVerifiedStyle()}
                        className={`nav-link ${
                          pathname === "/app/payout/details" ? "active" : ""
                        }`}
                        onClick={() => {
                          this.setState({ openPayout: !openPayout });
                        }}
                      >
                        &nbsp;<i className="nav-icon fa fa-money"></i>
                        &nbsp;&nbsp;
                        <p>
                          Payout
                          <i className="right fa fa-angle-left"></i>
                        </p>
                      </a>
                      {openPayout && (
                        <ul className="nav">
                          <li className="nav-item">
                            <Link
                              onClick={(e) => {
                                if (
                                  user &&
                                  user.role &&
                                  (user.role.name === USER_ROLE_SELLER ||
                                    user.role.name === USER_ROLE_MARKET) &&
                                  (user.verified != 1 ||
                                    user.support_verified != 1 ||
                                    user.seller_id == null)
                                ) {
                                  e.preventDefault();
                                }
                                this.CloseMenu();
                              }}
                              style={this.getVerifiedStyle()}
                              to="/app/payout/details"
                              className="nav-link"
                            >
                              <span className="nav-icon">&nbsp;&nbsp;</span>
                              <p>&nbsp;Payout Details</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={(e) => {
                                if (
                                  user &&
                                  user.role &&
                                  (user.role.name === USER_ROLE_SELLER ||
                                    user.role.name === USER_ROLE_MARKET) &&
                                  (user.verified != 1 ||
                                    user.support_verified != 1 ||
                                    user.seller_id == null)
                                ) {
                                  e.preventDefault();
                                }
                                this.CloseMenu();
                              }}
                              style={this.getVerifiedStyle()}
                              to="/app/payout/history"
                              className="nav-link"
                            >
                              <span className="nav-icon">&nbsp;&nbsp;</span>
                              <p>&nbsp;Refund History</p>
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>

                    <li className="nav-item">
                      <Link
                        onClick={(e) => {
                          if (
                            user &&
                            user.role &&
                            (user.role.name === USER_ROLE_SELLER ||
                              user.role.name === USER_ROLE_MARKET) &&
                            (user.verified !== 1 ||
                              user.support_verified !== 1 ||
                              user.seller_id === null)
                          ) {
                            e.preventDefault();
                          }
                          this.CloseMenu();
                        }}
                        style={this.getVerifiedStyle()}
                        to="/app/delivery"
                        className={`nav-link ${
                          pathname === "/app/delivery" ? "active" : ""
                        }`}
                      >
                        &nbsp;<i className="nav-icon fa fa-info-circle"></i>
                        &nbsp;
                        <p>&nbsp; Delivery Terms</p>
                      </Link>
                    </li>
                  </span>
                )}
                <li className="nav-item">
                  <Link
                    onClick={(e) => {
                      if (
                        user &&
                        user.role &&
                        user.role.name == USER_ROLE_SELLER &&
                        (user.verified != 1 ||
                          user.support_verified != 1 ||
                          user.seller_id == null)
                      ) {
                        e.preventDefault();
                      }
                      this.CloseMenu();
                    }}
                    style={this.getVerifiedStyle()}
                    to="/app/orders"
                    className={`nav-link ${
                      pathname == "/app/orders" ? "active" : ""
                    }`}
                  >
                    <i className="nav-icon fa fa-shopping-cart"></i>
                    <p>&nbsp;Orders</p>
                  </Link>
                </li>
                {user &&
                user.role &&
                user.role.name === USER_ROLE_MARKET ? null : (
                  <li className="nav-item">
                    <Link
                      onClick={(e) => {
                        if (
                          user &&
                          user.role &&
                          (user.role.name === USER_ROLE_SELLER ||
                            user.role.name === USER_ROLE_MARKET) &&
                          (user.verified != 1 ||
                            user.support_verified != 1 ||
                            user.seller_id == null)
                        ) {
                          e.preventDefault();
                        }
                        this.CloseMenu();
                      }}
                      style={this.getVerifiedStyle()}
                      to="/app/transactions"
                      className={`nav-link ${
                        pathname === "/app/transactions" ? "active" : ""
                      }`}
                    >
                      <i className="nav-icon fa fa-exchange"></i>
                      <p>My P2P Transactions</p>
                    </Link>
                  </li>
                )}
                <li className="nav-item">
                  <Link
                    onClick={(e) => {
                      if (
                        user &&
                        user.role &&
                        (user.role.name === USER_ROLE_SELLER ||
                          user.role.name === USER_ROLE_MARKET) &&
                        (user.verified != 1 ||
                          user.support_verified != 1 ||
                          user.seller_id == null)
                      ) {
                        e.preventDefault();
                      }
                      this.CloseMenu();
                    }}
                    style={this.getVerifiedStyle()}
                    to="/app/payment/history"
                    className={`nav-link ${
                      pathname === "/app/payment/history" ? "active" : ""
                    }`}
                  >
                    <i className="nav-icon fa fa-history"></i>
                    <p>Payment History</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={(e) => {
                      if (
                        user &&
                        user.role &&
                        (user.role.name === USER_ROLE_SELLER ||
                          user.role.name === USER_ROLE_MARKET) &&
                        (user.verified !== 1 ||
                          user.support_verified !== 1 ||
                          user.seller_id === null)
                      ) {
                        e.preventDefault();
                      }
                      this.CloseMenu();
                    }}
                    style={this.getVerifiedStyle()}
                    to="/app/disputes/all"
                    className={`nav-link ${
                      pathname === "/app/disputes/all" ? "active" : ""
                    }`}
                  >
                    <i className="nav-icon fa fa-question-circle"></i>
                    <p>Disputes</p>
                  </Link>
                </li>

                <div>
                  <li className="nav-item">
                    <a
                      style={this.getVerifiedStyle()}
                      className={`nav-link ${
                        pathname === "/app/report/payout" ? "active" : ""
                      }`}
                      onClick={() => {
                        this.setState({ openReports: !openReports });
                      }}
                    >
                      &nbsp;<i className="nav-icon fa fa-book"></i>&nbsp;&nbsp;
                      <p>
                        Reports
                        <i className="right fa fa-angle-left"></i>
                      </p>
                    </a>
                    {openReports && (
                      <ul className="nav">
                        {user &&
                        user.role &&
                        (user.role.name === USER_ROLE_SELLER ||
                          user.role.name === USER_ROLE_MARKET) ? (
                          <li className="nav-item">
                            <Link
                              onClick={(e) => {
                                if (
                                  user &&
                                  user.role &&
                                  (user.role.name === USER_ROLE_SELLER ||
                                    user.role.name === USER_ROLE_MARKET) &&
                                  (user.verified != 1 ||
                                    user.support_verified != 1 ||
                                    user.seller_id == null)
                                ) {
                                  e.preventDefault();
                                }
                                this.CloseMenu();
                              }}
                              style={this.getVerifiedStyle()}
                              to="/app/report/payout"
                              className="nav-link"
                            >
                              <span className="nav-icon">&nbsp;&nbsp;</span>
                              <p>&nbsp;Payout Report</p>
                            </Link>
                          </li>
                        ) : (
                          <li className="nav-item">
                            <Link
                              onClick={(e) => {
                                if (
                                  user &&
                                  user.role &&
                                  (user.role.name === USER_ROLE_SELLER ||
                                    user.role.name === USER_ROLE_MARKET) &&
                                  (user.verified != 1 ||
                                    user.support_verified != 1 ||
                                    user.seller_id == null)
                                ) {
                                  e.preventDefault();
                                }
                                this.CloseMenu();
                              }}
                              style={this.getVerifiedStyle()}
                              to="/app/report/refund"
                              className="nav-link"
                            >
                              <span className="nav-icon">&nbsp;&nbsp;</span>
                              <p>&nbsp;Refund Report</p>
                            </Link>
                          </li>
                        )}
                      </ul>
                    )}
                  </li>
                </div>

                {user &&
                  user.role &&
                  (user.role.name === USER_ROLE_SELLER ||
                    user.role.name === USER_ROLE_MARKET) &&
                  user.verified === 1 &&
                  user.support_verified === 1 && (
                    <li className="nav-item">
                      <Link
                        onClick={(e) => {
                          if (
                            user &&
                            user.role &&
                            (user.role.name === USER_ROLE_SELLER ||
                              user.role.name === USER_ROLE_MARKET) &&
                            (user.verified !== 1 ||
                              user.support_verified !== 1 ||
                              user.seller_id === null)
                          ) {
                            e.preventDefault();
                          }
                          this.CloseMenu();
                        }}
                        to="/app/support"
                        className={`nav-link ${
                          pathname === "/app/support" ? "active" : ""
                        }`}
                      >
                        <i className="nav-icon fa fa-life-ring"></i>
                        <p>
                          <strong>&nbsp; Support</strong>
                        </p>
                      </Link>
                    </li>
                  )}

                {!(user && user.role) && (
                  <li className="nav-item">
                    <Link
                      to="/app/support"
                      onClick={(e) => {
                        this.CloseMenu();
                      }}
                      className={`nav-link ${
                        pathname === "/app/support" ? "active" : ""
                      }`}
                    >
                      <i className="nav-icon fa fa-life-ring"></i>
                      <p>Support</p>
                    </Link>
                  </li>
                )}

                {user &&
                  user.role &&
                  (user.role.name === USER_ROLE_SELLER ||
                    user.role.name === USER_ROLE_MARKET ||
                    user.role.name === USER_ROLE_MARKET) && (
                    <li className="nav-item">
                      <a
                        style={this.getVerifiedStyle()}
                        onClick={(e) => {
                          if (
                            user &&
                            user.role &&
                            (user.role.name === USER_ROLE_SELLER ||
                              user.role.name === USER_ROLE_MARKET) &&
                            (user.verified !== 1 ||
                              user.support_verified !== 1 ||
                              user.seller_id === null)
                          ) {
                            e.preventDefault();
                          } else {
                            this.setState({ openAPI: !openAPI });
                          }
                        }}
                        className={`nav-link ${
                          pathname === "/app/api" ? "active" : ""
                        }`}
                      >
                        &nbsp;<i className="nav-icon fa fa-link"></i>&nbsp;
                        <p>
                          &nbsp; API
                          <i className="right fa fa-angle-left"></i>
                        </p>
                      </a>

                      {openAPI && (
                        <ul className="nav">
                          <li className="nav-item">
                            <a
                              onClick={(e) => {
                                if (
                                  user &&
                                  user.role &&
                                  (user.role.name === USER_ROLE_SELLER ||
                                    user.role.name === USER_ROLE_MARKET) &&
                                  (user.verified !== 1 ||
                                    user.support_verified !== 1 ||
                                    user.seller_id === null)
                                ) {
                                  e.preventDefault();
                                } else {
                                }
                                this.CloseMenu();
                              }}
                              href="http://plugins.atarapay.com"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={this.getVerifiedStyle()}
                              className="nav-link"
                            >
                              <span className="nav-icon">&nbsp;&nbsp;</span>
                              <p>&nbsp;API Documentation</p>
                            </a>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={(e) => {
                                if (
                                  user &&
                                  user.role &&
                                  (user.role.name === USER_ROLE_SELLER ||
                                    user.role.name === USER_ROLE_MARKET) &&
                                  (user.verified !== 1 ||
                                    user.support_verified !== 1 ||
                                    user.seller_id == null)
                                ) {
                                  e.preventDefault();
                                }
                                this.CloseMenu();
                              }}
                              style={this.getVerifiedStyle()}
                              to="/app/api"
                              className="nav-link"
                            >
                              <span className="nav-icon">&nbsp;&nbsp;</span>
                              <p>&nbsp;API Keys</p>
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                  )}
                {user && (
                  <li className="nav-item">
                    <Link
                      onClick={(e) => {
                        if (
                          user &&
                          user.role &&
                          user.role.name === USER_ROLE_SELLER &&
                          (user.verified !== 1 ||
                            user.support_verified !== 1 ||
                            user.seller_id === null)
                        ) {
                          e.preventDefault();
                        }
                        this.CloseMenu();
                      }}
                      style={this.getVerifiedStyle()}
                      to="/app/shareable-links"
                      className={`nav-link ${
                        pathname == "/app/shareable-links" ? "active" : ""
                      }`}
                    >
                      <i className="nav-icon fa fa-share-alt"></i>&nbsp;
                      <p>
                        Shareable Links
                        {/* <span className="right badge badge-danger">New</span> */}
                      </p>
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
            {/* <!-- /.sidebar-menu --> */}
          </div>
          {/* <!-- /.sidebar --> */}
        </aside>
      </div>
    );
  }
}

export default SideBar;
