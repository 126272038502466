import { termService } from "../service.js";
import { termConstants } from "../constants";
import { alertActions, userActions } from "./";

const createTerm = (term, history) => {
    return (dispatch) => {
        dispatch(request(term));

        termService.term
            .createTerm(term, history)
            .then((res) => {
                if (res.data.status == "success") {
                    dispatch(success(res.data.data));

                    dispatch(
                        alertActions.alert.success(
                            "Delivery Terms successfully created"?.replace(/_/g, " ")
                        )
                    );
                } else {
                    dispatch(failure(res.data.message));
                    dispatch(
                        alertActions.alert.error(res.data.message?.replace(/_/g, " "))
                    );
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout("seller", history));
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request(term) {
        return {type: termConstants.term.CREATE_TERM, term};
    }

    function success(term) {
        return {type: termConstants.term.CREATE_TERM_SUCCESS, term};
    }

    function failure(error) {
        return {type: termConstants.term.CREATE_TERM_FAILURE, error};
    }
};

const editTerm = (term, history) => {
    return (dispatch) => {
        dispatch(request(term));

        termService.term
            .editTerm(term, history)
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch(success(res.data.data));

                    dispatch(
                        alertActions.alert.success(res.data.message?.replace(/_/g, " "))
                    );
                } else {
                    dispatch(failure(res.data.message));
                    dispatch(
                        alertActions.alert.error(res.data.message?.replace(/_/g, " "))
                    );
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout("seller", history));
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request(term) {
        return {type: termConstants.term.EDIT_TERM, term};
    }

    function success(term) {
        return {type: termConstants.term.EDIT_TERM_SUCCESS, term};
    }

    function failure(error) {
        return {type: termConstants.term.EDIT_TERM_FAILURE, error};
    }
};

const getTerm = (history) => {
    return (dispatch) => {
        dispatch(request());

        termService.term
            .getTerm()
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch(success(res.data.data));

                    // dispatch(alertActions.alert.success((res.data.message)?.replace(/_/g, " ")));
                } else {
                    dispatch(failure(res.data.message));
                    // dispatch(alertActions.alert.error((res.data.message)?.replace(/_/g, " ")));
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout("seller", history));
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request() {
        return {type: termConstants.term.REQUEST_TERM};
    }

    function success(term) {
        return {type: termConstants.term.REQUEST_TERM_SUCCESS, term};
    }

    function failure(error) {
        return {type: termConstants.term.REQUEST_TERM_FAILURE, error};
    }
};

const createAddress = (data, type, history) => {
    return (dispatch) => {

        dispatch(request(data));

        termService.term
            .createAddress(data, type)
            .then((res) => {
                if (res.data.status == "success") {
                    dispatch(success(res.data.data));

                    dispatch(
                        alertActions.alert.success(
                            "Address successfully created"?.replace(/_/g, " ")
                        )
                    );
                    history.push("/app/delivery");
                } else {
                    dispatch(failure(res.data.message));
                    dispatch(
                        alertActions.alert.error(res.data.message?.replace(/_/g, " "))
                    );
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout("seller", history));
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request(data) {
        return {type: termConstants.term.CREATE_ADDRESS, data};
    }

    function success(address) {
        return {type: termConstants.term.CREATE_ADDRESS_SUCCESS, address};
    }

    function failure(error) {
        return {type: termConstants.term.CREATE_ADDRESS_FAILURE, error};
    }
};

const getAddresses = (type, history) => {
    return (dispatch) => {
        dispatch(request());

        termService.term
            .getAddresses(type)
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch(success(res.data.data));

                    // dispatch(alertActions.alert.success((res.data.message)?.replace(/_/g, " ")));
                } else {
                    dispatch(failure(res.data.message));
                    // dispatch(alertActions.alert.error((res.data.message)?.replace(/_/g, " ")));
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout("seller", history));
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request() {
        return {type: termConstants.term.REQUEST_ADDRESSES};
    }

    function success(addresses) {
        return {type: termConstants.term.REQUEST_ADDRESSES_SUCCESS, addresses};
    }

    function failure(error) {
        return {type: termConstants.term.REQUEST_ADDRESSES_FAILURE, error};
    }
};

const deleteAddress = (data, type, history) => {
    return (dispatch) => {
        dispatch(request(data));

        termService.term
            .deleteAddress(data, type)
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch(success(res.data.data));

                    // dispatch(alertActions.alert.success((res.data.message)?.replace(/_/g, " ")));
                    history.push("/app/delivery");
                } else {
                    dispatch(failure(res.data.message));
                    // dispatch(alertActions.alert.error((res.data.message)?.replace(/_/g, " ")));
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout("seller", history));
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request() {
        return {type: termConstants.term.DELETE_ADDRESS};
    }
    function success(address) {
        return {type: termConstants.term.DELETE_ADDRESS_SUCCESS, address};
    }

    function failure(error) {

        return {type: termConstants.term.DELETE_ADDRESS_FAILURE, error};
    }
};

const setAddressAsDefault = (data, type, history) => {
    return (dispatch) => {
        dispatch(request(data));

        termService.term
            .setAddressAsDefault(data, type)
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch(success(res.data.data));
                    history.push("/app/delivery");
                    // dispatch(alertActions.alert.success((res.data.message)?.replace(/_/g, " ")));
                } else {
                    dispatch(failure(res.data.message));
                    // dispatch(alertActions.alert.error((res.data.message)?.replace(/_/g, " ")));
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout("seller", history));
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request(data) {
        return {type: termConstants.term.DEFAULT_ADDRESS, data};
    }

    function success(address) {
        return {type: termConstants.term.DEFAULT_ADDRESS_SUCCESS, address};
    }

    function failure(error) {
        return {type: termConstants.term.DEFAULT_ADDRESS_FAILURE, error};
    }
};

const getServiceProviders = (type, history) => {
    return (dispatch) => {
        dispatch(request());

        termService.term
            .getServiceProviders(type)
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch(success(res.data.data));

                    // dispatch(alertActions.alert.success((res.data.message)?.replace(/_/g, " ")));
                } else {
                    dispatch(failure(res.data.message));
                    // dispatch(alertActions.alert.error((res.data.message)?.replace(/_/g, " ")));
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(userActions.user.logout("seller", history));
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    } else {
                        dispatch(failure(err.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err.response.data.message?.replace(/_/g, " ")
                            )
                        );
                    }
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request() {
        return {type: termConstants.term.REQUEST_SERVICE_PROVIDERS};
    }

    function success(service_providers) {
        return {type: termConstants.term.REQUEST_SERVICE_PROVIDERS_SUCCESS, service_providers};
    }

    function failure(error) {
        return {type: termConstants.term.REQUEST_SERVICE_PROVIDERS_FAILURE, error};
    }
};

export {createTerm, editTerm, getTerm, createAddress, getAddresses, deleteAddress,
    setAddressAsDefault, getServiceProviders};
