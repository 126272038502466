import React, { Component } from "react";
// import settingSchema from '../validation/settings';
import { settingActions, alertActions, userActions } from "../actions";
import swal from "@sweetalert/with-react";
import { Link } from "react-router-dom";
import Tour from "reactour";
import PhoneInput, { formatPhoneNumber } from "react-phone-number-input";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refundable_flag: 0,
      payment_frequency: 0,
      card_fee: 1,
      sp_card_fee: 1,
      escrow_fee_bearer: "customer",
      delivery_callback_url: "",
      error: null,
      submitted: false,
      loading: false,
      show: "",
      freq_show: "",
      callback_show: "",
      startTour: false,
      banks: [],
      buyer_cancellation: "0",
      delivery_type: "0"
    };
  }

  componentDidMount = () => {
    if (this.props.match.params.type === "frequency") {
      this.setState({ freq_show: "show" });
    } else {
      this.setState({ freq_show: "false" });
    }
    if (
      localStorage.getItem("trust_user_type") == "seller" ||
      localStorage.getItem("trust_user_type") == "marketplace"
    ) {
      this.props.getTerm(this.props.history);
    }
    if (
      this.props.user &&
      localStorage.getItem("trust_user_type") == "seller"
    ) {
      this.setState({
        startTour: this.props.user.setting_tour_done ? false : true,
        show: this.props.user.setting_tour_done ? "" : "show",
      });
    }
    this.props.getBanks("seller", this.props.history);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, function () {});
    if (name === "card_fee") {
      let msg =
        value === "1"
          ? "You will be responsible for paying the switch fees on incomplete and future orders. This will impact all incomplete and future orders. Incomplete orders are those that have not been paid out or refunded. "
          : "The buyer will be responsible for paying the switch fees on incomplete and future orders. This will impact all incomplete and future orders. Incomplete orders are those that have not been paid out or refunded. ";
      msg +=
        "To save your changes, please click OK and then the Save button located at the bottom right corner.";

      swal("Caution !!", msg, "warning");
    }
  };

  // handleBankChange = (e, banks) => {
  //   const { value } = e.target;
  //   console.log('va', value);
  //   console.log('s:', banks.find((e) => e.id === +value));

  //   const { details } = this.state;
  //   this.setState({
  //     details: {
  //       ...details,
  //       bank_name: banks.find((e) => e.id === +value).name,
  //       bank_code: banks.find((e) => e.id === +value).code,
  //       nip_bank_code: banks.find((e) => e.id === +value)
  //         .nip_bank_code,
  //     },
  //   });
  // };

  handlePhone = (value, name) => {
    this.setState({
      [name]: value,
    });
  };
  componentWillReceiveProps(props) {
    if (props.term && props.term.refundable_flag) {
      this.setState({ refundable_flag: props.term.refundable_flag });
    }
    if (props.term && props.term.payment_frequency) {
      this.setState({ payment_frequency: props.term.payment_frequency });
    }
    if (props.term && props.term.card_fee) {
      this.setState({ card_fee: props.term.card_fee });
    }
    if (props.term && props.term.sp_card_fee) {
      this.setState({ sp_card_fee: props.term.sp_card_fee });
    }
    if (props.term && props.term.escrow_fee_bearer) {
      this.setState({ escrow_fee_bearer: props.term.escrow_fee_bearer });
    }
    if (props.term && props.term.buyer_cancellation) {
      this.setState({ buyer_cancellation: props.term.buyer_cancellation });
    }
    if (props.term && props.term.delivery_type) {
      this.setState({ delivery_type: props.term.delivery_type });
    }
    if (props.term && props.term.delivery_callback_url) {
      this.setState({
        delivery_callback_url: props.term.delivery_callback_url,
      });
    }

    if (
      props.alert &&
      props.alert.message &&
      props.alert.type === "alert-danger"
    ) {
      swal("Error", props.alert.message, "error").then(() => {
        props.dispatch(alertActions.alert.clear());
      });
    } else if (
      props.alert &&
      props.alert.message &&
      props.alert.type === "alert-success"
    ) {
      swal("Success", props.alert.message, "success").then(() => {
        props.dispatch(alertActions.alert.clear());
      });
    }

    if (props.banks) {
      this.setState({ banks: props.banks, bank_code: props.banks[0].code });
    }
  }

  handleSubmit = (event, type) => {
    event.preventDefault();
    if (type === 1) {
      this.setState({ submitted: true, error: null });
      const { refundable_flag } = this.state;
      this.props.setRefundable({ refundable_flag }, this.props.history);
    } else if (type === 2) {
      this.setState({ submitted: true, error: null });
      const { payment_frequency } = this.state;
      this.props.setPayment({ payment_frequency }, this.props.history);
    } else if (type === 3) {
      this.setState({ submitted: true, error: null });
      const { card_fee } = this.state;
      this.props.setCardFee({ card_fee }, this.props.history);
    } else if (type === 5) {
      this.setState({ submitted: true, error: null });
      const { bvn, account_number, bank_code, phone_number } = this.state;
      this.props.setBVNVerification(
        { bvn, account_number, bank_code, phone_number },
        this.props.history
      );
    } else if (type === 6) {
      const { sp_card_fee } = this.state;
      this.props.setSPCardFee({ sp_card_fee }, this.props.history);
    } else if (type === 7) {
      const { escrow_fee_bearer } = this.state;
      this.props.setEscrowBearer({ escrow_fee_bearer }, this.props.history);
    }else if (type === 9) {
      const { buyer_cancellation } = this.state;
      this.props.setCanCancelOrder({ buyer_cancellation }, this.props.history);
    }else if (type === 10) {
      const { delivery_type } = this.state;
      this.props.setDeliveryHandledBy({ delivery_type }, this.props.history);
    } else {
      this.setState({ submitted: true, error: null });
      const { delivery_callback_url } = this.state;
      this.props.setCallbackUrl({ delivery_callback_url }, this.props.history);
    }
  };
  closeTour = () => {
    this.setState({ startTour: false });
  };
  finishTour = () => {
    this.props.dispatch(
      userActions.user.updateTour(
        localStorage.getItem("trust_user_type"),
        {
          firstname: this.props.user.firstname,
          lastname: this.props.user.lastname,
          phone_number: this.props.user.phone_number,
          email: this.props.user.email,
          setting_tour_done: 1,
        },
        this.props.history,
        "/app/payout/details"
      )
    );
  };

  render() {
    const {
      refundable_flag,
      payment_frequency,
      card_fee,
      banks,
      sp_card_fee,
      escrow_fee_bearer,
      buyer_cancellation,
      delivery_type,
    } = this.state;
    const { loading, alert } = this.props;
    // const { phone } = this.props.user;
    const steps = [
      {
        selector: ".ion-person",
        content: ({ goTo, inDOM }) => (
          <p>
            Click <b>Profile</b> icon, navigate to <b>Settings</b> and then to
            the <b>Payment Switch Fees</b> section.
          </p>
        ),
        position: "bottom",
        style: {
          borderRadius: "5px",
          backgroundColor: "#0e2433",
          color: "#ffffff",
        },
      },
      {
        selector: ".first-step",
        content: ({ goTo, inDOM }) => (
          <p>
            From here, you can transfer the payment switch fee to the buyer by
            changing the value to “Buyer” in the dropdown. The default value is
            set to “Seller”.
          </p>
        ),
        position: "bottom",
        style: {
          maxWidth: "50%",
          borderRadius: "5px",
          backgroundColor: "#0e2433",
          color: "#ffffff",
        },
      },
    ];

    return (
      <div className="content-wrapper" style={{ minHeight: "173px" }}>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Settings</h1> <br />
              </div>
            </div>
          </div>
        </div>
        {(localStorage.getItem("trust_user_type") === "seller" ||
          localStorage.getItem("trust_user_type") === "marketplace") && (
          <section className="content">
            <div className="container-fluid ">
              <br />
              {localStorage.getItem("trust_user_type") === "seller" ? (
                <Tour
                  steps={steps}
                  isOpen={this.state.startTour}
                  onRequestClose={this.closeTour}
                  disableKeyboardNavigation={["esc", "right", "left"]}
                  lastStepNextButton={
                    <button
                      className="btn btn-danger"
                      onClick={this.finishTour}
                    >
                      Got It!
                    </button>
                  }
                  showNumber={false}
                />
              ) : null}
              <div className="row">
                <div className="col col-sm-12 col-md-8">
                  <div className="callout callout-info">
                    <br />

                    <div className="row">
                      <div className="col col-sm-12 col-md-12">
                        <div id="accordion">
                          <div className="" id="headingTwo">
                            <h5>
                              <button
                                className="accord"
                                data-toggle="collapse"
                                data-target="#collapseTwo"
                                aria-expanded="true"
                                aria-controls="collapseTwo"
                              >
                                Frequency of Payment
                                <i className="pull-right fa fa-angle-down"></i>
                              </button>
                            </h5>
                          </div>
                          <br/>
                          <div className="col-12 col-sm-12">
                            <div
                              id="collapseTwo"
                              className={`collapse  ${this.state.freq_show}`}
                              aria-labelledby="headingTwo"
                              data-parent="#accordion"
                            >
                              <div className="form-group">
                                <label htmlFor="frequency">
                                  Frequency of Payment
                                </label>

                                <div className="row">
                                  <div className="col-12 col-sm-12">
                                    <select
                                      className="form-control"
                                      name="payment_frequency"
                                      onChange={this.handleChange.bind(this)}
                                      value={payment_frequency}
                                    >
                                      <option disabled="disabled" value="">
                                        {" "}
                                        Select Payment Frequency
                                      </option>
                                      <option value={0}>After 12 hours</option>
                                      {/* <option value={1}>Weekly</option>
                                                <option value={2}>Bi-Weekly</option>
                                                <option value={3}>Monthly</option>*/}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <p className="text-right">
                                <button
                                  className="btn btn-primary"
                                  style={{padding: "7 4"}}
                                  onClick={(e) => this.handleSubmit(e, 2)}
                                  disabled={loading}
                                >
                                  {loading ? "Loading" : "Save"}
                                </button>
                              </p>
                              <br/>
                              <br/>
                            </div>
                          </div>
                          <hr/>
                          <div className="" id="headingFive">
                            <h5>
                              <button
                                className="accord"
                                data-toggle="collapse"
                                data-target="#collapseFive"
                                aria-expanded="true"
                                aria-controls="collapseFive"
                              >
                                BVN Verification
                                <i className="pull-right fa fa-angle-down"></i>
                              </button>
                            </h5>
                          </div>
                          <br/>
                          <div className="col-12 col-sm-12">
                            <div
                              id="collapseFive"
                              className={`collapse `}
                              aria-labelledby="headingFive"
                              data-parent="#accordion"
                            >
                              <div className="form-group">
                                <label htmlFor="BVN">Verify your BVN</label>
                                <p>
                                  <small>
                                    We need your bank verification number (BVN)
                                    to process your payments from customers.
                                  </small>
                                </p>
                                <div className="row">
                                  <div className="col-12 col-sm-12">
                                    <input
                                      type="text"
                                      placeholder="Bank Verification Number(BVN)"
                                      maxLength={11}
                                      name="bvn"
                                      onChange={this.handleChange.bind(this)}
                                      className="form-control"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="row mt-3">
                                  <div className="col-12 col-sm-12">
                                    <select
                                      className="form-control"
                                      name="bank_code"
                                      onChange={this.handleChange.bind(this)}
                                    >
                                      <option disabled="disabled" value="">
                                        {" "}
                                        Select Bank
                                      </option>

                                      {banks &&
                                        banks.length > 0 &&
                                        banks.map((bank, key) => (
                                          <option key={key} value={bank.code}>
                                            {bank.name}
                                          </option>
                                        ))}
                                    </select>
                                    {/* <select
                                      className="form-control"
                                      onChange={(e) => this.handleBankChange(e, banks)}
                                    >
                                      <option disabled="disabled" value="">
                                        Select Bank
                                      </option>
                                      {banks.map((bank) => (
                                        <option key={bank.id} value={bank.id}>
                                          {bank.name}
                                        </option>
                                      ))}
                                    </select> */}
                                  </div>
                                </div>
                                <div className="row mt-3">
                                  <div className="col-12 col-sm-12">
                                    <input
                                      type="text"
                                      placeholder="BVN Account Number"
                                      maxLength={10}
                                      name="account_number"
                                      onChange={this.handleChange.bind(this)}
                                      className="form-control"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="row mt-3">
                                  <div className="col-12 col-sm-12">
                                    <PhoneInput
                                      defaultCountry="NG"
                                      placeholder="08XX XXX XXXX"
                                      limitMaxLength={true}
                                      inputclassname={
                                        "custom-input max-custom-input"
                                      }
                                      onChange={(value) =>
                                        this.handlePhone(
                                          (value &&
                                            formatPhoneNumber(
                                              value,
                                              "International"
                                            ).replace(/ /g, "")) ||
                                          "",
                                          "phone_number"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <p className="text-right">
                                <button
                                  className="btn btn-primary"
                                  style={{padding: "7 4"}}
                                  onClick={(e) => this.handleSubmit(e, 5)}
                                  disabled={loading}
                                >
                                  {loading ? "Loading" : "Submit"}
                                </button>
                              </p>
                              <br/>
                              <br/>
                            </div>
                          </div>
                          <hr/>

                          <div className="" id="headingThree">
                            <h5>
                              <button
                                className="accord"
                                data-toggle="collapse"
                                data-target="#collapseThree"
                                aria-expanded="true"
                                aria-controls="collapseThree"
                              >
                                Payment Switch Fees
                                <i className="pull-right fa fa-angle-down"></i>
                              </button>
                            </h5>
                          </div>
                          <br/>
                          <div className="col-12 col-sm-12 first-step">
                            <div
                              id="collapseThree"
                              className={`collapse  ${this.state.show}`}
                              aria-labelledby="headingThree"
                              data-parent="#accordion"
                            >
                              <div className="form-group">
                                <label htmlFor="card_payment">
                                  Payment Switch Fees
                                </label>
                                <p>
                                  <small>
                                    Here, you can set who pays the card and USSD
                                    fees. If you select Buyer, then card or USSD
                                    fee payment, which includes transfer fee of
                                    ₦50 or ₦80 charged by the payment switch
                                    (Paystack or Monnify), will be transferredto
                                    Buyer and paid at checkout. If you choose
                                    Seller, this means that you (Seller) willbe
                                    charged card or USSD fees at point of payout
                                    after the service has been accepted by
                                    Buyer. Please note this fee payment is set
                                    to Seller by default.
                                  </small>
                                </p>

                                <div className="row ">
                                  <div className="col-12 col-sm-12">
                                    <select
                                      className="form-control"
                                      name="card_fee"
                                      onChange={this.handleChange.bind(this)}
                                      value={card_fee}
                                    >
                                      <option disabled="disabled" value="">
                                        {" "}
                                        Select Who Pays Card Fee
                                      </option>
                                      <option value={1}>Seller</option>
                                      <option value={2}>Buyer</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <p className="text-right">
                                <button
                                  className="btn btn-primary"
                                  style={{padding: "7 4"}}
                                  onClick={(e) => this.handleSubmit(e, 3)}
                                  disabled={loading}
                                >
                                  {loading ? "Loading" : "Save"}
                                </button>
                              </p>
                              <br/>
                              <br/>
                            </div>
                          </div>
                          <hr/>

                          <div className="" id="headingFour">
                            <h5>
                              <button
                                className="accord"
                                data-toggle="collapse"
                                data-target="#collapseFour"
                                aria-expanded="true"
                                aria-controls="collapseFour"
                              >
                                Service Provider Transfer Fees
                                <i className="pull-right fa fa-angle-down"></i>
                              </button>
                            </h5>
                          </div>
                          <br/>
                          <div className="col-12 col-sm-12 first-step">
                            <div
                              id="collapseFour"
                              className={`collapse  ${this.state.show}`}
                              aria-labelledby="headingFour"
                              data-parent="#accordion"
                            >
                              <div className="form-group">
                                <label htmlFor="card_payment">
                                  Service Provider Transfer Fees
                                </label>
                                <p>
                                  <small>
                                    Here, you can set who pays the service
                                    provider (SP) transfer fees, if an SP is
                                    associated to the transaction. If you select
                                    Seller and SP, then the SP transfer fee
                                    payment, which is ₦50, will be shared
                                    amongst the Seller and SP according to the
                                    percentage commission and it shall be
                                    deducted at the point of payout after the
                                    service has been accepted by Buyer. If you
                                    choose Seller, this means that you (Seller)
                                    will be charged the SP transfer fee which
                                    shall be deducted at the point of payout
                                    after the service has been accepted by
                                    Buyer. Please note this fee payment is set
                                    to Seller by default. Visit our{" "}
                                    <a
                                      href="https://atarapay.com/pricing"
                                      target="_blank"
                                      style={{
                                        color: "#007bff",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      pricing page
                                    </a>{" "}
                                    for details.
                                  </small>
                                </p>

                                <div className="row ">
                                  <div className="col-12 col-sm-12">
                                    <select
                                      className="form-control"
                                      name="sp_card_fee"
                                      onChange={this.handleChange.bind(this)}
                                      value={sp_card_fee}
                                    >
                                      <option disabled="disabled" value="">
                                        {" "}
                                        Select Who Pays Service Provider
                                        Transfer Fees
                                      </option>
                                      <option value={1}>Seller</option>
                                      <option value={2}>
                                        Seller and Service Provider
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <p className="text-right">
                                <button
                                  className="btn btn-primary"
                                  style={{padding: "7 4"}}
                                  onClick={(e) => this.handleSubmit(e, 6)}
                                  disabled={loading}
                                >
                                  {loading ? "Loading" : "Save"}
                                </button>
                              </p>
                              <br/>
                              <br/>
                            </div>
                          </div>
                          <hr/>

                          <div className="" id="headingSeven">
                            <h5>
                              <button
                                className="accord"
                                data-toggle="collapse"
                                data-target="#collapseSeven"
                                aria-expanded="true"
                                aria-controls="collapseSeven"
                              >
                                Ecommerce Escrow Fees
                                <i className="pull-right fa fa-angle-down"></i>
                              </button>
                            </h5>
                          </div>
                          <br/>
                          <div className="col-12 col-sm-12 first-step">
                            <div
                              id="collapseSeven"
                              className={`collapse  ${this.state.show}`}
                              aria-labelledby="headingSeven"
                              data-parent="#accordion"
                            >
                              <div className="form-group">
                                <label htmlFor="card_payment">
                                  Ecommerce Escrow Fees
                                </label>
                                <p>
                                  <small>
                                    Here, you can set who pays the escrow fees
                                    when you integrate your website to AtaraPay.
                                    If you select Buyer, then escrow fee
                                    payment, will be transferred to the Buyer
                                    and paid at checkout. If you choose Seller,
                                    this means that you (Seller) will be charged
                                    escrow fees which shall be deducted at the
                                    point of payout after the service has been
                                    accepted by the Buyer. If you select Both,
                                    then the escrow fee will be split 50:50
                                    between you and the Buyer. Please note that
                                    this fee payment is set to Buyer by default.
                                    Visit our{" "}
                                    <a
                                      href="https://atarapay.com/pricing"
                                      target="_blank"
                                      style={{
                                        color: "#007bff",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      pricing page
                                    </a>{" "}
                                    for details.
                                  </small>
                                </p>

                                <div className="row ">
                                  <div className="col-12 col-sm-12">
                                    <select
                                      className="form-control"
                                      name="escrow_fee_bearer"
                                      onChange={this.handleChange.bind(this)}
                                      value={escrow_fee_bearer}
                                    >
                                      <option disabled="disabled" value="">
                                        {" "}
                                        Select Who Pays Ecommerce Escrow Fees
                                      </option>
                                      <option value={"seller"}>Seller</option>
                                      <option value={"customer"}>
                                        Customer
                                      </option>
                                      <option value={"both"}>Both</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <p className="text-right">
                                <button
                                  className="btn btn-primary"
                                  style={{padding: "7 4"}}
                                  onClick={(e) => this.handleSubmit(e, 7)}
                                  disabled={loading}
                                >
                                  {loading ? "Loading" : "Save"}
                                </button>
                              </p>
                              <br/>
                              <br/>
                            </div>
                          </div>
                          <hr/>
                          <div className="" id="headingEight">
                            <h5>
                              <button
                                className="accord"
                                data-toggle="collapse"
                                data-target="#collapseEight"
                                aria-expanded="true"
                                aria-controls="collapseEight"
                              >
                                Callback URL
                                <i className="pull-right fa fa-angle-down"></i>
                              </button>
                            </h5>
                          </div>
                          <br/>
                          <div className="col-12 col-sm-12">
                            <div
                              id="collapseEight"
                              className={`collapse  ${this.state.callback_show}`}
                              aria-labelledby="headingEight"
                              data-parent="#accordion"
                            >
                              <div className="form-group">
                                <label htmlFor="card_payment">
                                  Callback URL
                                </label>
                                <p>
                                  <small>
                                    Here, you can set the callback URL that our
                                    system would use to send updates to your app
                                    when the escrow order status changes. For
                                    more details, please visit{" "}
                                    <a
                                      href="https://plugins.atarapay.com/docs/automation/"
                                      style={{color: "blue"}}
                                      target="_blank"
                                    >
                                      https://plugins.atarapay.com/docs/automation/
                                    </a>
                                  </small>
                                </p>

                                <div className="row ">
                                  <div className="col-12 col-sm-12">
                                    <input
                                      type="text"
                                      name="delivery_callback_url"
                                      defaultValue={
                                        this.state.delivery_callback_url
                                      }
                                      placeholder="https://www.your-domain.com/callback"
                                      className="form-control"
                                      onBlur={this.handleChange.bind(this)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <p className="text-right">
                                <button
                                  className="btn btn-primary"
                                  style={{padding: "7 4"}}
                                  onClick={(e) => this.handleSubmit(e, 4)}
                                  disabled={loading}
                                >
                                  {loading ? "Loading" : "Save"}
                                </button>
                              </p>
                              <br/>
                              <br/>
                            </div>
                          </div>
                          <hr/>
                          <div className="" id="headingNine">
                            <h5>
                              <button
                                className="accord"
                                data-toggle="collapse"
                                data-target="#collapseNine"
                                aria-expanded="true"
                                aria-controls="collapseNine"
                              >
                                Order Cancellation
                                <i className="pull-right fa fa-angle-down"></i>
                              </button>
                            </h5>
                          </div>
                          <br/>
                          <div className="col-12 col-sm-12 first-step">
                            <div
                              id="collapseNine"
                              className={`collapse  ${this.state.show}`}
                              aria-labelledby="headingNine"
                              data-parent="#accordion"
                            >
                              <div className="form-group">
                                <label htmlFor="card_payment">
                                  Order Cancellation
                                </label>
                                <p>
                                  Here, you can set who can cancel an order after payment is successfully made for an
                                  order; Seller or Both (Seller and Buyer).
                                  When the order is cancelled by either party, the buyer is refunded less any applicable
                                  fees.
                                </p>

                                <div className="row ">
                                  <div className="col-12 col-sm-12">
                                    <select
                                      className="form-control"
                                      name="buyer_cancellation"
                                      onChange={this.handleChange.bind(this)}
                                      value={buyer_cancellation}
                                    >
                                      <option disabled="disabled" value="">
                                        {" "}
                                        Select Who Can Cancel the Payment of an Order
                                      </option>
                                      <option value="0">Seller</option>
                                      <option value="1">Both</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <p className="text-right">
                                <button
                                  className="btn btn-primary"
                                  style={{padding: "7 4"}}
                                  onClick={(e) => this.handleSubmit(e, 9)}
                                  disabled={loading}
                                >
                                  {loading ? "Loading" : "Save"}
                                </button>
                              </p>
                              <br/>
                              <br/>
                            </div>
                          </div>
                          <hr/>
                          <div className="" id="headingTen">
                            <h5>
                              <button
                                className="accord"
                                data-toggle="collapse"
                                data-target="#collapseTen"
                                aria-expanded="true"
                                aria-controls="collapseTen"
                              >
                                Delivery Service Options
                                <i className="pull-right fa fa-angle-down"></i>
                              </button>
                            </h5>
                          </div>
                          <br/>
                          <div className="col-12 col-sm-12 first-step">
                            <div
                              id="collapseTen"
                              className={`collapse  ${this.state.show}`}
                              aria-labelledby="headingTen"
                              data-parent="#accordion"
                            >
                              <div className="form-group">
                                <label htmlFor="card_payment">
                                  Delivery Service Options
                                </label>
                                <p>
                                  Here, you can set who makes delivery when a purchase is made for your physical goods;
                                  <br/>
                                  Deliver with AtaraPay | Deliver by Self.
                                  If you select Deliver with AtaraPay, we shall arrange the pickup of the item from you
                                  and deliver to the recipient.
                                  If you select Deliver by Self, this means that you will be responsible for your own
                                  delivery.
                                </p>

                                <div className="row ">
                                  <div className="col-12 col-sm-12">
                                    <select
                                      className="form-control"
                                      name="delivery_type"
                                      onChange={this.handleChange.bind(this)}
                                      value={delivery_type}
                                    >
                                      <option disabled="disabled" value="">
                                        {" "}
                                        Select who makes delivery when goods are purchased
                                      </option>
                                      <option value="1">Deliver By Self</option>
                                      <option value="2">Deliver with Atarapay</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <p className="text-right">
                                <button
                                  className="btn btn-primary"
                                  style={{padding: "7 4"}}
                                  onClick={(e) => this.handleSubmit(e, 10)}
                                  disabled={loading}
                                >
                                  {loading ? "Loading" : "Save"}
                                </button>
                              </p>
                              <br/>
                              <br/>
                            </div>
                          </div>
                          <hr/>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4"></div>
                </div>
                {" "}
                <br/>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

export default Settings;
