import { BASE_URL, LOCALSTORAGE_TOKEN_KEY } from "../constants";
import axios from "axios";

const setRefundable = (setting) => {
  return axios({
    method: "post",
    mode: "no-cors",
    url: `${BASE_URL}seller/term/refundableFlag`,
    data: setting,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      "Content-Type": "application/json",
    },
  });
};

const setPayment = (setting) => {
  return axios({
    method: "post",
    mode: "no-cors",
    url: `${BASE_URL}seller/term/paymentFrequency`,
    data: setting,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      "Content-Type": "application/json",
    },
  });
};

const setCardFee = (setting) => {
  return axios({
    method: "post",
    mode: "no-cors",
    url: `${BASE_URL}seller/term/setCardFee`,
    data: setting,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      "Content-Type": "application/json",
    },
  });
};
const setSPCardFee = (setting) => {
  return axios({
    method: "post",
    mode: "no-cors",
    url: `${BASE_URL}seller/term/setSPCardFee`,
    data: setting,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      "Content-Type": "application/json",
    },
  });
};
const setEscrowBearer = (setting) => {
  return axios({
    method: "post",
    mode: "no-cors",
    url: `${BASE_URL}seller/term/setEscrowBearer`,
    data: setting,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      "Content-Type": "application/json",
    },
  });
};

const setBVNVerification = (setting) => {
  return axios({
    method: "post",
    mode: "no-cors",
    url: `${BASE_URL}seller/bvnVerification`,
    data: setting,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      "Content-Type": "application/json",
    },
  });
};

const getBanks = (role) => {
  return axios({
    method: "get",
    mode: "no-cors",
    url: `${BASE_URL}${role}/banks`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      "Content-Type": "application/json",
    },
  });
};

const setCallbackUrl = (setting) => {
  return axios({
    method: "post",
    mode: "no-cors",
    url: `${BASE_URL}seller/term/setCallbackUrl`,
    data: setting,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      "Content-Type": "application/json",
    },
  });
};

const setCanCancelOrder = (setting) => {
  return axios({
    method: "post",
    mode: "no-cors",
    url: `${BASE_URL}seller/term/setBuyerCancellation`,

    data: setting,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      "Content-Type": "application/json",
    },
  });
};

const setDeliveryHandledBy = (setting) => {
  return axios({
    method: "post",
    mode: "no-cors",
    url: `${BASE_URL}seller/term/setDeliveryType`,

    data: setting,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      "Content-Type": "application/json",
    },
  });
};

export const settings = {
  setRefundable,
  setPayment,
  setCardFee,
  setSPCardFee,
  setEscrowBearer,
  setCallbackUrl,
  getBanks,
  setBVNVerification,
  setCanCancelOrder,
  setDeliveryHandledBy,
};
