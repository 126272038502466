import React from "react";
import { userService } from "../service.js";
import { userConstants, deliveryConstants } from "../constants";
import { alertActions, userActions } from "./";
import swal from "@sweetalert/with-react";

const login = (credentials, type, history) => {
  return (dispatch) => {
    dispatch(request(credentials));

    userService.user
      .login(credentials, type, dispatch)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success(res.data.data.token, true));
          localStorage.setItem("user", res.data.data.token);
          if (type === "seller") {
            userService.user.getUser(type).then((res) => {
              localStorage.setItem("trust_user_phone", res.data.data.phone_number);

              if (res.data.data.role_id === 3) {
                let type = "marketplace";
                localStorage.setItem("trust_user_type", type);
                dispatch(getDetails(type, history));
                if (res.data.message === "pending_validation") {
                  dispatch(
                    alertActions.alert.success(
                      "Login Successful"?.replace(/_/g, " ")
                    )
                  );
                  history.push("/app/business");
                } else {
                  dispatch(
                    alertActions.alert.success(
                      "Login Successful"?.replace(/_/g, " ")
                    )
                  );
                  history.push("/");
                }
              } else {
                localStorage.setItem("trust_user_type", type);
                dispatch(getDetails(type, history));
                if (res.data.message === "pending_validation") {
                  dispatch(
                    alertActions.alert.success(
                      "Login Successful"?.replace(/_/g, " ")
                    )
                  );
                  history.push("/app/business");
                } else {
                  dispatch(
                    alertActions.alert.success(
                      "Login Successful"?.replace(/_/g, " ")
                    )
                  );
                  history.push("/");
                }
              }
            });
          } else {
            localStorage.setItem("trust_user_type", type);
            dispatch(getDetails(type, history));
            if (res.data.message === "pending_validation") {
              dispatch(
                alertActions.alert.success(
                  "Login Successful"?.replace(/_/g, " ")
                )
              );
              history.push("/app/business");
            } else {
              dispatch(
                alertActions.alert.success(
                  "Login Successful"?.replace(/_/g, " ")
                )
              );

              const current_path = history.location.pathname;
              const current_page = current_path.slice(
                current_path.indexOf("/") + 1,
                8
              );
              if (current_page == "easyp2p") {
                swal({
                  title: "Success",
                  content: (
                    <div>
                      Login successful!, you can now proceed with your
                      transaction.
                    </div>
                  ),
                  icon: "success",
                });
                return console.log("location", current_page);
              } else {
                history.push("/");
              }
            }
          }
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
        }
      })
      .catch((err) => {
        const current_path = history.location.pathname;
        const current_page = current_path.slice(
          current_path.indexOf("/") + 1,
          8
        );
        if (err.response) {
          const current_path = history.location.pathname;
          const current_page = current_path.slice(
            current_path.indexOf("/") + 1,
            8
          );
          if (err.response.status === 401) {
            // dispatch(logout(history))
            if (type === "customer") {
              type = "buyer";
              if (current_page == "easyp2p")
                return swal("Error", err.response.data.message, "error");
            }
            history.push(`/login/${type}`);
            //     if(err.response.data.message !="invalid_credentials"){
            // if(type !== 'seller')
            //     history.push('/login/buyer');
            // else
            //     history.push('/login');
            if (err.response.data.message !== "invalid_credentials") {
              if (current_page == "easyp2p")
                return swal("Error", err.response.data.message, "error");
              // dispatch(failure(err.response.data.message));
              dispatch(
                alertActions.alert.error("unverified"?.replace(/_/g, " "))
              );
              dispatch(unverified(credentials.email));
            } else if (err.response.data.message === "invalid_credentials") {
              if (current_page == "easyp2p") {
                dispatch(failure(err.response.data.message));
                return swal("Error", err.response.data.message, "error");
              }
              dispatch(failure(err.response.data.message));
              dispatch(
                alertActions.alert.error(
                  "Invalid credentials"?.replace(/_/g, " ")
                )
              );
            } else {
              if (current_page == "easyp2p")
                return swal("Error", err.response.data.message, "error");
              dispatch(failure(err.response.data.message));
              dispatch(
                alertActions.alert.error(
                  err.response.data.message?.replace(/_/g, " ")
                )
              );
            }
          } else {
            if (current_page == "easyp2p")
              return swal("Error", err.response.data.message, "error");
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          if (current_page == "easyp2p")
            return swal(
              "Error",
              "You are experiencing a connection error, Please check your internet connection and try again.  Thank you.",
              "error"
            );
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(
            alertActions.alert.error(
              "Connection Error\n\nYou are experiencing a connection error that may be due to the following:\n1. Poor mobile internet connection\n2. Poor Wi-Fi connection\n3. Unstable network cable connection\n\nPlease fix the above and try again. Thank you."
            )
          );
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request(user) {
    return { type: userConstants.user.LOGIN_REQUEST, user };
  }
  function success(user, isEasyP2PLogin = false) {
    return { type: userConstants.user.LOGIN_SUCCESS, user, isEasyP2PLogin };
  }
  function unverified(error) {
    return { type: userConstants.user.LOGIN_UNVERIFIED, error };
  }
  function failure(error) {
    return { type: userConstants.user.LOGIN_FAILURE, error };
  }
};

const logout = (type, history) => {
  return (dispatch) => {
    userService.user.logout();
    dispatch({ type: userConstants.user.LOGOUT });
    if (type === "seller") {
      history.push(`/login/${type}`);
    } else if (type === "none") {
      console.log("ready...");
    } else {
      history.push(`/login/buyer`);
    }
  };
};

const checkVerified = (data, history) => {
  return (dispatch) => {
    // console.log(history);
    dispatch(request(data));
    userService.user
      .checkVerified(data)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success(res.data.message));
          if (res.data.message === "customer_verified") {
            history.push("/login/buyer");
          }
        } else {
          dispatch(failure(res.data.message));
          if (res.data.message === "customer_not_found") {
            if (data.ref !== "p2p-buyer" && data.ref !== "p2p-seller")
              history.push("/login/buyer");
          } else if (res.data.message === "not_verified") {
            dispatch(
              alertActions.alert.error(
                "Your phone number and email address is yet to be verified. To verify, please check your email for your verification link"?.replace(
                  /_/g,
                  " "
                )
              )
            );
            history.push("/login/buyer");
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);

          dispatch(failure(err.response.data.message));
          // dispatch(alertActions.alert.error((err.response.data.message)?.replace(/_/g, " ")));
          // dispatch(alertActions.alert.error((err.response.data.message)?.replace(/\\n/g, " ")));
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          // dispatch(alertActions.alert.error((err.message)?.replace(/_/g, " ")));
          // dispatch(alertActions.alert.error((err.message)?.replace(/\\n/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          // dispatch(alertActions.alert.error((err.message)?.replace(/_/g, " ")));
          // dispatch(alertActions.alert.error((err.message)?.replace(/\\n/g, " ")));
        }

        console.log(err.config);
      });
  };

  function request(data) {
    return { type: userConstants.user.GET_VERIFY_REQUEST, data };
  }
  function success(msg) {
    return { type: userConstants.user.GET_VERIFY_SUCCESS, msg };
  }
  function failure(error) {
    return { type: userConstants.user.GET_VERIFY_FAILURE, error };
  }
};

const resendEmail = (email, type, history) => {
  return (dispatch) => {
    // console.log(history);
    dispatch(request(email));
    userService.user
      .resendEmail(email, type)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success(res.data.data.email));
          dispatch(alertActions.alert.success(res.data.message));
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error("An error occurred, please try later")
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);

          dispatch(failure(err.response.data.message));
          // dispatch(alertActions.alert.error((err.response.data.message)?.replace(/_/g, " ")));
          // dispatch(alertActions.alert.error((err.response.data.message)?.replace(/\\n/g, " ")));
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          // dispatch(alertActions.alert.error((err.message)?.replace(/_/g, " ")));
          // dispatch(alertActions.alert.error((err.message)?.replace(/\\n/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          // dispatch(alertActions.alert.error((err.message)?.replace(/_/g, " ")));
          // dispatch(alertActions.alert.error((err.message)?.replace(/\\n/g, " ")));
        }

        console.log(err.config);
      });
  };

  function request(data) {
    return { type: userConstants.user.RESEND_EMAIL_REQUEST, data };
  }
  function success(msg) {
    return { type: userConstants.user.RESEND_EMAIL_SUCCESS, msg };
  }
  function failure(error) {
    return { type: userConstants.user.RESEND_EMAIL_FAILURE, error };
  }
};

const register = (data, type, history) => {
  return (dispatch) => {
    dispatch(request(data));

    userService.user
      .register(data, type)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success());
          if (res.data.message === "customer_updated") {
            dispatch(alertActions.alert.success("customer_updated"));
            history.push(`/login/${type}`);
          } else if (res.data.message === "customer_updated_otp_required") {
            // console.log(res.data.message, "updated")
            history.push("/confirmation/customer/" + res.data.data);
          } else {
            // console.log(res.data.message)
            dispatch(alertActions.alert.success(data.email));
            history.push(`/login/${type}`);
          }
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/\\n/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);

          dispatch(failure(err.response.data.message));
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/_/g, " ")
            )
          );
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/\\n/g, " ")
            )
          );
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(
            alertActions.alert.error(
              "Connection Error\n\nYou are experiencing a connection error that may be due to the following:\n1. Poor mobile internet connection\n2. Poor Wi-Fi connection\n3. Unstable network cable connection\n\nPlease fix the above and try again. Thank you."
            )
          );
          // dispatch(alertActions.alert.error((err.message)?.replace(/\\n/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
          dispatch(alertActions.alert.error(err.message?.replace(/\\n/g, " ")));
        }

        console.log(err.config);
      });
  };

  function request() {
    return { type: userConstants.user.REGISTER_REQUEST };
  }
  function success() {
    return { type: userConstants.user.REGISTER_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.user.REGISTER_FAILURE, error };
  }
};

const submitOtp = (type, data, token, history) => {
  return (dispatch) => {
    // console.log(data);

    dispatch(request(data));
    type === "marketplace" ? (type = "seller") : (type = type);

    userService.user
      .submitOtp(type, data, token)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success());
          // dispatch(alertActions.alert.success((res.data.message)?.replace(/_/g, " ")));
          if (type === "seller") {
            history.push("/login/seller");
            dispatch(
              alertActions.alert.success(
                "Your phone number has been successfully verified. Please click OK to login and complete your registration."
              )
            );
          }
          if (type === "buyer" || type === "customer") {
            history.push("/login/buyer");
            dispatch(
              alertActions.alert.success(
                "Your phone number has been successfully verified, you can now login."
              )
            );
          }
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/\\n/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);

          dispatch(failure(err.response.data.message));
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/_/g, " ")
            )
          );
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/\\n/g, " ")
            )
          );
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(
            alertActions.alert.error(
              "Connection Error\n\nYou are experiencing a connection error that may be due to the following:\n1. Poor mobile internet connection\n2. Poor Wi-Fi connection\n3. Unstable network cable connection\n\nPlease fix the above and try again. Thank you."
            )
          );
          // dispatch(alertActions.alert.error((err.message)?.replace(/\\n/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
          dispatch(alertActions.alert.error(err.message?.replace(/\\n/g, " ")));
        }

        console.log(err.config);
      });
  };

  function request() {
    return { type: userConstants.user.SUBMIT_OTP_REQUEST };
  }
  function success() {
    return { type: userConstants.user.SUBMIT_OTP_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.user.SUBMIT_OTP_FAILURE, error };
  }
};

const getOtp = (type, data, history) => {
  return (dispatch) => {
    // console.log(data);

    dispatch(request(data));
    type === "marketplace" ? (type = "seller") : (type = type);

    userService.user
      .getOtp(type, data)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success());
          dispatch(
            alertActions.alert.success(
              "OTP has been sent to your mobile phone number"
            )
          );
          // dispatch(alertActions.alert.success((res.data.message)?.replace(/_/g, " ")));
          // history.push('/login');
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/\\n/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);

          dispatch(failure(err.response.data.message));
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/_/g, " ")
            )
          );
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/\\n/g, " ")
            )
          );
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(
            alertActions.alert.error(
              "Connection Error\n\nYou are experiencing a connection error that may be due to the following:\n1. Poor mobile internet connection\n2. Poor Wi-Fi connection\n3. Unstable network cable connection\n\nPlease fix the above and try again. Thank you."
            )
          );
          // dispatch(alertActions.alert.error((err.message)?.replace(/\\n/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
          dispatch(alertActions.alert.error(err.message?.replace(/\\n/g, " ")));
        }

        console.log(err.config);
      });
  };

  function request() {
    return { type: userConstants.user.GET_OTP_REQUEST };
  }
  function success() {
    return { type: userConstants.user.GET_OTP_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.user.GET_OTP_FAILURE, error };
  }
};

const confirmBuyer = (token, history) => {
  return (dispatch) => {
    // console.log(data);

    dispatch(request());

    userService.user
      .confirmCustomer(token)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success());
          history.push("/login");
          dispatch(
            alertActions.alert.success(
              "Your email has been verified you can now log in"
            )
          );
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);

          dispatch(failure(err.response.data.message));
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/_/g, " ")
            )
          );
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(
            alertActions.alert.error(
              "Connection Error\n\nYou are experiencing a connection error that may be due to the following:\n1. Poor mobile internet connection\n2. Poor Wi-Fi connection\n3. Unstable network cable connection\n\nPlease fix the above and try again. Thank you."
            )
          );
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };

  function request() {
    return { type: userConstants.user.CONFIRM_BUYER_REQUEST };
  }
  function success() {
    return { type: userConstants.user.CONFIRM_BUYER_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.user.CONFIRM_BUYER_FAILURE, error };
  }
};

const submitBusiness = (data, email, history) => {
  return (dispatch) => {
    // console.log(data);

    dispatch(request(data));

    userService.user
      .submitBusiness(data, dispatch)
      .then((res) => {
        if (res.data.status == "success") {
          // console.log(res.data.data);
          dispatch(success());
          if (res.data.data.business_name === null) {
            // setTimeout( dispatch(getDetails("seller",history)), 10000);
            dispatch(
              alertActions.alert.success(
                `Thank you for completing your registration on AtaraPay. Click OK to be redirected to your account.`?.replace(
                  /_/g,
                  " "
                )
              )
            );

            // history.push('/');
          } else {
            // setTimeout( dispatch(getDetails("seller",history)), 10000);
            dispatch(alertActions.alert.success(email?.replace(/_/g, " ")));

            // history.push('/app/pending');
          }

          // history.push('/app');
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/\\n/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout("seller", history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(
            alertActions.alert.error(
              "Connection Error\n\nYou are experiencing a connection error that may be due to the following:\n1. Poor mobile internet connection\n2. Poor Wi-Fi connection\n3. Unstable network cable connection\n\nPlease fix the above and try again. Thank you."
            )
          );
          // dispatch(alertActions.alert.error((err.message)?.replace(/\\n/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
          dispatch(alertActions.alert.error(err.message?.replace(/\\n/g, " ")));
        }

        console.log(err.config);
      });
  };

  function request() {
    return { type: userConstants.user.SUBMIT_BUSINESS_REQUEST };
  }
  function success() {
    return { type: userConstants.user.SUBMIT_BUSINESS_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.user.SUBMIT_BUSINESS_FAILURE, error };
  }
};

const viewDashboard = (type, history) => {
  return (dispatch) => {
    dispatch(request());

    userService.user
      .getDashboard(type)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success(res.data));
        } else {
          dispatch(failure(res.data.message));
          // dispatch(alertActions.alert.error((res.data.message)?.replace(/_/g, " ")));
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout(type, history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err?.response?.data?.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err?.response?.data?.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(
            alertActions.alert.error(
              "Connection Error\n\nYou are experiencing a connection error that may be due to the following:\n1. Poor mobile internet connection\n2. Poor Wi-Fi connection\n3. Unstable network cable connection\n\nPlease fix the above and try again. Thank you."
            )
          );
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request() {
    return { type: userConstants.user.DASHBOARD_DETAILS_REQUEST };
  }
  function success(dashboard) {
    return {
      type: userConstants.user.DASHBOARD_DETAILS_SUCCESS,
      dashboard: dashboard,
    };
  }
  function failure(error) {
    return { type: userConstants.user.DASHBOARD_DETAILS_FAILURE, error };
  }
};

const bvnPay = (history) => {
  return (dispatch) => {
    // console.log();

    dispatch(request());

    userService.user
      .bvnPay()
      .then((res) => {
        // console.log(res);
        if (res.data.status === "success") {
          // window.open(res.data.data.auth.authorization_url,'_blank');
          window.location.href = res.data.data.auth.authorization_url;
          return;
          // dispatch(alertActions.alert.success((res.data.message)?.replace(/_/g, " ")));
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout("seller", history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          // dispatch(alertActions.alert.error((err.message)?.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          // dispatch(alertActions.alert.error((err.message)?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };

  function request() {
    return { type: userConstants.user.BVN_PAY_REQUEST };
  }
  function success() {
    return { type: userConstants.user.BVN_PAY_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.user.BVN_PAY_FAILURE, error };
  }
};

const goLive = (type, data, process, history) => {
  return (dispatch) => {
    // console.log();

    dispatch(request());
    type === "marketplace" ? (type = "seller") : (type = type);

    userService.user
      .goLive(type, data, process)
      .then((res) => {
        // console.log(res);

        if (res.data.status === "success") {
          dispatch(success(res.data.data));
          dispatch(
            alertActions.alert.success(res.data.message?.replace(/_/g, " "))
          );
        } else {
          dispatch(failure(res.data.message));
          // dispatch(alertActions.alert.error((res.data.message)?.replace(/_/g, " ")));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout("seller", history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          // dispatch(alertActions.alert.error((err.message)?.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          // dispatch(alertActions.alert.error((err.message)?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };

  function request() {
    return { type: userConstants.user.GO_LIVE_REQUEST };
  }
  function success(key) {
    return { type: userConstants.user.GO_LIVE_SUCCESS, key };
  }
  function failure(error) {
    return { type: userConstants.user.GO_LIVE_FAILURE, error };
  }
};

const genKey = (type, data, history) => {
  return (dispatch) => {
    // console.log();

    dispatch(request());
    type === "marketplace" ? (type = "seller") : (type = type);

    userService.user
      .genKey(type, data)
      .then((res) => {
        // console.log(res);

        if (res.data.status === "success") {
          dispatch(success(res.data.data));
          dispatch(alertActions.alert.success(res.data.message));
        } else {
          dispatch(failure(res.data.message));
          // dispatch(alertActions.alert.error((res.data.message)?.replace(/_/g, " ")));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout("seller", history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                "Connection Error\n\nYou are experiencing a connection error that may be due to the following:\n1. Poor mobile internet connection\n2. Poor Wi-Fi connection\n3. Unstable network cable connection\n\nPlease fix the above and try again. Thank you."
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          // dispatch(alertActions.alert.error((err.message)?.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          // dispatch(alertActions.alert.error((err.message)?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };

  function request() {
    return { type: userConstants.user.GEN_KEY_REQUEST };
  }
  function success(key) {
    return { type: userConstants.user.GEN_KEY_SUCCESS, key };
  }
  function failure(error) {
    return { type: userConstants.user.GEN_KEY_FAILURE, error };
  }
};
const viewKey = (type, history) => {
  return (dispatch) => {
    // console.log();

    dispatch(request());
    type === "marketplace" ? (type = "seller") : (type = type);
    userService.user
      .viewKey(type)
      .then((res) => {
        // console.log(res);

        if (res.data.status == "success") {
          dispatch(success(res.data.data));
          // dispatch(alertActions.alert.success((res.data.message)?.replace(/_/g, " ")));
        } else {
          dispatch(failure(res.data.message));
          // dispatch(alertActions.alert.error((res.data.message)?.replace(/_/g, " ")));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout(type, history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          // dispatch(alertActions.alert.error((err.message)?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };

  function request() {
    return { type: userConstants.user.GET_KEY_REQUEST };
  }
  function success(key) {
    return { type: userConstants.user.GET_KEY_SUCCESS, key };
  }
  function failure(error) {
    return { type: userConstants.user.GET_KEY_FAILURE, error };
  }
};
const getDetails = (type, history) => {
  return (dispatch) => {
    // console.log();

    dispatch(request());
    type === "marketplace" ? (type = "seller") : (type = type);

    userService.user
      .getUser(type)
      .then((res) => {
        // console.log(res);
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
          // dispatch(alertActions.alert.success((res.data.message)?.replace(/_/g, " ")));
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout(type, history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          // dispatch(alertActions.alert.error((err.message)?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };

  function request() {
    return { type: userConstants.user.GETALL_REQUEST };
  }
  function success(user) {
    return { type: userConstants.user.GETALL_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.user.GETALL_FAILURE, error };
  }
};

const getDispatchers = (type, history) => {
  return (dispatch) => {
    dispatch(request());

    userService.user
      .getDispatchers(type)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
        } else {
          dispatch(failure(res.data.message));
          // dispatch(alertActions.alert.error((res.data.message)?.replace(/_/g, " ")));
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout(type, history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request() {
    return { type: deliveryConstants.delivery.GET_DELIVERY_MEN };
  }
  function success(deliveryMen) {
    return {
      type: deliveryConstants.delivery.GET_DELIVERY_MEN_SUCCESS,
      deliveryMen,
    };
  }
  function failure(error) {
    return { type: deliveryConstants.delivery.GET_DELIVERY_MEN_FAILURE, error };
  }
};

const updateProfile = (type, data, history) => {
  return (dispatch) => {
    dispatch(request());
    type === "marketplace" ? (type = "seller") : (type = type);

    userService.user
      .updateProfile(type, data)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
          dispatch(
            alertActions.alert.success("Profile Updated"?.replace(/_/g, " "))
          );
          //    history.push("/app/profile/view");
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/\\n/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout(type, history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/\\n/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/\\n/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(
            alertActions.alert.error(
              "Connection Error\n\nYou are experiencing a connection error that may be due to the following:\n1. Poor mobile internet connection\n2. Poor Wi-Fi connection\n3. Unstable network cable connection\n\nPlease fix the above and try again. Thank you."
            )
          );
          // dispatch(alertActions.alert.error((err.message)?.replace(/\\n/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
          dispatch(alertActions.alert.error(err.message?.replace(/\\n/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request() {
    return { type: userConstants.user.UPDATE_PROFILE };
  }
  function success(user) {
    return { type: userConstants.user.UPDATE_PROFILE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.user.UPDATE_PROFILE_FAILURE, error };
  }
};

const updateTour = (type, data, history, nxturl) => {
  return (dispatch) => {
    dispatch(request());
    type === "marketplace" ? (type = "seller") : (type = type);

    userService.user
      .updateProfile(type, data)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
          //dispatch(alertActions.alert.success(("Tour Completed ")?.replace(/_/g, " ")));
          history.push(nxturl);
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/\\n/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout(type, history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/\\n/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/\\n/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(
            alertActions.alert.error(
              "Connection Error\n\nYou are experiencing a connection error that may be due to the following:\n1. Poor mobile internet connection\n2. Poor Wi-Fi connection\n3. Unstable network cable connection\n\nPlease fix the above and try again. Thank you."
            )
          );
          // dispatch(alertActions.alert.error((err.message)?.replace(/\\n/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
          dispatch(alertActions.alert.error(err.message?.replace(/\\n/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request() {
    return { type: userConstants.user.UPDATE_PROFILE };
  }
  function success(user) {
    return { type: userConstants.user.UPDATE_PROFILE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.user.UPDATE_PROFILE_FAILURE, error };
  }
};
const updatePassword = (type, data, history) => {
  return (dispatch) => {
    dispatch(request());
    type === "marketplace" ? (type = "seller") : (type = type);

    userService.user
      .updatePassword(type, data)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success());
          dispatch(
            alertActions.alert.success(res.data.message?.replace(/_/g, " "))
          );
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/\\n/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout(type, history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/\\n/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/\\n/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(
            alertActions.alert.error(
              "Connection Error\n\nYou are experiencing a connection error that may be due to the following:\n1. Poor mobile internet connection\n2. Poor Wi-Fi connection\n3. Unstable network cable connection\n\nPlease fix the above and try again. Thank you."
            )
          );
          // dispatch(alertActions.alert.error((err.message)?.replace(/\\n/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
          dispatch(alertActions.alert.error(err.message?.replace(/\\n/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request() {
    return { type: userConstants.user.PASSWORD_CHANGE_REQUEST };
  }
  function success() {
    return { type: userConstants.user.PASSWORD_CHANGE_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.user.PASSWORD_CHANGE_FAILURE, error };
  }
};

const forgotPassword = (type, data, phone, history) => {
  return (dispatch) => {
    dispatch(request());

    userService.user
      .forgotPassword(type, data)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success());
          if (type === "seller")
            dispatch(
              alertActions.alert.success(
                "An email containing the password reset link has been sent to your email address."
              )
            );
          else
            dispatch(
              alertActions.alert.success(
                "An email containing the password reset link has been sent to the email address registered against " +
                  phone +
                  "."
              )
            );
        } else {
          if (res.data.message === "user_not_found") {
            dispatch(
              alertActions.alert.error(
                "Account does not exist. Please register."
              )
            );
          } else {
            dispatch(failure(res.data.message));
            dispatch(
              alertActions.alert.error(res.data.message?.replace(/_/g, " "))
            );
            dispatch(
              alertActions.alert.error(res.data.message?.replace(/\\n/g, " "))
            );
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(
            alertActions.alert.error(
              "Connection Error\n\nYou are experiencing a connection error that may be due to the following:\n1. Poor mobile internet connection\n2. Poor Wi-Fi connection\n3. Unstable network cable connection\n\nPlease fix the above and try again. Thank you."
            )
          );
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request() {
    return { type: userConstants.user.FORGOT_PASSWORD_REQUEST };
  }
  function success() {
    return { type: userConstants.user.FORGOT_PASSWORD_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.user.FORGOT_PASSWORD_FAILURE, error };
  }
};

const resetPassword = (type, data, history) => {
  return (dispatch) => {
    dispatch(request());
    type === "marketplace" ? (type = "seller") : (type = type);
    userService.user
      .resetPassword(type, data)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success());
          dispatch(
            alertActions.alert.success(res.data.message?.replace(/_/g, " "))
          );
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/\\n/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            history.push("/login");
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(
            alertActions.alert.error(
              "Connection Error\n\nYou are experiencing a connection error that may be due to the following:\n1. Poor mobile internet connection\n2. Poor Wi-Fi connection\n3. Unstable network cable connection\n\nPlease fix the above and try again. Thank you."
            )
          );
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };

  function request() {
    return { type: userConstants.user.RESET_PASSWORD_REQUEST };
  }
  function success() {
    return { type: userConstants.user.RESET_PASSWORD_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.user.RESET_PASSWORD_FAILURE, error };
  }
};
export const user = {
  login,
  logout,
  checkVerified,
  resendEmail,
  getDetails,
  viewDashboard,
  getDispatchers,
  forgotPassword,
  confirmBuyer,
  resetPassword,
  register,
  updateProfile,
  updateTour,
  updatePassword,
  goLive,
  genKey,
  viewKey,
  getOtp,
  submitOtp,
  submitBusiness,
  bvnPay,
};
