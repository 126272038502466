import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";




import Profile from '../views/profile';
import { userActions } from '../actions';

const mapStateToProps = (state) =>{
    
    const { loading, user, updating } = state.users;
    
    const alert  = state.alert;
    return {
      
        alert,
        user,
        loading,
        updating
    };
 }

 const mapDispatchToProps = (dispatch) =>({
     dispatch,
    update:(type,data,history)=>dispatch(userActions.user.updatePassword(type,data,history)),
    updateProfile:(type,data,history)=>dispatch(userActions.user.updateProfile(type,data,history))
 })
 

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Profile));