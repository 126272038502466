import { userConstants } from "../constants";

const initialState = {};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.user.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        loading: true,
      };
    case userConstants.user.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loading: false,
        user: action.user,
        isEasyP2PLogin: action.isEasyP2PLogin,
      };
    case userConstants.user.LOGIN_UNVERIFIED:
      return {
        loggedIn: false,
        loading: false,
        user: action.error,
      };
    case userConstants.user.LOGIN_FAILURE:
      return {
        loading: false,
      };

    default:
      return state;
  }
};

export default auth;
