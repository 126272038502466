import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Modal from "react-responsive-modal";
import * as moment from "moment";
import { alertActions } from "../actions";
import swal from "@sweetalert/with-react";
import transactionHelper from "../helpers/transaction";

import { css } from "@emotion/core";
import { ClipLoader } from "react-spinners";

const override = css`
  display: block;
  margin-top: 10%;
  margin-left: 50%;
  margin-right: 40%;
  border-color: red;
`;

const formatToSixDigits = (number) => {
  var output = number + "";
  while (output.length < 6) {
    output = "0" + output;
  }
  return output;
};

const GenerateReportForm = (props) => (
  <>
    <div className="row">
      <div className="col-12 col-sm-2">
        <div className="form-group">
          <label htmlFor="role">Role</label>
          <select
            className="form-control"
            name="role"
            onChange={props.handleChange}
          >
            {props?.user?.role?.name == "MarketPlace" ? (
              <>
                <option value="marketplace">Market Op</option>
                <option value="Service Provider">Seller</option>
              </>
            ) : (
              <>
                <option value="seller">Seller</option>
                <option value="Service Provider">Service Provider</option>
              </>
            )}
          </select>
        </div>
      </div>

      {props.isServiceProvider && (
        <div className="col-12 col-sm-2">
          <div className="form-group">
            <label htmlFor="Seller name">
              {props?.user?.role?.name == "MarketPlace"
                ? "Seller name"
                : "Service Provider"}
            </label>
            <select
              className="form-control"
              value={props.sp_id}
              name="sp_id"
              onChange={props.handleChange}
            >
              {props?.user?.role?.name == "Seller"
                ? props?.serviceProviders?.map(
                    ({ id, firstname, lastname }) => (
                      <option value={id}>
                        {firstname} {lastname}
                      </option>
                    )
                  )
                : props?.serviceProviders?.map((el) => (
                    <option value={el?.sellers[0]?.users?.seller_id}>
                      {el?.sellers[0]?.users?.firstname}{" "}
                      {el?.sellers[0]?.users?.lastname}
                    </option>
                  ))}
            </select>
          </div>
        </div>
      )}
      <div className="col-12 col-sm-2">
        <div className="form-group">
          <label htmlFor="period">Period</label>
          <select
            className="form-control"
            name="period"
            onChange={props.handleChange}
          >
            <option value="7">7 Days Ago</option>
            <option value="60">60 Days Ago</option>
            <option value="90">90 Days Ago</option>
            <option value="Custom">Custom</option>
          </select>
        </div>
      </div>
      <div className="col-12 col-sm-2">
        <div className="form-group">
          <label htmlFor="format">Format</label>
          <select
            className="form-control"
            name="format"
            value={props.format}
            onChange={props.handleChange}
          >
            <option value="PDF">PDF</option>
            <option value="CSV">CSV</option>
          </select>
        </div>
      </div>

      <div
        className={
          props.isServiceProvider ? "col-12 col-sm-2" : "col-12 col-sm-3"
        }
      >
        <div className="form-group">
          <label htmlFor="from">Date From</label>
          <input
            type="date"
            name="start_date"
            value={props.startDate}
            disabled={!props.isCustom}
            onChange={props.handleChange}
            className="form-control"
          />
        </div>
      </div>
      <div
        className={
          props.isServiceProvider ? "col-12 col-sm-2" : "col-12 col-sm-3"
        }
      >
        <div className="form-group">
          <label htmlFor="from">Date To</label>
          <input
            type="date"
            name="end_date"
            value={props.endDate}
            disabled={!props.isCustom}
            onChange={props.handleChange}
            className="form-control"
          />
        </div>
      </div>
    </div>

    <div className="row ">
      <div className="col-12 col-sm-2">
        <div className="form-group">
          <label>&nbsp;</label>
          <div className="row">
            <div className="col-12">
              <button
                disabled={false}
                onClick={props.handleSend}
                className="btn btn-dark btn-block form-control"
              >
                <center>Send</center>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-2">
        <div className="form-group">
          <label>&nbsp;</label>
          <div className="row">
            <div className="col-12">
              <button
                disabled={false}
                onClick={props.handleDownload}
                className="btn btn-info btn-block form-control"
              >
                <center>Download</center>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

class payoutReport extends Component {
  state = {
    filterable: false,
    notCustom: true,
    period: "7",
    assignModal: false,
    fullModal: false,
    from_date: "",
    filter: "",
    format: "PDF",
    sp_id: "",

    to_date: "",
    data: [],
    order: "",
    fields: {
      order_id: 0,
      delivery_man_id: 0,
    },
    role: this.props?.user?.role?.name == "Seller" ? "seller" : "marketplace",
    isCustom: false,
    isServiceProvider: false,
    report: [],
    payments: [],
    transaction: [],
    serviceProviders: [],
    pay: [],
  };

  componentDidMount() {
    if (
      localStorage.getItem("trust_user_type") === "seller" ||
      localStorage.getItem("trust_user_type") === "marketplace"
    ) {
      this.props.getPayoutReport("seller", this.props.history);
    }

    if (localStorage.getItem("trust_user_type") === "marketplace")
      this.props.getMarketPlaceSellers("seller", this.props.history);
  }

  componentWillReceiveProps(props) {
    if (props.accounts) {
      const sp = props.accounts[0];
      console.log("sp", sp);
      console.log("sp_id", sp?.service_providers?.[0]?.id);
      if (sp) {
        this.setState({ serviceProviders: sp["service_providers"] });
        this.setState({ sp: sp["service_providers"] });
        this.setState({ sp_id: sp?.service_providers?.[0]?.id });
      }
    }
    if (props.marketPlaceSellers) {
      console.log("market", props.marketPlaceSellers);
      console.log(
        "market_id",
        props.marketPlaceSellers?.[0]?.sellers[0]?.users?.seller_id
      );
      this.setState({ serviceProviders: props.marketPlaceSellers });
      this.setState({
        sp_id: props.marketPlaceSellers?.[0]?.sellers[0]?.users?.seller_id,
      });
    }
    if (props.report) {
      this.setState({ report: [...props.report] });
      this.setState({ data: [...props.report] });
      this.setState({ payments: props.payouts });
    }
    if (
      props.alert &&
      props.alert.message &&
      props.alert.type === "alert-danger"
    ) {
      swal("Error", props.alert.message, "error").then(() => {
        props.dispatch(alertActions.alert.clear());
      });
    }

    if (
      props.alert &&
      props.alert.message &&
      props.alert.type === "alert-success"
    ) {
      swal("Success", props.alert.message, "success").then(() => {
        props.dispatch(alertActions.alert.clear());
      });
      this.onCloseModal("assignModal");
    }
  }
  calculateCardFee = (data, role) => {
    let cost =
      parseFloat(data.product_cost / 100) * data.quantity +
      parseFloat(transactionHelper.calculateShipping(data, 1, 1)[role]) / 100;
    let fee = (1.5 / 100) * cost;

    if (cost >= 2500) fee = fee + 100;
    if (fee >= 2000) fee = 2000;
    return fee + 50;
  };
  handleSelect = (e) => {
    const { value } = e.target;
    if (value === "Custom") {
      this.setState({ notCustom: !this.state.notCustom });
    }
    this.filterPeriod(value);
    this.setState({ period: value });
  };
  filterPeriod = (value) => {
    const data = this.state.payments;
    if (value === "All") {
      this.table_rows = data;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({ notCustom: true, data: [...this.table_rows] });
    }

    if (value === "This Week") {
      var ordersThisWeek = [];

      for (var i = 0; i < data.length; i++) {
        var now = moment();
        var input = moment(data[i].created_at);
        var isThisWeek = now.isoWeek() === input.isoWeek();

        if (isThisWeek) {
          ordersThisWeek.push(data[i]);
        }
      }

      this.table_rows = ordersThisWeek;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      /* console.log(this.table_rows); */
      this.setState({ notCustom: true, data: [...this.table_rows] });
    }

    if (value === "This Month") {
      var ordersThisMonth = [];

      for (let i = 0; i < data.length; i++) {
        //var now = moment();
        let input = moment(data[i].created_at);
        var isThisMonth = input.isSame(new Date(), "month");

        if (isThisMonth) {
          ordersThisMonth.push(data[i]);
        }
      }

      this.table_rows = ordersThisMonth;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({ notCustom: true, data: [...this.table_rows] });
    }

    if (value === "60 Days Ago") {
      var ordersSixtyDaysAgo = [];

      for (let i = 0; i < data.length; i++) {
        var sixty_days_ago = moment().subtract(60, "days");

        var isBetweenSixtyDays = moment(data[i].created_at).isAfter(
          sixty_days_ago
        );

        if (isBetweenSixtyDays) {
          ordersSixtyDaysAgo.push(data[i]);
        }
      }

      this.table_rows = ordersSixtyDaysAgo;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({ notCustom: true, data: [...this.table_rows] });
    }

    if (value === "90 Days Ago") {
      var ordersNinetyDaysAgo = [];

      for (let i = 0; i < data.length; i++) {
        var ninety_days_ago = moment().subtract(90, "days");

        var isBetweenNinetyDays = moment(data[i].created_at).isAfter(
          ninety_days_ago
        );

        if (isBetweenNinetyDays) {
          ordersNinetyDaysAgo.push(data[i]);
        }
      }

      this.table_rows = ordersNinetyDaysAgo;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({ notCustom: true, data: [...this.table_rows] });
    }

    if (value === "Custom") {
      this.table_rows = data;
      this.to_date = null;
      this.from_date = null;
    }
  };

  filterDates = () => {
    const data = this.state.payments;
    var ordersBetweenTheTwoDates = [];
    for (var i = 0; i < data.length; i++) {
      var isBetweenDates = moment(data[i].created_at).isBetween(
        this.state.from_date,
        this.state.to_date
      ); // true
      console.log(
        "from_date:",
        this.state.from_date,
        "to_date:",
        this.state.to_date,
        "created_at:",
        data[i].created_at,
        "isBetweenDates:",
        isBetweenDates
      );
      if (isBetweenDates) {
        ordersBetweenTheTwoDates.push(data[i]);
      }
    }

    this.table_rows = ordersBetweenTheTwoDates;
    this.page = 1;
    return this.table_rows;
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "period")
      this.setState({
        isCustom: value == "Custom" ? true : false,
      });
    if (value == "Service Provider") this.setState({ isServiceProvider: true });
    if (value == "marketplace" || value == "seller")
      this.setState({ isServiceProvider: false });

    this.setState({ [name]: value });
  };

  handleSend = () => {
    const { period, start_date, end_date, format, role, sp_id } = this.state;
    const data = {
      period: period == "Custom" ? "" : period,
      start_date,
      end_date,
      format,
      role: this.props?.user?.role?.name == "Seller" ? "seller" : "marketplace",
      sp_id,
      type: "Payout",
      request_type: "Send",
    };
    if (!this.state.isServiceProvider) delete data.sp_id;
    //  console.log("req", {period, start_date, end_date, format, role,  type: "Payout", request_type:"download"});
    this.props.generateReport("seller", data, "payout", this.props.history);
  };

  handleDownload = () => {
    const { period, start_date, end_date, format, role, sp_id } = this.state;
    const data = {
      period: period == "Custom" ? "" : period,
      start_date,
      end_date,
      format,
      sp_id,
      role: this.props?.user?.role?.name == "Seller" ? "seller" : "marketplace",
      type: "Payout",
      request_type: "download",
    };
    if (!this.state.isServiceProvider) delete data.sp_id;
    // console.log("req", {period, start_date, end_date, format, role,  type: "Payout", request_type:"send"});
    this.props.generateReport("seller", data, "payout", this.props.history);
  };

  returnStatus(status) {
    switch (status) {
      case 1:
        return "completed";
        break;
      case 2:
        return "gw-pending";
        break;
      case 3:
        return "gw-failed";
        break;
      default:
        return "pending";
        break;
    }
  }

  filterTable = (filter) => {
    var data = this.state.payments;
    var p2p = "P2P";
    var ecommerce = "eCommerce";
    var ownAccount = "Own Account";
    var filteredRows = [];
    if (filter !== "") {
      for (var i = 0; i < data.length; i++) {
        if (
          (data[i]?.transaction &&
            formatToSixDigits(data[i]?.transaction?.id)?.match(
              new RegExp(filter, "gi")
            )) ||
          (data[i].created_at &&
            data[i].created_at?.match(new RegExp(filter, "gi"))) ||
          (data[i]?.transaction &&
            data[i]?.transaction?.payment?.payment_ref &&
            data[i]?.transaction?.payment?.payment_ref
              ?.toUpperCase()
              .includes(filter.toUpperCase())) ||
          (data[i]?.amount &&
            data[i]?.amount?.match(new RegExp(filter, "gi"))) ||
          (data[i]?.service_provider &&
            data[i]?.service_provider?.firstname &&
            data[i]?.service_provider?.firstname?.match(
              new RegExp(filter, "gi")
            )) ||
          (data[i]?.service_provider &&
            data[i]?.service_provider?.lastname &&
            data[i]?.service_provider?.lastname?.match(
              new RegExp(filter, "gi")
            )) ||
          (data[i].type !== 10 && ownAccount.match(new RegExp(filter, "gi"))) ||
          (data[i]?.seller?.firstname &&
            data[i]?.seller?.firstname !==
              data[i]?.service_provider?.firstname &&
            data[i]?.seller?.firstname?.match(new RegExp(filter, "gi"))) ||
          (data[i]?.seller?.lastname &&
            // data[i]?.seller?.lastname !== data[i]?.service_provider?.lastname &&
            data[i]?.service_provider &&
            data[i]?.seller?.lastname?.match(new RegExp(filter, "gi"))) ||
          (data[i]?.transaction?.customers?.firstname &&
            // data[i]?.transaction?.customers?.firstname !==
            //   data[i]?.service_provider?.firstname &&
            data[i]?.service_provider &&
            data[i]?.transaction?.customers?.firstname?.match(
              new RegExp(filter, "gi")
            )) ||
          (data[i]?.transaction?.customers?.lastname &&
            // data[i]?.transaction?.customers?.lastname !==
            //   data[i]?.service_provider?.lastname &&
            !data[i]?.service_provider &&
            data[i]?.transaction?.customers?.lastname?.match(
              new RegExp(filter, "gi")
            )) ||
          this.returnStatus(data[i]?.status)
            .toLowerCase()
            .includes(filter.trim().toLowerCase()) ||
          (data[i]?.transaction &&
            data[i]?.transaction?.type === 1 &&
            ecommerce.match(new RegExp(filter, "gi"))) ||
          (data[i]?.transaction &&
            data[i]?.transaction?.type === 2 &&
            p2p.match(new RegExp(filter, "gi")))
        ) {
          filteredRows.push(data[i]);
        }
      }

      this.setState({ data: [...filteredRows] });
    } else {
      this.setState({ data: [...this.state.payments] });
    }
  };

  handleFilter = (e) => {
    const { value } = e.target;
    this.setState({ filter: value }, () => this.filterTable(this.state.filter));
    // this.filterTable();
  };
  onOpenModal = (name, value, value2) => {
    this.setState({ [name]: true });
    this.setState({ transaction: value, pay: value2 });
  };

  onCloseModal = (name) => {
    this.setState({ [name]: false });
    this.setState({ transaction: "" });
    this.setState({ pay: "" });
  };

  formatAmount = (amount) => {
    let a = amount + "";
    let naira = a.slice(0, -3);
    let kobo = a.substr(a.length - 2);
    return naira + "." + kobo;
  };

  render() {
    const { loading, user } = this.props;
    const {
      filterable,
      role,
      isCustom,
      isServiceProvider,
      serviceProviders,
      sp_id,
    } = this.state;
    var columns = [];

    columns = [
      {
        Header: (props) => (
          <span>
            <b>SN</b>
          </span>
        ),
        id: "sn",
        filterable,
        maxWidth: 80,
        accessor: (d) => d, // String-based value accessors!
        Cell: (row) => {
          return <span>{row.index + 1}</span>;
        },
      },

      {
        Header: (props) => (
          <span>
            <b>Report Owner </b>
          </span>
        ),
        id: "firstname",
        filterable,
        maxWidth: 100,
        accessor: (d) => d, // String-based value accessors!
        Cell: (props) => <span>{props?.value?.report_role}</span>,
      },
      {
        Header: (props) => (
          <span>
            <b>Start Date</b>
          </span>
        ),
        id: "start_date",
        filterable,
        maxWidth: 100,
        accessor: (d) => d, // String-based value accessors!
        Cell: (props) => <span>{props.value.start_date}</span>,
      },
      {
        Header: (props) => (
          <span>
            <b>End Date</b>
          </span>
        ),
        id: "end_date",
        filterable,
        maxWidth: 100,
        accessor: (d) => d, // String-based value accessors!
        Cell: (props) => <span>{props.value.end_date}</span>,
      },

      {
        id: "format", // Required because our accessor is not a string
        Header: (props) => (
          <span>
            <b>Format</b>
          </span>
        ),
        filterable,
        maxWidth: 140,
        accessor: (d) => d, // Custom value accessors!
        Cell: (props) => <span>{props.value.format}</span>, // Custom cell components!
      },

      {
        id: "date_requested",
        filterable,
        maxWidth: 170,
        Header: (props) => (
          <span>
            <b>Date Requested</b>
          </span>
        ),
        accessor: (d) => d, // String-based value accessors!
        Cell: (props) => <span>{props.value.created_at}</span>,
      },
      {
        Header: (props) => (
          <span>
            <b>Recipient</b>
          </span>
        ),
        id: "recipient",
        filterable,
        accessor: (d) => d,
        Cell: (props) => <span>{props.value.recipient_email}</span>, // Custom cell components!
      },
      {
        id: "date_sent",
        filterable,
        maxWidth: 100,
        Header: (props) => (
          <span>
            <b>Date Sent</b>
          </span>
        ),
        accessor: (d) => d, // String-based value accessors!
        Cell: (props) => <span>{props.value.updated_at}</span>,
      },
      {
        id: "status",
        filterable,
        maxWidth: 100,
        Header: (props) => (
          <span>
            <b>Status</b>
          </span>
        ),
        accessor: (d) => d, // String-based value accessors!
        className: "text-center",
        Cell: (props) => (
          <div>
            {props.value.status == "Downloaded" ? (
              <span className="badge badge-success">Downloaded</span>
            ) : props.value.status == "Sent" ? (
              <span className="badge badge-info">Sent</span>
            ) : (
              props.value.status == "In progress" && (
                <span className="badge badge-warning">In Progress</span>
              )
            )}
          </div>
        ),
      },
    ];

    return (
      <div>
        <Modal
          open={this.state.fullModal}
          onClose={() => this.onCloseModal("fullModal")}
          center
        >
          <form>
            <div className="modal-header">
              <h4 className="modal-title">
                <small>
                  Transaction
                  {formatToSixDigits(
                    this.state.transaction && this.state.transaction.id
                  )}
                </small>
              </h4>
            </div>
            <div className="modal-body">
              <div className="row"></div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Order ID</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={formatToSixDigits(
                        this.state.transaction && this.state.transaction.id
                      )}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Order Value</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={
                        this.state.transaction &&
                        "₦" +
                          (
                            this.state.transaction.amount_payed / 100
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                      }
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                {role === "seller" ? (
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Buyer Name</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          (this.state.transaction &&
                            this.state.transaction.customers &&
                            this.state.transaction.customers.firstname) +
                          " " +
                          (this.state.transaction &&
                            this.state.transaction.customers &&
                            this.state.transaction.customers.lastname)
                        }
                        className="form-control"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Seller Name</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          (this.state.transaction &&
                            this.state.transaction.user &&
                            this.state.transaction.user.firstname) +
                          " " +
                          (this.state.transaction &&
                            this.state.transaction.sellers &&
                            this.state.transaction.user &&
                            this.state.transaction.user.lastname)
                        }
                        className="form-control"
                      />
                    </div>
                  </div>
                )}
                <div className="col-sm-6">
                  {role === "seller" ? (
                    <div className="form-group">
                      <label>
                        <small>
                          <b>Marketplace Business Name</b>
                        </small>
                      </label>{" "}
                      <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.pay &&
                          this.state.pay.marketplace &&
                          this.state.pay.marketplace.marketplaceuser &&
                          this.state.pay.marketplace.marketplaceuser.seller
                            ? this.state.pay.marketplace.marketplaceuser.seller
                                .business_name
                            : this.props.user &&
                              this.props.user.seller &&
                              this.props.user.seller.individual
                            ? this.props.user.firstname +
                              " " +
                              this.props.user.lastname
                            : this.props.user &&
                              this.props.user.seller &&
                              this.props.user.seller.business_name
                        }
                        className="form-control"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label>
                        <small>
                          <b>Marketplace Business Name</b>
                        </small>
                      </label>{" "}
                      <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.transaction &&
                          this.state.transaction.sellers &&
                          this.state.transaction.sellers.business_name
                            ? this.state.transaction &&
                              this.state.transaction.sellers &&
                              this.state.transaction.sellers.business_name
                            : "N/A"
                        }
                        className="form-control"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Escrow Fee Paid By</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={
                        this.state.transaction &&
                        this.state.transaction.escrow_fee_bearer === "seller"
                          ? "Seller"
                          : this.state.transaction &&
                            this.state.transaction.escrow_fee_bearer === "both"
                          ? "Both"
                          : "Buyer"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Escrow Fee</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={
                        this.state.transaction &&
                        "(₦" +
                          (
                            transactionHelper.calculateTransactionEscrow(
                              (this.state.transaction.amount_net +
                                this.state.transaction.shipping_cost *
                                  (this.state.transaction &&
                                  this.state.transaction.shipping_fee_bearer ===
                                    "both"
                                    ? 0.5
                                    : this.state.transaction &&
                                      this.state.transaction
                                        .shipping_fee_bearer === "seller"
                                    ? 0
                                    : 1)) /
                                100
                            ) *
                            (this.state.transaction &&
                            this.state.transaction.escrow_fee_bearer === "both"
                              ? 0.5
                              : 1)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) +
                          ")"
                      }
                      className="form-control text-danger"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Shipping Fee Paid By</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={
                        this.state.transaction &&
                        this.state.transaction.shipping_fee_bearer === "seller"
                          ? "Seller"
                          : this.state.transaction &&
                            this.state.transaction.shipping_fee_bearer ===
                              "both"
                          ? "Both"
                          : "Buyer"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  {role === "seller" ? (
                    <div className="form-group">
                      <label>Shipping Fee</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.transaction &&
                          "₦" +
                            (
                              this.state.transaction.shipping_cost / 100
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        className="form-control"
                      />
                    </div>
                  ) : this.state.transaction &&
                    this.state.transaction.shipping_fee_bearer === "seller" ? (
                    <div className="form-group">
                      <label>Shipping Fee</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.transaction &&
                          "(₦" +
                            (
                              this.state.transaction.shipping_cost / 100
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) +
                            ")"
                        }
                        className="form-control text-danger"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label>Shipping Fee</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.transaction &&
                          "₦" +
                            (
                              this.state.transaction.shipping_cost / 100
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        className="form-control"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label style={{ "font-size": "12px" }}>
                      Card & Transfer Fee Paid By
                    </label>{" "}
                    <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={
                        this.state.transaction &&
                        this.state.transaction.tx_fee_bearer === "seller"
                          ? "Seller"
                          : this.state.transaction.sla === 1
                          ? "Seller"
                          : "Buyer"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  {this.state.transaction &&
                  this.state.transaction.status === 9 &&
                  this.state.transaction.tx_fee_bearer === "seller" &&
                  this.state.transaction.sla === 1 ? (
                    <div className="form-group">
                      <label>Card & Transfer Fee</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.transaction &&
                          "₦" +
                            this.calculateCardFee(
                              this.state.transaction,
                              "customer"
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        className="form-control"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label>Card & Transfer Fee</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.transaction &&
                          "(₦" +
                            this.calculateCardFee(
                              this.state.transaction,
                              "customer"
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) +
                            ")"
                        }
                        className="form-control text-danger"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Product Amount</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={
                        this.state.transaction &&
                        "₦" +
                          (
                            this.state.transaction.product_cost / 100
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  {role === "seller" ? (
                    <div className="form-group">
                      <label>Total Payout</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.pay &&
                          "₦" +
                            (this.state.pay.amount / 100).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        className="form-control"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label>Total Refund</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.pay &&
                          "₦" +
                            (this.state.pay.amount / 100).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        className="form-control"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Payment Channel</label> <br />{" "}
                    <input
                      type="text"
                      readonly="readonly"
                      value={
                        this.state.transaction && this.state.transaction.payment
                          ? this.state.transaction &&
                            this.state.transaction.payment &&
                            this.state.transaction.payment.method
                          : "Card"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  {role === "seller" ? (
                    <div className="form-group">
                      <label>Payout Status</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.pay && this.state.pay.status
                            ? "Paid"
                            : "Pending"
                        }
                        className="form-control"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label>Refund Status</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.pay && this.state.pay.status
                            ? "Paid"
                            : "Pending"
                        }
                        className="form-control"
                      />
                    </div>
                  )}
                </div>
              </div>
              {this.state.transaction &&
              this.state.transaction.status === 9 &&
              this.state.transaction.tx_fee_bearer === "seller" &&
              this.state.transaction.sla === 1 ? (
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Cancellation Fee</label> <br />{" "}
                      <input
                        type="text"
                        readonly="readonly"
                        value={
                          this.state.transaction &&
                          "₦" +
                            (
                              this.state.transaction.cancellation_fee / 100
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <br />
              <br />
            </div>
          </form>
        </Modal>
        <div className="content-wrapper" style={{ minHeight: 136 }}>
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-12">
                  <h1 className="m-0 text-dark">Payout Report</h1>
                  <div className="attop">
                    <p>
                      On this page, you would be able to Generate payout reports
                      for transactions made by AtaraPay to the Seller and
                      Service Providers on orders placed from the Seller’s and
                      Marketplace Operator’s website or P2P escrow transaction
                      and accepted by the Buyer.
                    </p>
                  </div>{" "}
                  <br />
                </div>
              </div>
            </div>
          </div>
          {!loading ? (
            <section className="content">
              <div className="container-fluid">
                <br />
                <div className="row ">
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="alert animated bounceIn alert-danger d-none search-alert">
                      <a aria-label="close" className="close alert-close">
                        ×
                      </a>
                      <span></span>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <GenerateReportForm
                          startDate={this.state.start_date}
                          endDate={this.state.end_date}
                          format={this.format}
                          user={user}
                          sp_id={sp_id}
                          isCustom={isCustom}
                          isServiceProvider={isServiceProvider}
                          serviceProviders={serviceProviders}
                          handleChange={this.handleChange}
                          handleSend={this.handleSend}
                          handleDownload={this.handleDownload}
                        />
                        <br />
                        <div className="table-responsive">
                          <ReactTable
                            defaultPageSize={5}
                            filterable
                            data={this.state.data}
                            columns={columns}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <br />
              </div>
            </section>
          ) : (
            <div className="sweet-loading">
              <ClipLoader
                class={override}
                sizeUnit={"px"}
                size={70}
                color={"blue"}
                loading={loading}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default payoutReport;
