import React, { Component } from 'react';
import { connect } from 'react-redux';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
import NumberFormat from 'react-number-format';
import Modal from 'react-responsive-modal';
import { ClipLoader } from 'react-spinners';
import swal from '@sweetalert/with-react';
import { Link, withRouter } from 'react-router-dom';

import transactionHelper from '../helpers/transaction';
import transactionHelpers from '../helpers/transaction';
import transactionSchema from '../validation/transaction';
import { termActions, userActions } from '../actions';
import axios from 'axios';
import { BASE_URL } from '../constants';
import * as yup from 'yup';
import BrowserDetection from 'react-browser-detection';
import ReactModal from 'react-responsive-modal';

const Tab = (props) => {
  return (
    <div
      style={{
        ...(props.active
          ? { color: '#005599', borderBottom: '3px solid #005599' }
          : {
              color: '#bbb',
              borderBottom: '3px solid #bbb',
            }),
        paddingBottom: 10,
        textAlign: 'center',
        fontSize: 16,
        float: 'left',
        width: '33.33%',
      }}
    >
      {props.children}
    </div>
  );
};
let promoInput = React.createRef();

const ModalComponent = (props) => {
  console.log('props', props);
  const escrowFees = (props) => {
    let payableEscrow =
      transactionHelpers.calculatePayableEscrow(props.data) /
      props.data.conversion_ratio;

    let promo_amount = 0;
    if (props.promo) promo_amount = payableEscrow * props.promo.promo_percent;
    return payableEscrow - promo_amount;
  };

  const cardFees = (props) => {
    let payableCardFees =
      props.data.currency !== 'USD'
        ? transactionHelpers.calculateCardFee(props.data, props.role)
        : transactionHelpers.calculateUsdCardFee(props.data, props.role);
    return payableCardFees;
  };

  const netAmount = (props) => {
    let productCost = props.data.amount_payed * props.data.quantity;
    let netCost = productCost / props.data.conversion_ratio;

    return netCost;
  };
  const refundableAmount = (props) => {
    let refundAmount = transactionHelpers.calculateTransactionRefundable(
      props.data
    );

    let txFees =
      props.data.tx_fee_bearer === 'seller'
        ? cardFees(props)
        : 50 / props.data.conversion_ratio;
    txFees = txFees * props.data.conversion_ratio;

    let netRefundAmount = (refundAmount - txFees) / props.data.conversion_ratio;

    return netRefundAmount;
  };

  const logisticFees = (props) => {
    let totalCost =
      props.data.category === 'Money Market'
        ? netAmount(props)
        : netAmount(props) * props.data.conversion_ratio;
    let logisticCost =
      (props.transactionData.sellers.delivery_terms.logistics_fee * totalCost) /
      100;

    if (logisticCost > 5000) {
      logisticCost = 5000;
    }

    return logisticCost;
  };

  const grandTotal = (props) => {
    let payableAmount = transactionHelpers.calculateTransactionPayable(
      props.data,
      0,
      0,
      props.promo
    );
    let txFees =
      props.data &&
      props.data.tx_fee_bearer &&
      props.data.tx_fee_bearer !== 'seller'
        ? cardFees(props)
        : 0;
    if (props.data.category !== 'Money Market')
      txFees = txFees * props.data.conversion_ratio;
    let grandTotalAmount =
      (payableAmount + txFees) / props.data.conversion_ratio;
    return grandTotalAmount;
  };
  const grandTotalNGN = (props) => {
    let grandTotalUsd = grandTotal(props);
    return grandTotalUsd * props.data.conversion_ratio;
  };

  const transactionCurrency = (props) => {
    let currency = props.data.currency;
    return currency;
  };

  return (
    <ReactModal open={props.open} onClose={props.onClose}>
      <div className=''>
        <div className='row'>
          <div className='col-10'>
            <img
              src='/dist/img/logo.3fc64c3.png'
              alt='AtaraPay Logo'
              style={{ opacity: 1, width: '200px', paddingLeft: 15 }}
            />
          </div>
        </div>
        <br />
        <div className='container'>
          <p>
            Order ID: <strong>{props.orderId}</strong>
          </p>
          <p>
            Your AtaraPay email is <strong>{props.data.buyer_email}</strong>
          </p>
          <p>
            Your AtaraPay phone number is{' '}
            <strong>{props.data.original_buyer_phone}</strong>
          </p>
          <p>Alternative Recipient Phone Number </p>
          <PhoneInput
            defaultCountry='NG'
            placeholder='080X XXX XXXX'
            value={
              (props.data &&
                formatPhoneNumber(props.seller_phone, 'National')) ||
              formatPhoneNumber(props.seller_phone, 'International')
            }
            inputClassName={'text-alt'}
            onChange={(value) => props.handlePhone(value)}
          />
          <p>
            <small>
              [Please enter this number if you will not be able to receive the
              item(s) <br />
              in person. Ensure to update the international code accordingly]
            </small>
          </p>
          <p>
            You will be charged escrow fee of{' '}
            {toLocaleDisplay(escrowFees(props), transactionCurrency(props))}{' '}
            {props.data &&
            props.data.tx_fee_bearer &&
            props.data.tx_fee_bearer !== 'seller'
              ? 'plus Switch fee of ' +
                toLocaleDisplay(cardFees(props), transactionCurrency(props))
              : ' '}{' '}
            and will be depositing{' '}
            {toLocaleDisplay(netAmount(props), transactionCurrency(props))} plus{' '}
            {toLocaleDisplay(escrowFees(props), transactionCurrency(props))}{' '}
            {props.data &&
            props.data.tx_fee_bearer &&
            props.data.tx_fee_bearer !== 'seller'
              ? 'plus ' +
                toLocaleDisplay(cardFees(props), transactionCurrency(props))
              : ' '}{' '}
            into escrow today. When you accept the item, we will credit the
            merchant with{' '}
            {toLocaleDisplay(netAmount(props), transactionCurrency(props))}. If
            you reject the item,{' '}
            {props?.transactionData?.sellers?.delivery_terms &&
            props?.transactionData?.sellers?.delivery_terms.refund_option === 0
              ? 'you shall get full replacement for them.'
              : 'we will refund you ' +
                toLocaleDisplay(
                  refundableAmount(props),
                  transactionCurrency(props)
                ) +
                ' within 24 hours.'}
          </p>
          <blockquote>
            {props?.transactionData?.sellers ? (
              <>
                Seller's Delivery Terms:
                {props?.transactionData?.sellers?.delivery_terms &&
                props?.transactionData?.sellers?.delivery_terms
                  ?.refund_option === 0 ? (
                  <ul>
                    <li className='text-danger'>
                      Seller supports full replacements. No refund allowed
                    </li>
                  </ul>
                ) : (
                  <div>
                    <p>
                      A logistics fee of{' '}
                      <span className={'text-danger'}>
                        <b>
                          {props.transactionData.sellers &&
                            props.transactionData.sellers.delivery_terms &&
                            toLocaleDisplay(
                              logisticFees(props),
                              transactionCurrency(props)
                            )}
                        </b>
                      </span>{' '}
                      will be charged from your escrow deposit, if you;
                    </p>
                    <ul>
                      <li>
                        Cancel after{' '}
                        {(props.transactionData.sellers &&
                          props.transactionData.sellers.delivery_terms &&
                          props.transactionData.sellers.delivery_terms.SLA ===
                            '0.5') ||
                        (props.transactionData.sellers &&
                          props.transactionData.sellers.delivery_terms &&
                          props.transactionData.sellers.delivery_terms.SLA <
                            1.0)
                          ? props.transactionData.sellers &&
                            props.transactionData.sellers.delivery_terms &&
                            props.transactionData.sellers.delivery_terms.SLA *
                              60 +
                              ' minutes'
                          : props.transactionData.sellers &&
                            props.transactionData.sellers.delivery_terms &&
                            props.transactionData.sellers.delivery_terms.SLA +
                              ' hours'}
                      </li>
                      {/* <li>Reject at no fault of Seller</li> */}
                      <li>Refund will be made less applicable fees</li>
                    </ul>
                  </div>
                )}
              </>
            ) : (
              <div>
                <p>
                  A logistics fee of{' '}
                  <span className={'text-danger'}>
                    <b>{toLocaleDisplay(0.0, transactionCurrency(props))}</b>
                  </span>{' '}
                  will be charged from your escrow deposit, if you;
                </p>
                <ul>
                  <li>Cancel after 0 minutes</li>
                  <li>Refund will be made less applicable fees</li>
                </ul>
              </div>
            )}
          </blockquote>

          <p>
            After making successful payment, please complete your AtaraPay
            registration from the link sent to your email address.
          </p>
          <p>
            Please note that maximum delivery days of the item(s) is{' '}
            {Number(props.data.max_delivery_days)} days after which if the order
            is not accepted or rejected, your funds will be refunded less escrow
            fees.
          </p>
          <p>
            For details on our escrow service click{' '}
            <a
              rel='noopener noreferrer'
              target='_blank'
              href={'https://www.atarapay.com/why_escrow'}
            >
              here
            </a>
          </p>
          <p className={'text-danger'}>
            <small>
              Your funds are insured by a NAICOM-licensed insurance company.
            </small>
          </p>
        </div>
        <div className='clearfix'>
          <div className='form-inline float-left'>
            <div className='form-group'>
              <input
                type='text'
                ref={promoInput}
                disabled={props.promo && props.promo.promo_code}
                name='promo'
                id='promo_code'
                placeholder='Promo code'
                className='form-control col-sm-3 col-md-6 ml-3 mb-2'
                style={{ width: '125px', fontSize: '11px' }}
              />

              {props.promo && props.promo.promo_code ? (
                <button
                  className='btn btn-link col-md-3 col-sm-3 ml-1 mb-2 text-danger'
                  style={{ paddingLeft: 5, textDecoration: 'none' }}
                  onClick={() => props.clearCoupon(promoInput)}
                >
                  Clear
                </button>
              ) : (
                <button
                  type='button'
                  className='btn btn-link col-md-3 col-sm-3 ml-1 mb-2'
                  style={{
                    paddingLeft: 5,
                    color: '#007bff',
                    textDecoration: 'none',
                  }}
                  onClick={() => props.applyCoupon(promoInput)}
                >
                  Apply
                </button>
              )}
            </div>
          </div>
          <div className='float-right'>
            <button
              onClick={props.onClose}
              className='btn btn-md btn-danger mb-2'
            >
              {' '}
              Cancel
            </button>
            &nbsp;&nbsp;
            <button
              onClick={props.pay}
              className='btn btn-md btn-info mb-2'
              disabled={props.isPaying}
            >
              <i className='fa fa-coins'></i>
              Yes, Desposit{' '}
              {toLocaleDisplay(grandTotal(props), transactionCurrency(props))}
              {transactionCurrency(props) === 'USD'
                ? ' (' + toLocaleDisplay(grandTotalNGN(props), 'NGN') + ')'
                : ''}
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

const formatToSixDigits = (number) => {
  var output = number + '';
  while (output.length < 6) {
    output = '0' + output;
  }
  return output;
};

const displayCustomer = (text) => {
  if (text === 'customer') {
    return 'Buyer';
  }
  return text;
};

const toLocaleDisplay = (quantity, curency = 'NGN') => {
  if (quantity === undefined) quantity = 0;

  return quantity.toLocaleString(undefined, {
    style: 'currency',
    currency: curency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

class EasyP2P extends Component {
  state = {
    otp: null,
    step: 1,
    data: {
      // Preset values
      currency: 'NGN',
      conversion_ratio: 1,
      buyer_phone: '',
      buyer_email: '',
      seller_phone: '',
      seller_email: '',
      amount_payed: 0,
      contract_files: null,
      escrow_fee_bearer: 'customer',
      product_desc: '',
      product_name: '',
      max_delivery_days: 0,

      // Fixed values. These will never change
      shipping_method: 0,
      shipping_cost: 0,
      shipping_fee_bearer: '',
      delivery_location: 'As agreed offline between both parties',
      quantity: 1,
      easyp2p: 1,
      is_additional_services: 0,
      additional_services: [],
      extra: {},
      recipient: {},
      category: 'General Transaction',
      type: 2,
    },
    customer: null,
    cust_id: null,
    seller_details_error: false,

    alt: '',

    device_type: 'browser',
    device_version: '0.1',
    customer_exists: false,
    buyer_exists: false,
    disable_seller: false,

    promo: null,

    transaction_created: false,
    transactionData: null,

    confirmTransactionData: null,

    verifyModalOpen: false,
    confirmTransactionModalOpen: false,

    seller_exists: null,
    otpSent: false,
    otpVerified: false,
    enteredOtp: '',
    resendOtp: false,
    enteredPassword: '',

    otpLoading: false,
    paymentLoading: false,
    creatingTransaction: false,

    step2AfterOtp: false,
    terms: '',

    confirmation_token: null,
    doPayment: false,
  };

  constructor(props) {
    super(props);

    if (!this.props.sellerLink && this.props.match.params) {
      this.state = {
        ...this.state,
        data: {
          ...this.state.data,
          buyer_phone: this.props.match.params.phone || '',
          buyer_email: this.props.match.params.email || '',
        },
      };
      console.log('datalog', this.state.data);
    }

    if (this.props.sellerLink && this.props.match.params) {
      this.state = {
        ...this.state,
        data: {
          ...this.state.data,
          seller_phone: this.props.match.params.phone,
          seller_email: this.props.match.params.email,
        },
        disable_seller: true,
      };
      console.log('datalog', this.state.data);
    }
  }

  componentDidMount() {
    const { email, phone } = this.props.match.params;
    this.checkBuyerExists(
      phone,
      email,
      this.props.sellerLink,
      this.state.otpVerified
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isEasyP2PLogin !== prevProps.isEasyP2PLogin) {
      this.setState({ buyer_exists: false });
    }
    if (this.props.user !== prevProps.user) {
      this.setState({ cust_id: this.props.user.id });
    }

    if (prevProps.term !== this.props.term) {
      this.setState({ terms: this.props.term });
    }

    if (
      prevProps.match.params.phone !== this.props.match.params.phone ||
      prevProps.match.params.email !== this.props.match.params.email
    ) {
      if (
        this.props.match.params.phone === undefined ||
        this.props.match.params.email === undefined
      ) {
        this.setState({
          verifyModalOpen: false,
        });
      } else {
        this.setState({
          verifyModalOpen: true,
          data: {
            ...this.state.data,
            buyer_email: this.props.match.params.email,
            buyer_phone: this.props.match.params.phone,
          },
        });
      }
    }
  }

  browserHandler = {
    default: (browser) => {
      this.setState({
        device_type: browser,
      });
      return null;
    },
  };

  checkBuyerExists = (phone, email, sellerLink, otpVerified) => {
    axios({
      method: 'post',
      mode: 'no-cors',
      url: `${BASE_URL}customer/checkBuyer`,
      data: { email: phone },
      headers: {
        'Access-Control-Allow-Origin': '*',
        // 'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      },
    })
      .then((data) => {
        if (
          data?.data?.message == 'customer_exist' &&
          !data?.data?.data?.firstTime
        )
          return this.setState({ buyer_exists: true });

        if (!sellerLink && email && phone && !otpVerified) {
          this.setState({
            verifyModalOpen: true,
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  handleLogin = (e) => {
    e.preventDefault();
    // const { dispatch } = this.props;
    const { phone } = this.props.match.params;
    const credentials = { email: phone, password: this.state.enteredPassword };

    this.props.login(credentials, 'customer', this.props.history);
  };

  handleCheckUser = (phone_number) => {
    this.setState({
      seller_details_error: false,
    });

    axios({
      method: 'post',
      mode: 'no-cors',
      url: `${BASE_URL}seller/checkUserRecord`,
      data: {
        phone_number: this.state.data.seller_phone,
        email:
          this.state.data.seller_email !== ''
            ? this.state.data.seller_email
            : null,
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        // 'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      },
    })
      .then(({ data }) => {
        console.log(data);
        if (data.status == 'success') {
          if (data.message == 'account_exists') {
            this.setState({
              seller_exists: true,
              data: {
                ...this.state.data,
                seller_email: data.data.email,
              },
            });
          } else {
            this.setState({
              seller_exists: false,
            });
          }
        } else {
          if (data.message == 'seller_details_mismatch') {
            swal(
              'Error',
              'There is already an account with this email address but the phone number does not match',
              'error'
            );
            this.setState({
              seller_exists: false,
              seller_details_error: true,
            });
          }

          this.setState({
            seller_exists: false,
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  completeStepOne = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!this.state.otpVerified && !this.props.loggedIn) {
      this.setState(
        {
          step2AfterOtp: true,
        },
        () => {
          this.openVerifyModal();
        }
      );
      return;
    }

    const details = transactionHelpers.formatTransactionData({
      ...this.state.data,
      role: 'customer',
      cust_id: this.state.cust_id,
      max_delivery_days:
        this.state.data.max_delivery_days === 0
          ? 100
          : this.state.data.max_delivery_days,
    });

    transactionSchema
      .validate({ ...details, extra: JSON.parse(details.extra) })
      .then(() => {
        // confirm transaction details

        this.setState({
          step: 2,
          // confirmTransactionModalOpen: true,
          confirmTransactionData: transactionHelper.rformatTransactionData({
            ...this.state.data,
            amount_net:
              this.state.data.amount_payed *
              100 *
              this.state.data.conversion_ratio,
            additional_service_amount: 0,
            shipping_cost: 0,
            tx_fee_bearer: 'seller',

            original_buyer_phone: this.state.data.buyer_phone,
            original_seller_phone: this.state.data.seller_phone,
          }),
        });
      })
      .catch((error) => {
        swal('', error.errors[0], 'error');
        return this.setState({ error: '' });
      });
  };

  confirmTransaction = () => {
    this.setState({
      creatingTransaction: true,
    });

    this.createTransaction((data) => {
      this.setState({
        step: 3,
        transaction_created: true,
        transactionData: transactionHelper.rformatTransactionData(data),
        confirmTransactionModalOpen: false,
        doPayment: true,
      });
    });
  };

  doPayment = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      paymentLoading: true,
    });

    const data = {
      order_id: this.state.transactionData.id,
      alt: this.state.alt,
      cust_id: this.state.cust_id,
    };

    if (this.state.promo && this.state.promo.promo_code) {
      data.promo_code = this.state.promo.promo_code;
    }

    let paymentType = 'payment';

    if (this.state.transactionData.gateway_name === 'USSD') {
      paymentType = 'ussdPayment';
    }

    axios({
      method: 'post',
      mode: 'no-cors',
      url: `${BASE_URL}customer/easyTransaction/${paymentType}`,
      data: data,
      headers: {
        'Access-Control-Allow-Origin': '*',
        // 'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      },
    })
      .then(({ data: res }) => {
        console.log('payment_data', res.data, paymentType);

        if (res.status == 'error') {
          swal(
            'Error',
            `Error creating payment link. Error: ${res.message}`,
            'error'
          );
          return;
        }

        if (paymentType === 'ussdPayment') {
          swal({
            title: 'Success',
            icon: 'success',
            content: (
              <div>
                <p>
                  Thank you for choosing AtaraPay. An email has been sent to
                  your registered AtaraPay email address with the details
                  below.This order will be valid for 48 hours from when you
                  placed the order until {res.data.auth.responseBody.expiryDate}
                  . The order will be cancelled if payment made is less than the
                  expected total amount.
                </p>
                <p>
                  To complete your purchase, please make payment via USSD or
                  Bank Transfer.
                </p>
              </div>
            ),
            buttons: {
              Cancel: {
                text: 'Pay Later',
                value: 'later',
                className: 'btn-info',
              },
              Confirm: {
                text: 'Click to Pay Now',
                value: 'catch',
                className: 'btn-success',
              },
            },
          }).then((value) => {
            switch (value) {
              case 'catch':
                let checkoutUrl = res.data.auth.responseBody.checkoutUrl;

                if (checkoutUrl != undefined) {
                  window.location.href = checkoutUrl;
                } else {
                  console.log(res.data);
                }
                break;

              case 'later':
                window.location.href = 'http://atarapay.com';
              default:
                return;
            }
          });
        } else {
          let checkoutUrl = res.data.auth.authorization_url;

          if (checkoutUrl != undefined) {
            window.location.href = checkoutUrl;
          } else {
            console.log(res.data);
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => {
        this.setState({
          paymentLoading: false,
        });
      });
  };

  createCustomer = (callback) => {
    this.setState({
      otpLoading: true,
    });

    const customerData = {
      reg_type: 'easy_p2p',
      easy_p2p: 1,
      email: this.state.data.buyer_email,
      phone_number: this.state.data.buyer_phone,
      device_type: this.state.device_type,
      device_version: this.state.device_version,
    };

    const customerSchema = yup.object().shape({
      email: yup
        .string()
        .email('Please provide a valid email')
        .required("Please provide buyer's email address"),
      phone_number: yup
        .string()
        .required("Please provide buyer's phone number"),
      easy_p2p: yup.number().required(),
      reg_type: yup.string().required(),
      device_type: yup.string().required(),
    });

    customerSchema
      .validate(customerData)
      .then(() => {
        axios({
          method: 'post',
          mode: 'no-cors',
          url: `${BASE_URL}customer/register`,
          data: customerData,
          headers: {
            'Access-Control-Allow-Origin': '*',
            // 'Authorization': 'Bearer ' + localStorage.getItem("user"),
            'Content-Type': 'application/json',
          },
        })
          .then(({ data }) => {
            console.log(data);

            if (data.message == 'customer_registered') {
              this.setState(
                {
                  customer: data.data.customer,
                  cust_id: data.data.customer.id,
                  confirmation_token: data.data.code,
                },
                () => {
                  if (callback) {
                    callback();
                  }
                }
              );
            } else if (data.message == 'customer_already_exist') {
              this.setState({
                customer_exists: true,
                otpLoading: false,
                verifyModalOpen: false,
              });
            } else {
              swal('Error', 'Error creating customer', 'error');
            }
          })
          .catch((err) => {
            console.log(err, err.response);
            swal('Error', 'Error registering account', 'error');

            this.setState({
              otpLoading: false,
            });
          });
      })
      .catch((err) => {
        swal('', err.errors[0], 'error');
        return this.setState({ error: '' });
      });
  };

  createTransaction = async (callback) => {
    const details = transactionHelper.formatTransactionData({
      ...this.state.data,
      cust_id: this.state.cust_id,
      max_delivery_days:
        this.state.data.max_delivery_days === 0
          ? 100
          : this.state.data.max_delivery_days,
    });

    var formData = new FormData();
    // const data = this.state.confirmTransactionData
    for (var x in details) {
      formData.append(x, details[x]);
    }

    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    axios({
      method: 'post',
      mode: 'no-cors',
      url: `${BASE_URL}customer/easyTransaction/create`,
      data: formData,
      headers: {
        'Access-Control-Allow-Origin': '*',
        // 'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        if (data.status == 'success') {
          if (data.message == 'transaction_created') {
            if (callback) {
              console.log(data.data);
              callback(data.data);
            }
          }
        } else {
          console.log(data);
        }
      })
      .catch((err) => {
        swal('Error', `Could not create the transaction`, 'error');
      })
      .finally(() => {
        this.setState({
          creatingTransaction: false,
        });
      });
  };

  handleChange = (e) => {
    const { value, name } = e.target;

    const data = {
      ...this.state.data,
      [name]: value,
    };

    this.setState({
      data,
    });
  };

  handleCurrencyChange = (event) => {
    let Currency = event.currentTarget.getAttribute('Currency');

    // let paymentMethod = '';
    // let conversionRatio = 1;

    if (Currency == 'USD') {
      this.setState(
        {
          data: {
            ...this.state.data,
            amount_payed: 0,
            payment_method: 'Paystack',
            currency: Currency,
          },
        },
        () => {
          this.getconversion((conversion_ratio) => {
            console.log(conversion_ratio);
            this.setState({
              data: {
                ...this.state.data,
                conversion_ratio,
              },
            });
          });
        }
      );
    } else {
      this.setState({
        data: {
          ...this.state.data,
          amount_payed: 0,
          currency: Currency,
          conversion_ratio: 1,
        },
      });
    }

    // let data = {
    //     ...this.state.data,
    //     amount_payed: 0,
    //     payment_method: paymentMethod,
    //     currency: Currency,
    //     conversion_ratio: conversionRatio
    // }
    // this.setState({data});
  };

  async getconversion(callback) {
    const resp = await fetch('https://api.atarapay.com/api/abokiExchangeRate');
    const result = await resp.json();
    // let data = {...this.state.data, conversion_ratio: (result.quotes.USDNGN).toFixed(2)}

    callback(result.data.USDNGN.toFixed(2));

    // this.setState({data});
  }

  handleFileChange = (e) => {
    const { files } = e.target;

    if (files.length > 0) {
      if (files[0].size > 4 * 1024 * 1024) {
        swal('Error', 'file size must be less than 4mb', 'error');
        this.fileRef.value = null;

        return;
      }

      this.setState({
        data: {
          ...this.state.data,
          contract_files: files[0],
        },
      });
    }
  };

  handlePhone = (value, name) => {
    let data = { ...this.state.data, [name]: value };
    this.setState({ data });
  };

  openVerifyModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      verifyModalOpen: true,
    });
  };

  closeVerifyModal = () => {
    this.setState({
      verifyModalOpen: false,
    });
  };

  sendOtp = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (this.state.data.buyer_phone == '') {
      swal('Error', 'Please fill in your phone number', 'error');
      return;
    }

    if (this.state.data.buyer_email == '') {
      swal('Error', 'Please fill in your email address', 'error');
      return;
    }

    this.createCustomer(this.sendCode);
  };

  sendCode = (e) => {
    if (this.state.data.buyer_phone == '') {
      swal('Error', 'Please fill in your phone number', 'error');
      return;
    }

    if (this.state.data.buyer_email == '') {
      swal('Error', 'Please fill in your email address', 'error');
      return;
    }

    if (e) {
      e.preventDefault();
    }
    this.setState({
      otpLoading: true,
    });
    // send otp
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}customer/sendToken/${this.state.confirmation_token}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        // 'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        this.setState({
          otpSent: true,
          resendOtp: false,
        });
      })
      .catch((err) => {
        swal('Error', 'Error sending OTP. Please try again later', 'error');
      })
      .finally(() => {
        this.setState({
          otpLoading: false,
        });
      });
  };

  verifyOtp = (e) => {
    e.preventDefault();

    this.setState({
      otpLoading: true,
    });

    let otpVerified = 0;

    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}customer/confirmation/${this.state.confirmation_token}/${this.state.enteredOtp}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        // 'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      },
    })
      .then(({ data }) => {
        console.log(data);

        if (data.status == 'error') {
          otpVerified = true;
          swal('Error', 'OTP Not Verified', 'error');
        } else {
          if (data.message && data.message == 'token_expired') {
            swal(
              'OTP Expired',
              'Your OTP expired. Please request another OTP and enter it within 10 minutes',
              'error'
            );

            this.setState(
              {
                resendOtp: true,
              },
              () => {}
            );
          } else if (data.message && data.message == 'customer_verified') {
            swal({
              title: 'Success!',
              text: 'OTP Verified',
              icon: 'success',
              timer: 1200,
            });

            this.setState(
              {
                otpVerified: true,
                verifyModalOpen: false,
              },
              () => {
                // go to step 2 after verifying otp
                if (this.state.step2AfterOtp) {
                  this.completeStepOne();
                }
              }
            );
          }
        }
      })
      .catch(() => {
        swal('Error', 'OTP Not Verified', 'error');
      })
      .finally(() => {
        this.setState({
          otpLoading: false,
        });
      });
  };

  applyPromo = (promoInput) => {
    const promoData = {
      transaction_id: this.state.transactionData.id,
      promo_code: promoInput.current.value,
      customer_phone: this.state.data.buyer_phone,
      seller_phone: this.state.data.seller_phone,
    };

    axios({
      method: 'post',
      mode: 'no-cors',
      url: `${BASE_URL}promo/verify_promocode`,
      data: promoData,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.data.status === 'success') {
          swal('Success', 'Promo Code Applied', 'success');
          this.setState({
            promo: res.data.data,
          });
        } else {
          if (res.data.message.includes('promo_code_invalid')) {
            swal('Failure', 'Promo Code Invalid', 'error');
          } else if (res.data.message.includes('promo_code_already_expired')) {
            swal('Failure', 'Promo code has already expired', 'error');
          }
        }
      })
      .catch((err) => {
        swal('Error', 'Promo code not valid', 'error');

        console.log(err);
        if (err) {
          console.log(err.response);
        }
      });
  };

  render() {
    const { step, data, terms, transactionData, confirmTransactionData } =
      this.state;
    const toInternationalFormat = (phone = '', prefix = '+234') => {
      return prefix + phone.toString().substring(1);
    };
    return (
      <>
        <BrowserDetection>{this.browserHandler}</BrowserDetection>
        <Modal
          open={this.state.confirmTransactionModalOpen}
          onClose={() => {
            this.setState({
              confirmTransactionModalOpen: false,
            });
          }}
        >
          <div
            style={{
              minWidth: 500,
              maxWidth: '100%',
            }}
          ></div>
        </Modal>

        <Modal
          open={this.state.buyer_exists}
          showCloseIcon={false}
          closeOnOverlayClick={false}
          onClose={() => {
            this.setState({
              buyer_exists: false,
            });
          }}
        >
          <div className='row'>
            <div className='col-md-12'>
              <h5>Account exists</h5>
              <div className='mb-3'>
                You are already registered on the platform, Please login below
              </div>
            </div>
          </div>
          <form onSubmit={this.handleLogin}>
            <div className='row'>
              <div className='col-sm-12 col-md-12'>
                <div className='form-group'>
                  <label>Password</label> <br />{' '}
                  <input
                    type='password'
                    value={this.state.enteredPassword}
                    name='enteredPassword'
                    className='form-control'
                    onChange={(e) =>
                      this.setState({ enteredPassword: e.target.value })
                    }
                  />
                </div>

                <button type='submit' className='btn btn-primary w-100'>
                  {this.props.loading ? 'Please wait...' : 'Submit'}
                </button>
                <div>
                  <Link to={'/forgot/buyer'} target='_blank'>
                    Forgot password?
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </Modal>

        <Modal
          open={this.state.customer_exists}
          onClose={() => this.setState({ customer_exists: false })}
        >
          <div
            style={{
              width: 420,
              maxWidth: '100%',
              minHeight: 100,
            }}
          >
            <h5>Account exists</h5>
            You are already registered on the platform. <br /> Please{' '}
            <Link to={'/login/buyer'}>log in</Link> to create a transaction
          </div>
        </Modal>
        <Modal
          open={this.state.verifyModalOpen}
          onClose={this.closeVerifyModal}
          closeOnOverlayClick={false}
          center
          showCloseIcon={false}
        >
          <div
            style={{
              width: 420,
              maxWidth: '100%',
            }}
          >
            {this.state.otpLoading ? (
              <div className={'text-center'}>
                <ClipLoader loading={true} size={100} />
                <div>Please wait. This will take a few seconds.</div>
              </div>
            ) : (
              <>
                <div className='row'>
                  <div className='col-md-12'>
                    <h5>Verify Phone Number</h5>
                    <div className='mb-3'>
                      {!this.state.otpSent
                        ? 'Please confirm that your phone number is correct. We will send an SMS OTP to this phone number.'
                        : `Enter the OTP sent to your phone number`}
                    </div>
                  </div>
                </div>
                {!this.state.otpSent || this.state.resendOtp ? (
                  <form
                    onSubmit={(e) => {
                      if (this.state.resendOtp) {
                        this.sendCode(e);
                      } else {
                        this.sendOtp(e);
                      }
                    }}
                  >
                    <div className='row'>
                      <div className='col-sm-12 col-md-12'>
                        <div className='form-group'>
                          <PhoneInput
                            defaultCountry='NG'
                            placeholder='080X XXX XXXX'
                            value={data.buyer_phone}
                            disabled={this.state.resendOtp}
                            onChange={(value) => {
                              this.handlePhone(
                                (value &&
                                  formatPhoneNumber(
                                    value,
                                    'International'
                                  ).replace(/ /g, '')) ||
                                  '',
                                'buyer_phone'
                              );
                            }}
                          />
                        </div>

                        <button type='submit' className='btn btn-primary'>
                          {this.state.resendOtp
                            ? 'Resend OTP'
                            : 'Confirm Phone Number'}
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div>
                    <form onSubmit={this.verifyOtp}>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='form-group'>
                            <input
                              type='text'
                              value={this.state.enteredOtp}
                              name='enteredOtp'
                              onChange={(e) =>
                                this.setState({
                                  enteredOtp: e.target.value,
                                })
                              }
                              className='form-control'
                            />
                          </div>
                          <button
                            className='btn btn-primary'
                            onClick={this.verifyOtp}
                          >
                            Submit
                          </button>

                          <button
                            className={'btn btn-danger ml-2'}
                            onClick={(e) => {
                              e.preventDefault();

                              this.setState(
                                {
                                  resendOtp: true,
                                },
                                () => this.sendCode()
                              );
                            }}
                          >
                            Resend OTP
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal>

        <div className='container'>
          <div className='card' style={{ marginTop: 100 }}>
            <div className='card-body'>
              <div className='content-header'>
                <div className='container-fluid'>
                  <div className='row mb-2'>
                    <div className='col-sm-6'>
                      <h1 className='m-0 text-dark'>New P2P Transaction</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='pl-4 pr-4 mb-3'
                style={{
                  display: 'flex',
                }}
              >
                <Tab active={step === 1}>01 - Transaction Details</Tab>
                <Tab active={step === 2}>02 - Review Transaction</Tab>
                <Tab active={step === 3}>03 - Complete Payment</Tab>
                <div style={{ clear: 'both' }} />
              </div>
              {step === 1 && (
                <section>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-sm-6'>
                        <small>
                          (<span className='text-danger'>*</span>) indicates
                          mandatory field{' '}
                        </small>
                        <br />
                        <label>
                          Your Phone Number:{' '}
                          <span className='text-danger'>*</span>
                        </label>

                        <PhoneInput
                          name={'buyer_phone'}
                          defaultCountry='NG'
                          placeholder='080X XXX XXXX'
                          value={data.buyer_phone}
                          disabled={
                            this.state.otpVerified ||
                            this.state.otpSent ||
                            this.props.loggedIn
                          }
                          onChange={(value) => {
                            this.handlePhone(
                              (value &&
                                formatPhoneNumber(
                                  value,
                                  'International'
                                ).replace(/ /g, '')) ||
                                '',
                              'buyer_phone'
                            );
                          }}
                        />
                      </div>
                      <div className='col-sm-6'>
                        <div className='callout callout-help'>
                          Your phone number. You will be required to verify this
                          phone number through an OTP
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className='row'>
                      <div className='col-sm-6'>
                        <label>
                          Your Email Address:{' '}
                          <span className='text-danger'>*</span>
                        </label>
                        <input
                          type='email'
                          placeholder=''
                          className='form-control'
                          value={data.buyer_email}
                          onChange={this.handleChange}
                          disabled={
                            this.state.otpVerified ||
                            this.state.otpSent ||
                            this.props.loggedIn
                          }
                          name='buyer_email'
                        />
                      </div>
                      <div className='col-sm-6'>
                        <div className='callout callout-help'>
                          Email address of buyer
                        </div>
                      </div>
                    </div>
                    <hr />
                    {data.buyer_phone &&
                      data.buyer_email &&
                      !this.props.loggedIn &&
                      !this.state.otpVerified && (
                        <>
                          <div className='row'>
                            <div className='col-sm-6'>
                              <button
                                className={'btn btn-primary mt-2'}
                                onClick={this.openVerifyModal}
                              >
                                {this.state.otpSent
                                  ? 'Enter OTP'
                                  : 'Verify Phone Number'}
                              </button>
                            </div>

                            <div className='col-sm-6'>
                              <div className='callout callout-help'>
                                You will be required to verify your phone number
                                before you can make a transaction.
                              </div>
                            </div>
                          </div>
                          <hr />
                        </>
                      )}
                    <div className='row'>
                      <div className='col-sm-6'>
                        <label>
                          Seller Phone Number:{' '}
                          <span className='text-danger'>*</span>
                        </label>
                        <PhoneInput
                          defaultCountry='NG'
                          placeholder='080X XXX XXXX'
                          value={data.seller_phone}
                          name='seller_phone'
                          disabled={this.state.disable_seller}
                          onChange={(value) => {
                            this.handlePhone(
                              (value &&
                                formatPhoneNumber(
                                  value,
                                  'International'
                                ).replace(/ /g, '')) ||
                                '',
                              'seller_phone'
                            );

                            if (this.timer) {
                              clearTimeout(this.timer);
                            }
                            this.timer = setTimeout(
                              () => this.handleCheckUser(),
                              2000
                            );
                          }}
                        />

                        {this.state.seller_details_error && (
                          <span className='text-danger'>
                            <small>
                              Seller email and phone number does not match.
                            </small>
                          </span>
                        )}

                        {this.state.seller_exists === true &&
                          !this.state.seller_details_error && (
                            <div className='text-success'>
                              <div>
                                <small>User exists as seller</small>
                              </div>
                              <div>
                                <small>
                                  On submission of this form, a notification of
                                  this transaction will be sent to the email
                                  address that exists against the number entered
                                  above
                                </small>
                              </div>
                            </div>
                          )}
                        {this.state.seller_exists === false &&
                          !this.state.seller_details_error && (
                            <span className='text-info'>
                              <small>
                                User is not registered with us. The seller will
                                receive an email with instructions to register
                              </small>
                            </span>
                          )}
                      </div>
                      <div className='col-sm-6'>
                        <div className='callout callout-help'>
                          This number will be used to inform the seller about a
                          new transaction. Please note this number cannot be
                          edited after successful transaction creation
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className='row'>
                      <div className='col-sm-6'>
                        <label>
                          Seller Email Address:{' '}
                          <span className='text-danger'>*</span>
                        </label>
                        <input
                          type='email'
                          placeholder=''
                          className='form-control'
                          value={data.seller_email}
                          onChange={this.handleChange}
                          name='seller_email'
                          disabled={
                            this.state.seller_exists === true ||
                            this.state.disable_seller
                          }
                          onKeyUp={() => {
                            if (this.timer) {
                              clearTimeout(this.timer);
                            }
                            this.timer = setTimeout(
                              () => this.handleCheckUser(),
                              2000
                            );
                          }}
                        />
                      </div>
                      <div className='col-sm-6'>
                        <div className='callout callout-help'>
                          Enter Seller's Email Address
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className='row all-center'>
                      <div className='col-sm-6'>
                        <label>
                          Transaction Title:{' '}
                          <span className='text-danger'>*</span>
                        </label>
                        <input
                          className='form-control'
                          name='product_name'
                          onChange={this.handleChange}
                          value={data.product_name}
                        />
                      </div>
                      <div className='col-sm-6'>
                        <br />
                        <div className='callout callout-help'>
                          A short description of the item to be purchased or
                          sold. For example, "Buying Mercedes Benz.
                        </div>
                      </div>
                    </div>
                    <br />
                    <hr />
                    <div className='row all-center'>
                      <div className='col-sm-6'>
                        <label>
                          Product Description and Terms of Service:{' '}
                          <span className='text-danger'>*</span>
                        </label>
                        <textarea
                          className='form-control'
                          name='product_desc'
                          maxLength='5000'
                          onChange={this.handleChange}
                          value={data.product_desc}
                        ></textarea>
                      </div>
                      <div className='col-sm-6'>
                        <br />
                        <div className='callout callout-help'>
                          Please provide detailed description of the product or
                          service and any terms of service that should be
                          adhered to during the transaction. (Up to 5,000
                          characters)
                        </div>
                      </div>
                    </div>
                    <br />
                    <hr />
                    <div className='row'>
                      <div className='col-sm-6'>
                        <label>
                          Who pays the Escrow fee:{' '}
                          <span className='text-danger'>*</span>
                        </label>
                        <div className='radio'>
                          <input
                            type='radio'
                            name='escrow_fee_bearer'
                            checked={data.escrow_fee_bearer === 'customer'}
                            onChange={this.handleChange}
                            value='customer'
                          />
                          &nbsp;&nbsp;Buyer
                        </div>
                        <div className='radio'>
                          <input
                            type='radio'
                            name='escrow_fee_bearer'
                            checked={data.escrow_fee_bearer === 'seller'}
                            value='seller'
                            onChange={this.handleChange}
                          />
                          &nbsp;&nbsp;Seller
                        </div>
                        <div className='radio'>
                          <input
                            type='radio'
                            name='escrow_fee_bearer'
                            checked={data.escrow_fee_bearer === 'both'}
                            value='both'
                            onChange={this.handleChange}
                          />
                          &nbsp;&nbsp;50% Buyer / 50% Seller
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='callout callout-help'>
                          This identifies who is responsible for paying the
                          escrow fees. If the Buyer is selected, the fees are
                          added to the transaction total. If the Seller is
                          selected, the fees are deducted from the proceeds.
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className='row'>
                      <div className='col-sm-6'>
                        <label>
                          Total Price (Please also select currency):{' '}
                          <span className='text-danger'>*</span>
                        </label>
                        <div className='input-group mt-3 mb-3'>
                          <div className='input-group-prepend'>
                            <button
                              type='button'
                              id='currencySelector'
                              className='btn btn-outline-primary dropdown-toggle'
                              data-toggle='dropdown'
                            >
                              {data.currency === '' ? 'Select' : data.currency}
                            </button>

                            <div className='dropdown-menu'>
                              <a
                                className='dropdown-item'
                                href='javascript:void(0);'
                                onClick={this.handleCurrencyChange}
                                currency='NGN'
                              >
                                NGN
                              </a>
                              <a
                                className='dropdown-item'
                                href='javascript:void(0);'
                                onClick={this.handleCurrencyChange}
                                currency='USD'
                              >
                                USD
                              </a>
                            </div>
                          </div>
                          <NumberFormat
                            className='form-control'
                            defaultValue={0.0}
                            value={data.amount_payed}
                            isNumericString={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            name='amount_payed'
                            onChange={(e) => {
                              const event = {
                                ...e,
                                target: {
                                  ...e.target,
                                  value: e.target.value
                                    .replace(/NGN /g, '')
                                    .replace(/,/g, ''),
                                  name: e.target.name,
                                },
                              };
                              this.handleChange(event);
                            }}
                            thousandSeparator={true}
                            allowNegative={false}
                          />
                        </div>
                        {data.amount_payed * data.quantity <
                          1000 / data.conversion_ratio && (
                          <small className='text-danger'>
                            Important! Total Amount must not be less than{' '}
                            {data.currency}{' '}
                            {(1000 / data.conversion_ratio).toFixed(2)}
                          </small>
                        )}
                      </div>
                      <div className='col-sm-6'>
                        <div className='callout callout-help'>
                          Please select the currency and enter the price of the
                          item
                        </div>
                      </div>
                    </div>
                    <br />
                    <hr />
                    <div className='row'>
                      <div className='col-sm-6'>
                        <label>Maximum Delivery Days (Optional):</label>
                        <input
                          type='number'
                          name='max_delivery_days'
                          onChange={this.handleChange}
                          className='form-control'
                          value={this.state.data.max_delivery_days}
                        />
                      </div>
                      <div className='col-sm-6'>
                        <div className='callout callout-help'>
                          This is the number of days that merchant must deliver
                          the items by, failing which there shall be automatic
                          refund from escrow back to Buyer's account
                        </div>
                      </div>
                    </div>
                    <br />
                    <hr />
                    <div className='row all-center'>
                      <div className='col-sm-6'>
                        <label>Upload contract files (optional)</label>
                        {this.state.data.contract_files === null ? (
                          <input
                            type='file'
                            className='form-control'
                            name='contract_files'
                            accept={''}
                            onChange={this.handleFileChange}
                            multiple={false}
                            ref={(ref) => (this.fileRef = ref)}
                          />
                        ) : (
                          <div>
                            <div>
                              <strong>File</strong>:{' '}
                              {this.state.data.contract_files.name}
                              <br />
                              <strong>Size</strong>:{' '}
                              {(
                                this.state.data.contract_files.size /
                                1024 /
                                1024
                              ).toFixed(2)}{' '}
                              mb
                            </div>
                            <div className='mt-2'>
                              <a
                                href='#'
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    data: {
                                      ...this.state.data,
                                      contract_files: null,
                                    },
                                  });
                                }}
                                className='text-danger'
                              >
                                Remove File
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className='col-sm-6'>
                        <br />
                        <div className='callout callout-help'>
                          Optional: Files for the contract
                        </div>
                      </div>
                    </div>
                    <br />
                    <hr />
                    <div className='row all-center'>
                      <div className='col-sm-6'>
                        <label>
                          Payment Method: <span className='text-danger'>*</span>
                        </label>
                        <select
                          className='form-control'
                          name='payment_method'
                          defaultValue={data.payment_method}
                          onChange={this.handleChange}
                          value={data.payment_method}
                        >
                          <option value=''> Please Select One</option>
                          <option value='Paystack' id='optionPaystack'>
                            Paystack
                          </option>
                          {data.currency === 'NGN' && (
                            <option value='USSD' id='optionMonnify'>
                              Monnify
                            </option>
                          )}

                          {/* {data.currency == "USD" &&
                            terms.payout_currency == "USD" && (
                              <option value="Paypal" id="optionPaypal">
                                PayPal (USD Debit and Credit Payment)
                              </option>
                            )} */}
                        </select>
                      </div>
                      <div className='col-sm-6'>
                        <br />
                        <div className='callout callout-help'>
                          Please select payment method; Paystack, Monnify or
                          PayPal. Paystack and Monnify support NGN payments
                          while PayPal and Paystack support USD payments.
                        </div>
                      </div>
                    </div>
                    <br />
                    <hr />
                    {this.state.seller_details_error && (
                      <div className='row mb-3'>
                        <div className='col-md-12'>
                          <div className='text-danger'>
                            Seller Details don't match. Please enter the correct
                            seller information to continue
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='row'>
                      <div className='col-md-12'>
                        <button
                          className='btn btn-primary'
                          onClick={this.completeStepOne}
                          disabled={
                            this.state.paymentLoading ||
                            this.state.otpLoading ||
                            this.state.creatingTransaction
                            // ||!this.props.loggedIn
                          }
                        >
                          Step 2: Verify Details
                        </button>
                      </div>
                    </div>
                    <hr />
                    {/*<div className="row">*/}
                    {/*  <div className="col-sm-6"><label>Transaction category: <span className="text-danger">*</span> </label>*/}
                    {/*    <select className="form-control"*/}
                    {/*            name="category"*/}
                    {/*            value={"General Transaction"}>*/}
                    {/*      <option value={`General Transaction`}>General Transaction</option>*/}
                    {/*    </select>*/}
                    {/*  </div>*/}
                    {/*  <div className="col-sm-6">*/}
                    {/*    <div className="callout callout-help">*/}
                    {/*      Select a category that best describes the item.*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    {/*<hr/>*/}
                  </div>
                </section>
              )}
              {(step === 2 || step === 3) && (
                <section>
                  <ModalComponent
                    isPaying={this.state.paymentLoading}
                    open={this.state.doPayment}
                    onClose={() => {
                      this.setState({
                        doPayment: false,
                      });
                    }}
                    orderId={transactionData ? transactionData.id : null}
                    oldData={data}
                    transactionData={transactionData}
                    data={confirmTransactionData}
                    role={'customer'}
                    pay={this.doPayment}
                    applyCoupon={this.applyPromo}
                    promo={this.state.promo}
                    clearCoupon={(promoInput) => {
                      this.setState({
                        promo: null,
                      });

                      promoInput.current.value = null;
                    }}
                    handlePhone={(value) => {
                      this.setState({
                        alt: value,
                      });
                    }}
                  />

                  {this.state.creatingTransaction ? (
                    <div className={'text-center'}>
                      <ClipLoader loading={true} size={100} />
                      <div>Please wait. This will take a few seconds.</div>
                    </div>
                  ) : (
                    <>
                      <div
                        className='bg-header bg-callout text-center'
                        style={{ padding: 10 }}
                      >
                        <strong>Transaction Details</strong>
                      </div>
                      <div className='table-responsive'>
                        <table className='table'>
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Item Description</th>

                              <th className='text-center'>Quantity</th>
                              <th className='text-right' style={{ width: 170 }}>
                                Unit Price
                              </th>

                              <th className='text-right' style={{ width: 120 }}>
                                Total Price
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td className={'text-left'}>
                                {data.product_name}
                                <br />

                                <small>{data.product_desc}</small>
                              </td>
                              <td className='text-center'>{data.quantity}</td>

                              <td className='text-right'>
                                {toLocaleDisplay(
                                  confirmTransactionData.amount_payed /
                                    confirmTransactionData.conversion_ratio,
                                  confirmTransactionData.currency
                                )}
                              </td>
                              <td className='text-right'>
                                {toLocaleDisplay(
                                  (confirmTransactionData.amount_payed *
                                    confirmTransactionData.quantity) /
                                    confirmTransactionData.conversion_ratio,
                                  confirmTransactionData.currency
                                )}
                                {/*{toLocaleDisplay(((data.amount_payed * data.quantity) / data.conversion_ratio), data.currency)}*/}
                              </td>
                            </tr>

                            <tr>
                              <td />
                              <td />
                              <td />
                              <td className='text-right'>
                                <strong>Escrow Fee</strong>
                              </td>
                              <td className='text-right'>
                                {toLocaleDisplay(
                                  parseFloat(
                                    transactionHelper.calculatePayableEscrow(
                                      confirmTransactionData
                                    ) / confirmTransactionData.conversion_ratio
                                  ),
                                  confirmTransactionData.currency
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td />
                              <td />
                              <td />
                              <td className='text-right'>
                                <strong>Payment Switch Fees</strong>
                              </td>
                              <td className='text-right'>
                                {toLocaleDisplay(
                                  confirmTransactionData.tx_fee_bearer !==
                                    'seller'
                                    ? confirmTransactionData.payment_method ===
                                      'USSD'
                                      ? transactionHelper.calculateUSSDCardFee(
                                          confirmTransactionData,
                                          'customer'
                                        )
                                      : confirmTransactionData.currency ===
                                        'USD'
                                      ? transactionHelper.calculateUsdCardFee(
                                          confirmTransactionData,
                                          'customer'
                                        )
                                      : transactionHelper.calculateCardFee(
                                          confirmTransactionData,
                                          'customer'
                                        )
                                    : 0,
                                  confirmTransactionData.currency
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td />
                              <td />
                              <td />
                              <td className='text-right'>
                                <strong>Total</strong>
                              </td>
                              <td className='text-right'>
                                {toLocaleDisplay(
                                  eval(
                                    parseFloat(
                                      transactionHelper.calculateTransactionPayable(
                                        confirmTransactionData
                                      ) /
                                        confirmTransactionData.conversion_ratio
                                    ) +
                                      (confirmTransactionData.tx_fee_bearer !==
                                      'seller'
                                        ? confirmTransactionData.gateway_name ===
                                          'USSD'
                                          ? transactionHelper.calculateUSSDCardFee(
                                              confirmTransactionData,
                                              'customer'
                                            )
                                          : confirmTransactionData.currency ===
                                            'USD'
                                          ? transactionHelper.calculateUsdCardFee(
                                              confirmTransactionData,
                                              'customer'
                                            )
                                          : transactionHelper.calculateCardFee(
                                              confirmTransactionData,
                                              'customer'
                                            )
                                        : 0)
                                  ),
                                  confirmTransactionData.currency
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className='row'>
                        <div className='col-sm-12'>
                          <div
                            className='bg-header text-center'
                            style={{ padding: 10 }}
                          >
                            <strong>Terms</strong>
                          </div>
                        </div>
                      </div>
                      <div className='card' style={{ padding: 20 }}>
                        {step === 3 && transactionData && (
                          <div className='row'>
                            <div className='col-6 text-right'>
                              <strong>Order ID:</strong>
                            </div>
                            <div className='col-6 capitalize'>
                              {formatToSixDigits(transactionData.id)}
                            </div>
                          </div>
                        )}
                        <div className='row'>
                          <div className='col-6 text-right'>
                            <strong>Transaction Title:</strong>
                          </div>
                          <div className='col-6 capitalize'>
                            {confirmTransactionData.product_name}
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-6 text-right'>
                            <strong>Buyer:</strong>
                          </div>
                          <div className='col-6'>{data.buyer_email}</div>
                        </div>
                        <div className='row'>
                          <div className='col-6 text-right'>
                            <strong>Seller:</strong>
                          </div>
                          <div className='col-6'>
                            {data &&
                            data.sellers &&
                            data.sellers.individual !== 1
                              ? data &&
                                data.sellers &&
                                data.sellers.business_email
                              : data.seller_email}
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-6 text-right'>
                            <strong>Escrow Fee to be paid by:</strong>
                          </div>
                          <div className='col-6'>
                            <strong>
                              {displayCustomer(
                                confirmTransactionData.escrow_fee_bearer ===
                                  'both'
                                  ? 'Buyer and Seller'
                                  : confirmTransactionData.escrow_fee_bearer ===
                                    'seller'
                                  ? 'Seller'
                                  : 'Buyer'
                              )}{' '}
                              (
                              {toLocaleDisplay(
                                parseFloat(
                                  transactionHelper.calculatePayableEscrow(
                                    confirmTransactionData,
                                    1
                                  ) / confirmTransactionData.conversion_ratio
                                ),
                                confirmTransactionData.currency
                              )}
                              )
                            </strong>
                            <br />
                            <small>
                              The buyer is responsible for 100% of the escrow
                              fee in the event the transaction is cancelled or
                              the merchandise is rejected.
                            </small>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-6 text-right'>
                            <strong>Payment Switch Fees:</strong>
                          </div>
                          <div className='col-6'>
                            {confirmTransactionData.payment_method ===
                            'USSD' ? (
                              <strong>
                                {confirmTransactionData.tx_fee_bearer ===
                                'seller'
                                  ? 'Seller'
                                  : 'Buyer'}{' '}
                                (
                                {toLocaleDisplay(
                                  transactionHelper.calculateUSSDCardFee(
                                    confirmTransactionData,
                                    'customer'
                                  )
                                )}
                                )
                              </strong>
                            ) : (
                              <strong>
                                {confirmTransactionData.tx_fee_bearer ===
                                'seller'
                                  ? 'Seller'
                                  : 'Buyer'}
                                (
                                {confirmTransactionData.currency === 'USD'
                                  ? toLocaleDisplay(
                                      transactionHelper.calculateUsdCardFee(
                                        confirmTransactionData,
                                        'customer'
                                      ),
                                      confirmTransactionData.currency
                                    )
                                  : toLocaleDisplay(
                                      transactionHelper.calculateCardFee(
                                        confirmTransactionData,
                                        'customer'
                                      ),
                                      confirmTransactionData.currency
                                    )}
                                )
                              </strong>
                            )}
                            <br />
                            <small>
                              The buyer is responsible for 100% of the card and
                              transfer fee in the event the transaction is
                              cancelled or the merchandise is rejected.
                            </small>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-6 text-right'>
                            <strong>Payment Method:</strong>
                          </div>
                          <div className='col-6'>
                            {confirmTransactionData.payment_method ===
                            'USSD' ? (
                              <strong>{'Monnify'} </strong>
                            ) : (
                              <strong>{'Paystack'}</strong>
                            )}
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-6 text-right'>
                            <strong>Contract Uploaded?</strong>
                          </div>
                          <div className='col-6'>
                            {data.contract_files ? 'Yes' : 'No'}
                          </div>
                        </div>
                      </div>
                      {step === 2 && (
                        <div className='row'>
                          <div className='col-md-12 text-right'>
                            <div className='text-center text-danger mb-3 mt-3'>
                              Please make sure all the details are correct. You
                              will not be able to change details after clicking
                              the <em>Make Payment</em> button
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  <div className='row'>
                    {step === 2 && (
                      <>
                        <div className='col-sm-6 col-xs-12 text-sm-left'>
                          {step === 2 && !this.state.transaction_created && (
                            <button
                              className='btn btn-danger'
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  step: 1,
                                });
                              }}
                            >
                              Go Back
                            </button>
                          )}
                        </div>
                        <div className='col-sm-6 col-xs-12 text-sm-right'>
                          <button
                            disabled={this.state.creatingTransaction}
                            className={'btn btn-primary'}
                            onClick={() => {
                              this.confirmTransaction();
                            }}
                          >
                            Make Payment
                          </button>
                        </div>
                      </>
                    )}

                    {step === 3 && (
                      <div className='col-md-12 text-center'>
                        <button
                          className='btn btn-primary'
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              doPayment: true,
                            });
                          }}
                        >
                          Make Payment
                        </button>
                      </div>
                    )}
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { isEasyP2PLogin, loading, loggedIn } = state.auth;
  const { term } = state.terms;
  const { user } = state.users;
  return {
    loading,
    loggedIn,
    term,
    user,
    isEasyP2PLogin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: (type, history) => dispatch(userActions.user.logout(type, history)),
  login: (credentials, type, history) =>
    dispatch(userActions.user.login(credentials, type, history)),
  getTerm: (history) => dispatch(termActions.getTerm(history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EasyP2P)
);
