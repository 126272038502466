import { supportConstants } from '../constants';
 const  support = (state = {}, action)=>{
  switch (action.type) {
    case supportConstants.support.CREATE_SUPPORT_MAIL:
      return { loading: true };
    case supportConstants.support.CREATE_SUPPORT_MAIL_SUCCESS:
      return {success:true,loading:false};
    case supportConstants.support.CREATE_SUPPORT_MAIL_FAILURE:
      return {success:false,loading:false};
    case supportConstants.support.VERIFY_BUSINESS_REQUEST:
      return { confirming: true };
    case supportConstants.support.VERIFY_BUSINESS_SUCCESS:
      return { success: true };
    case supportConstants.support.VERIFY_BUSINESS_FAILURE:
      return { success: false, error: action.error };
    default:
      return state
  }
}

export default support;