import React, { Component } from 'react';
import { userActions, alertActions, orderActions } from '../actions';
import {Link} from 'react-router-dom';
import swal from '@sweetalert/with-react'
import { jsx, css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';
import { loaderOverride } from "../helpers/styleHelper";

const findOrder = (orders,id)=>{

    return orders.filter(a=>a.id===id);
    
  }

  const formatToSixDigits = (number)=>{
    var output = number + '';
    while (output.length < 6) {
        output = '0' + output;
    }
    return output;
}
const intialState =  {
    subject: '',
    description: '',
    attachment: ''
}
class RaiseDispute extends Component {
    
    constructor (props) {
        super(props)
        this.state = {
            fields : {
                subject: '',
                description: '',
                attachment: ''
            },
            error: null,
            submitted: false
        }
    }

    handleChange = (e) => {
        const {name, value} = e.target;
        const fields = { ...this.state.fields, [name]: value}
        this.setState({fields});
    }

    handleFileChange = (e) => {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.files[0];
        this.setState({ fields })
    }
    componentWillReceiveProps(props){
        if(props.alert && props.alert.message && props.alert.type==="alert-danger"){
            swal("Error",props.alert.message,"error").then(() => {
                props.dispatch(alertActions.alert.clear());
              });
        }else if(props.alert && props.alert.message && props.alert.type!=="alert-danger"){
            //  ${support.email}
        swal({title:"Success",content:<span>{props.alert.message}</span>,icon:"success"}).then(() => {
                props.dispatch(alertActions.alert.clear());
              });
            this.setState({fields:intialState,email:this.props.user&&this.props.user.email})
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        
        this.setState({ submitted: true, error: null });

        const support = {"reason":this.state.fields.description,email:this.props.user.email,"transaction_id":this.props.order.id,status:1};

        // const { dispatch} = this.props;
        //console.log("support:", this.props.history);
        this.props.raise(window.localStorage.getItem("trust_user_type"),support, this.props.history);
        //   e.target.files[0] = " ";
            // this.setState({fields:intialState})
       
       
       
        
    }
render(){
    const {order,user,alert,loading}= this.props;
    // const order = {};
    // orders && orders ? order = findOrder(orders,this.props.match.params.id) :order =  {};
return(
    order!={} ? 
<div class="content-wrapper" style={{minHeight: 423}}>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0 text-dark">Raise Dispute for Order {formatToSixDigits(order.id)}</h1> <br />
                </div>
            </div>
        </div>
    </div>
    <section class="content">
        <div class="container-fluid"><br />ss
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <div class="callout callout-info"><br />
                       {/* {alert && alert.message && <div class={`alert animated bounceIn support-alert  ${alert.type}` }><a aria-label="close"
                                class="close alert-close">×</a> <span class="alert-message-content">{alert.message}</span></div>} */}
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group"><label for="order_number">Order Number *</label> <input type="text"
                                       value={formatToSixDigits(order.id)}
                                        readonly="readonly" class="form-control" /></div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group"><label for="email">Contact Email *</label> <input type="text"
                                        id="email" class="form-control" value={user.email} readOnly/></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group"><label for="subject">Subject*</label> <input type="text" id="subject"
                                        class="form-control" name="subject" value={this.state.fields.subject} onChange={this.handleChange} required/></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group"><label for="dispute_description">Description *</label>
                         
                                <textarea id="dispute_description" class="form-control" name="description" value={this.state.fields.description}  onChange={this.handleChange} style={{zIndex: "auto" , lineHeight: "6" ,fontSize: "16", transition: ("none 0s ease 0s") ,background: "transparent !important"}} required>
                                </textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group"><label for="attachment">Attachment</label><br /> <input type="file"
                                        name="attachment" onChange={this.handleFileChange} id="attachment" /></div>
                            </div>
                        </div> <br /> <button class="btn btn-primary" 
                    onClick={this.handleSubmit.bind(this)}>Send Email</button> <br /><br />
                    </div>
                </div>
            </div> <br />
        </div>
    </section>
</div>
:
<div className='sweet-loading'>
        <ClipLoader
          cssOverride={loaderOverride}
          size={70}
          color={'blue'}
          loading={loading}
        />
        </div>
)
}
}

export default RaiseDispute;