import React , {Component} from 'react';
import termSchema from '../validation/terms';
import { termActions, alertActions } from '../actions'
import {Link} from 'react-router-dom';
import swal from '@sweetalert/with-react';

class Confirmation extends Component{

	constructor(props) {
		super(props);
		this.state={
		confirmDisabled:true,
		otp: ""
	}

	}
    
  getOtp=(e)=>{
		e.preventDefault();
       this.props.get(this.props.match.params.type,this.props.match.params.token,this.props.history);
	   this.setState({confirmDisabled:false});
	}

  submitOtp=(e)=>{
		e.preventDefault();
		this.props.send(this.props.match.params.type,this.state.otp,this.props.match.params.token,this.props.history);
	}
	
	handleChange=(e)=>{
      this.setState({otp:e.target.value});
	}
	componentWillReceiveProps(props){
        if(props.alert && props.alert.message &&  props.alert.type === 'alert-danger'){
					swal("Error", props.alert.message, "error").then(() => {
						this.props.dispatch(alertActions.alert.clear());
					});
		}

		if (props.alert && props.alert.message && props.alert.type === "alert-success") {
			swal("Success", props.alert.message, "success").then(() => {
				this.props.dispatch(alertActions.alert.clear());
			});
		}
		
    }
    render(){
		const {sending,submitting} = this.props;
        return(
			<div className="background-gradient" style={{ height: '100vh' }}>
				<nav class="navbar navbar-expand-lg fixed-top navbar-inverse">
					<a class="navbar-brand" href="https://www.atarapay.com">
						<img src="/dist/img/logo-header.png" alt="" className="logo-img img img-responsive" />
					</a>
					<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>
				</nav>

				<div class="row row-margin top-margin-60">
					<div className="col-sm-3 col-md-3"></div>
					<div className="col-sm-8 col-md-6 col-12">
						<div className="card card-margin text-center" style={{marginTop: "150px"}}>
							<h3>Verify your phone number</h3>
							<p>Click on 'Request OTP' to get an OTP sent to your phone. Use this for verification</p>
							<input type="text" name="otp" class="form-control" value={this.state.otp} onChange={this.handleChange} placeholder="Write OTP here"/>
					
							<br />
							<div class="form-group">
								<button class="btn btn-dark-blue" type="submit" onClick={this.submitOtp} disabled={this.state.confirmDisabled}>{submitting&&submitting?'Submitting':'Submit'}</button>
								&nbsp;&nbsp;
								<button class="btn btn-primary" onClick={this.getOtp}>{sending&&sending?'Resend':'Request OTP'}</button>
							</div>
							<p className="text-center text-danger">
							   Nigeria: Turn off Do Not Disturb (DND) to receive OTP by sending “Allow” to 2442
							</p>
						</div>

					</div>
			<div class="col-sm-2 col-md-3"></div>
		</div>	

	</div>
	

        )
    }
   
}
export default Confirmation;





