import React, {Component} from 'react';
import StageThree from './transaction/StageThree';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux'
import {transactionActions, alertActions, payoutActions, termActions, orderActions} from '../actions';
import transactionHelper from '../helpers/transaction';

import swal from '@sweetalert/with-react';


class SingleTransaction extends Component {    
    ///dummy
    //shall be replace with async action of props
    componentDidMount = () => {
        // this.props.getTerms(this.props.history)
        if(!localStorage.getItem("user")){
         if (this.props.location.pathname.includes("transaction/pay")){
             return this.props.history.push("/login/buyer");
         }else if(this.props.location.pathname.includes("transaction/seller")){
             return this.props.history.push("/login/seller");
         } else if (this.props.location.pathname.includes("transaction/buyer")){
             return this.props.history.push("/login/buyer");
         }
        }
        this.props.getTransactions(localStorage.getItem("trust_user_type"), this.props.history)
    }
    state = {
        type: '',
        product_name: '',
        category: '',
        buyer_email: '',
        buyer_phone: '',
        seller_email: '',
        seller_phone: '',
        amount_payed: 0,
        quantity: 1,
        escrow_fee_bearer: '',
        shipping_method: 0,
        shipping_cost: 0,
        max_delivery_days: 0,
        shipping_fee_bearer: '',
        product_desc: '',
        is_additional_serivces: 0,
        additional_services: [],
        extra: {},
        alt_phone: ''
    }

    editTransaction = (data, history) => {
        this.props.editTransaction(data, history);
        this.props.history.push('/app/transaction');
    }

    cancelTransaction = (response) => {
        this.props.cancel(this.props.role, response, this.props.history)
    }

    handlePhone = (value, name) => {
        let data = { ...this.state.data, [name]: value }
        this.setState({ data });
    }
    componentWillReceiveProps = (props) => {
        if(props.alert && props.alert.message && props.alert.type==="alert-danger"){
            swal("", props.alert.message, "error").then(() => {
                props.dispatch(alertActions.alert.clear())
            })
        } else if(props.alert && props.alert.message && props.alert.type==="alert-success"){
            swal("Success", props.alert.message, "success").then(() => {
                this.props.dispatch(alertActions.alert.clear())
                props.history.push(`/app/transactions`);
            })
        }

        if(props.transactions && props.transactions.length > 0 && (!props.payment || props.payment === null || props.payment === undefined) && (!props.paymentError || props.paymentError === null || props.paymentError === undefined || props.paymentError === "") && (!props.paymentLoading || props.paymentLoading === null || props.paymentLoading === undefined)) {
            const {id} = props.match.params;
            const { transactions, role } = props
            let data = transactions && transactions.find(transaction => transaction.id == id);
            data = data && transactionHelper.rformatTransactionData(data);

            this.props.getTransactionPaymentInformation(role, {
                transaction_id: data.id,
                type: data.type,  
                seller_phone: data.seller_phone,
                buyer_phone:  data.buyer_phone,
                currency: data.currency,
                promo_code: data.promo_id ?? "",
                product_cost: data.product_cost,
                shipping_cost: data.shipping_cost,
                shipping_fee_bearer: data.shipping_fee_bearer ?? "seller",
                escrow_fee_bearer: data.escrow_fee_bearer,
                payment_method: data.payment ? data.payment.method: ""
            }, this.props.history)
        }
    }

    componentWillUnmount = () => {
        this.props.clearTransactionPaymentInformation();
    }
    render() {
        const {id} = this.props.match.params;
        const { transactions, role, terms, user, accounts } = this.props
        let data = transactions && transactions.find(transaction => transaction.id === +id);
        data = data && transactionHelper.rformatTransactionData(data);
        if (!data) {
            this.props.history.push('/app/transactions');
        }
        return (
            <div>
                {data && 
                <StageThree 
                    user={user}
                    role={role}
                    accounts={accounts}
                    terms={terms}
                    data={data&&data} 
                    pay={this.props.pay}
                    cancel={this.cancelTransaction}
                    handleViewChange={this.changeView} 
                    editTransaction={this.editTransaction} 
                    handlePhone={this.handlePhone}
                    history={this.props.history}
                    getTransactions={this.props.getTransactions}
                    paymentInfo={this.props.payment}
                />}
            </div>
            
        )
    }
}

export default withRouter(
    connect(
        state => ({
            transactions: state.transaction.transactions,
            role: localStorage.getItem('trust_user_type'),
            terms: state.terms,
            user: state.users.user,
            accounts: state.payout.accounts,
            payment: state.orders.payment,
            paymentError: state.orders.paymentError,
            paymentLoading: state.orders.paymentLoading
        }),
        dispatch => ({
            getTransactions: (type, history) => dispatch(transactionActions.transaction.getTransactions(type, history)),
            cancel: (type, response, history) => dispatch(transactionActions.transaction.cancelTransaction(type, response, history)),
            pay: (type, _transaction, alt_phone, history) => dispatch(transactionActions.transaction.makePayment(type, _transaction, alt_phone, history)),
            deSelectTransaction:() => dispatch(transactionActions.transaction.cancelEditTransaction()),
            viewPayout:(type, history)=>dispatch(payoutActions.payout.view(type, history)),
            getTerms: (history) => dispatch(termActions.getTerm(history)),
            getTransactionPaymentInformation: (type, data, history) => dispatch(orderActions.order.getTransactionPaymentInformation(type, data, history)),
            clearTransactionPaymentInformation: () => dispatch(orderActions.order.clearTransactionPaymentInformation()),
            dispatch
        })
    )(SingleTransaction)
);