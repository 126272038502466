import React , {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import { alertActions } from '../actions'
import swal from '@sweetalert/with-react';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import { USER_ROLE_SELLER, USER_ROLE_MARKET } from '../constants';
import { loaderOverride } from "../helpers/styleHelper";

class Dashboard extends Component{

    state = {
        dashboard: [],
        role: localStorage.getItem("trust_user_type"),
    }


   componentDidMount() {
       const { user } = this.props;
       if(user && user.role && user.role.name === USER_ROLE_MARKET){
            this.setState({
                role: 'marketplace'
            })
        }

        if (user && user.role &&
            user.role.name === USER_ROLE_SELLER && user.verified === 1 && user.support_verified !== 1) {
           this.props.history.push('/app/pending');
        }

        if (user && user.role &&
            user.role.name === USER_ROLE_MARKET && user.verified === 1 && user.support_verified !== 1) {
            this.props.history.push('/app/pending');
        }

       if (user && user.role &&
           user.role.name === USER_ROLE_SELLER && user.verified !== 1 && user.support_verified !== 1) {
           this.props.history.push('/app/business');
        }

       if (user && user.role &&
            user.role.name === USER_ROLE_MARKET && user.verified !== 1 && user.support_verified !== 1) {
            this.props.history.push('/app/business');
        }

        
        if (user && user.role && user.role.name === USER_ROLE_SELLER && (user.setting_tour_done==0||user.payment_tour_done==0||user.delivery_tour_done==0||user.api_tour_done==0)){
             swal({
                title:'Welcome',
                text:"Let's take a short tour guide through the following key features. (Click \"Got it\" to complete each feature).", 
                content: (
                    <ul className="text-left" style={{listStyle:'none'}}>
                     <li> <i className={(user.setting_tour_done===1)?` fa fa-check-square-o text-success` : ` fa fa-square-o`}></i> Payment Switch Fees </li>
                     <li> <i className={(user.payment_tour_done===1)?` fa fa-check-square-o text-success` : ` fa fa-square-o`}></i> Payout Details</li>
                     <li> <i className={(user.delivery_tour_done===1)?` fa fa-check-square-o text-success` : ` fa fa-square-o`}></i> Delivery Terms</li>
                     <li> <i className={(user.api_tour_done===1)?` fa fa-check-square-o text-success` : ` fa fa-square-o`}></i> API Keys</li></ul>),
                icon:"success"}).then(()=>{
                 if(user.setting_tour_done===0){
                    this.props.history.push('/app/settings');
                 }
                 else if(user.payment_tour_done===0){
                    this.props.history.push('/app/payout/details');
                 }
                 else if(user.delivery_tour_done===0){
                    this.props.history.push('/app/delivery');
                 }
                  else if(user.api_tour_done===0){
                    this.props.history.push('/app/api');
                 }
              }
            );

        }
        else if (user && user.role && user.role.name === USER_ROLE_MARKET && (user.payment_tour_done==0||user.api_tour_done==0)){
             swal({
                title:'Welcome',
                text:"Let's take a short tour guide through the following key features. (Click \"Got it\" to complete each feature).", 
                content: (
                    <ul className="text-left" style={{listStyle:'none'}}>
                     <li> <i className={(user.payment_tour_done===1)?` fa fa-check-square-o text-success` : ` fa fa-square-o`}></i> Payout Details</li>
                     <li> <i className={(user.api_tour_done===1)?` fa fa-check-square-o text-success` : ` fa fa-square-o`}></i> API Keys</li></ul>),
                icon:"success"}).then(()=>{
                 if(user.payment_tour_done===0){
                    this.props.history.push('/app/payout/details');
                 }
                 else if(user.api_tour_done===0){
                    this.props.history.push('/app/api');
                 }
              }
            );

        }
           


     }

    componentWillReceiveProps(props) {
        if (props.alert && props.alert.message && props.alert.type == "alert-danger") {
            swal("Error", props.alert.message, "error").then(() => {
                props.dispatch(alertActions.alert.clear());
            });
        }
        
        if (props.dashboard !== null) {
            this.setState({ dashboard: props.dashboard });
        }

    }

    padOrderId = (number) => {
    let id = '' + number;
    while (id.length < 6) {
        id = '0' + id;
    };

    return id;
    }


    render(){
        const { dashboard, loading, alert, user } = this.props;      
        
        return(
           
<div  class="content-wrapper" style={{minHeight: 137}}>
    <div  class="content-header">
        <div  class="container-fluid">
            <div  class="row mb-2">
                <div  class="col-sm-6">
                    <h1  class="m-0 text-dark">Dashboard</h1>
                </div>
            </div>
        </div>
    </div>
                {!loading && this.props.dashboard ? 
    <section  class="content">
        <div  class="container-fluid">
            <div  class="row">
            <div class={user && user.role && user.role.name === USER_ROLE_MARKET ? "col-12 col-sm-4 col-md-3" : "col-12 col-sm-4 col-md-4" }>
                                    {(this.state.role === "seller" || this.state.role === "marketplace") ?
                <div>
                <span>
                    <div  class="info-box"> <span  class="info-box-icon bg-info elevation-1"><i
                                 class="ion-ios-pulse-strong"></i></span>
                        <div  class="info-box-content"><span  class="info-box-text">Payout 
                                Total</span> 
                                <i class="ion-help floatr-top" data-tip data-for='payout'></i>
                                <span  class="info-box-number">
                                ₦ {this.props.dashboard.data.total_completed_transactions}
                            </span></div>
                    </div>
                    <ReactTooltip id='payout' place="bottom" aria-haspopup='true'>
                        Payout Total refers to the total sum in Naira of AtaraPay escrow orders <br />paid out to Seller whose orders were Accepted by the Buyer<br />Please note that this amount is less escrow and card fees.
                    </ReactTooltip>
                </span>
                </div> : <span>
                    <div class="info-box"> <span class="info-box-icon bg-info elevation-1"><i
                        class="ion-ios-pulse-strong"></i></span>
                        <div class="info-box-content"><span class="info-box-text">Total Orders</span>
                        <i class="ion-help floatr-top" data-tip data-for='payout'></i>
                        <span class="info-box-number">
                            ₦ {this.props.dashboard.data.total_orders_transactions}
                        </span></div>
                </div>
                <ReactTooltip id='payout' place="bottom" aria-haspopup='true'>
                                                Total Orders refer to the total sum in Naira of AtaraPay escrow orders <br />placed via Seller website or Person to Person (P2P) transaction created via the <br />Buyer, Seller or Broker’s AtaraPay account. These orders will each go through the<br/> escrow status of Pending, Cancelled, Accepted, Rejected, Disputed and Completed
                </ReactTooltip>
                </span>}
                
                </div>

                {user && user.role && user.role.name === USER_ROLE_MARKET &&

                <div class={user && user.role && user.role.name === USER_ROLE_MARKET ? "col-12 col-sm-4 col-md-3" : "col-12 col-sm-4 col-md-4" }>
                    <div>
                        <span>
                            <div  class="info-box"> <span  class="info-box-icon bg-warning elevation-1"><i
                                        class="ion-ios-person"></i></span>
                                <div  class="info-box-content"><span  class="info-box-text">Number of Sellers
                                        Total</span> 
                                        <i class="ion-help floatr-top" data-tip data-for='sellerCount'></i>
                                        <span  class="info-box-number">
                                        {this.props.dashboard.data.marketplace_child}
                                    </span></div>
                            </div>
                            <ReactTooltip id='sellerCount' place="bottom" aria-haspopup='true'>
                                Number of sellers refers to the total number of sellers <br />under a marketplace operator
                            </ReactTooltip>
                        </span>
                    </div>
                </div>
                }
                <div class={user && user.role && user.role.name === USER_ROLE_MARKET ? "col-12 col-sm-4 col-md-3" : "col-12 col-sm-4 col-md-4" }>
                { (this.state.role === "seller" || this.state.role === "marketplace") ?
                <span>
                    <div  class="info-box mb-3"> <span  class="info-box-icon bg-danger elevation-1"><i
                                 class="ion-ios-help-outline"></i></span>
                        <div  class="info-box-content"><span  class="info-box-text">Payout
                                Pending</span> 
                        <i class="ion-help floatr-top" data-tip data-for='pending'></i>
                        <span class="info-box-number">₦ {this.props.dashboard.data.total_pending_transactions}</span></div>
                    </div>
                    <ReactTooltip id='pending' place="bottom" aria-haspopup='true'>
                                                Payout Pending refers to the total sum in Naira of AtaraPay escrow orders <br />yet to be paid out to Seller whose orders were Accepted by the Buyer.<br />Please note that this amount is less escrow and card fees.
                    </ReactTooltip> </span> :

                        <span>
                            <div class="info-box mb-3"> <span class="info-box-icon bg-danger elevation-1"><i
                                class="ion-ios-help-outline"></i></span>
                                <div class="info-box-content"><span class="info-box-text">Refund
                            Pending</span>
                                                <i class="ion-help floatr-top" data-tip data-for='pending'></i>
                                                    <span class="info-box-number">₦ {this.props.dashboard.data.total_refund_transactions}</span></div>
                                        </div>
                                        <ReactTooltip id='pending' place="bottom" aria-haspopup='true'>
                                                Refund Pending refers to the total sum in Naira of AtaraPay escrow orders <br />yet to be refunded to Buyer whose orders were Rejected by the Buyer.<br />Please note that this amount is less escrow, card and shipping fees.
                </ReactTooltip></span>
                }                        
                </div>              
                <div  class="clearfix hidden-md-up"></div>
                <div class={user && user.role && user.role.name === USER_ROLE_MARKET ? "col-12 col-sm-4 col-md-3" : "col-12 col-sm-4 col-md-4" }>
                    <div  class="info-box mb-3"> <span  class="info-box-icon bg-primary elevation-1"><i
                                 class="ion-ios-cart-outline"></i></span>
                        <div  class="info-box-content"><span  class="info-box-text">Orders</span>
                            <i class="ion-help floatr-top" data-tip data-for='orders'></i>
                        <span class="info-box-number">{this.props.dashboard.data.total_orders}</span></div>
                    </div>
                    <ReactTooltip id='orders' place="bottom" aria-haspopup='true'>
                                        Orders refer to the number of AtaraPay escrow orders placed via Seller website or Person to Person (P2P) transaction <br />created via the Buyer, Seller or Broker’s AtaraPay account. These orders will each go through the escrow <br />status of Pending, Cancelled, Accepted, Rejected, Disputed and Completed
                    </ReactTooltip>
                </div>
            </div>
            <div  class="row">
                <div  class="col-12 col-sm-6 col-md-8">
                    <div  class="card">
                        <div  class="card-header border-transparent">
                            <h3 class="card-title" style={{ fontSize: 15, fontWeight: 600, marginTop: 5, marginBottom: 0.3 }}>Latest Orders</h3>
                        </div>
                        <div  class="card-body p-0">
                        { this.props.dashboard.data.recent_orders.length ?
                            <div  class="table-responsive">
                                <table  class="table m-0">
                                    <thead >
                                        <tr >
                                            <th >Order ID</th>
                                            <th >Item</th>
                                            {user && user.role && user.role.name === USER_ROLE_MARKET && <th >Seller</th> }
                                            <th >Status</th>
                                            <th >Price</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {this.props.dashboard.data.recent_orders.map((item, i) => {
                                                            return [
                                        <tr key={i}>
                                            <td>{this.padOrderId(item.id)}</td>
                                            <td>{item.product_name}</td>
                                            {user && user.role && user.role.name === USER_ROLE_MARKET && <td>{item && item.sellers && item.sellers.users && item.sellers.users.firstname}<br/></td> }
                                            {item.status.id === 1 || item.status.id === 11 || item.status.id === 7 || item.status.id === 3  ?
                                               <td> <span class="badge badge-warning"> {item.status.title}</span> </td>: item.status.id === 9 || item.status.id === 4 || item.status.id === 10 ?
                                                <td> <span class="badge badge-danger"> {item.status.title}</span> </td> : item.status.id === 6 || item.status.id === 8 ?
                                                 <td><span class="badge badge-info"> {item.status.title}</span> </td> : item.status.id === 2 || item.status.id === 5 ?
                                                 <td><span class="badge badge-success"> {item.status.title}</span> </td>:
                                                <td><span class="badge badge-warning">{item.status.title}</span></td>
                                            }
                                               
                                              
                                            <td >
                                                ₦ {(item.amount_payed / 100).toLocaleString('en', {minimumFractionDigits: 2 }) }

                                            </td>
                                        </tr>
                                                    
                                            ];})}
                                                                                      
                                    </tbody>
                                </table>
                            </div> : 
                            <div style={{fontSize: 14, padding: 10, color: 'red'}}>No recent orders</div>
                            }
                        </div>
                        <div  class="card-footer clearfix">
                                            <Link class="btn btn-sm btn-primary" style={{ padding: "8 40", color: "rgb(255, 255, 255)", float: "right" }} to="/app/orders">View
                                All Orders</Link></div>
                    </div>
                </div>
                <div class="col-md-4 col-12 col-sm-6 side">
              <div class="card-header">
                <h3 class="card-title" style={{fontSize: 15, fontWeight: 600, marginTop: 5, marginBottom: 0.3}}>Order Status</h3>
              </div>
             
              <div class="card-body p-0">
                    <ReactTooltip id='global' place="bottom" type="dark" effect="float" />
                    <div class="info-box mb-1 mt-1">
                        <i class="ion-help floatr" data-tip data-for='ptranx'></i>
                        <span class="info-box-icon"><i class="fa fa-clock-o"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Pending Transactions</span>
                            <span class="info-box-number">{this.props.dashboard.data.pending_transactions}</span>
                        </div>
                    <ReactTooltip id='ptranx' place="bottom" aria-haspopup='true'>
                        Pending Transactions refers to the total number of AtaraPay escrow orders <br />that are pending delivery to the Buyer or Recipient
                    </ReactTooltip>
                    </div>

                    <div class="info-box mb-1">
                        <i class="ion-help floatr" data-tip data-for='ctranx'></i>
                        <span class="info-box-icon"><i class="fa fa-times"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Cancelled Transactions</span>
                            <span class="info-box-number">{this.props.dashboard.data.cancelled_transactions}</span>
                        </div>
                    <ReactTooltip id='ctranx' place="bottom" aria-haspopup='true'>
                        Cancelled Transactions refers to the total number of AtaraPay escrow orders <br/>that were cancelled by the Buyer within or beyond the Seller’s <br/>Service Level Agreement (SLA) for cancellations
                    </ReactTooltip>
                    </div>

                    <div class="info-box mb-1 ">
                        <i class="ion-help floatr" data-tip data-for='atranx'></i>
                        <span class="info-box-icon"><i class="fa fa-thumbs-up"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Accepted Transactions</span>
                            <span class="info-box-number">{this.props.dashboard.data.accepted_transactions}</span>
                        </div>
                    <ReactTooltip id='atranx' place="bottom" aria-haspopup='true'>
                        Accepted Transactions refers to the total number of AtaraPay escrow <br/>orders that were accepted by the Buyer
                    </ReactTooltip>
                    </div>

                    <div class="info-box mb-1">
                        <i class="ion-help floatr" data-tip data-for='rtranx'></i>
                            <span class="info-box-icon"><i class="fa fa-thumbs-down"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">Rejected Transactions</span>
                                <span class="info-box-number">{this.props.dashboard.data.rejected_transactions}</span>
                            </div>
                    <ReactTooltip id='rtranx' place="bottom" aria-haspopup='true'>
                        Rejected Transactions refers to the total number of AtaraPay <br/>escrow orders that were rejected by the Buyer
                    </ReactTooltip>
                    </div>


                    <div class="info-box mb-1">
                        <i class="ion-help floatr" data-tip data-for='cotranx'></i>
                        <span class="info-box-icon"><i class="fa fa-check"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Completed Transactions</span>
                            <span class="info-box-number">{this.props.dashboard.data.completed_transactions}</span>
                        </div>
                    <ReactTooltip id='cotranx' place="bottom" aria-haspopup='true'>
                        <p>Completed transactions are transactions that are one of the below;</p>
                        <ol type="1">
                        <li>Cancelled</li>
                        <li>Effect a refund to Buyer due to rejection of items</li>
                                                    <li>Effect payout to Seller due to acceptance of items or cancellation fee due</li>
                        <li>Effect settlement in favour of Buyer or Seller from a dispute resolution</li>
                        </ol>
                    </ReactTooltip>
                    </div>
                    <div class="info-box mb-1">
                        <i class="ion-help floatr" data-tip data-for='dtranx'></i>
                        <span class="info-box-icon"><i class="fa fa-question"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Disputed Transactions</span>
                            <span class="info-box-number">{this.props.dashboard.data.disputed_transactions}</span>
                        </div>
                    <ReactTooltip id='dtranx' place="bottom" aria-haspopup='true'>
                                                Disputed Transactions refers to the total number of disputes raised on <br />AtaraPay escrow orders concerning the Seller or Buyer
                    </ReactTooltip>
                    </div>
                    
              </div>
              
            </div>
              
            </div>
        </div>
                    </section> :
                    <div className='sweet-loading'>
                        <ClipLoader
                            cssOverride={loaderOverride}
                            size={70}
                            color={'blue'}
                            loading={loading}
                        />
                    </div>
                }
</div>
        )
    }
}

export default withRouter(Dashboard);