import React from 'react';
import { connect } from "react-redux";
import { Redirect, withRouter } from 'react-router-dom';
import { USER_ROLE_SELLER } from '../constants';

const Authorization = (WrappedComponent) => {
    class WithAuthorization extends React.Component {
        constructor(props){
            super(props)
        }
        // componentDidMount = () => {
        //     if (this.props.user && this.props.user.seller_id && (this.props.user.verified != 1 || this.props.user.support_verified != 1)) {
        //         // return <Redirect to={{pathname: "/login",state: { from: this.props.location }}}/>
        //         this.props.history.push('/app/business');
        //     }
        // }

        render () {
            const user = this.props && this.props.user
            // console.log("lets see",user);
            // const { pathname } = this.props.location;
            if(user && user.role && user.role.name === USER_ROLE_SELLER && user.verified === 1 && user.support_verified === 1 && user.seller_id !== null){
                return <WrappedComponent {...this.props} />
            }
            else if (user && user.role && user.role.name === USER_ROLE_SELLER && user.verified !== 1 && user.support_verified !== 1 && user.seller_id === null) {
                // console.log("true");
                // return <Redirect to={{pathname: "/app/business",state: { from: this.props.location }}}/>
                return <Redirect to="/app/business"/>
            }
            else if(user && user.role && user.role.name === USER_ROLE_SELLER && user.verified === 1 && user.support_verified !== 1 && user.seller_id !== null){
                return <Redirect to="/app/pending"/>
            }else{
                return <WrappedComponent {...this.props} />
            }
           
        }
    }
    return withRouter(connect( (state) => ({user: state.users.user}))(WithAuthorization));
}

export default Authorization;