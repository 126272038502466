const toInternationalFormat = (phone = "", prefix = "+234") => {
  return prefix + phone.toString().substring(1);
};
const formatTransactionData = (data) => {
  /**
   * Convert Every price field to Naira Form
   * Clean Multiples for Additional Services
   */

  let additional_service_amount = 0;
  data.additional_services.map((service) => {
    return additional_service_amount += parseInt(service.cost * 100);
  });

  additional_service_amount = additional_service_amount * data.conversion_ratio;

  /** Amount Payed and Shipping Cost is "" (String) Fix */
  data.amount_payed =
    data.amount_payed === ""
      ? 0
      : data.category == "Money Market"
      ? data.conversion_ratio * data.quantity
      : data.amount_payed * data.conversion_ratio;
  data.shipping_cost =
    data.shipping_method === 0
      ? 0
      : data.shipping_cost === ""
      ? 0
      : data.shipping_cost * data.conversion_ratio;

  data.additional_services = Array.from(
    data.additional_services,
    (service) => ({ ...service, cost: service.cost * 100 })
  );
  return {
    ...data,
    amount_payed:
      data.category == "Money Market"
        ? data.conversion_ratio * data.quantity * 100
        : data.quantity * data.amount_payed * 100,
    extra: JSON.stringify(data.extra),
    recipient: JSON.stringify(data.recipient),
    additional_service_amount,
    shipping_cost: data.shipping_cost * 100,
    additional_services: data.additional_services.filter(
      (service) => service.selected
    ),
  };
};

const rformatTransactionData = (data) => {
  const rselected = Array.from(data.additional_services, (service) => ({
    ...service,
    cost: service.cost / 100,
    selected: true,
  }));
  return {
    ...data,
    amount_payed: parseFloat(data.amount_net / data.quantity / 100),
    additional_service_amount: parseFloat(data.additional_service_amount / 100),
    shipping_cost: data.shipping_cost / 100,
    additional_services: rselected,
    buyer_phone: toInternationalFormat(data.buyer_phone),
    seller_phone: toInternationalFormat(data.seller_phone),
  };
};

const formatTransctionType = (intValue) => {
  switch (intValue) {
    case 2:
      return "general";
    case 3:
      return "milestone";
    default:
      return null;
  }
};

const calculateTransactionEscrow = (taxable) => {
  if ((1.5 / 100) * parseFloat(taxable) >= 50000) {
    return 50000;
  } else if ((1.5 / 100) * parseFloat(taxable) <= 500) {
    return 500;
  } else {
    return (1.5 / 100) * parseFloat(taxable);
  }
};

const calculateUsdTransactionEscrow = (taxable) => {
  return calculateTransactionEscrow(taxable);
};
const calculatePayableShipping = (data) => {
  let x;
  switch (data.shipping_fee_bearer) {
    case "customer":
      x = 1;
      break;
    case "seller":
      x = 0;
      break;
    default:
      x = 0.5;
  }

  return data.shipping_cost * x;
};

/** Calculate Shipping for Display */
const calculateShipping = (data, d = 1, di = 0) => {
  let x, y;
  d = data.shipping_fee_bearer === "customer" ? 1 : d;
  switch (data.shipping_fee_bearer) {
    case "customer":
      x = 1;
      y = 1;
      break;
    case "seller":
      x = di;
      y = 1;
      break;
    default:
      x = 0.5;
      y = 0.5;
  }
  return {
    customer: parseFloat(data.shipping_cost * x),
    seller: parseFloat(data.shipping_cost * y * d),
  };
};

const calculatePayableEscrow = (data, display = 0, promo) => {
  let x, y;
  switch (data.escrow_fee_bearer) {
    case "customer":
      y = 1;
      break;
    case "seller":
      y = display;
      break;
    default:
      y = 0.5;
  }
  switch (data.shipping_fee_bearer) {
    case "customer":
      x = 1;
      break;
    case "seller":
      x = 0;
      break;
    default:
      x = 0.5;
  }

  const payable_minus_escrow = eval(
    parseFloat(data.amount_payed * data.quantity) +
      parseFloat(data.additional_service_amount) +
      parseFloat(data.shipping_cost * x)
  );

  let promo_amount = 0;
  if (promo) {
    let { promo_percent } = promo;
    if (promo_percent)
      promo_amount =
        eval(calculateTransactionEscrow(payable_minus_escrow) * y) *
        promo_percent;
  }

  return (
    eval(calculateTransactionEscrow(payable_minus_escrow) * y) - promo_amount
  );
};

const calculateCardFee = (data, role, fmt = 0, action) => {
  let Escrowfee = calculatePayableEscrow(data);
  let cost =
    parseFloat(
      (fmt === 1 ? data.amount_net / 100 : data.amount_payed) * data.quantity
    ) +
    parseFloat(calculateShipping(data)[role]) +
    Escrowfee;
  let fee = calculateFinalCardFeePLusTx(cost, "Paystack", "NGN");
  if (action === "refund") {
    return fee - 50;
  }
  return fee;
};

const calculateUsdCardFee = (data, role, fmt = 0, action = "") => {
  let Escrowfee = calculatePayableEscrow(data);
  let cost =
    parseFloat(
      (fmt === 1 ? data.amount_net / 100 : data.amount_payed) * data.quantity
    ) +
    parseFloat(calculateShipping(data)[role]) +
    Escrowfee;
  let fee = calculateFinalCardFeePLusTx(cost, "Paystack", "USD");
  return fee / data.conversion_ratio;
};

const calculateUSSDCardFee = (data, role, fmt = 0, action = "") => {
  let Escrowfee = calculatePayableEscrow(data);
  let cost =
    parseFloat(
      (fmt === 1 ? data.amount_net / 100 : data.amount_payed) * data.quantity
    ) +
    parseFloat(calculateShipping(data)[role]) +
    Escrowfee;
  let fee = calculateFinalCardFeePLusTx(cost, "USSD", "NGN");
  return fee;
};

const calculateTransactionPayable = (data, fmt = 0, modal = 0, promo) => {
  let x, y;
  switch (data.escrow_fee_bearer) {
    case "customer":
      y = 1;
      break;
    case "seller":
      y = 0;
      break;
    default:
      y = 0.5;
  }
  switch (data.shipping_fee_bearer) {
    case "customer":
      x = 1;
      break;
    case "seller":
      x = 0;
      break;
    default:
      x = 0.5;
  }

  const payable_minus_escrow = eval(
    parseFloat(
      (fmt === 1 ? data.amount_net / 100 : data.amount_payed) *
        (modal === 1 ? 1 : data.quantity)
    ) +
      parseFloat(
        fmt === 1
          ? data.additional_service_amount / 100
          : data.additional_service_amount
      ) +
      parseFloat(
        (fmt === 1 ? data.shipping_cost / 100 : data.shipping_cost) * x
      )
  );
  const payable_plus_escrow = eval(
    payable_minus_escrow + calculateTransactionEscrow(payable_minus_escrow) * y
  );
  let promo_amount = 0;
  if (promo) {
    let { promo_percent } = promo;
    if (promo_percent)
      promo_amount =
        calculateTransactionEscrow(payable_minus_escrow) * y * promo_percent;
  }
  return payable_plus_escrow - promo_amount;
};

const calculateTransactionPayableByBuyer = (data) => {
  let x, y;
  switch (data.escrow_fee_bearer) {
    case "customer":
      y = 1;
      break;
    case "seller":
      y = 0;
      break;
    default:
      y = 0.5;
  }
  switch (data.tx_fee_bearer) {
    case "customer":
      x = 1;
      break;
    case "seller":
      x = 0;
      break;
    default:
      x = 1;
  }
  let totalAmount = data.amount_net / 100;
  let addServiceAmt = data.additional_service_amount / 100;
  let shippingCost = (data.shipping_cost * x) / 100;
  let payable_minus_escrow = eval(totalAmount + addServiceAmt + shippingCost);
  let escrowFees = calculateTransactionEscrow(payable_minus_escrow) * y;
  let payable_plus_escrow = eval(payable_minus_escrow + escrowFees);
  let cardFee =
    calculateFinalCardFeePLusTx(
      payable_plus_escrow,
      data.gateway_name,
      data.currency
    ) * x;
  return payable_plus_escrow + cardFee;
};

const calculateFinalCardFeePLusTx = (ngnOrderAmount, gateway, currency) => {
  let cardfeePercent = gateway == "USSD" ? 0.75 : currency == "USD" ? 3.9 : 1.5;
  let maxCappingValue = gateway == "USSD" ? 200 : 2000;
  let additionalChargeCheckUp = currency == "USD" ? 0 : 2500;
  let aditionalCharge = gateway == "USSD" ? 0 : 100;
  let cardFee = (cardfeePercent * ngnOrderAmount) / 100;
  let txFee = gateway == "USSD" ? 80 : 50;
  if (ngnOrderAmount >= additionalChargeCheckUp) {
    cardFee = cardFee + aditionalCharge;
  }
  if (currency != "USD") {
    cardFee = cardFee >= maxCappingValue ? maxCappingValue : cardFee;
  }
  return cardFee + txFee;
};

const calculateTransactionRefundable = (data, fmt = 0) => {
  let x, y;
  switch (data.escrow_fee_bearer) {
    case "customer":
      y = 0;
      break;
    case "seller":
      y = 1;
      break;
    default:
      y = 0.5;
  }
  switch (data.shipping_fee_bearer) {
    case "customer":
      x = 1;
      break;
    case "seller":
      x = 0;
      break;
    default:
      x = 0.5;
  }
  const payable_minus_escrow = eval(
    parseFloat(
      (fmt === 1
        ? data.amount_payed / 100
        : fmt === 2
        ? data.amount_net / 100
        : data.amount_payed) * (fmt === 1 || fmt === 2 ? 1 : data.quantity)
    ) +
      parseFloat(
        fmt === 1 || fmt === 2
          ? data.additional_service_amount / 100
          : data.additional_service_amount
      ) +
      parseFloat(
        (fmt === 1 || fmt === 2
          ? data.shipping_cost / 100
          : data.shipping_cost) * x
      )
  );

  const payable_plus_escrow = eval(
    payable_minus_escrow - calculateTransactionEscrow(payable_minus_escrow) * y
  );

  return payable_plus_escrow;
};

export const toLocaleDisplay = (quantity, currency) => {
  return quantity.toLocaleString(undefined, {
    style: "currency",
    currency: currency || "NGN",
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const escrowFees = (props) => {
  let { payment_info, conversion_ratio } = props.data;
  return payment_info?.buyerEscrowFees / 100 / (conversion_ratio ?? 1);
};

const transactionCurrency = (data) => {
  let currency = data.category === 'Money Market' ? 'NGN' : data.currency;
  return currency;
};

const cardFees = (props) => {
  let payableCardFees =
    props?.data?.currency === 'USD' && props?.data?.category !== 'Money Market'
      ? (props?.data?.payment_info?.buyerCardFees +
        props?.data?.payment_info?.buyerTransferFees) /
      100 /
      props?.data?.conversion_ratio
      : (props?.data?.payment_info?.buyerCardFees +
        props?.data?.payment_info?.buyerTransferFees) /
      100;
  return payableCardFees;
};
const netAmount = (props) => {
  let productCost = props.data.amount_payed * props.data.quantity;
  let shippingCost = calculateShipping(props.data)[
    props.role
    ];
  let netCost = (productCost + shippingCost) / props.data.conversion_ratio;
  if (props.data.category === 'Money Market') {
    productCost = props.data.conversion_ratio * props.data.quantity;
    netCost = productCost + shippingCost;
  }
  return netCost;
};

const productAmount = (props) => {
  let { conversion_ratio, payment_info } = props.data;
  return payment_info?.productCost /100 /conversion_ratio;
};

const payoutAmount = (props) => {
  let { conversion_ratio, payment_info } = props.data;
  return payment_info?.payoutAmount /100 /conversion_ratio;
};

const refundableAmount = (props) => {
  let txFees =
    props?.data.tx_fee_bearer === 'seller'
      ? cardFees(props)
      : 50 / props?.data?.conversion_ratio;
  txFees = txFees * props?.data?.conversion_ratio;
  let netRefundAmount =
    props?.data?.payment_info?.refundAmount /
    100 /
    props?.data?.conversion_ratio;

  if (props?.data?.category === 'Money Market') {
    txFees = props?.data.tx_fee_bearer === 'seller' ? cardFees(props) : 50;
    netRefundAmount =
      props?.data?.payment_info?.refundAmount /
      100 /
      props?.data?.conversion_ratio;
  }

  return netRefundAmount;
};
const logisticFees = (props) => {
  let totalCost =
    props.data.category === 'Money Market'
      ? netAmount(props)
      : netAmount(props) * props.data.conversion_ratio;
  let logisticCost = (props.terms.logistics_fee * totalCost) / 100;
  if (logisticCost > 5000) {
    logisticCost = 5000;
  }
  if (props.data.category !== 'Money Market')
    return logisticCost / props.data.conversion_ratio;
  return logisticCost;
};

const grandTotal = (props, values) => {
  const {
    payment_info: { totalPayable = null } = {},
    conversion_ratio,
    tx_fee_bearer,
    category,
  } = values || {};

  return category !== 'Money Market'
    ? totalPayable / 100 / conversion_ratio
    : totalPayable / 100;
};
const grandTotalUSD = (props, values) => {
  return grandTotal(props, values);
};

export default {
  formatTransactionData,
  formatTransctionType,
  calculateTransactionPayable,
  calculateTransactionPayableByBuyer,
  calculateTransactionRefundable,
  calculatePayableEscrow,
  calculateTransactionEscrow,
  calculatePayableShipping,
  calculateShipping,
  rformatTransactionData,
  calculateCardFee,
  calculateUSSDCardFee,
  calculateUsdCardFee,
  toLocaleDisplay,
  escrowFees,
  cardFees,
  netAmount,
  productAmount,
  payoutAmount,
  refundableAmount,
  logisticFees,
  grandTotalUSD,
  grandTotal,
  transactionCurrency
};
