import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Dashboard from "./../components/_dashboard";
import Support from "./../components/_support";
import Apis from "./../components/_api";
import Disputes from "./../components/_disputes";
import Courier from "./../components/_courier";
import Dispatcher from "./../components/_dispatchers";
import Delivery from "./../components/_delivery";
import P2P from "./../views/transaction/Main";
import Pending from "./../views/pending";
import PaymentHistory from "./../components/_payments";
import PayOutDetails from "./_payout";
import PayOutHistory from "./_payouts";
import PayOutReport from "./_payout_report";
import RefundReport from "./_refund_report";
import Order from "./../components/_order";
import Settings from "../components/_settings";
import Profile from "../components/_profile";
import RaiseDispute from "../components/_raise";
import {
  userActions,
  orderActions,
  payoutActions,
  logisticActions,
} from "../actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Transactions from "./_transactions";
import SingleTransaction from "../components/_single_transaction";
import Business from "./../components/_business";
import Help from "./../views/transaction/Help";
import Authorization from "../helpers/Authorization";

import swal from "@sweetalert/with-react";
import ShareableLinks from "../views/ShareableLinks";
class Main extends Component {
  componentDidMount() {
    if (!localStorage.getItem("user")) {
      if (this.props.location.pathname.includes("transaction/pay")) {
        return this.props.history.push("/login/buyer");
      } else if (this.props.location.pathname.includes("transaction/seller")) {
        this.props.history.push("/login/seller");
      } else if (this.props.location.pathname.includes("transaction/buyer")) {
        this.props.history.push("/login/buyer");
      } else {
        this.props.history.push("/login");
      }
    } else {
      if (
        localStorage.getItem("trust_user_type") === null &&
        localStorage.getItem("trust_user_type") !== "seller" &&
        localStorage.getItem("trust_user_type") !== "customer"
      ) {
        this.props.history.push("/login");
        swal("Error", "You are not authorized, Log in", "error");
      } else {
        // console.log(this.props.user);

        let type = localStorage.getItem("trust_user_type");
        this.props.getUser(type, this.props.history);
        this.props.getOrders(type, this.props.history);
        this.props.viewDisputes(type, this.props.history);
        this.props.viewPayments(type, this.props.history);
        this.props.viewPayouts(type, this.props.history);
        this.props.viewDashboard(
          localStorage.getItem("trust_user_type"),
          this.props.history
        );
        if (type === "seller") {
          this.props.viewKey("seller", this.props.history);
          this.props.viewCouriers(this.props.history);
          this.props.viewPayout("seller", this.props.history);
          this.props.getDispatchers("seller", this.props.history);
        }

        if (type === "marketplace") {
          this.props.viewKey(type, this.props.history);
        }
      }
    }
  }

  render() {
    return (
      <Route>
        <Switch>
          <Route
            exact
            redirect="/app/dashboard"
            path="/"
            component={Authorization(Dashboard)}
          />
          <Route
            exact
            path="/app/dashboard"
            component={Authorization(Dashboard)}
          />
          <Route exact path="/app/support" component={Support} />
          <Route exact path="/app/api" component={Authorization(Apis)} />
          <Route
            exact
            path="/app/disputes/all"
            component={Authorization(Disputes)}
          />
          <Route
            exact
            path="/app/logistics/dispatcher"
            component={Authorization(Dispatcher)}
          />
          <Route
            exact
            path="/app/logistics/courier"
            component={Authorization(Courier)}
          />
          <Route
            exact
            path="/app/delivery"
            component={Authorization(Delivery)}
          />
          <Route exact path="/app/pending" component={Pending} />
          <Route
            exact
            path="/app/payment/history"
            component={Authorization(PaymentHistory)}
          />
          <Route
            exact
            path="/app/payout/details"
            component={Authorization(PayOutDetails)}
          />

          <Route exact path="/app/payout/history" component={PayOutHistory} />
          <Route exact path="/app/report/payout" component={PayOutReport} />
          <Route exact path="/app/report/refund" component={RefundReport} />
          <Route
            exact
            path="/app/profile/view"
            component={Authorization(Profile)}
          />
          <Route
            exact
            path="/app/dispute/raise/:id"
            component={Authorization(RaiseDispute)}
          />
          <Route exact path="/app/orders" component={Authorization(Order)} />
          <Route
            exact
            path="/app/transactions/:status?/:id?"
            component={Authorization(Transactions)}
          />
          <Route
            exact
            path="/app/transaction/:type?/:id"
            component={Authorization(SingleTransaction)}
          />
          <Route exact path="/app/p2p/help" component={Authorization(Help)} />
          <Route
            exact
            path="/app/business/:msg?/:status?/:email?"
            component={Business}
          />
          <Route
            exact
            path="/app/settings/:type?"
            component={Authorization(Settings)}
          />
          <Route
            exact
            path="/app/shareable-links"
            component={Authorization(ShareableLinks)}
          />
          <Route
            path="/app/transaction"
            component={Authorization(() => (
              <P2P />
            ))}
          />
          <Route
            path="/app/buyer"
            component={() => (
              <div>
                <Route>
                  <Switch>
                    <Route
                      exact
                      path="/app/buyer"
                      component={Dashboard}
                    ></Route>
                    <Route
                      exact
                      path="/app/buyer/dashboard"
                      component={Dashboard}
                    ></Route>
                    {/* <Route exact path="/app/buyer/route_name" component={}></Route> */}
                  </Switch>
                </Route>
              </div>
            )}
          />

          <Route
            path="/app/seller"
            component={() => (
              <div>
                <Route>
                  <Switch>
                    <Route
                      exact
                      path="/app/seller"
                      component={Dashboard}
                    ></Route>
                    <Route
                      exact
                      path="/app/seller/dashboard"
                      component={Dashboard}
                    ></Route>
                    <Route
                      exact
                      path="/app/seller/support"
                      component={Support}
                    />
                    <Route exact path="/app/seller/api" component={Apis} />
                    <Route
                      exact
                      path="/app/seller/disputes/all"
                      component={Disputes}
                    />
                    <Route
                      exact
                      path="/app/seller/logistics/dispatcher"
                      component={Dispatcher}
                    />
                    <Route
                      exact
                      path="/app/seller/logistics/courier"
                      component={Courier}
                    />
                    <Route
                      exact
                      path="/app/seller/delivery"
                      component={Delivery}
                    />
                    <Route
                      exact
                      path="/app/seller/payment/history"
                      component={PaymentHistory}
                    />
                    <Route
                      exact
                      path="/app/seller/payout/details"
                      component={PayOutDetails}
                    />
                    <Route
                      exact
                      path="/app/seller/payout/history"
                      component={PayOutHistory}
                    />
                    <Route
                      exact
                      path="/app/seller/profile/view"
                      component={Profile}
                    />

                    <Route
                      exact
                      path="/app/seller/dispute/raise/:id"
                      component={RaiseDispute}
                    />

                    <Route exact path="/app/seller/orders" component={Order} />
                    <Route
                      exact
                      path="/app/seller/transactions"
                      component={Transactions}
                    />
                    <Route
                      exact
                      path="/app/seller/transaction/:id"
                      component={SingleTransaction}
                    />
                    {/* <Route exact path="/app/seller/confirmation/:id" component={Confirmation} /> */}
                    <Route
                      exact
                      path="/app/seller/settings"
                      component={Settings}
                    />
                    <Route
                      path="/app/seller/transaction"
                      component={() => <P2P />}
                    />
                    {/* <Route exact path="/app/seller/route_name" component={}></Route> */}
                  </Switch>
                </Route>
              </div>
            )}
          />
        </Switch>
      </Route>
    );
  }
}
const mapStateToProps = (state) => {
  // const { loading,success} = state.support;
  const { user } = state.users;
  // const alert  = state.alert;
  return {
    user,
  };
};
const mapDispatchToProps = (dispatch) => ({
  viewCouriers: (history) =>
    dispatch(logisticActions.delivery.viewCouriers(history)),
  viewKey: (type, history) => dispatch(userActions.user.viewKey(type, history)),
  viewDisputes: (type, history) =>
    dispatch(orderActions.order.viewDisputes(type, history)),
  getUser: (type, history) =>
    dispatch(userActions.user.getDetails(type, history)),
  viewPayout: (type, history) =>
    dispatch(payoutActions.payout.view(type, history)),
  viewPayouts: (type, history) =>
    dispatch(payoutActions.payout.all(type, history)),
  viewPayments: (type, history) =>
    dispatch(orderActions.order.viewPayments(type, history)),
  viewDashboard: (type, history) =>
    dispatch(userActions.user.viewDashboard(type, history)),
  getOrders: (type, history) =>
    dispatch(orderActions.order.view(type, history)),
  getBanks: (type, history) =>
    dispatch(payoutActions.payout.getBanks(type, history)),
  getDispatchers: (type, history) =>
    dispatch(userActions.user.getDispatchers(type, history)),
  //  clear:()=> dispatch(alertActions.alert.clear())
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
