import React, { Component } from "react";
import Modal from "react-responsive-modal";
import countries from "../../helpers/countries";
import states from "../../helpers/states";

class CreateAddress extends Component {

    state = {

        details: {
            street: "",
            city: "",
            state: "",
            country: "",
            post_code: "",
            apartment: "",
            sp_id: ""
        },
        submitted: false
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const details = {
            "longitude": '', "latitude": '', "is_default": 1,
            "post_code": "1000001",
            "phone_number": localStorage.getItem("trust_user_phone"), "role": 1, ...this.state.details
        };
        if (!details.post_code || details.post_code === "") {
            details.post_code = "1000001"
        }
        this.props.handleSubmit(details, this.props.role)
    };
    handleChange = (e) => {
        let {name, value} = e.target;
        let {details} = this.state;
        this.setState({details: {...details, [name]: value}});

    };

    render() {
        const {adding, street, city, country, state, post_code, apartment, serviceProviders, addSpAddress} = this.props;
        return (
            <>
                <Modal
                    open={this.props.add || addSpAddress} onClose={() => this.props.onCloseModal(this.props.add ? "add" : "addSpAddress")} center>
                    <form style={{width: "500px"}} onSubmit={this.handleSubmit}>
                        <div className="modal-header">
                            <h4 className="modal-title">Enter Address Information</h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                {/* {alert && alert.message && <div class={`alert buyer-alert ${alert.type}`} ><a
                                                aria-label="close" class="close alert-close">Ã—</a> <span   class="alert-message-content">{alert.message}</span></div>} */}
                            </div>
                            <p>* Indicates mandatory fields</p>

                            { addSpAddress && serviceProviders ?
                                <div className="row form-group">
                                <div className="col-sm-12">
                                    <label>Service Provider* </label>{" "}
                                    <select
                                        name="sp_id"
                                        onChange={this.handleChange}
                                        className="form-control"
                                        required
                                    >
                                        <option value="">Select Service Provider </option>
                                        {serviceProviders.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.firstname || ""} {item.lastname || ""} ({item.email || ""})
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div> : null}

                            <div className="row form-group">
                                <div className="col-sm-6">
                                    <label>Street *</label>{" "}
                                    <input
                                        name="street"
                                        value={street}
                                        onChange={this.handleChange}
                                        type="text"
                                        className="form-control"
                                        required
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <label>State* </label>{" "}
                                    <input
                                        name="state"
                                        value={state}
                                        onChange={this.handleChange}
                                        type="text"
                                        className="form-control"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-sm-12">
                                    <label>Apartment, Suite </label>{" "}
                                    <input
                                        name="apartment"
                                        value={apartment}
                                        onChange={this.handleChange}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-sm-6">
                                    <label>City* </label>{" "}
                                    <input
                                        name="city"
                                        value={city}
                                        onChange={this.handleChange}
                                        type="text"
                                        className="form-control"
                                        required
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <label>Post Code</label>{" "}
                                    <input
                                        name="post_code"
                                        value={post_code}
                                        onChange={this.handleChange}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-sm-12">
                                    <label>Country* </label>{" "}
                                    <select
                                        name="country"
                                        onChange={this.handleChange}
                                        className="form-control"
                                        required
                                    >
                                        <option value={country || ""}>{country || "Select A Country"} </option>
                                        {[{name: 'Nigeria', code: 'NG'},].map((item) => (
                                            <option key={item.code} value={item.name}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="submit" disabled={adding} className="btn btn-primary">
                                {adding ? "Submitting ..." : "Submit"}
                            </button>
                        </div>
                    </form>

                </Modal>
            </>
        );
    }
}

class UpdateAddress extends Component {

    state = {

        set_default: false,
        delete: false,
        detail: {
            street: "",
            city: "",
            state: "",
            country: "",
            post_code: "",
            apartment: ""
        },
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.handleSubmit(this.props.detail, this.props.role)
    };

    render() {
        const {deleting, updating,  detail} = this.props;

        const action = this.props.delete ? "delete" : "set_default"
        return (
            <>
                <Modal open={this.props.delete || this.props.set_default} onClose={() => this.props.onCloseModal(action)} center>
                    {detail && action === "delete"?
                        <div>
                            <div className="modal-header">
                                <h4 className="modal-title">Delete Address</h4>
                            </div>
                            <div className="modal-body">
                                <div className="row form-group">
                                    <div className="col-sm-12">
                                        <p>Are you sure you want to delete this address <br/>
                                            <br/>
                                            {detail.street}
                                            {detail.apartment} {detail.city} {detail.state} {detail.country}?
                                            <br/>
                                            <br/>This process cannot be reversed.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={() => this.props.onCloseModal("delete")} data-dismiss="modal"
                                        className="btn btn-primary">Cancel
                                </button>
                                &nbsp;&nbsp;
                                <button onClick={this.handleSubmit} disabled={deleting} type="button"
                                        className="btn btn-danger">{deleting ? 'Deleting ...' : 'Delete'}</button>
                            </div>
                        </div> : null}
                    {detail && action === "set_default" ?
                        <div>
                            <div className="modal-header">
                                <h4 className="modal-title">Set as Default</h4>
                            </div>
                            <div className="modal-body">
                                <div className="row form-group">
                                    <div className="col-sm-12">
                                        <p>Are you sure you want to set this address as your default pickup address<br/>
                                            <br/>
                                            {detail.street}
                                            {detail.apartment} {detail.city} {detail.state} {detail.country}?</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={() => this.props.onCloseModal("set_default")}
                                        data-dismiss="modal"
                                        className="btn btn-primary">Cancel
                                </button>
                                &nbsp;&nbsp;
                                <button type="button" disabled={updating} onClick={this.handleSubmit}
                                        className="btn btn-danger">{updating ? "Updating" : "Set as Default"}</button>
                            </div>
                        </div>
                        : null}

                </Modal>            </>
        );
    }
}


export {
    CreateAddress,
    UpdateAddress
};
