import { createStore, applyMiddleware,compose  } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';

const loggerMiddleware = createLogger();

//  const store = createStore(
//     rootReducer,
//    compose( applyMiddleware(
//         thunkMiddleware,
//         loggerMiddleware
//     ),
//         window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//     )
    
// );
const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
export default store;