import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import RefundReport from '../views/refundReport';
import { orderActions, payoutActions } from '../actions';
// import { payoutActions } from '../actions';

const mapStateToProps = (state) =>{
    const { loading, payouts} = state.payout;
    const { user } = state.users;
    

    const alert  = state.alert;

    const payment= state.orders.payment;
    const paymentLoading= state.orders.paymentLoading;
    
    return {
        alert,
        loading,
        payouts,
        user,
        payment,
        paymentLoading
    };
     
     
 }
 const mapDispatchToProps = (dispatch) => ({
    getTransactionPaymentInformation: (type, data, history) => dispatch(orderActions.order.getTransactionPaymentInformation(type, data, history)),
    clearTransactionPaymentInformation: () => dispatch(orderActions.order.clearTransactionPaymentInformation()),
    dispatch
}
)
 

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RefundReport));