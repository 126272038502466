
import React,{Component }from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Disputes from '../views/Disputes';
import { orderActions } from '../actions';
import Order from '../views/Order';
import RaiseDispute from '../views/raiseDispute';
// import { payoutActions } from '../actions';

const findOrder = (orders,id)=>{
    
    return orders.find(a=>a.id==id)
    
  }
class Raise extends Component{
    // componentDidMount(){
    //     this.props.view("seller",this.props.history);
    // }
    render(){
        var order ={};
        this.props.orders ? order = findOrder(this.props.orders,this.props.match.params.id ) :order= {}
        return(
            <div>
            {order!=={} && this.props.user &&
          <RaiseDispute order={order} user={this.props.user} loading={this.props.loading} alert={this.props.alert} raise={this.props.raise} dispatch={this.props.dispatch}/>
        }
          </div>
        )
    }

}

const mapStateToProps = (state) =>{
    const { loading,orders} = state.orders;
    const {user} = state.users;
    

    const alert  = state.alert;
    
    return {
        alert,
        loading,
        user,
        orders
  
    };
     
     
 }
 const mapDispatchToProps = (dispatch) => ({
     dispatch,
    raise:(type,data,history)=>dispatch(orderActions.order.raiseDispute(type,data,history)),
    view:(type,history)=>dispatch(orderActions.order.viewDisputes(type,history)),
    
    
}
)
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Raise));