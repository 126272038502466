import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Modal from "react-responsive-modal";
import * as moment from "moment";
import { alertActions } from "../actions";
import swal from "@sweetalert/with-react";

import { ClipLoader } from "react-spinners";
import {loaderOverride} from "../helpers/styleHelper"



const formatToSixDigits = (number) => {
  var output = number + "";
  while (output.length < 6) {
    output = "0" + output;
  }
  return output;
};

class paymentHistory extends Component {
  state = {
    filterable: false,
    notCustom: true,
    period: "All",
    assignModal: false,
    fullModal: false,
    from_date: "",
    filter: "",
    to_date: "",
    data: [],
    order: "",
    fields: {
      order_id: 0,
      delivery_man_id: 0,
    },
    payments: [],
  };

  componentDidMount() {
    if (this.props.payments) {
      console.log(this.props.payments);
      this.setState({ data: [...this.props.payments] });
    }
  }

  componentWillReceiveProps(props) {
    if (
      props.alert &&
      props.alert.message &&
      props.alert.type === "alert-danger"
    ) {
      swal("Error", props.alert.message, "error").then(() => {
        props.dispatch(alertActions.alert.clear());
      });
    }

    if (
      props.alert &&
      props.alert.message &&
      props.alert.type === "alert-success"
    ) {
      swal("Success", props.alert.message, "success").then(() => {
        props.dispatch(alertActions.alert.clear());
      });
      this.onCloseModal("assignModal");
    }

    if (props.payments) {
      this.setState({ data: [...props.payments] });
      this.setState({ payments: props.payments });
    }
  }
  handleSelect = (e) => {
    const { value } = e.target;
    if (value === "Custom") {
      this.setState({ notCustom: !this.state.notCustom });
    }
    this.filterPeriod(value);
    this.setState({ period: value });
  };
  filterPeriod = (value) => {
    const data = this.state.payments;
    console.log(data);

    if (value === "All") {
      this.table_rows = data;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({ notCustom: true, data: [...this.props.payments] });
    }

    if (value === "This Week") {
      var ordersThisWeek = [];

      for (var i = 0; i < data.length; i++) {
        var now = moment();
        var input = moment(data[i].created_at);
        var isThisWeek = now.isoWeek() === input.isoWeek();

        if (isThisWeek) {
          ordersThisWeek.push(data[i]);
        }
      }

      this.table_rows = ordersThisWeek;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({ notCustom: true, data: [...this.table_rows] });
    }

    if (value === "This Month") {
      var ordersThisMonth = [];

      for (let i = 0; i < data.length; i++) {
        let input = moment(data[i].created_at);
        var isThisMonth = input.isSame(new Date(), "month");

        if (isThisMonth) {
          ordersThisMonth.push(data[i]);
        }
      }

      this.table_rows = ordersThisMonth;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({ notCustom: true, data: [...this.table_rows] });
    }

    if (value === "60 Days Ago") {
      var ordersSixtyDaysAgo = [];

      for (let i = 0; i < data.length; i++) {
        var sixty_days_ago = moment().subtract(60, "days");

        var isBetweenSixtyDays = moment(data[i].created_at).isAfter(
          sixty_days_ago
        );

        if (isBetweenSixtyDays) {
          ordersSixtyDaysAgo.push(data[i]);
        }
      }

      this.table_rows = ordersSixtyDaysAgo;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({ notCustom: true, data: [...this.table_rows] });
    }

    if (value === "90 Days Ago") {
      var ordersNinetyDaysAgo = [];

      for (let i = 0; i < data.length; i++) {
        var ninety_days_ago = moment().subtract(90, "days");

        var isBetweenNinetyDays = moment(data[i].created_at).isAfter(
          ninety_days_ago
        );

        if (isBetweenNinetyDays) {
          ordersNinetyDaysAgo.push(data[i]);
        }
      }

      this.table_rows = ordersNinetyDaysAgo;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({ notCustom: true, data: [...this.table_rows] });
    }

    if (value === "Custom") {
      this.table_rows = data;
      this.to_date = null;
      this.from_date = null;
    }
  };

  filterDates = (data) => {
    var ordersBetweenTheTwoDates = [];

    for (var i = 0; i < data.length; i++) {
      var isBetweenDates = moment(data[i].created_at).isBetween(
        this.state.from_date,
        this.state.to_date
      );
      if (isBetweenDates) {
        ordersBetweenTheTwoDates.push(data[i]);
      }
    }

    this.table_rows = ordersBetweenTheTwoDates;
    this.page = 1;
    return this.table_rows;
  };
  handleDate = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  filterTable = (filter) => {
    var data = this.state.payments;
    var filteredRows = [];
    if (filter !== "") {
      for (var i = 0; i < data.length; i++) {
        if (
          (data[i].transaction_number &&
            formatToSixDigits(data[i].transaction_number).match(
              new RegExp(filter, "gi")
            )) ||
          (data[i].created_at && data[i].created_at.includes(filter)) ||
          (data[i].payment_ref &&
            data[i].payment_ref.toUpperCase().includes(filter.toUpperCase())) ||
          (data[i].amount_payed &&
            data[i].amount_payed > 0 &&
            String(data[i].amount_payed).match(new RegExp(filter, "gi")))
        ) {
          filteredRows.push(data[i]);
        }
      }

      this.setState({ data: [...filteredRows] });
    } else {
      this.setState({ data: [...this.state.payments] });
    }
  };

  handleFilter = (e) => {
    const { value } = e.target;
    this.setState({ filter: value }, () => this.filterTable(this.state.filter));
  };
  onOpenModal = (name, payments) => {
    this.setState({ [name]: true });
    this.setState({ payments: payments });
  };

  onCloseModal = (name) => {
    this.setState({ [name]: false });
    this.setState({ payments: "" });
  };

  formatAmount = (amount) => {
    let a = amount + "";
    let naira = a.slice(0, -3);
    let kobo = a.substr(a.length - 2);
    return naira + "." + kobo;
  };

  render() {
    const { loading, user } = this.props;
    const { filterable } = this.state;
    console.log(loading);
    var columns = [];
    // eslint-disable-next-line no-lone-blocks
    {
      user && user.role && user.role.name === "MarketPlace"
        ? (columns = [
            {
              Header: () => (
                <span>
                  <b>SN</b>
                </span>
              ),
              id: "sn",
              filterable,
              maxWidth: 80,
              accessor: (d) => d, // String-based value accessors!
              Cell: (row) => {
                return <span>{row.index + 1}</span>;
              },
            },
            {
              Header: () => (
                <span>
                  <b>Order ID</b>
                </span>
              ),
              id: "id",
              filterable,
              maxWidth: 140,
              accessor: (d) => d, // String-based value accessors!
              Cell: (props) => (
                <a
                  href="#/app/payment/history"
                  onClick={() => this.onOpenModal("fullModal", props.value)}
                >
                  {props.value.transaction_number}
                </a>
              ),
            },
            {
              Header: () => (
                <span>
                  <b>Seller</b>
                </span>
              ),
              id: "firstname",
              filterable,
              maxWidth: 140,
              accessor: (d) => d, // String-based value accessors!
              Cell: (props) => (
                <span className="number">
                  {props.value == null
                    ? ""
                    : props.value.business.users.firstname}
                  <br />
                  {props.value == null
                    ? ""
                    : props.value.business.users.lastname}
                </span>
              ), // Custom cell components!
            },
            {
              Header: () => (
                <span>
                  <b>Buyer</b>
                </span>
              ),
              id: "firstname",
              filterable,
              maxWidth: 140,
              accessor: (d) => d, // String-based value accessors!
              Cell: (props) => (
                <span className="number">
                  {props.value == null ? "" : props.value.customer.firstname}
                  <br />
                  {props.value == null ? "" : props.value.customer.lastname}
                </span>
              ), // Custom cell components!
            },
            {
              id: "amount", // Required because our accessor is not a string
              Header: () => (
                <span>
                  <b>Amount Paid</b>
                </span>
              ),
              filterable,
              maxWidth: 170,
              accessor: (d) => d, // Custom value accessors!
              Cell: (props) => (
                <span className="number">
                  {props.value === null
                    ? ""
                    : "₦" +
                      (props.value.amount / 100).toLocaleString("en", {
                        minimumFractionDigits: 2,
                      })}
                </span>
              ), // Custom cell components!
            },
            {
              id: "transaction",
              filterable,
              maxWidth: 170,
              Header: () => (
                <span>
                  <b>Date Paid</b>
                </span>
              ),
              accessor: (d) => d, // String-based value accessors!
              Cell: (props) => (
                <span>{props.value == null ? "" : props.value.created_at}</span>
              ),
            },
            {
              Header: () => (
                <span>
                  <b>Transaction No.</b>
                </span>
              ),
              id: "reference",
              filterable,
              accessor: (d) => d,
              Cell: (props) => (
                <span className="number">
                  {props.value === null ? "" : props.value.payment_ref}
                </span>
              ), // Custom cell components!
            },
          ])
        : (columns = [
            {
              Header: () => (
                <span>
                  <b>SN</b>
                </span>
              ),
              id: "sn",
              filterable,
              maxWidth: 80,
              accessor: (d) => d, // String-based value accessors!
              Cell: (row) => {
                return <span>{row.index + 1}</span>;
              },
            },
            {
              Header: () => (
                <span>
                  <b>Order ID</b>
                </span>
              ),
              id: "id",
              filterable,
              maxWidth: 140,
              accessor: (d) => d, // String-based value accessors!
              Cell: (props) => (
                <a
                  href="#/app/payment/history"
                  onClick={() => this.onOpenModal("fullModal", props.value)}
                >
                  {props.value.transaction_number}
                </a>
              ),
            },
            {
              id: "amount", // Required because our accessor is not a string
              Header: () => (
                <span>
                  <b>Amount Paid</b>
                </span>
              ),
              filterable,
              maxWidth: 170,
              accessor: (d) => d, // Custom value accessors!
              Cell: (props) => (
                <span className="number">
                  {props.value === null
                    ? ""
                    : "₦" +
                      (props.value.amount / 100).toLocaleString("en", {
                        minimumFractionDigits: 2,
                      })}
                </span>
              ), // Custom cell components!
            },
            {
              id: "transaction",
              filterable,
              maxWidth: 170,
              Header: () => (
                <span>
                  <b>Date Paid</b>
                </span>
              ),
              accessor: (d) => d, // String-based value accessors!
              Cell: (props) => (
                <span>{props.value == null ? "" : props.value.created_at}</span>
              ),
            },
            {
              Header: () => (
                <span>
                  <b>Transaction No.</b>
                </span>
              ),
              id: "reference",
              filterable,
              accessor: (d) => d,
              Cell: (props) => (
                <span className="number">
                  {props.value === null ? "" : props.value.payment_ref}
                </span>
              ), // Custom cell components!
            },
          ]);
    }

    return (
      <div>
        <Modal
          open={this.state.fullModal}
          onClose={() => this.onCloseModal("fullModal")}
          center
        >
          <form>
            <div className="modal-header">
              <h4 className="modal-title">
                <small>
                  Transaction{" "}
                  {formatToSixDigits(
                    this.state.payments &&
                      this.state.payments.transaction_number
                  )}
                </small>
              </h4>
            </div>
            <div className="modal-body">
              <div className="row"></div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Payment Number</label> <br />{" "}
                    <input
                      type="text"
                      readOnly
                      defaultValue={formatToSixDigits(
                        this.state.payments && this.state.payments.id
                      )}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Reference</label> <br />{" "}
                    <input
                      type="text"
                      readOnly
                      defaultValue={
                        this.state.payments && this.state.payments.payment_ref
                      }
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Transaction Date</label> <br />{" "}
                    <input
                      type="text"
                      readOnly
                      defaultValue={
                        this.state.payments && this.state.payments.created_at
                          ? this.state.payments.created_at
                          : this.state.payments.payment_date
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Buyer Name</label> <br />{" "}
                    <input
                      type="text"
                      readOnly
                      defaultValue={
                        this.state.payments &&
                        this.state.payments.customer &&
                        this.state.payments.customer.firstname +
                          " " +
                          this.state.payments.customer.lastname
                      }
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Seller Name</label> <br />{" "}
                    <input
                      type="text"
                      readOnly
                      defaultValue={
                        this.state.payments &&
                        this.state.payments.business &&
                        this.state.payments.business.business_name
                          ? this.state.payments.business.business_name
                          : "AtaraPay Marketplace"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      <small>
                        <b>Marketplace Business Name</b>
                      </small>
                    </label>{" "}
                    <br />{" "}
                    <input
                      type="text"
                      readOnly
                      defaultValue={
                        user && user.role && user.role.name === "Seller"
                          ? "N/A"
                          : this.state.payments &&
                            this.state.payments.business &&
                            this.state.payments.business.business_name
                          ? this.state.payments.business.business_name
                          : "AtaraPay Marketplace"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Product Amount</label> <br />{" "}
                    <input
                      type="text"
                      readOnly
                      defaultValue={
                        this.state.payments && this.state.payments.amount
                          ? "₦" +
                            (this.state.payments.amount / 100).toLocaleString(
                              "en",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : "₦" +
                            (0).toLocaleString("en", {
                              minimumFractionDigits: 2,
                            })
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Shipping Amount</label> <br />{" "}
                    <input
                      type="text"
                      readOnly
                      defaultValue={
                        this.state.payments && this.state.payments.shipping_cost
                          ? "₦" +
                            this.state.payments.shipping_cost.toLocaleString(
                              "en",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : "₦" +
                            (0).toLocaleString("en", {
                              minimumFractionDigits: 2,
                            })
                      }
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Escrow Fee Paid By</label> <br />{" "}
                    <input
                      type="text"
                      readOnly
                      defaultValue={
                        this.state.payments &&
                        this.state.payments.payment_info &&
                        this.state.payments.payment_info.escrow_fee_bearer
                          ? this.state.payments.payment_info.escrow_fee_bearer
                          : "N/A"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      <small>
                        <b>Payment Transaction Fee</b>
                      </small>
                    </label>{" "}
                    <br />{" "}
                    <input
                      type="text"
                      readOnly
                      defaultValue={"1.5%"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Payment Channel</label> <br />{" "}
                    <input
                      type="text"
                      readOnly
                      defaultValue={
                        this.state.payments &&
                        this.state.payments.method === "card"
                          ? "Paystack"
                          : "Monnify"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Payment Status</label> <br />{" "}
                    <input
                      type="text"
                      readOnly
                      defaultValue={
                        this.state.payments &&
                        this.state.payments.gateway_response
                      }
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Payment Value</label> <br />{" "}
                    <input
                      type="text"
                      readOnly
                      defaultValue={
                        this.state.payments &&
                        "₦" +
                          (
                            this.state.payments.amount_payed / 100
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          })
                      }
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <br />
              <br />
            </div>
          </form>
        </Modal>
        <div className="content-wrapper" style={{ minHeight: 136 }}>
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-12">
                  <h1 className="m-0 text-dark">Payment History</h1>
                  <div className="attop">
                    <p>
                      On this page, you will find details of payments made by
                      the Buyer on orders placed from the Seller’s website or a
                      P2P escrow transaction
                    </p>
                  </div>{" "}
                  <br />
                </div>
              </div>
            </div>
          </div>
          {!loading ? (
            <section className="content">
              <div className="container-fluid">
                <br />
                <div className="row ">
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="alert animated bounceIn alert-danger d-none search-alert">
                      <a aria-label="close" className="close alert-close">
                        ×
                      </a>
                      <span></span>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12 col-sm-2">
                            <div className="form-group">
                              <label htmlFor="period">Period</label>
                              <select
                                className="form-control"
                                name="filter"
                                onChange={this.handleSelect}
                              >
                                <option value="All">All</option>
                                <option value="This Week">This Week</option>
                                <option value="This Month">This Month</option>
                                <option value="60 Days Ago">60 Days Ago</option>
                                <option value="90 Days Ago">90 Days Ago</option>
                                <option value="Custom">Custom</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-12 col-sm-2">
                            <div className="form-group">
                              <label htmlFor="filter">Filter</label>
                              <input
                                type="text"
                                placeholder="Filter"
                                className="form-control"
                                value={this.state.filter}
                                onChange={this.handleFilter}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-3">
                            <div className="form-group">
                              <label htmlFor="from">Date From</label>{" "}
                              <input
                                type="date"
                                name="from_date"
                                value={this.state.from_date}
                                disabled={this.state.notCustom}
                                onChange={this.handleDate}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-3">
                            <div className="form-group">
                              <label htmlFor="from">Date To</label>{" "}
                              <input
                                type="date"
                                name="to_date"
                                value={this.state.to_date}
                                disabled={this.state.notCustom}
                                onChange={this.handleDate}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-2">
                            <div className="form-group">
                              {" "}
                              <label>&nbsp;</label>
                              <div className="row">
                                <div className="col-12">
                                  <button
                                    disabled={this.state.notCustom}
                                    onClick={() => {
                                      this.setState({
                                        data: [
                                          ...this.filterDates(this.state.data),
                                        ],
                                      });
                                    }}
                                    className="btn btn-info btn-block form-control"
                                  >
                                    <center>Search</center>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                        <br />
                        <div className="table-responsive">
                          <ReactTable
                            defaultPageSize={5}
                            filterable
                            data={this.state.data}
                            columns={columns}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <br />
              </div>
            </section>
          ) : (
            <div className="sweet-loading">
              <ClipLoader
                cssOverride={loaderOverride}
                size={70}
                color={"blue"}
                loading={loading}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default paymentHistory;
