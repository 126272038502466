import { reportService } from "../service.js";
import { alertActions, userActions } from "./index.js";
import { ASSET_URL, reportConstants } from "../constants";
import { download } from "../helpers/utility.js";

const getPayoutReport = (type, history) => {
  return (dispatch) => {
    dispatch(request());

    reportService.report
      .getPayoutReport(type)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err?.response) {
          if (err?.response.status === 401) {
            dispatch(userActions.user.logout(type, history));
            dispatch(failure(err?.response.data.message));
            dispatch(
              alertActions.alert.error(
                err?.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err?.response.data.message));
            dispatch(
              alertActions.alert.error(
                err?.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err?.request) {
          dispatch(failure(err?.message));
          dispatch(alertActions.alert.error(err?.message?.replace(/_/g, " ")));
        } else {
          dispatch(failure(err?.message));
          dispatch(alertActions.alert.error(err?.message?.replace(/_/g, " ")));
        }
      });
  };
  function request() {
    return { type: reportConstants.report.GET_PAYOUT_REPORT_REQUEST };
  }
  function success(report) {
    return {
      type: reportConstants.report.GET_PAYOUT_REPORT_SUCCESS,
      report,
    };
  }
  function failure(error) {
    return {
      type: reportConstants.report.GET_PAYOUT_REPORT_FAILURE,
      error,
    };
  }
};

const generateReport = (type, data, report_type, history) => {
  return (dispatch) => {
    dispatch(request());

    reportService.report
      .generateReport(type, data, report_type)
      .then((res) => {
        // console.log(res);
        if (res.data.status === "success") {
          const isDownload = res.data.data.status == "Downloaded";
          dispatch(success(res.data.data));
          dispatch(
            alertActions.alert.success(
              isDownload
                ? "Report generated and downloaded successfully"
                : "Report has been successfully sent to your email"?.replace(
                    /_/g,
                    " "
                  )
            )
          );
          history.push("/app/report/payout");
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err?.response) {
          if (err?.response.status === 401) {
            dispatch(userActions.user.logout("seller", history));
            dispatch(failure(err?.response.data.message));
            dispatch(
              alertActions.alert.error(
                err?.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err?.response.data.message));
            dispatch(
              alertActions.alert.error(
                err?.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err?.request) {
          console.log(err?.request);
          console.log(err?.message);
          dispatch(failure(err?.message));
          dispatch(alertActions.alert.error(err?.message?.replace(/_/g, " ")));
        } else {
          console.log(err?.message);
          dispatch(failure(err?.message));
          dispatch(alertActions.alert.error(err?.message?.replace(/_/g, " ")));
        }

        console.log(err?.config);
      });
  };
  function request() {
    return { type: reportConstants.report.GENERATE_REPORT_REQUEST };
  }
  function success(report) {
    console.log("fileLink", ASSET_URL + report.file);
    if (report.request_type == "download")
      download(`${ASSET_URL + report.file}`, "atarapayPayoutReport");
    return { type: reportConstants.report.GENERATE_REPORT_SUCCESS, report };
  }
  function failure(error) {
    return { type: reportConstants.report.GENERATE_REPORT_FAILURE, error };
  }
};

const getMarketPlaceSellers = (type, history) => {
  return (dispatch) => {
    reportService.report
      .getMarketPlaceSellers(type)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
        } else {
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err?.response) {
          if (err?.response.status === 401) {
            dispatch(userActions.user.logout(type, history));
            dispatch(
              alertActions.alert.error(
                err?.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(
              alertActions.alert.error(
                err?.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err?.request) {
          dispatch(alertActions.alert.error(err?.message?.replace(/_/g, " ")));
        } else {
          dispatch(alertActions.alert.error(err?.message?.replace(/_/g, " ")));
        }
      });
  };

  function success(marketPlaceSellers) {
    return {
      type: reportConstants.report.GET_MARKETPLACE_SELLERS_SUCCESS,
      marketPlaceSellers,
    };
  }
};

export const report = {
  getPayoutReport,
  generateReport,
  getMarketPlaceSellers,
};
