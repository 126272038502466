import React, { Component } from "react";
import { alertActions } from "../actions";
import PhoneInput, { formatPhoneNumber } from "react-phone-number-input";
import { Link, withRouter } from "react-router-dom";
import swal from "@sweetalert/with-react";

const initialState = {
  email: "",
  submitted: false,
};

class ForgotPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      submitted: false,
    };
  }

  componentWillReceiveProps = (props) => {
    if (
      props.alert &&
      props.alert.message &&
      props.alert.type == "alert-danger"
    ) {
      swal("Error", props.alert.message, "error").then(() => {
        this.props.dispatch(alertActions.alert.clear());
      });
    }
    if (
      props.alert &&
      props.alert.message &&
      props.alert.type == "alert-success"
    ) {
      swal("Success", props.alert.message, "success").then(() => {
        this.props.dispatch(alertActions.alert.clear());
      });
    }
  };
  // componentDidUpdate=(props)=>{
  //     if(props.success == true){
  //         this.setState({initialState});
  //     }
  // }
  handleTabClick = (type) => {
    const { dispatch } = this.props;
    this.setState(initialState);
    dispatch(alertActions.alert.clear());
    this.props.history.push(`/forgot/${type}`);
    this.setState({ type: type });
  };

  handlePhone = (value, name) => {
    // value = value.replace(/[^a-zA-Z ]/g, "");
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  hideAlert = () => {
    const { dispatch } = this.props;
    dispatch(alertActions.alert.clear());
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email } = this.state;
    const data = { email: email };
    let type = e.target.dataset.type;
    if (type === "buyer") type = "customer";
    const { dispatch } = this.props;
    if (email) {
      this.props.sendLink(type, data, email, this.props.history);
    }
  };
  render() {
    const { sending, alert } = this.props;
    const { email } = this.state;
    const { type } = this.props.match.params;

    return (
      <div className="background-gradient" style={{ height: "100vh" }}>
        <nav class="navbar navbar-expand-lg fixed-top navbar-inverse">
          <a class="navbar-brand" href="https://www.atarapay.com">
            <img
              src="/dist/img/logo-header.png"
              alt=""
              className="logo-img img img-responsive"
            />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </nav>

        <div class="row row-margin top-margin-60">
          <div className="col-sm-3 col-md-3"></div>
          <div className="col-sm-8 col-md-6 col-12">
            <div className="card card-margin">
              {/* <ul class="nav nav-tabs nav-justified" role="tablist">
                        <li class="nav-item">
                            <a  className={`nav-link  ${type&&type?(type&&type==`buyer`?`active`:``):`active`}`} onClick={()=>{this.handleTabClick("buyer")}} href="#buyer" role="tab" data-toggle="tab">Buyer</a>
                        </li>
                        <li class="nav-item">
                            <a  className={`nav-link  ${type&&type?(type&&type==`seller`?`active`:``):``}`} onClick={()=>{this.handleTabClick("seller")}}  href="#seller" role="tab" data-toggle="tab">Seller</a>
                        </li>
                        {/* <li class="nav-item">
                            <a  className={`nav-link  ${type&&type?(type&&type==`market`?`active`:``):``}`} onClick={()=>{this.handleTabClick("market")}} href="#marketplace" role="tab" data-toggle="tab">Marketplace Admin</a>
                        </li> 
                    </ul> */}
              <div class="tab-content">
                <div
                  role="tabpanel"
                  class={`tab-pane  ${
                    type && type
                      ? type && type === `buyer`
                        ? `active`
                        : ``
                      : ``
                  }`}
                  id="buyer"
                >
                  <br />
                  <form data-type="buyer" onSubmit={this.handleSubmit}>
                    <h4>
                      <strong>Forgot Password (Buyer)</strong>
                    </h4>
                    {/* {alert&&alert.message&&alert.type!="alert-danger"&&<div class={`alert animated bounceIn buyer-alert ${alert.type}`}><a  aria-label="close" onClick={this.hideAlert} class="close alert-close">×</a>
                                 <span  class="alert-message-content">{alert.message}</span></div>} */}
                    <div class="form-group">
                      <label for="buyer_email">
                        <small>Please enter your phone number*</small>
                      </label>
                      {/* <div class="col-sm-12"> */}
                      {/* <div class="form-group"> */}
                      <PhoneInput
                        defaultCountry="NG"
                        placeholder="08XX XXX XXXX"
                        limitMaxLength={true}
                        value={
                          email &&
                          formatPhoneNumber(email && email, "International")
                        }
                        onChange={(value) =>
                          this.handlePhone(
                            (value &&
                              formatPhoneNumber(value, "International").replace(
                                / /g,
                                ""
                              )) ||
                              "",
                            "email"
                          )
                        }
                        inputClassName={"custom-input"}
                      />

                      {/* <input type="text" required="required" name="phone_number" value={user.phone_number} onChange={this.handleChange} placeholder="08XX XXX XXXX"
                maxLength="11" class="form-control"/> */}
                      {/* </div> */}
                      {/* <input
                                type="text" required="required" name="phone_number" value={user.phone_number} onChange={this.handleChange} placeholder="08XX XXX XXXX" */}
                      {/* maxLength="11" class="form-control"/> */}
                      {/* </div> */}
                    </div>
                    <div class="form-group">
                      <input
                        type="submit"
                        name="button"
                        value="Send password reset link"
                        class="btn btn-dark-blue form-control btn-customer-forgot-password"
                      />
                    </div>
                    <hr />
                    <p class="text-center">
                      {" "}
                      Log in as{" "}
                      <Link to="/login/buyer" class="">
                        Buyer
                      </Link>{" "}
                      or{" "}
                      <Link to="/login/seller" class="">
                        Seller
                      </Link>{" "}
                    </p>
                  </form>
                </div>
                <div
                  role="tabpanel"
                  class={`tab-pane   ${
                    type && type
                      ? type && type == `seller`
                        ? `active`
                        : ``
                      : ``
                  }`}
                  id="seller"
                >
                  <br />
                  <form data-type="seller" onSubmit={this.handleSubmit}>
                    <h4>
                      <strong>Forgot Password (Seller)</strong>
                    </h4>
                    {alert && alert.message && (
                      <div
                        class={`alert animated bounceIn buyer-alert ${alert.type}`}
                      >
                        <a
                          aria-label="close"
                          onClick={this.hideAlert}
                          class="close alert-close"
                        >
                          ×
                        </a>
                        <span class="alert-message-content">
                          {alert.message}
                        </span>
                      </div>
                    )}

                    <div class="form-group">
                      <label for="seller_email">
                        <small>Please enter your email address*</small>
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                        v-model="seller_email"
                        id="seller_email"
                        class="form-control"
                        required="required"
                      />
                    </div>

                    <div class="form-group">
                      <input
                        type="submit"
                        name="button"
                        value="Send password reset link"
                        class="btn btn-dark-blue form-control btn-seller-forgot-password"
                      />
                    </div>
                    <hr />
                    <p class="text-center">
                      {" "}
                      Log in as{" "}
                      <Link to="/login/buyer" class="">
                        Buyer
                      </Link>{" "}
                      or{" "}
                      <Link to="/login/seller" class="">
                        Seller
                      </Link>{" "}
                    </p>
                  </form>
                </div>
                <div
                  role="tabpanel"
                  class={`tab-pane  ${
                    type && type
                      ? type && type == `market`
                        ? `active`
                        : ``
                      : ``
                  }`}
                  id="marketplace"
                >
                  <br />
                  <form data-type="market" onSubmit={this.handleSubmit}>
                    <h4>
                      <strong>Forgot Password (Marketplace Admin)</strong>
                    </h4>
                    {alert && alert.message && (
                      <div
                        class={`alert animated bounceIn buyer-alert ${alert.type}`}
                      >
                        <a aria-label="close" class="close alert-close">
                          ×
                        </a>
                        <span class="alert-message-content">
                          {alert.message}
                        </span>
                      </div>
                    )}
                    <div class="form-group">
                      <label for="email">
                        <small>Please enter your email address*</small>
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                        v-model="marketplace_email"
                        id="email"
                        class="form-control"
                        required="required"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="submit"
                        name="button"
                        value="Send password reset link"
                        class="btn btn-dark-blue form-control"
                      />
                    </div>
                    <hr />
                    <p class="text-center">
                      {" "}
                      Log in as{" "}
                      <Link to="/login/buyer" class="">
                        Buyer
                      </Link>{" "}
                      or{" "}
                      <Link to="/login/seller" class="">
                        Seller
                      </Link>{" "}
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ForgotPass);
