import React, { Component } from "react";

class StageOne extends Component {
  render() {
    const { data } = this.props;
    const { handleChange, _nextStage, role } = this.props;
    return (
      <div className="content-wrapper" style={{ minHeight: 93 }}>
        <div className="attop">
          {role === "customer" ? (
            <a
              href={process.env.BUYER_P2P_VIDEO}
              className="btn btn-primary"
              target="_blank"
            >
              Watch: How To Create P2P Transaction as a Buyer
            </a>
          ) : (
            <a
              href={process.env.SELLER_P2P_VIDEO}
              className="btn btn-primary"
              target="_blank"
            >
              Watch: How To Create P2P Transaction as a Seller
            </a>
          )}
          <br />
          <br />
          <div>
            This is where the Person to Person (P2P) escrow transaction is
            created. Buyer or Seller creates an escrow transaction for the
            purpose of completing a purchase using AtaraPay Escrow Service. The
            steps are as follows;
            <ol>
              <li>Buyer or Seller fills the create Transaction form below</li>
              <li>
                The other party (Buyer or Seller) receives an email requesting
                them to agree to transaction
              </li>
              <li>Buyer is informed via email and SMS to make payment</li>
              <li>Seller ships item to or fulfills service for Buyer</li>
              <li>Buyer accepts or rejects</li>
              <li>AtaraPay pays Seller or refunds Buyer</li>
            </ol>
          </div>
        </div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">
                  Select the type of transaction{" "}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <br />
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="bg-callout alert information">
                  <ul>
                    <li>
                      Review the transaction types below, and Select the type
                      that fits what you are buying or selling.
                    </li>
                    <li>
                      AtaraPay may refuse to accept certain payment types, for
                      any reason, at its discretion.
                    </li>
                  </ul>
                </div>
              </div>
            </div>{" "}
            <br />
            <div className="row">
              <div className="col-sm-6 col-12">
                <div className="form-group">
                  <label>Type of Transaction</label>
                  <select
                    className="form-control"
                    value={data.type}
                    name="type"
                    onChange={handleChange.bind(this)}
                  >
                    <option value={2}>General</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Your Role</label>
                  <input
                    hidden
                    className="form-control"
                    disabled="disabled"
                    value={role}
                    name="role"
                    onChange={handleChange.bind(this)}
                  />
                  <input
                    className="form-control"
                    disabled="disabled"
                    value={
                      role === "customer"
                        ? `Buyer`
                        : role?.charAt(0)?.toUpperCase() + role?.slice(1)
                    }
                  />
                </div>

                <div className="form-group">
                  <button onClick={_nextStage} className="btn btn-info">
                    Continue
                  </button>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <br />
                <div className="callout callout-help">
                  General is used for most goods and services.
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default StageOne;
