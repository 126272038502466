import { reportConstants } from "../constants";

const initialState = {};

const reports = (state = initialState, action) => {
  switch (action.type) {
    case reportConstants.report.GET_PAYOUT_REPORT_REQUEST:
      return { ...state, loading: true };
    case reportConstants.report.GET_PAYOUT_REPORT_SUCCESS:
      return { ...state, report: action.report, loading: false };

    case reportConstants.report.GET_MARKETPLACE_SELLERS_SUCCESS:
      return { ...state, marketPlaceSellers: action.marketPlaceSellers };

    default:
      return state;
  }
};

export default reports;
