import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Order from "../views/Order";
import { orderActions, newOrderActions, transactionActions } from "../actions";
// import { payoutActions } from '../actions';

const mapStateToProps = (state) => {
  const { loading, orders, deliverySuccess, canceling, adding, orderStatus } =
    state.orders;
  const { canceling2 } = state.transaction;
  const { deliveryMen } = state.users;

  const alert = state.alert;

  return {
    alert,
    loading,
    adding,
    orders,
    orderStatus,
    canceling,
    canceling2,
    deliveryMen,
    deliverySuccess,
  };
};
const mapDispatchToProps = (dispatch) => ({
  view: (type, history) => dispatch(orderActions.order.view(type, history)),
  cancel: (type, data, history) =>
    dispatch(orderActions.order.cancelDispute(type, data, history)),
  rejectOrder: (type, data, history) =>
    dispatch(newOrderActions.PODOrder.rejectOrder(type, data, history)),
  acceptOrder: (type, data, history) =>
    dispatch(newOrderActions.PODOrder.acceptOrder(type, data, history)),
  confirmImpediment: (type, data, history) =>
    dispatch(newOrderActions.PODOrder.confirmImpediment(type, data, history)),
  assignDispatcher: (type, data, history) =>
    dispatch(orderActions.order.assignDispatcher(type, data, history)),
  cancelTransaction: (type, response, history) =>
    dispatch(
      transactionActions.transaction.cancelTransaction(type, response, history)
    ),
  completeOrder: (data) => dispatch(orderActions.order.completeOrder(data)),
  readyForPickup: (data, history) => dispatch(orderActions.order.readyForPickup(data, history)),
  addServiceProvider: (data, history) =>
    dispatch(orderActions.order.addServiceProvider(data, history)),
  dispatch,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Order));
