import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
import autolist from '../../helpers/autolist';
import NumberFormat from 'react-number-format';

import { Terms } from '../ModalViews';
import { clearInterval } from 'timers';
import { green } from 'ansi-colors';

class StageTwo extends Component {
  state = {
    termModal: false,
    buyer_phone: '',
    seller_phone: '',
  };
  toggleModal = () => {
    this.setState((prevState) => ({ termModal: !prevState.termModal }));
  };
  timer = null;
  componentDidMount() {
    if (this.props.data)
      this.setState({
        buyer_phone: this.props.data.buyer_phone,
        seller_phone: this.props.seller_phone,
      });
  }
  render() {
    const { data, edit, error, terms } = this.props;

    const {
      role,
      adding,
      accounts,
      updating,
      _nextStage,
      _prevStage,
      other_party,
      handleSubmit,
      handleChange,
      fillPhone,
      handlePriceChange,
      handleExtraChange,
      handleAdSelection,
      handleUpdateCancel,
      handleUpdateTransaction,
      handleCheckBoxToggle,
      handleCurrencyChange,
      handlePhone,
      handleCheckUser,
      handleRecipientChange,
    } = this.props;

    return (
      <div className='content-wrapper' style={{ minHeight: 93 }}>
        <Terms open={this.state.termModal} onClose={this.toggleModal} />
        <div className='content-header'>
          <div className='container-fluid'>
            <div className='row mb-2'>
              <div className='col-sm-6'>
                <h1 className='m-0 text-dark'>New General Transaction</h1>
              </div>
            </div>
          </div>
        </div>{' '}
        <br />
        <section className='content'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12 col-sm-12 col-md-12'>
                <div className='bg-callout alert '>
                  <span>
                    Please fill the form below to provide the terms for this
                    transaction.
                    <br />
                    NOTE:
                    <ol>
                      <li>
                        You can only create escrow transaction for one product
                        type. For example, your cart can only contain same type/
                        make of car or truck or house but not a combination of
                        them
                      </li>
                      <li>
                        You can enter multiple quantities for the same item
                      </li>
                      <li>
                        The total value of the product sum should not be less
                        than ₦ 1,000.00
                      </li>
                      <li>
                        Buyer will be required to accept or reject at point of
                        delivery of the item
                      </li>
                    </ol>
                  </span>
                </div>
              </div>
            </div>{' '}
            <br />
            <div className='row'>
              <div className='col-sm-6'>
                <small>
                  (<span className='text-danger'>*</span>) indicates mandatory
                  field{' '}
                </small>
                <br />
                <label>
                  Transaction title: <span className='text-danger'>*</span>{' '}
                </label>
                <input
                  type='text'
                  placeholder='Buying Mercedez Benz'
                  className='form-control'
                  value={data.product_name}
                  onChange={handleChange.bind(this)}
                  name='product_name'
                />
              </div>
              <div className='col-sm-6'>
                <div className='callout callout-help'>
                  A short description of the item to be purchased or sold. For
                  example, "Buying Mercedes Benz.
                </div>
              </div>
            </div>
            <hr />
            <div className='row'>
              <div className='col-sm-6'>
                <label>
                  Transaction category: <span className='text-danger'>*</span>{' '}
                </label>
                <select
                  className='form-control'
                  name='category'
                  value={data.category}
                  onChange={handleChange.bind(this)}
                  disabled={edit}
                >
                  <option value={``}> Please Select One </option>
                  <option value={`General Transaction`}>
                    General Transaction
                  </option>
                  <option value={`Money Market`}>Money Market</option>
                </select>
                {edit ? <small>Edit Mode</small> : ''}
              </div>
              <div className='col-sm-6'>
                <div className='callout callout-help'>
                  Select a category that best describes the item.
                </div>
              </div>
            </div>
            <hr />
            {role && role === 'seller' ? (
              <div className='row'>
                <div className='col-sm-6'>
                  <label>
                    Buyer's phone number: <span className='text-danger'>*</span>
                  </label>
                  <PhoneInput
                    defaultCountry='NG'
                    placeholder='080X XXX XXXX'
                    limitMaxLength={true}
                    value={
                      edit
                        ? '+' + formatPhoneNumber(data.buyer_phone, 'National')
                        : this.state.buyer_phone
                    }
                    disabled={edit}
                    onChange={(value) => {
                      return handlePhone(
                        (value &&
                          formatPhoneNumber(value, 'International').replace(
                            / /g,
                            ''
                          )) ||
                          '',
                        'buyer_phone'
                      );
                    }}
                    onKeyUp={() => {
                      clearTimeout(this.timer);
                      this.timer = setTimeout(
                        () => handleCheckUser('customer', data.buyer_phone),
                        1000
                      );
                    }}
                  />

                  <div style={{ color: green }}>
                    {other_party && other_party ? (
                      <div className='text-success'>
                        <small> User exists as Buyer</small>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <small className='text-danger'>
                    Please note this number cannot be edited after successful
                    transaction creation
                  </small>
                  <div style={{ color: green }}>
                    {other_party && other_party ? (
                      <div className='text-success'>
                        <small>
                          On submission of this form, a notification of this
                          transaction will be sent to the email address that
                          exists against the number entered above
                        </small>
                        <br />
                        {/* <a className="text-info" onClick={() => { fillPhone('buyer_phone', other_party.phone_number)}}>Autofill Phone number</a> */}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='callout callout-help'>
                    This number will be used to inform the buyer about a new
                    transaction. Please note this number cannot be edited after
                    successful transaction creation
                  </div>
                </div>
              </div>
            ) : (
              <div className='row'>
                <div className='col-sm-6'>
                  <label>
                    Seller's phone number:{' '}
                    <span className='text-danger'>*</span>
                  </label>
                  <PhoneInput
                    defaultCountry='NG'
                    placeholder='080X XXX XXXX'
                    value={
                      edit
                        ? '+' + formatPhoneNumber(data.seller_phone, 'National')
                        : data.seller_phone
                    }
                    disabled={edit}
                    onChange={(value) =>
                      handlePhone(
                        (value &&
                          formatPhoneNumber(value, 'International').replace(
                            / /g,
                            ''
                          )) ||
                          '',
                        'seller_phone'
                      )
                    }
                    onKeyUp={() => {
                      clearTimeout(this.timer);
                      this.timer = setTimeout(
                        () => handleCheckUser('seller', data.seller_phone),
                        1000
                      );
                    }}
                  />

                  <div style={{ color: green }}>
                    {other_party && other_party ? (
                      <div className='text-success'>
                        <small> User exists as Seller</small>
                        <br />
                      </div>
                    ) : (
                      ''
                    )}

                    <small className='text-danger'>
                      Please note this number cannot be edited after successful
                      transaction creation
                    </small>
                  </div>
                  <div style={{ color: green }}>
                    {other_party && other_party ? (
                      <div className='text-success'>
                        <small>
                          On submission of this form, a notification of this
                          transaction will be sent to the email address that
                          exists against the number entered above
                        </small>
                        <br />
                        {/* <a className="text-info" onClick={() => { fillPhone('buyer_phone', other_party.phone_number)}}>Autofill Phone number</a> */}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='callout callout-help'>
                    This number will be used to inform the seller about a new
                    transaction. Please note this number cannot be edited after
                    successful transaction creation
                  </div>
                </div>
              </div>
            )}
            {role && role === 'seller' ? (
              !other_party ? (
                <div className='row'>
                  <hr />
                  <div className='col-sm-6'>
                    <label>
                      Buyer's Email address:{' '}
                      <span className='text-danger'>*</span>
                    </label>
                    <input
                      type='email'
                      className={`form-control ${
                        other_party && other_party ? 'user_exists' : ''
                      } `}
                      onChange={handleChange.bind(this)}
                      name='buyer_email'
                      disabled={edit || other_party || !data.buyer_phone}
                    />
                    <div style={{ color: green }}>
                      {other_party && other_party ? (
                        <div className='text-success'>
                          <small>
                            On submission of this form, a notification of this
                            transaction will be sent to the email address that
                            exists against the number entered above
                          </small>
                          <br />
                          {/* <a className="text-info" onClick={() => { fillPhone('buyer_phone', other_party.phone_number)}}>Autofill Phone number</a> */}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <br />
                    <div className='callout callout-help'>
                      Enter Buyer’s Email Address
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )
            ) : !other_party ? (
              <div className='row'>
                <div className='col-sm-6'>
                  <label>
                    Seller's Email address:{' '}
                    <span className='text-danger'>*</span>
                  </label>
                  <input
                    type='email'
                    className={`form-control ${
                      other_party && other_party ? 'user_exists' : ''
                    } `}
                    onChange={handleChange.bind(this)}
                    name='seller_email'
                    disabled={
                      edit ||
                      other_party ||
                      !data.seller_phone ||
                      (edit && data.seller_email && data.seller_phone) ||
                      (edit && data.seller_email && !data.seller_phone)
                    }
                    value={edit ? data.seller_email : undefined}
                  />
                </div>
                <div className='col-sm-6'>
                  <br />
                  <div className='callout callout-help'>
                    Enter Seller's Email Address
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            <hr />
            {data.category && data.category == 'Money Market' ? (
              <div>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label>
                      Agreed Conversion Rate:{' '}
                      <span className='text-danger'>*</span>
                    </label>
                    <input
                      type='number'
                      className='form-control'
                      pattern='^[0–9]$'
                      onChange={handleChange}
                      value={data.conversion_ratio ? data.conversion_ratio : 1}
                      name='conversion_ratio'
                      min='1'
                    />
                  </div>
                  <div className='col-sm-6'>
                    <div className='callout callout-help'>
                      This is the conversion rate agreed between the buyer and
                      the seller to enable calculation of the Naira equivalent
                      to be paid into escrow. For example, if the conversion
                      rate agreed is =N=400 to 1 amount of the currency, then
                      enter 400 into the field
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ) : null}
            {data.category && data.category == 'Money Market' ? (
              <div>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label>
                      Currency Amount: <span className='text-danger'>*</span>
                    </label>
                    <input
                      type='number'
                      className='form-control'
                      pattern='^[0–9]$'
                      onChange={handleChange}
                      value={data.quantity ? data.quantity : ''}
                      name='quantity'
                      min='1'
                    />
                  </div>
                  <div className='col-sm-6'>
                    <div className='callout callout-help'>
                      This is the value of the currency to be paid into the
                      recipient’s bank account. For example, if the value is
                      $1,000.00, then enter 1000 into the field
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ) : null}
            {data.category && data.category == 'Money Market' ? (
              <div>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label>
                      Currency: <span className='text-danger'>*</span>
                    </label>
                    <select
                      className='form-control'
                      onChange={handleChange}
                      defaultValue={data.currency ? data.currency : ''}
                      name='currency'
                    >
                      <option value=''>Please Choose</option>
                      <option value='AFN'>AFN -  Afghanistan</option>
                      <option value='EUR'>EUR -  Akrotiri and Dhekelia</option>
                      <option value='ALL'>ALL -  Albania</option>
                      <option value='DZD'>DZD -  Algeria</option>
                      <option value='EUR'>
                        EUR -  Andorra/Austria/Belgium
                      </option>
                      <option value='AOA'>AOA -  Angola</option>
                      <option value='XCD'>XCD -  Anguilla</option>
                      <option value='ARS'>ARS -  Argentina</option>
                      <option value='AMD'>AMD -  Armenia</option>
                      <option value='AWG'>AWG -  Aruba</option>
                      <option value='SHP'>SHP -  Ascension Island</option>
                      <option value='AUD'>AUD -  Australia</option>
                      <option value='AZN'>AZN -  Azerbaijan</option>
                      <option value='BSD'>BSD -  Bahamas, The</option>
                      <option value='BHD'>BHD -  Bahrain</option>
                      <option value='BDT'>BDT -  Bangladesh</option>
                      <option value='BBD'>BBD -  Barbados</option>
                      <option value='BYN'>BYN -  Belarus</option>
                      <option value='BZD'>BZD -  Belize</option>
                      <option value='XOF'>XOF -  Benin Burkina Faso</option>
                      <option value='BMD'>BMD -  Bermuda</option>
                      <option value='BTN'>BTN -  Bhutan</option>
                      <option value='BOB'>BOB -  Bolivia</option>
                      <option value='USD'>USD -  Bonaire</option>
                      <option value='BAM'>BAM -  Bosnia and Herzegovina</option>
                      <option value='BWP'>BWP -  Botswana</option>
                      <option value='BRL'>BRL -  Brazil</option>
                      <option value='BND'>BND -  Brunei</option>
                      <option value='BGN'>BGN -  Bulgaria</option>
                      <option value='BIF'>BIF -  Burundi</option>
                      <option value='KHR'>KHR -  Cambodia</option>
                      <option value='XAF'>XAF -  Cameroon </option>
                      <option value='CAD'>CAD -  Canada</option>
                      <option value='CVE'>CVE -  Cape Verde</option>
                      <option value='KYD'>KYD -  Cayman Islands</option>
                      <option value='CLP'>CLP -  Chile</option>
                      <option value='CNY'>CNY -  China</option>
                      <option value='COP'>COP -  Colombia</option>
                      <option value='KMF'>KMF -  Comoros</option>
                      <option value='CDF'>CDF -  Congo</option>
                      <option value='CKD'>CKD[G] -  Cook Islands</option>
                      <option value='CRC'>CRC -  Costa Rica</option>
                      <option value='XOF'>XOF -  Côte d'Ivoire</option>
                      <option value='HRK'>HRK -  Croatia</option>
                      <option value='CUP'>CUP -  Cuba</option>
                      <option value='ANG'>ANG -  Curaçao</option>
                      <option value='EUR'>EUR -  Cyprus</option>
                      <option value='CZK'>CZK -  Czech Republic</option>
                      <option value='DKK'>DKK -  Denmark</option>
                      <option value='DJF'>DJF -  Djibouti</option>
                      <option value='XCD'>XCD -  Dominica</option>
                      <option value='DOP'>DOP -  Dominican Republic</option>
                      <option value='EGP'>EGP -  Egypt</option>
                      <option value='XAF'>XAF -  Equatorial Guinea</option>
                      <option value='XAF'>XAF -  Eritrea</option>
                      <option value='EUR'>EUR -  Estonia</option>
                      <option value='SZL'>SZL -  Eswatini</option>
                      <option value='ETB'>ETB -  Ethiopia</option>
                      <option value='FKP'>FKP -  Falkland Islands</option>
                      <option value='DKK'>DKK -  Faroe Islands</option>
                      <option value='FJD'>FJD -  Fiji</option>
                      <option value='EUR'>EUR -  Finland</option>
                      <option value='EUR'>EUR -  France</option>
                      <option value='XPF'>XPF -  French Polynesia</option>
                      <option value='XAF'>XAF -  Gabon</option>
                      <option value='GMD'>GMD -  Gambia, The</option>
                      <option value='GEL'>GEL -  Georgia</option>
                      <option value='EUR'>EUR -  Germany</option>
                      <option value='GHS'>GHS -  Ghana</option>
                      <option value='GIP'>GIP -  Gibraltar</option>
                      <option value='EUR'>EUR -  Greece / Italy</option>
                      <option value='XCD'>XCD -  Grenada</option>
                      <option value='GTQ'>GTQ -  Guatemala</option>
                      <option value='GGP'>GGP[G] -  Guernsey</option>
                      <option value='GNF'>GNF -  Guinea</option>
                      <option value='XOF'>XOF -  Guinea-Bissau</option>
                      <option value='GYD'>GYD -  Guyana</option>
                      <option value='HTG'>HTG -  Haiti</option>
                      <option value='HNL'>HNL -  Honduras</option>
                      <option value='HKD'>HKD -  Hong Kong</option>
                      <option value='HUF'>HUF -  Hungary</option>
                      <option value='ISK'>ISK -  Iceland</option>
                      <option value='INR'>INR -  India</option>
                      <option value='IDR'>IDR -  Indonesia</option>
                      <option value='IRR'>IRR -  Iran</option>
                      <option value='IQD'>IQD -  Iraq</option>
                      <option value='IMP'>IMP[G] -  Isle of Man</option>
                      <option value='ILS'>ILS -  Israel</option>
                      <option value='JMD'>JMD -  Jamaica</option>
                      <option value='JPY'>JPY -  Japan</option>
                      <option value='JEP'>JEP[G] -  Jersey</option>
                      <option value='JOD'>JOD -  Jordan</option>
                      <option value='KZT'>KZT -  Kazakhstan</option>
                      <option value='KES'>KES -  Kenya</option>
                      <option value='KID'>KID[G] -  Kiribati</option>
                      <option value='KPW'>KPW -  Korea, North</option>
                      <option value='KRW'>KRW -  Korea, South</option>
                      <option value='EUR'>EUR -  Kosovo</option>
                      <option value='KWD'>KWD -  Kuwait</option>
                      <option value='KGS'>KGS -  Kyrgyzstan</option>
                      <option value='LAK'>LAK -  Laos</option>
                      <option value='EUR'>EUR -  Latvia</option>
                      <option value='LBP'>LBP -  Lebanon</option>
                      <option value='LSL'>LSL -  Lesotho</option>
                      <option value='LRD'>LRD -  Liberia</option>
                      <option value='LYD'>LYD -  Libya</option>
                      <option value='CHF'>CHF -  Liechtenstein</option>
                      <option value='EUR'>EUR -  Lithuania</option>
                      <option value='EUR'>EUR -  Luxembourg</option>
                      <option value='MOP'>MOP -  Macau</option>
                      <option value='MGA'>MGA -  Madagascar</option>
                      <option value='MWK'>MWK -  Malawi</option>
                      <option value='MYR'>MYR -  Malaysia</option>
                      <option value='MVR'>MVR -  Maldives</option>
                      <option value='XOF'>XOF -  Mali</option>
                      <option value='EUR'>EUR -  Malta</option>
                      <option value='USD'>USD -  Marshall Islands</option>
                      <option value='MRU'>MRU -  Mauritania</option>
                      <option value='MUR'>MUR -  Mauritius</option>
                      <option value='MXN'>MXN -  Mexico</option>
                      <option value='USD'>USD -  Micronesia</option>
                      <option value='MDL'>MDL -  Moldova</option>
                      <option value='EUR'>EUR -  Monaco</option>
                      <option value='MNT'>MNT -  Mongolia</option>
                      <option value='EUR'>EUR -  Montenegro</option>
                      <option value='XCD'>XCD -  Montserrat</option>
                      <option value='MAD'>MAD -  Morocco</option>
                      <option value='MZN'>MZN -  Mozambique</option>
                      <option value='MMK'>MMK -  Myanmar</option>
                      <option value='NAD'>NAD -  Namibia</option>
                      <option value='AUD'>AUD -  Nauru</option>
                      <option value='NPR'>NPR -    Nepal</option>
                      <option value='EUR'>EUR -  Netherlands[H]</option>
                      <option value='XPF'>XPF -  New Caledonia</option>
                      <option value='NZD'>NZD -  New Zealand</option>
                      <option value='NIO'>NIO -  Nicaragua</option>
                      <option value='XOF'>XOF -  Niger</option>
                      <option value='NGN'>NGN -  Nigeria</option>
                      <option value='NZD'>NZD -  Niue</option>
                      <option value='MKD'>MKD -  North Macedonia</option>
                      <option value='TRY'>TRY -  Northern Cyprus</option>
                      <option value='NOK'>NOK -  Norway</option>
                      <option value='OMR'>OMR -  Oman</option>
                      <option value='PKR'>PKR -  Pakistan</option>
                      <option value='USD'>USD -  Palau</option>
                      <option value='ILS'>ILS -  Palestine</option>
                    </select>
                  </div>
                  <div className='col-sm-6'>
                    <div className='callout callout-help'>
                      This refers to the currency of the money to be paid to the
                      recipient’s bank account. For example, if the currency is
                      in dollars, then select US Dollars
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ) : null}
            <div className='row'>
              <div className='col-sm-6'>
                <div className='row'>
                  <div className='col-sm-8'>
                    {data.category && data.category == 'Money Market' ? (
                      <label>Unit Price:</label>
                    ) : (
                      <label>
                        Unit Price (Please also select currency):{' '}
                        <span className='text-danger'>*</span>
                      </label>
                    )}
                    <div className='input-group mt-3 mb-3'>
                      {data.category && data.category != 'Money Market' ? (
                        <div className='input-group-prepend'>
                          <button
                            type='button'
                            id='currencySelector'
                            className='btn btn-outline-primary dropdown-toggle'
                            data-toggle='dropdown'
                          >
                            {data.currency === '' ? 'Select' : data.currency}
                          </button>

                          <div className='dropdown-menu'>
                            <a
                              className='dropdown-item'
                              href='javascript:void(0);'
                              onClick={handleCurrencyChange.bind(this)}
                              currency='NGN'
                            >
                              NGN
                            </a>
                            <a
                              className='dropdown-item'
                              href='javascript:void(0);'
                              onClick={handleCurrencyChange.bind(this)}
                              currency='USD'
                            >
                              USD
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div className='input-group-prepend'>
                          <button
                            type='button'
                            id='currencySelector'
                            className='btn btn-outline-primary'
                          >
                            NGN
                          </button>
                        </div>
                      )}
                      <NumberFormat
                        className='form-control'
                        defaultValue={0.0}
                        value={
                          data.category && data.category == 'Money Market'
                            ? data.quantity * data.conversion_ratio
                            : data.amount_payed
                        }
                        isNumericString={true}
                        decimalScale={2}
                        disabled={
                          data.category && data.category == 'Money Market'
                        }
                        fixedDecimalScale={true}
                        name='amount_payed'
                        onChange={(e) => {
                          const event = {
                            ...e,
                            target: {
                              ...e.target,
                              value: e.target.value
                                .replace(/NGN /g, '')
                                .replace(/,/g, ''),
                              name: e.target.name,
                            },
                          };
                          handleChange(event);
                        }}
                        thousandSeparator={true}
                        allowNegative={false}
                      />
                    </div>
                    {data.amount_payed * data.quantity <
                      1000 / data.conversion_ratio && (
                      <small className='text-danger'>
                        Important! Total Amount must not be less than{' '}
                        {data.currency}{' '}
                        {(1000 / data.conversion_ratio).toFixed(2)}
                      </small>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='callout callout-help'>
                  Please select the currency and enter the price of the item
                </div>
              </div>
            </div>
            <hr />
            {data.category && data.category != 'Money Market' ? (
              <div>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label>
                      Quantity: <span className='text-danger'>*</span>
                    </label>
                    <input
                      type='number'
                      className='form-control'
                      pattern='^[0–9]$'
                      onChange={handleChange}
                      value={data.quantity}
                      name='quantity'
                      min='1'
                    />
                  </div>
                  <div className='col-sm-6'>
                    <div className='callout callout-help'>
                      Please enter the Quantity of the item.
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ) : null}
            {data.category && data.category == 'Money Market' ? (
              <div>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label>
                      Recipient’s Bank Details:{' '}
                      <span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control mb-2'
                      placeholder="Recipient's full name"
                      name='recipient_name'
                      value={
                        data.recipient.recipient_name
                          ? data.recipient.recipient_name
                          : ''
                      }
                      onChange={handleRecipientChange.bind(this)}
                    />
                    <input
                      type='text'
                      className='form-control mb-2'
                      placeholder="Recipient's Physical Address"
                      name='recipient_address'
                      onChange={handleRecipientChange.bind(this)}
                      defaultValue={
                        data.recipient.recipient_address
                          ? data.recipient.recipient_address
                          : ''
                      }
                    />
                    <input
                      type='text'
                      className='form-control mb-2'
                      placeholder="Recipient's Bank name"
                      name='recipient_bank_name'
                      onChange={handleRecipientChange.bind(this)}
                      defaultValue={
                        data.recipient.recipient_bank_name
                          ? data.recipient.recipient_bank_name
                          : ''
                      }
                    />
                    <input
                      type='text'
                      className='form-control mb-2'
                      placeholder="Recipient's Bank Address"
                      name='recipient_bank_address'
                      onChange={handleRecipientChange.bind(this)}
                      defaultValue={
                        data.recipient.recipient_bank_address
                          ? data.recipient.recipient_bank_address
                          : ''
                      }
                    />
                    <input
                      type='text'
                      className='form-control mb-2'
                      placeholder="Recipient's Account Number"
                      name='recipient_account_number'
                      onChange={handleRecipientChange.bind(this)}
                      defaultValue={
                        data.recipient.recipient_account_number
                          ? data.recipient.recipient_account_number
                          : ''
                      }
                    />
                    <select
                      className='form-control mb-2'
                      name='recipient_account_type'
                      onChange={handleRecipientChange.bind(this)}
                      defaultValue={
                        data.recipient.recipient_account_type
                          ? data.recipient.recipient_account_type
                          : ''
                      }
                    >
                      <option value=''>Choose Account type</option>
                      <option value='saving'>Savings</option>
                      <option value='checking'>Checking</option>
                    </select>
                    <input
                      type='text'
                      className='form-control mb-2'
                      placeholder='Recipient Bank’s BIC/SWIFT code'
                      name='recipient_bank_swift'
                      defaultValue={
                        data.recipient.recipient_bank_swift
                          ? data.recipient.recipient_bank_swift
                          : ''
                      }
                      onChange={handleRecipientChange.bind(this)}
                    />
                  </div>
                  <div className='col-sm-6'>
                    <div className='callout callout-help'>
                      This refers to the bank details of the recipient that will
                      receive the currency being transferred
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ) : null}
            <div className='row'>
              <div className='col-sm-6'>
                <label>
                  Who pays the Escrow fee:{' '}
                  <span className='text-danger'>*</span>
                </label>
                <div className='radio'>
                  <input
                    type='radio'
                    name='escrow_fee_bearer'
                    checked={data.escrow_fee_bearer === 'customer'}
                    onChange={handleChange}
                    value='customer'
                  />
                  &nbsp;&nbsp;Buyer
                </div>
                <div className='radio'>
                  <input
                    type='radio'
                    name='escrow_fee_bearer'
                    checked={data.escrow_fee_bearer === 'seller'}
                    value='seller'
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;Seller
                </div>
                <div className='radio'>
                  <input
                    type='radio'
                    name='escrow_fee_bearer'
                    checked={data.escrow_fee_bearer === 'both'}
                    value='both'
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;50% Buyer / 50% Seller
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='callout callout-help'>
                  This identifies who is responsible for paying the escrow fees.
                  If the Buyer is selected, the fees are added to the
                  transaction total. If the Seller is selected, the fees are
                  deducted from the proceeds.
                </div>
              </div>
            </div>
            <hr />
            {data.category && data.category != 'Money Market' ? (
              <div>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label>
                      Shipping Cost <span className='text-danger'>*</span>
                    </label>
                    <div className='radio'>
                      <input
                        type='radio'
                        name='shipping_method'
                        checked={data.shipping_method == 0}
                        value={0}
                        onChange={handleChange}
                      />
                      &nbsp;&nbsp;No Shipping Cost Required
                    </div>
                    <div className='radio'>
                      <input
                        type='radio'
                        name='shipping_method'
                        checked={data.shipping_method == 1}
                        value={1}
                        onChange={handleChange}
                      />
                      &nbsp;&nbsp;Shipping Cost Required
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className='callout callout-help'>
                      This identifies if shipping will be required in this
                      transaction.?
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ) : null}
            {data.shipping_method == 1 ? (
              <div>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label>
                      Shipping cost: <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mt-3 mb-3'>
                      <div className='input-group-prepend'>
                        <button
                          type='button'
                          id='currency Displayer'
                          className='btn btn-outline-primary'
                        >
                          {data.currency}
                        </button>
                      </div>
                      <NumberFormat
                        className='form-control'
                        defaultValue={0.0}
                        value={data.shipping_cost}
                        isNumericString={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        name='shipping_cost'
                        onChange={(e) => {
                          const event = {
                            ...e,
                            target: {
                              ...e.target,
                              value: e.target.value
                                .replace(/NGN /g, '')
                                .replace(/,/g, ''),
                              name: e.target.name,
                            },
                          };
                          handleChange(event);
                        }}
                        thousandSeparator={true}
                        prefix={''}
                        allowNegative={false}
                      />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className='callout callout-help'>
                      This identifies the cost of delivery
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ) : (
              ''
            )}
            {data.shipping_method == 1 ? (
              <div>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label>
                      Who pays the shipping fee:{' '}
                      <span className='text-danger'>*</span>
                    </label>
                    <div className='radio'>
                      <input
                        type='radio'
                        name='shipping_fee_bearer'
                        checked={data.shipping_fee_bearer == 'customer'}
                        value='customer'
                        onChange={handleChange}
                      />
                      &nbsp;&nbsp;Buyer
                    </div>
                    <div className='radio'>
                      <input
                        type='radio'
                        name='shipping_fee_bearer'
                        checked={data.shipping_fee_bearer == 'seller'}
                        value='seller'
                        onChange={handleChange}
                      />
                      &nbsp;&nbsp;Seller
                    </div>
                    <div className='radio'>
                      <input
                        type='radio'
                        name='shipping_fee_bearer'
                        checked={data.shipping_fee_bearer == 'both'}
                        value='both'
                        onChange={handleChange}
                      />
                      &nbsp;&nbsp;50% Buyer / 50% Seller
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className='callout callout-help'>
                      This identifies who is responsible for shipping fees. If
                      the Buyer is selected, the fees are added to the
                      transaction total. If the Seller is selected, the fees are
                      deducted from the proceeds.
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ) : (
              ''
            )}
            <div>
              <div className='row all-center'>
                <div className='col-sm-6'>
                  <label>
                    Shipping Address: <span className='text-danger'>*</span>
                  </label>
                  <textarea
                    className='form-control'
                    name='delivery_location'
                    onChange={handleChange}
                    value={data.delivery_location}
                  ></textarea>
                </div>
                <div className='col-sm-6'>
                  <br />
                  <div className='callout callout-help'>
                    The Address the goods are to be delivered (Up to 255
                    characters)
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <label>
                  Maximum Delivery Days (optional):{' '}
                  <span className='text-danger'>*</span>
                </label>
                <input
                  type='number'
                  className='form-control'
                  onChange={handleChange}
                  pattern='^[0–9]$'
                  value={data.max_delivery_days}
                  name='max_delivery_days'
                  max='90'
                  min='0'
                />
              </div>
              <div className='col-sm-6'>
                <div className='callout callout-help'>
                  This is the number of days that merchant must deliver the
                  items by, failing which there shall be automatic refund from
                  escrow back to Buyer's account. This is optional.
                </div>
              </div>
            </div>
            <hr />
            <div className='row all-center'>
              <div className='col-sm-6'>
                <label>
                  Product Description and Terms of Service:{' '}
                  <span className='text-danger'>*</span>
                </label>
                <textarea
                  className='form-control'
                  name='product_desc'
                  maxLength='5000'
                  onChange={handleChange}
                  value={data.product_desc}
                ></textarea>
              </div>
              <div className='col-sm-6'>
                <br />
                <div className='callout callout-help'>
                  Please provide detailed description of the product or service
                  and any terms of service that should be adhered to during the
                  transaction. (Up to 5,000 characters)
                </div>
              </div>
            </div>{' '}
            <br />
            <hr />
            <div className='row all-center'>
              <div className='col-sm-6'>
                <label>
                  Payment Method: <span className='text-danger'>*</span>
                </label>
                <select
                  className='form-control'
                  name='payment_method'
                  defaultValue={data.payment_method}
                  onChange={handleChange.bind(this)}
                  disabled={edit}
                >
                  <option value=''> Please Select One </option>
                  <option value='Paystack' id='optionPaystack'>
                    Paystack (NGN Debit and Credit Payment)
                  </option>

                  <option value='USSD' id='optionMonnify'>
                    Monnify (NGN Bank Transfer Payment)
                  </option>

                  {data.currency == 'USD' &&
                    terms?.payout_currency == 'USD' && (
                      <option value='Paypal' id='optionPaypal'>
                        PayPal (USD Debit and Credit Payment)
                      </option>
                    )}
                </select>
              </div>
              <div className='col-sm-6'>
                <br />
                <div className='callout callout-help'>
                  Please select payment method; Paystack, Monnify or PayPal.
                  Paystack and Monnify support NGN payments while PayPal and
                  Paystack support USD payments.
                </div>
              </div>
            </div>{' '}
            <br />
            <hr />
            <div className='clearfix'>
              {error && (
                <div className='alert animated bounceIn support-alert alert-danger'>
                  <a aria-label='close' className='close alert-close'>
                    ×
                  </a>
                  <span className='alert-message-content'>{error}</span>
                </div>
              )}
              {/*{data.amount_payed * data.quantity < 1000 || data && !data.escrow_fee_bearer || data && data.shipping_method == 1 && !data.shipping_fee_bearer || data && data.shipping_method == 1 && !data.shipping_cost || data && !data.max_delivery_days || role && role === 'customer' && data && !data.seller_phone || role && role === 'seller' && data && !data.buyer_phone || data && !data.category || data && !data.product_name || data && !data.product_desc || data && !data.delivery_location ? <p className="text-danger">To enable you submit, ensure all mandatory fields are filled</p> : null }*/}
              <small className='float-left'>
                By Clicking Start Your Transaction you agree to{' '}
                <a
                  onClick={this.toggleModal}
                  style={{ cursor: 'pointer' }}
                  className='text-info'
                >
                  AtaraPay Terms and Conditions
                </a>
              </small>
              <br></br>
              <div className='float-left mb-5'>
                <button onClick={_prevStage} className='btn btn-sm btn-info'>
                  {' '}
                  <i className='fa fa-arrow-left'></i> Back{' '}
                </button>
                &nbsp;&nbsp;
                {edit ? (
                  <span>
                    <button
                      onClick={() => handleUpdateCancel(data.id)}
                      className='btn btn-dark btn-sm'
                    >
                      {' '}
                      <i className='fa fa-close'></i> Cancel Update
                    </button>
                    &nbsp;&nbsp;
                    <button
                      onClick={handleUpdateTransaction}
                      className='btn btn-success btn-lg'
                    >
                      {' '}
                      <i className='fa fa-rocket'></i>
                      {updating
                        ? 'Updating Transaction ...'
                        : 'Update Your Transaction'}
                    </button>
                  </span>
                ) : (
                  // <Link to="/app/seller/transaction/1" className="btn btn-info btn-lg"> <i className="fa fa-rocket"></i> Start Your Transaction</Link>
                  //disabled={updating || data.amount_payed * data.quantity < 50000 || data && !data.escrow_fee_bearer || data && data.shipping_method == 1 && !data.shipping_fee_bearer || data && data.shipping_method == 1 && !data.shipping_cost || data && !data.max_delivery_days || role && role === 'customer' && data && !data.seller_phone || role && role === 'seller' && data && !data.buyer_phone || data && !data.category || data && !data.product_name || data && !data.product_desc || data && !data.delivery_location}... disabled={adding || data.amount_payed * data.quantity < 50000 || data && !data.escrow_fee_bearer || data && data.shipping_method == 1 && !data.shipping_fee_bearer || data && data.shipping_method == 1 && !data.shipping_cost || data && !data.max_delivery_days || role && role === 'customer' && data && !data.seller_phone || role && role === 'seller' && data && !data.buyer_phone || data && !data.category || data && !data.product_name ||data && !data.product_desc || data && !data.delivery_location}
                  <button
                    onClick={handleSubmit}
                    className='btn btn-info btn-lg'
                  >
                    {' '}
                    <i className='fa fa-rocket'></i>{' '}
                    {adding ? 'Loading ...' : 'Start Your Transaction'}
                  </button>
                )}
              </div>
            </div>
            <br />
            <br />
          </div>
        </section>
      </div>
    );
  }
}
export default StageTwo;
