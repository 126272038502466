import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Business from '../views/addBusiness';
import { userActions } from '../actions';
// import { payoutActions } from '../actions';


const mapStateToProps = (state) =>{

    // const {} = state.users
    const {user, adding, success } = state.users
    const alert = state.alert
    return {
        user,
        adding,
        success,
        alert,
    };
     
     
}

const mapDispatchToProps = (dispatch) => ({
    add:(data,email, history)=>dispatch(userActions.user.submitBusiness(data,email, history)),
    bvnPay: history => dispatch(userActions.user.bvnPay(history)),
    dispatch,
    
})
 

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Business));