import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import { payoutActions } from "../../actions";

class Identification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idNumber: "",
      action: "",
    };
  }

  componentDidMount = () => {
    const {idNumber, action} = this.props.match.params;
    const transactionAction = action === "approve" ? 1 : 0
    if (localStorage.getItem("trust_user_type")) {
      this.props.managePhotoId("seller", idNumber, transactionAction, this.props.history);
    } else {
      this.props.publicManagePhotoId(idNumber, transactionAction, this.props.history);
    }
  };

  render() {
    return (
      <div className="text-center">
        <ClipLoader loading={true} size={100}/>
        <div>Please wait. This will take a few seconds.</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {loading} = state.payout;
  return {
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  managePhotoId: (type, id, action, history) =>
    dispatch(payoutActions.payout.managePhotoId(type, id, action, history)),
  publicManagePhotoId: (id, action, history) =>
    dispatch(payoutActions.payout.publicManagePhotoId(id, action, history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Identification)
);
