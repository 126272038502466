import React, {Component} from 'react';
import axios from "axios";
import {BASE_URL} from "../constants";
import {ClipLoader} from "react-spinners";

class ShortcodeRedirect extends Component {

    state = {
        error: false,
        loading: true,
    }

    componentDidMount() {
        this.checkCode();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('update')
        if (prevProps.match.params.short_code !== this.props.match.params.short_code) {
            this.checkCode();
        }
    }

    checkCode = () => {
        const {short_code} = this.props.match.params;
        this.setState({
            loading: true,
        })
        axios({
            method: 'get',
            mode: 'no-cors',
            url: `${BASE_URL}seller/checkUserCode/${short_code}`,
            headers: {
                'Access-Control-Allow-Origin': '*',
                // 'Authorization': 'Bearer ' + localStorage.getItem("user"),
                'Content-Type': 'application/json',
            }
        })
            .then(({data}) => {
                console.log(data)
                const {email, phone_number} = data.data;
                setTimeout(() => {
                    window.location.href = `/#/easyp2p/seller/${email}/${phone_number}`;
                },1000)
            })
            .catch(err => {
                console.log(err.response)
                this.setState({
                    error: true
                })
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    render() {
        return (

            <div style={{height: '100vh', backgroundColor: '#f7fcfc'}}>
                <nav className="navbar navbar-expand-lg fixed-top navbar-inverse">
                    <a className="navbar-brand" href="http://www.atarapay.com">
                        <img src="/dist/img/logo-header.png" alt="" className="logo-img img img-responsive"/>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
                            aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </nav>
                <section>
                    <div className="row row-margin top-margin-60">
                        <div className="col-sm-3 col-md-3"/>
                        <div className="col-sm-8 col-md-6 col-12">
                            <div className="card card-margin text-center">
                                <p style={{fontSize: 18, marginBottom: 28}} className="text-center">
                                    {
                                        this.state.loading ?
                                            <ClipLoader
                                                size={50}
                                                loading={true}
                                            />
                                            :
                                            <>
                                                {
                                                    this.state.error ?
                                                        'The URL is invalid.'
                                                        :
                                                        <>
                                                            Please wait..
                                                            <br/>
                                                            Your will be redirected shortly.
                                                        </>
                                                }
                                            </>
                                    }
                                </p>
                            </div>

                            <div className="col-sm-3 col-md-3"/>

                        </div>
                    </div>
                    {/* </div> */}
                </section>


            </div>
        );
    }
}

export default ShortcodeRedirect;