import { BASE_URL, LOCALSTORAGE_TOKEN_KEY } from '../constants';
import axios from 'axios';


const createTerm = (term) => {

    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL}seller/term/create`,
        data: term,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
        }
    })

}
const editTerm = (term) => {
    return axios({
        method: 'put',
        mode: 'no-cors',
        url: `${BASE_URL}seller/term/edit`,
        data: term,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
        }
    })

}

const getTerm = () => {

    return axios({
        method: 'get',
        mode: 'no-cors',
        url: `${BASE_URL}seller/terms`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })

}

const getAddresses = (type) => {

    return axios({
        method: 'get',
        mode: 'no-cors',
        url: `${BASE_URL + type}/address/view`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })

}

const createAddress = (data, type) => {
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL + type}/address/create`,
        data: data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })

}

const deleteAddress = (data, type) => {
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL + type}/address/delete`,
        data: data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })

}

const setAddressAsDefault = (data, type) => {
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL + type}/address/default`,
        data: data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })

}

const getServiceProviders = (type) => {

    return axios({
        method: 'get',
        mode: 'no-cors',
        url: `${BASE_URL}serviceprovider/getSellerServiceProviders`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })

}


// function getUser(id) {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };

//     return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
// }

// function register(user) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(user)
//     };

//     return fetch(`${config.apiUrl}/users/register`, requestOptions).then(handleResponse);
// }

// function update(user) {
//     const requestOptions = {
//         method: 'PUT',
//         headers: { ...authHeader(), 'Content-Type': 'application/json' },
//         body: JSON.stringify(user)
//     };

//     return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);;
// }

export const term = {
    createTerm,
    editTerm,
    getTerm,
    createAddress,
    getAddresses,
    deleteAddress,
    setAddressAsDefault,
    getServiceProviders
};


