import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Settings from "../views/Settings";
import { termActions, settingActions } from "../actions";

const mapStateToProps = (state) => {
  const { loading, term } = state.terms;
  const { banks } = state.settings;

  const alert = state.alert;

  return {
    alert,
    loading,
    term,
    banks,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getTerm: (history) => dispatch(termActions.getTerm(history)),
  setRefundable: (setting, history) =>
    dispatch(settingActions.setRefundable(setting, history)),
  setPayment: (setting, history) =>
    dispatch(settingActions.setPayment(setting, history)),
  setCardFee: (setting, history) =>
    dispatch(settingActions.setCardFee(setting, history)),
  setSPCardFee: (setting, history) =>
    dispatch(settingActions.setSPCardFee(setting, history)),
  setEscrowBearer: (setting, history) =>
    dispatch(settingActions.setEscrowBearer(setting, history)),
  setCallbackUrl: (setting, history) =>
    dispatch(settingActions.setCallbackUrl(setting, history)),
  getBanks: (setting, history) =>
    dispatch(settingActions.getBanks(setting, history)),
  setBVNVerification: (setting, history) =>
    dispatch(settingActions.setBVNVerification(setting, history)),
  setCanCancelOrder: (setting, history) =>
      dispatch(settingActions.setCanCancelOrder(setting, history)),
  setDeliveryHandledBy: (setting, history) =>
      dispatch(settingActions.setDeliveryHandledBy(setting, history)),

});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
);
