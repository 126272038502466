import { BASE_URL, LOCALSTORAGE_TOKEN_KEY } from '../constants';
import axios from "axios";

const getPayoutReport = (type) => {
  return axios({
    method: "get",
    mode: "no-cors",
    url: `${BASE_URL + type}/payout/report  `,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      "Content-Type": "application/json",
    },
  });
};

const generateReport = (type, data, report_type) => {
  return axios({
    method: "post",
    mode: "no-cors",
    url: `${BASE_URL + type}/${report_type}/generateReport `,
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      "Content-Type": "application/json",
    },
  });
};

const getMarketPlaceSellers = () => {
  return axios({
    method: "get",
    mode: "no-cors",
    url: `${BASE_URL}marketplace/getMarketplaceSellers `,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
      "Content-Type": "application/json",
    },
  });
};

export const report = {
  getPayoutReport,
  generateReport,
  getMarketPlaceSellers,
};
