export const order = {
  ORDER_DETAILS_REQUEST: "ORDER_DETAILS_REQUEST",
  ORDER_DETAILS_SUCCESS: "ORDER_DETAILS_SUCCESS",
  ORDER_DETAILS_FAILURE: "ORDER_DETAILS_FAILURE",

  PAY_DETAILS_REQUEST: "PAY_DETAILS_REQUEST",
  PAY_DETAILS_SUCCESS: "PAY_DETAILS_SUCCESS",
  PAY_DETAILS_FAILURE: "PAY_DETAILS_FAILURE",


  SET_STATUS : "SET_STATUS",

  ASSIGN_DELIVERY_MAN: "ASSIGN_DELIVERY_MAN",
  ASSIGN_DELIVERY_MAN_SUCCESS: " ASSIGN_DELIVERY_MAN_SUCCESS",
  ASSIGN_DELIVERY_MAN_FAILURE: " ASSIGN_DELIVERY_MAN_FAILURE",

  VERIFY_PROMO_REQUEST: "VERIFY_PROMO_REQUEST",
  VERIFY_PROMO_SUCCESS: "VERIFY_PROMO_SUCCESS",
  VERIFY_PROMO_FAILURE: "VERIFY_PROMO_FAILURE",
  VERIFY_PROMO_CLEAR: "VERIFY_PROMO_CLEAR",
  CLEAR_PROMO_REQUEST: "CLEAR_PROMO_REQUEST",
  CLEAR_PROMO_FAILURE: "CLEAR_PROMO_FAILURE",
  CLEAR_PROMO_SUCCESS: "CLEAR_PROMO_SUCCESS",

  PROCESS_PAYMENT_REQUEST: "PROCESS_PAYMENT_REQUEST",
  PROCESS_PAYMENT_SUCCESS: " PROCESS_PAYMENT_SUCCESS",
  PROCESS_PAYMENT_FAILURE: " PROCESS_PAYMENT_FAILURE",

  GET_TRANSACTION_PAYMENT_REQUEST: "GET_TRANSACTION_PAYMENT_REQUEST",
  GET_TRANSACTION_PAYMENT_SUCCESS: " GET_TRANSACTION_PAYMENT_SUCCESS",
  GET_TRANSACTION_PAYMENT_FAILURE: " GET_TRANSACTION_PAYMENT_FAILURE",
  GET_TRANSACTION_PAYMENT_CLEAR: " GET_TRANSACTION_PAYMENT_CLEAR",

  START_POD: "START_POD",
  START_POD_SUCCESS: " START_POD_SUCCESS",
  START_POD_FAILURE: " START_POD_FAILURE",
};
