import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './store';
import { Provider } from 'react-redux';
import "babel-polyfill";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import * as Sentry from '@sentry/browser';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import TagManager from 'react-gtm-module';
import { HashRouter as Router } from 'react-router-dom'; 
const {
  REACT_APP_TAG_MANAGER_KEY: TAG_MANAGER_KEY,
  REACT_APP_SENTRY_URL: SENTRY_URL,
  REACT_APP_BUGSNAG_API_KEY: BUGSNAG_API_KEY,
} = process.env;

// Sentry bug tracker init
Sentry.init({
  dsn: SENTRY_URL,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/test-api.atarapay\.com\/api/,
        /^https:\/\/api.atarapay\.com\/api/,
      ],
    }),
    new Sentry.Replay(),
  ],
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  release: 'atarapay-frontent@1.6.1',
});

// Bugsnag tracker init
Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react')
.createErrorBoundary(React)

const tagManagerArgs = {
  gtmId: TAG_MANAGER_KEY,
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ErrorBoundary>
      <App />
      </ErrorBoundary>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
