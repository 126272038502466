export const delivery = {
    GET_DELIVERY_MEN:     ' GET_DELIVERY_MEN',
    GET_DELIVERY_MEN_SUCCESS: ' GET_DELIVERY_MEN_SUCCESS',
    GET_DELIVERY_MEN_FAILURE: ' GET_DELIVERY_MEN_FAILURE',

    GET_DELIVERY_MAN_TYPE:     ' GET_DELIVERY_MAN_TYPE',
    GET_DELIVERY_MAN_TYPE_SUCCESS: ' GET_DELIVERY_MAN_TYPE_SUCCESS',
    GET_DELIVERY_MAN_TYPE_FAILURE: ' GET_DELIVERY_MAN_TYPE_FAILURE',
    
    UPDATE_DELIVERY_MAN:         'UPDATE_DELIVERY_MAN',
    UPDATE_DELIVERY_MAN_SUCCESS: 'UPDATE_DELIVERY_MAN_SUCCESS',
    UPDATE_DELIVERY_MAN_FAILURE: 'UPDATE_DELIVERY_MAN_FAILURE',
    
    DELETE_DELIVERY_MAN:         ' DELETE_DELIVERY_MAN',
    DELETE_DELIVERY_MAN_SUCCESS: ' DELETE_DELIVERY_MAN_SUCCESS',
    DELETE_DELIVERY_MAN_FAILURE: ' DELETE_DELIVERY_MAN_FAILURE',
   
    ADD_DELIVERY_MAN:         ' ADD_DELIVERY_MAN',
    ADD_DELIVERY_MAN_SUCCESS: ' ADD_DELIVERY_MAN_SUCCESS',
    ADD_DELIVERY_MAN_FAILURE: ' ADD_DELIVERY_MAN_FAILURE',


    GET_COURIERS_REQUEST: 'GET_COURIERS_REQUEST',
    GET_COURIERS_SUCCESS: 'GET_COURIERS_SUCCESS',
    GET_COURIERS_FAILURE: 'GET_COURIERS_FAILURE',

    ADD_COURIER_REQUEST: 'ADD_COURIER_REQUEST',
    ADD_COURIER_SUCCESS: 'ADD_COURIER_SUCCESS',
    ADD_COURIER_FAILURE: 'ADD_COURIER_FAILURE',

    UPDATE_COURIER_REQUEST: 'UPDATE_COURIER_REQUEST',
    UPDATE_COURIER_SUCCESS: 'UPDATE_COURIER_SUCCESS',
    UPDATE_COURIER_FAILURE: 'UPDATE_COURIER_FAILURE',

    DELETE_COURIER_REQUEST: 'DELETE_COURIER_REQUEST',
    DELETE_COURIER_SUCCESS: 'DELETE_COURIER_SUCCESS',
    DELETE_COURIER_FAILURE: 'DELETE_COURIER_FAILURE',


    
}