export const payout = {
    PAYOUT_DETAIL_ADD_REQUEST: 'PAYOUT_DETAIL_ADD_REQUEST',
    PAYOUT_DETAIL_ADD_SUCCESS: 'PAYOUT_DETAIL_ADD_SUCCESS',
    PAYOUT_DETAIL_ADD_FAILURE: 'PAYOUT_DETAIL_ADD_FAILURE',

    PAYOUT_DETAIL_EDIT_REQUEST: 'PAYOUT_DETAIL_EDIT_REQUEST',
    PAYOUT_DETAIL_EDIT_SUCCESS: 'PAYOUT_DETAIL_EDIT_SUCCESS',
    PAYOUT_DETAIL_EDIT_FAILURE: 'PAYOUT_DETAIL_EDIT_FAILURE',


    ALL_PAYOUTS_REQUEST: 'ALL_PAYOUTS_REQUEST',
    ALL_PAYOUTS_SUCCESS: 'ALL_PAYOUTS_SUCCESS',
    ALL_PAYOUTS_FAILURE: 'ALL_PAYOUTS_FAILURE',

    PAYOUT_DETAIL_DELETE_REQUEST: 'PAYOUT_DETAIL_DELETE_REQUEST',
    PAYOUT_DETAIL_DELETE_SUCCESS: 'PAYOUT_DETAIL_DELETE_SUCCESS',
    PAYOUT_DETAIL_DELETE_FAILURE: 'PAYOUT_DETAIL_DELETE_FAILURE',


    PAYOUT_DETAIL_SET_DEFAULT_REQUEST: 'PAYOUT_DETAIL_SET_DEFAULT_REQUEST',
    PAYOUT_DETAIL_SET_DEFAULT_SUCCESS: 'PAYOUT_DETAIL_SET_DEFAULT_SUCCESS',
    PAYOUT_DETAIL_SET_DEFAULT_FAILURE: 'PAYOUT_DETAIL_SET_DEFAULT_FAILURE',

    PAYOUT_DETAILS_REQUEST: 'PAYOUT_DETAIL_REQUEST',
    PAYOUT_DETAILS_SUCCESS: 'PAYOUT_DETAIL_SUCCESS',
    PAYOUT_DETAILS_FAILURE: 'PAYOUT_DETAIL_FAILURE',

    PAYOUT_SP_DELETE_REQUEST: 'PAYOUT_SP_DELETE_REQUEST',
    PAYOUT_SP_DELETE_SUCCESS: 'PAYOUT_SP_DELETE_SUCCESS',
    PAYOUT_SP_DELETE_FAILURE: 'PAYOUT_SP_DELETE_FAILURE',

    PHOTO_ID_ADD_SUCCESS: 'PHOTO_ID_ADD_SUCCESS',
    PHOTO_ID_ADD_REQUEST: 'PHOTO_ID_ADD_REQUEST',
    PHOTO_ID_ADD_FAILURE: 'PHOTO_ID_ADD_FAILURE',

    MANAGE_PHOTO_ID_SUCCESS: 'MANAGE_PHOTO_ID_SUCCESS',
    MANAGE_PHOTO_ID_REQUEST: 'MANAGE_PHOTO_ID_REQUEST',
    MANAGE_PHOTO_ID_FAILURE: ' MANAGE_PHOTO_ID_FAILURE',

    GET_BANKS: "GET_BANKS",
    GET_BANKS_SUCCESS: "GET_BANKS_SUCCESS",
    GET_BANKS_FAILURE: "GET_BANKS_FAILURE",

}
