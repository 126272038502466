import React from 'react';
import ReactModal from 'react-responsive-modal';
import transactionHelpers, { toLocaleDisplay } from '../helpers/transaction';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';

const escrowFees = (props) => {
  let { payment_info, conversion_ratio } = props.data;
  return payment_info?.buyerEscrowFees / 100 / (conversion_ratio ?? 1);
};

const cardFees = (props) => {
  let payableCardFees =
    props?.data?.currency === 'USD' && props?.data?.category !== 'Money Market'
      ? (props?.data?.payment_info?.buyerCardFees +
          props?.data?.payment_info?.buyerTransferFees) /
        100 /
        props?.data?.conversion_ratio
      : (props?.data?.payment_info?.buyerCardFees +
          props?.data?.payment_info?.buyerTransferFees) /
        100;
  return payableCardFees;
};
const netAmount = (props) => {
  let productCost = props.data.amount_payed * props.data.quantity;
  let shippingCost = transactionHelpers.calculateShipping(props.data)[
    props.role
  ];
  let netCost = (productCost + shippingCost) / props.data.conversion_ratio;
  if (props.data.category === 'Money Market') {
    productCost = props.data.conversion_ratio * props.data.quantity;
    netCost = productCost + shippingCost;
  }
  return netCost;
};

const productAmount = (props) => {
  let { conversion_ratio, payment_info } = props.data;
  return payment_info?.productCost /100 /conversion_ratio;
};

const payoutAmount = (props) => {
  let { conversion_ratio, payment_info } = props.data;
  return payment_info?.payoutAmount /100 /conversion_ratio;
};

const refundableAmount = (props) => {
  let txFees =
    props?.data.tx_fee_bearer === 'seller'
      ? cardFees(props)
      : 50 / props?.data?.conversion_ratio;
  txFees = txFees * props?.data?.conversion_ratio;
  let netRefundAmount =
    props?.data?.payment_info?.refundAmount /
    100 /
    props?.data?.conversion_ratio;

  if (props?.data?.category === 'Money Market') {
    txFees = props?.data.tx_fee_bearer === 'seller' ? cardFees(props) : 50;
    netRefundAmount =
      props?.data?.payment_info?.refundAmount /
      100 /
      props?.data?.conversion_ratio;
  }

  return netRefundAmount;
};
const logisticFees = (props) => {
  let totalCost =
    props.data.category === 'Money Market'
      ? netAmount(props)
      : netAmount(props) * props.data.conversion_ratio;
  let logisticCost = (props.terms.logistics_fee * totalCost) / 100;
  if (logisticCost > 5000) {
    logisticCost = 5000;
  }
  if (props.data.category !== 'Money Market')
    return logisticCost / props.data.conversion_ratio;
  return logisticCost;
};

const grandTotal = (props, values) => {
  const {
    payment_info: { totalPayable = null } = {},
    conversion_ratio,
    tx_fee_bearer,
    category,
  } = values || {};

  return category !== 'Money Market'
    ? totalPayable / 100 / conversion_ratio
    : totalPayable / 100;
};
const grandTotalUSD = (props, values) => {
  const { conversion_ratio } = values;
  return grandTotal(props, values) * conversion_ratio;
};

let promoInput = React.createRef();

const transactionCurrency = (data) => {
  let currency = data.category === 'Money Market' ? 'NGN' : data.currency;
  return currency;
};

const ModalComponent = (props) => {
  const { open, onClose, data, user, seller_phone, promoID, showCouponLoader } = props
  return (
    <ReactModal open={open} onClose={onClose}>
      <div class=''>
        <div class='row'>
          <div class='col-10'>
            <img
              src='/dist/img/logo.3fc64c3.png'
              alt='AtaraPay Logo'
              style={{ opacity: 1, width: '200px', paddingLeft: 15 }}
            />
          </div>
        </div>
        <br />
        <div className='container'>
          <p>
            Your AtaraPay email is <strong>{user && user.email}</strong>
          </p>
          <p>
            Your AtaraPay phone number is{' '}
            <strong>{user && user.phone_number}</strong>
          </p>
          <p>Alternative Recipient Phone Number </p>
          <PhoneInput
            defaultCountry='NG'
            placeholder='080X XXX XXXX'
            value={
              (props.data && formatPhoneNumber(seller_phone, 'National')) ||
              formatPhoneNumber(seller_phone, 'International')
            }
            inputClassName={'text-alt'}
            onChange={(value) => props.handlePhone(value)}
          />
          <p>
            <small>
              [Please enter this number if you will not be able to receive the
              item(s) <br />
              in person. Ensure to update the international code accordingly]
            </small>
          </p>
          <p>
            You will be charged escrow fee of
            {' '}{toLocaleDisplay(escrowFees(props), transactionCurrency(data))}{' '}
            {props.data &&
            props.data.tx_fee_bearer &&
            props.data.tx_fee_bearer !== 'seller'
              ? 'plus Switch fee of ' +
                toLocaleDisplay(cardFees(props), transactionCurrency(data))
              : ' '}{' '}
            and will be depositing{' '}
            {toLocaleDisplay(productAmount(props), transactionCurrency(data))} plus{' '}
            {toLocaleDisplay(escrowFees(props), transactionCurrency(data))}{' '}
            {props.data &&
            props.data.tx_fee_bearer &&
            props.data.tx_fee_bearer !== 'seller'
              ? 'plus ' +
                toLocaleDisplay(cardFees(props), transactionCurrency(data))
              : ' '}{' '}
            into escrow today. When you accept the item, we will credit the
            merchant with{' '}
            {toLocaleDisplay(payoutAmount(props), transactionCurrency(data))}. If
            you reject the item,{' '}
            {props.terms && props.terms.refund_option === 0
              ? 'you shall get full replacement for them.'
              : 'we will refund you ' +
                toLocaleDisplay(
                  refundableAmount(props),
                  transactionCurrency(data)
                ) +
                ' within 24 hours.'}
          </p>
          <blockquote>
            Seller's Delivery Terms:
            {props.terms && props.terms.refund_option === 0 ? (
              <ul>
                <li className='text-danger'>
                  Seller supports full replacements. No refund allowed
                </li>
              </ul>
            ) : (
              <div>
                <p>
                  A logistics fee of{' '}
                  <span class={'text-danger'}>
                    <b>
                      {props.data.sellers &&
                        props.terms &&
                        toLocaleDisplay(
                          logisticFees(props),
                          transactionCurrency(data)
                        )}
                    </b>
                  </span>{' '}
                  will be charged from your escrow deposit, if you;
                </p>
                <ul>
                  <li>
                    Cancel after{' '}
                    {(props.data.sellers &&
                      props.terms &&
                      props.terms.SLA === '0.5') ||
                    (props.data.sellers && props.terms && props.terms.SLA) < 1.0
                      ? props.data.sellers &&
                        props.terms &&
                        props.terms.SLA * 60 + ' minutes'
                      : props.data.sellers &&
                        props.terms &&
                        props.terms.SLA + ' hours'}
                  </li>
                  {/* <li>Reject at no fault of Seller</li> */}
                  <li>Refund will be made less applicable fees</li>
                </ul>
              </div>
            )}
          </blockquote>

          <p>
            After making successful payment, please complete your AtaraPay
            registration from the link sent to your email address.
          </p>
          <p>
            Please note that maximum delivery days of the item(s) is{' '}
            {Number(props.data.max_delivery_days)} days after which if the order
            is not accepted or rejected, your funds will be refunded less escrow
            fees.
          </p>
          <p>
            For details on our escrow service click{' '}
            <a
              rel='noopener noreferrer'
              target='_blank'
              href={'https://www.atarapay.com/why_escrow'}
            >
              here
            </a>
          </p>
          <p className={'text-danger'}>
            <small>
              Your funds are insured by a NAICOM-licensed insurance company.
            </small>
          </p>
        </div>
        <div className='clearfix'>
          <div className='form-inline float-left'>
            <div className='form-group'>
              <input
                type='text'
                ref={promoInput}
                disabled={
                (props.promo &&
                  props.promo.promo_code &&
                  promoInput &&
                  promoInput.current &&
                  promoInput.current.value) || promoID
                }
                name='promo'
                id='promo_code'
                placeholder='Promo code'
                className='form-control col-sm-3 col-md-6 ml-3 mb-2'
                style={{ width: '125px', fontSize: '11px' }}
              />

              {(props.promo && props.promo.promo_code
                && promoInput && promoInput.current
                && promoInput.current.value) || promoID ? (
                <button
                  className='btn btn-link col-md-3 col-sm-3 ml-1 mb-2 text-danger'
                  style={{ paddingLeft: 5, textDecoration: 'none' }}
                  onClick={() => props.clearCoupon(promoInput)}
                >
                  {showCouponLoader ? 'loading....' : 'Clear'}
                </button>
              ) : (
                <button
                  type='button'
                  className='btn btn-link col-md-3 col-sm-3 ml-1 mb-2'
                  style={{
                    paddingLeft: 5,
                    color: '#007bff',
                    textDecoration: 'none',
                  }}
                  onClick={() => props.applyCoupon(promoInput)}
                >
                  {showCouponLoader ? 'loading....' : 'Apply'}
                </button>
              )}
            </div>
          </div>
          <div className='float-right'>
            <button
              onClick={props.onClose}
              className='btn btn-md btn-danger mb-2'
            >
              {' '}
              Cancel
            </button>
            &nbsp;&nbsp;
            <button
              onClick={
                props.data.gateway_name == 'Paypal'
                  ? props.handlePaypal
                  : props.pay
              }
              className='btn btn-md btn-info mb-2'
            >
              <i className='fa fa-coins'></i>
              Yes, Desposit{' '}
              {data &&
                toLocaleDisplay(
                  grandTotal(props, data),
                  transactionCurrency(data)
                )}{' '}
              {transactionCurrency(data) === 'USD'
                ? `(${
                    data && toLocaleDisplay(grandTotalUSD(props, data), 'NGN')
                  })`
                : ''}
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
export default ModalComponent;
