import { supportService } from "../service.js";
import { supportConstants } from "../constants";
import { alertActions, userActions } from "./";

const createSupportMail = (support, type, history) => {
  console.log("support", support);
  console.log("type", type);
  return (dispatch) => {
    dispatch(request(support));

    supportService
      .createSupportMail(support, type)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success(res.data.data));

          dispatch(
            alertActions.alert.success(support.email?.replace(/_/g, " "))
          );
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/\\n/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 401) {
            dispatch(userActions.user.logout(type, history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request(support) {
    return { type: supportConstants.support.CREATE_SUPPORT_MAIL, support };
  }
  function success(support) {
    return {
      type: supportConstants.support.CREATE_SUPPORT_MAIL_SUCCESS,
      support,
    };
  }
  function failure(error) {
    return {
      type: supportConstants.support.CREATE_SUPPORT_MAIL_FAILURE,
      error,
    };
  }
};

const BusinessVerification = (token, answer, history) => {
  return (dispatch) => {
    // console.log(data);

    dispatch(request());

    supportService
      .verifyBusiness(token, answer)
      .then((res) => {
        dispatch(success());
        if (res.data.status === "success") {
          history.push("/login");
          if (res.data.message === "seller_approved") {
            dispatch(
              alertActions.alert.success(
                `Business has been approved`?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(
              alertActions.alert.success(
                `Business approval has been declined`?.replace(/_/g, " ")
              )
            );
          }
        } else {
          history.push("/login");
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);

          dispatch(failure(err.response.data.message));
          dispatch(
            alertActions.alert.error(
              err.response.data.message?.replace(/_/g, " ")
            )
          );
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };

  function request() {
    return { type: supportConstants.support.VERIFY_BUSINESS_REQUEST };
  }
  function success() {
    return { type: supportConstants.support.VERIFY_BUSINESS_SUCCESS };
  }
  function failure(error) {
    return { type: supportConstants.support.VERIFY_BUSINESS_FAILURE, error };
  }
};

export { createSupportMail, BusinessVerification };
