import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";



import PayOutDetails from '../views/payoutDetails';
import { payoutActions } from '../actions';

const mapStateToProps = (state) =>{
    const { adding , accounts, loading, updating, deleting, banks} = state.payout;
    const {user} = state.users;

    const alert  = state.alert;

    return {
        alert,
        adding,
        updating,
        deleting,
        accounts,
        loading,
        user,
        banks
    };


 }
 const mapDispatchToProps = (dispatch) => ({
    view:(type, history)=>dispatch(payoutActions.payout.view(type, history)),
    getBanks:(type, history)=>dispatch(payoutActions.payout.getBanks(type, history)),
    edit:(data,type, history)=>dispatch(payoutActions.payout.edit(data,type, history)),
    delDetail:(id,type, history)=>dispatch(payoutActions.payout.delDetail(id,type, history)),
    setDefault:(id,type, history)=>dispatch(payoutActions.payout.setDefault(id,type, history)),
    delSp:(id,type, history)=>dispatch(payoutActions.payout.delSp(id,type, history)),
    createSp:(id,type, history)=>dispatch(payoutActions.payout.delSp(id,type, history)),
    dispatch
}
)


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(PayOutDetails));
