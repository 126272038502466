import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Modal from 'react-responsive-modal';
import * as moment from 'moment';
import { alertActions } from '../actions';
import swal from '@sweetalert/with-react';
import matchSorter from 'match-sorter';

import { jsx, css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';
import transactionHelper from '../helpers/transaction';

const override = css`
  display: block;
  margin-top: 10%;
  margin-left: 40%;
  margin-right: 60%;
  border-color: red;
`;

const formatToSixDigits = (number) => {
    var output = number + '';
    while (output.length < 6) {
        output = '0' + output;
    }
    return output;
};

class Order extends Component {
    state = {
        filterable: false,
        notCustom: true,
        period: 'All',
        assignModal: false,
        fullModal: false,
        from_date: '',
        filter: '',
        to_date: '',
        cancel: false,
        data: [],
        refundable: false,
        order: '',
        checkorders: [],
        fields: {
            order_id: 0,
            delivery_man_id: 0,
        },
        orders: [],
        role: 'seller',
        reason: '',
        response: '',
        cancelOrder: false,
        completeDelivery: true,
        payoutCurrency: 'NGN',
        terms: [],
        current_date: '',
        validSec: 300,
    };

    componentDidMount() {
        if (localStorage.getItem('trust_user_type') === 'seller')
            this.setState({role: 'seller'});
        else this.setState({role: 'buyer'});
        if (this.props.orders) {
            this.setState({data: [...this.props.orders]});
        }
    }

    componentWillReceiveProps(props) {
        if (
            props.alert &&
            props.alert.message &&
            props.alert.type === 'alert-danger'
        ) {
            swal('Error', props.alert.message, 'error').then(() => {
                props.dispatch(alertActions.alert.clear());
            });
        }

        if (
            props.alert &&
            props.alert.message &&
            props.alert.type === 'alert-success'
        ) {
            swal('Success', props.alert.message, 'success').then(() => {
                props.dispatch(alertActions.alert.clear());
                if (this.state.role === 'seller') {
                    this.props.view('seller', this.props.history);
                } else {
                    this.props.view('customer', this.props.history);
                }
            });
            this.onCloseModal('assignModal');
            this.onCloseModal('cancel');
            this.onCloseModal('cancelOrder');
            this.setState({reason: '', order: ''});
        }
        if (props.orders) {
            this.setState({data: [...props.orders]});
            this.setState({orders: [...props.orders]});
        }
    }

    handleCancel = (e) => {
        e.preventDefault();
        const {order} = this.state;
        this.props.cancel(
            localStorage.getItem('trust_user_type'),
            {dispute_id: order.id},
            this.props.history
        );
    };
    handleSelect = (e) => {
        const {value} = e.target;
        if (value == 'Custom') {
            this.setState({notCustom: !this.state.notCustom});
        }
        this.filterPeriod(value);
        this.setState({period: value});
    };
    filterPeriod = (value) => {
        const data = this.state.orders;
        if (value == 'All') {
            this.table_rows = data;
            this.notCustom = true;
            this.page = 1;
            this.setState({
                to_date: '',
                from_date: '',
                notCustom: true,
                data: [...this.props.orders],
            });
        }

        if (value == 'This Week') {
            var ordersThisWeek = [];

            for (var i = 0; i < data.length; i++) {
                var now = moment();
                var input = moment(data[i].transaction_date);
                var isThisWeek = now.isoWeek() == input.isoWeek();

                if (isThisWeek) {
                    ordersThisWeek.push(data[i]);
                }
            }

            this.table_rows = ordersThisWeek;
            this.notCustom = true;
            this.page = 1;
            console.log(this.table_rows);
            this.setState({
                to_date: '',
                from_date: '',
                notCustom: true,
                data: [...this.table_rows],
            });
        }

        if (value == 'This Month') {
            var ordersThisMonth = [];

            for (var i = 0; i < data.length; i++) {
                //var now = moment();
                var input = moment(data[i].transaction_date);
                var isThisMonth = input.isSame(new Date(), 'month');

                if (isThisMonth) {
                    ordersThisMonth.push(data[i]);
                }
            }

            this.table_rows = ordersThisMonth;
            this.notCustom = true;
            this.page = 1;
            this.setState({
                to_date: '',
                from_date: '',
                notCustom: true,
                data: [...this.table_rows],
            });
        }

        if (value == '60 Days Ago') {
            var ordersSixtyDaysAgo = [];

            for (var i = 0; i < data.length; i++) {
                var sixty_days_ago = moment().subtract(60, 'days');

                var isBetweenSixtyDays = moment(data[i].transaction_date).isAfter(
                    sixty_days_ago
                );

                if (isBetweenSixtyDays) {
                    ordersSixtyDaysAgo.push(data[i]);
                }
            }

            this.table_rows = ordersSixtyDaysAgo;
            this.notCustom = true;
            this.page = 1;
            this.setState({
                to_date: '',
                from_date: '',
                notCustom: true,
                data: [...this.table_rows],
            });
        }

        if (value == '90 Days Ago') {
            var ordersNinetyDaysAgo = [];

            for (var i = 0; i < data.length; i++) {
                var ninety_days_ago = moment().subtract(90, 'days');

                var isBetweenNinetyDays = moment(data[i].transaction_date).isAfter(
                    ninety_days_ago
                );

                if (isBetweenNinetyDays) {
                    ordersNinetyDaysAgo.push(data[i]);
                }
            }

            this.table_rows = ordersNinetyDaysAgo;
            this.notCustom = true;
            this.page = 1;
            this.setState({
                to_date: '',
                from_date: '',
                notCustom: true,
                data: [...this.table_rows],
            });
        }

        if (value == 'Custom') {
            this.table_rows = data;
            this.setState({to_date: '', from_date: ''});
        }
    };

    filterDates = (data) => {
        if (this.state.from_date == '') {
            swal('Error', 'Please select a From date', 'error');
            return this.state.orders;
        }
        if (this.state.to_date == '') {
            swal('Error', 'Please select a To date', 'error');
            return this.state.orders;
        }

        if (moment(this.state.from_date).isAfter(this.state.to_date)) {
            swal(
                'Error',
                'Please select a From date that is before your To date',
                'error'
            );
            return this.state.orders;
        }

        //console.log(this.state.from_date); console.log( this.state.to_date);

        var ordersBetweenTheTwoDates = [];

        for (var i = 0; i < data.length; i++) {
            var isBetweenDates = moment(data[i].transaction_date).isBetween(
                this.state.from_date,
                this.state.to_date
            ); // true
            // console.log(isBetweenDates);
            if (isBetweenDates) {
                ordersBetweenTheTwoDates.push(data[i]);
            }
        }

        this.table_rows = ordersBetweenTheTwoDates;
        this.page = 1;
        console.log(this.table_rows);
        return this.table_rows;
    };
    handleDate = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    };
    handleDispatch = (e) => {
        const {name, value} = e.target;
        const fields = {...this.state.fields, delivery_man_id: value};
        this.setState({fields});
    };
    assignDispatcher = (e) => {
        e.preventDefault();
        const {fields, order} = this.state;
        const data = {
            order_id: order.id,
            delivery_man_id: fields.delivery_man_id,
        };
        this.props.assignDispatcher('seller', data, this.props.history);
        this.setState({data: [this.state.orders]});
    };
    filterTable = (filter) => {
        var data = this.state.orders;
        var filteredRows = [];
        let notPaid = 'not paid';
        let paid = 'paid';
        if (filter != '') {
            for (var i = 0; i < data?.length; i++) {
                if (
                    (data[i]?.id &&
                        formatToSixDigits(data[i]?.id)?.match(new RegExp(filter, 'gi'))) ||
                    (data[i]?.customers &&
                        data[i]?.customers?.lastname?.match(new RegExp(filter, 'gi'))) ||
                    (data[i]?.customers &&
                        data[i]?.customers?.firstname?.match(new RegExp(filter, 'gi'))) ||
                    (data[i]?.product_name &&
                        data[i]?.product_name?.match(new RegExp(filter, 'gi'))) ||
                    (data[i]?.sellers &&
                        data[i]?.sellers?.business_name &&
                        data[i]?.sellers?.business_name?.match(new RegExp(filter, 'gi'))) ||
                    (data[i]?.sellers &&
                        data[i]?.sellers?.users &&
                        data[i]?.sellers?.users?.lastname?.match(
                            new RegExp(filter, 'gi')
                        )) ||
                    (data[i]?.sellers &&
                        data[i]?.sellers?.users &&
                        data[i]?.sellers?.users?.firstname?.match(
                            new RegExp(filter, 'gi')
                        )) ||
                    (data[i]?.transaction_date &&
                        data[i]?.transaction_date?.match(new RegExp(filter, 'gi'))) ||
                    (data[i]?.status &&
                        data[i]?.status?.title?.match(new RegExp(filter, 'gi'))) ||
                    (data[i]?.payment_number === 0 &&
                        notPaid.match(new RegExp(filter, 'gi'))) ||
                    (data[i]?.payment_number && paid.match(new RegExp(filter, 'gi')))
                ) {
                    filteredRows.push(data[i]);
                }
            }

            this.setState({data: [...filteredRows]});
        } else {
            this.setState({data: [...this.state.orders]});
        }
    };
    handleFilter = (e) => {
        const {value} = e.target;
        this.setState({filter: value}, () => this.filterTable(this.state.filter));
    };
    onOpenModal = (name, order) => {
        this.setState({[name]: true});
        this.setState({order: order});
    };
    onCloseModal = (name) => {
        this.setState({[name]: false});
        this.setState({order: '', reason: ''});
    };
    formatAmount = (amount) => {
        let a = amount + '';
        let naira = a.slice(0, -3);
        let kobo = a.substr(a.length - 2);
        return naira + '.' + kobo;
    };
    handleChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    };
    cancelTransaction = () => {
        console.log(this.state.role, 'Role');
        this.props.cancelTransaction(
            this.state.role,
            {order_id: this.state.order.id, comment: this.state.reason},
            this.props.history
        );
    };
    AssignServiceProvider = () => {
        this.props.addServiceProvider(
            {
                order_id: this.state.order.id,
                sp_id: this.state.sp_id,
                commission: this.state.commission,
                seller: this.state.order.beneficiary_merchant_id,
            },
            this.props.history
        );
    };
    acceptDelivery = (e) => {
        e.preventDefault();

        const data = {
            answer: 'Y',
            state: 1,
            order_code: this.state.order.order_code,
        };

        this.props.acceptOrder('accept', data, this.props.history);
        this.onCloseModal('acceptDeliveryModal');
    };

    rejectDelivery = (e) => {
        e.preventDefault();

        const data = {
            answer: 'N',
            state: 1,
            order_code: this.state.order.order_code,
            reject: this.state.reject,
        };

        this.props.rejectOrder('reject', data, this.props.history);
        this.onCloseModal('rejectDeliveryModal');
    };

    confirmImpediment = (e) => {
        e.preventDefault();

        const data = {
            answer: this.state.response,
            state: this.state.order.attempts == '1' ? 1 : 2,
            order_code: this.state.order.order_code,
            type: this.state.order.attempts == '1' ? 'inspections' : 'inspection',
            refundType: this.state.order?.delivery_term?.refund_option,
        };
        this.props.confirmImpediment('response', data, this.props.history);
        this.onCloseModal('confirmImpedimentModal');
    };

    confirmModals = (e) => {
        const phone = this.state.order.customers.phone_number;
        const email = this.state.order.customers.email;
        const orderId = formatToSixDigits(this.state.order.id);
        let data = {order: orderId, phone_number: phone, email: email};
        this.props.completeOrder(data);
        this.props.dispatch(
            alertActions.alert.success(
                'Thank you. Buyer has been notified to accept or reject your delivery.'
            )
        );
        this.onCloseModal('confirmModal');
    };

    triggerPickup = (e) => {
        this.props.readyForPickup({order: this.state.order.id,}, this.props.history);
    };
    checkDeliveryTerm = (
        is_refundable,
        transaction_date,
        current_date,
        validSec
    ) => {
        let date1 = new Date(transaction_date?.replace(/-/g, '/'));
        let date2 = new Date(current_date?.replace(/-/g, '/'));
        let SecDiff = (date2.getTime() - date1.getTime()) / 1000;
        if (is_refundable === 0) {
            if (SecDiff > validSec) return false;
        }
        return true;
    };

    checkAll = (ele) => {
        let order_check = [];
        var checkboxes = document.getElementsByClassName('checkBox');
        if (ele.target.checked === true) {
            for (var i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].type === 'checkbox') {
                    if (
                        checkboxes[i].alt === 'Pending' ||
                        checkboxes[i].alt === 'Pending II'
                    ) {
                        checkboxes[i].checked = true;
                        order_check.push(checkboxes[i].value);
                    }
                }
            }
        } else {
            for (var j = 0; j < checkboxes.length; j++) {
                if (checkboxes[j].type === 'checkbox') {
                    checkboxes[j].checked = false;
                    //console.log(checkboxes[j].value);
                }
            }
        }
        // this.setState({checkorders: order_check});
    };

    bulkDelivery() {
        var items = document.getElementsByClassName('checkBox');
        //var len = jQuery("[name='checks[]']:checked").length;
        var len = [].slice
            .call(document.querySelectorAll("[name='checks']"))
            .filter(function (e) {
                return e.checked;
            }).length;

        if (len > 0) {
            for (var i = 0; i < items.length; i++) {
                if (items[i].type === 'checkbox' && items[i].checked === true) {
                    var split_array = items[i].value.split(',');
                    var orderId = split_array[0];
                    var email = split_array[1];
                    var phone = split_array[2];
                    let data = {order: orderId, phone_number: phone, email: email};
                    this.props.completeOrder(data);
                    this.props.dispatch(
                        alertActions.alert.success(
                            'Thank you. Buyer has been notified to accept or reject your delivery.'
                        )
                    );
                }
            }
        } else {
            this.props.dispatch(
                alertActions.alert.error('Please select anyone transaction to process')
            );
        }
    }

    render() {
        const {
            orders,
            loading,
            deliveryMen,
            alert,
            canceling,
            canceling2,
            user,
            adding,
        } = this.props;
        const {filterable, role, completeDelivery, payoutCurrency} = this.state;
        //   var data = [];
        //   orders ? data = orders  : data = [];
        var columns = [];
        if (localStorage.getItem('trust_user_type') === 'customer') {
            columns = [
                {
                    Header: (props) => (
                        <span>
              <b>Order ID</b>
            </span>
                    ),
                    id: 'id',
                    filterable,
                    accessor: (d) => d, // String-based value accessors!
                    Cell: (props) => (
                        <a
                            href='#/app/orders'
                            onClick={() => this.onOpenModal('fullModal', props.value)}
                        >
                            {formatToSixDigits(props.value.id)}
                        </a>
                    ),
                },
                {
                    Header: (props) => (
                        <span>
              <b>Item</b>
            </span>
                    ),
                    id: 'item',
                    filterable,
                    accessor: (d) => d, // String-based value accessors!
                    Cell: (props) => (
                        <span className='number'>{props.value.product_name}</span>
                    ),
                },
                {
                    Header: (props) => (
                        <span>
              <b>Seller</b>
            </span>
                    ),
                    id: 'seller',
                    filterable,
                    accessor: (d) => d.sellers,
                    Cell: (props) => (
                        <span className='number'>
              {props.value == null ? (
                  <span className='text-danger'>Yet to Register</span>
              ) : props.value.business_name ? (
                  props.value.business_name
              ) : (
                  props.value.users &&
                  props.value.users.firstname + ' ' + props.value.users.lastname
              )}
            </span>
                    ), // Custom cell components!
                },
                {
                    id: 'transaction_date', // Required because our accessor is not a string
                    Header: (props) => (
                        <span>
              <b>Order Date</b>
            </span>
                    ),
                    filterable,
                    accessor: 'transaction_date', // Custom value accessors!
                },
                {
                    id: 'value',
                    filterable,
                    Header: (props) => (
                        <span>
              <b>Order Status</b>
            </span>
                    ),
                    accessor: (d) => d.status.title, // String-based value accessors!
                },
                {
                    id: 'payment',
                    sortable: true,
                    filterable,
                    Header: (props) => (
                        <span>
              <b>Payment Status</b>
            </span>
                    ),
                    accessor: 'payment', // String-based value accessors!
                    className: 'text-left',
                    Cell: (props) => (
                        <div>
                            {props.value ? (
                                <span class='badge badge-success'>Paid</span>
                            ) : (
                                <span class='badge badge-danger'>Not Paid</span>
                            )}
                        </div>
                    ),
                },
                {
                    id: 'actions',
                    sortable: false,
                    filterable: false,
                    Header: (props) => (
                        <span>
              <b>Actions</b>
            </span>
                    ),
                    accessor: (d) => d,
                    Cell: (props) => (
                        <span>
              {props.value.status.title === 'Disputed' && (
                  <a
                      style={{cursor: 'pointer'}}
                      class='text-dark'
                      onClick={() => this.onOpenModal('cancel', props.value)}
                  >
                      <small>Cancel Dispute</small>
                      &nbsp;&nbsp;
                      <br/>
                  </a>
              )}
                            {props?.value?.gateway_name == 'USSD' &&
                                props?.value?.payment_number == 0 && (
                                    <a
                                        style={{cursor: 'pointer'}}
                                        class='text-primary'
                                        href={props?.value?.ussd_checkout_url}
                                    >
                                        <small>Complete payment</small>
                                        &nbsp;&nbsp;
                                        <br/>
                                    </a>
                                )}
                            {props.value.status.title == 'Pending Buyer Response' && (
                                <>
                                    <a
                                        style={{cursor: 'pointer'}}
                                        class='text-success'
                                        // href={props.value.tx_accept_link}
                                        onClick={() =>
                                            this.onOpenModal('acceptDeliveryModal', props.value)
                                        }
                                    >
                                        <small>Accept</small>
                                        &nbsp;&nbsp;
                                    </a>
                                    <span
                                        style={{height: '4px', borderLeft: '1px solid #7f7f7f'}}
                                    ></span>
                                    &nbsp;&nbsp;
                                    <a
                                        style={{cursor: 'pointer'}}
                                        class='text-danger'
                                        // href={props.value.tx_reject_link}
                                        onClick={() =>
                                            this.onOpenModal('rejectDeliveryModal', props.value)
                                        }
                                    >
                                        <small>Reject</small>
                                        &nbsp;&nbsp;
                                        <br/>
                                    </a>
                                </>
                            )}
                            {props.value.delivery_term &&
                                this.checkDeliveryTerm(
                                    props.value.delivery_term.refund_option,
                                    props.value.transaction_date,
                                    props.value.extra.current_date,
                                    props.value.extra.validSec
                                ) &&
                                props.value.status.title !== 'Accepted' &&
                                props.value.status.title !== 'Rejected' &&
                                props.value.status.title !== 'Canceled' &&
                                props.value.status.title !== 'Complete' &&
                                props.value.status.title !== 'Pending Buyer Response' &&
                                props.value.status.title !== 'Pending II' &&
                                props.value.status.title !== 'Disputed' && (
                                    <a
                                        style={{cursor: 'pointer'}}
                                        class='text-danger'
                                        onClick={() => this.onOpenModal('cancelOrder', props.value)}
                                    >
                                        <small>Cancel Order</small>
                                        &nbsp;&nbsp;
                                        <br/>
                                    </a>
                                )}
            </span>
                    ), // String-based value accessors!
                },
            ];
        } else if (user && user.role && user.role.name === 'MarketPlace') {
            columns = [
                {
                    Header: (props) => (
                        <span>
              <b>Order ID</b>
            </span>
                    ),
                    id: 'id',
                    filterable,
                    accessor: (d) => d, // String-based value accessors!
                    Cell: (props) => (
                        <a
                            href='#/app/orders'
                            onClick={() => this.onOpenModal('fullModal', props.value)}
                        >
                            {formatToSixDigits(props.value.id)}
                        </a>
                    ),
                },
                {
                    Header: (props) => (
                        <span>
              <b>Item</b>
            </span>
                    ),
                    id: 'item',
                    filterable,
                    accessor: (d) => d, // String-based value accessors!
                    Cell: (props) => (
                        <span className='number'>{props.value.product_name}</span>
                    ),
                },
                {
                    Header: (props) => (
                        <span>
              <b>Seller</b>
            </span>
                    ),
                    id: 'seller',
                    filterable,
                    accessor: (d) => d.sellers,
                    Cell: (props) => (
                        <span className='number'>
              {props.value == null
                  ? ''
                  : props.value.business_name
                      ? props.value.business_name
                      : props.value.users.firstname +
                      ' ' +
                      props.value.users.lastname}
            </span>
                    ), // Custom cell components!
                },
                {
                    Header: (props) => (
                        <span>
              <b>Buyer</b>
            </span>
                    ),
                    id: 'firstname',
                    filterable,
                    accessor: (d) => d.customers,
                    Cell: (props) => (
                        <span className='number'>
              {props?.value?.firstname}
                            <br/>
                            {props?.value?.lastname}
            </span> // Custom cell components!
                    ),
                },
                {
                    id: 'transaction_date', // Required because our accessor is not a string
                    Header: (props) => (
                        <span>
              <b>Order Date</b>
            </span>
                    ),
                    filterable,
                    accessor: 'transaction_date', // Custom value accessors!
                },
                {
                    id: 'value',
                    filterable,
                    Header: (props) => (
                        <span>
              <b>Order Status</b>
            </span>
                    ),
                    accessor: (d) => d.status.title, // String-based value accessors!
                },
                {
                    id: 'payment',
                    sortable: true,
                    filterable,
                    Header: (props) => (
                        <span>
              <b>Payment Status</b>
            </span>
                    ),
                    accessor: 'payment', // String-based value accessors!
                    className: 'text-left',
                    Cell: (props) => (
                        <div>
                            {props.value ? (
                                <span class='badge badge-success'>Paid</span>
                            ) : (
                                <span class='badge badge-danger'>Not Paid</span>
                            )}
                        </div>
                    ),
                },
            ];
        } else {
            var i = 1;
            columns = [
                {
                    Header: (props) => (
                        <span>
              <input
                  type='checkbox'
                  name='check[]'
                  onChange={(ev) => this.checkAll(ev)}
              />
            </span>
                    ),
                    id: 'check',
                    sortable: false,
                    filterable: false,
                    accessor: (d) => d, // String-based value accessors!
                    Cell: (props) =>
                        // props.value.customers === null ? null : props.value.status.title ===
                        //     "Pending" || props.value.status.title === "Pending II" ? (
                        props?.value?.status?.title !== 'Accepted' &&
                        props?.value?.status?.title !== 'Rejected' &&
                        props?.value?.status?.title !== 'Pending Buyer Response' &&
                        props?.value?.status?.title !== 'Dispute' &&
                        props?.value?.status?.title !== 'Canceled' &&
                        props?.value?.status?.title !== 'Complete' &&
                        props?.value?.status?.title !== 'Disputed' &&
                        props?.value?.payment &&
                        !(
                            props?.value?.attempts == '1' &&
                            props?.value?.seller_attempts == 'Y'
                        ) &&
                        completeDelivery !== false ? (
                            <input
                                type='checkbox'
                                className='checkBox'
                                name='checks'
                                alt={props.value.status.title}
                                value={
                                    props.value.customers.email === null
                                        ? ''
                                        : props.value.id +
                                        ',' +
                                        props.value.customers.email +
                                        ',' +
                                        props.value.customers.phone_number
                                }
                            />
                        ) : null,
                },
                {
                    Header: () => (
                        <span>
              <b>Order ID</b>
            </span>
                    ),
                    id: 'id',
                    filterable,
                    accessor: (d) => d, // String-based value accessors!

                    filterAll: true,
                    Cell: (props) => (
                        <a
                            href='#/app/orders'
                            onClick={() => this.onOpenModal('fullModal', props.value)}
                        >
                            {formatToSixDigits(props.value.id)}
                        </a>
                    ),
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {keys: ['id']}),
                },
                {
                    Header: () => (
                        <span>
              <b>Item</b>
            </span>
                    ),
                    id: 'item',
                    filterable,
                    accessor: (d) => d, // String-based value accessors!
                    Cell: (props) => (
                        <span className='number'>{props.value.product_name}</span>
                    ),
                },
                {
                    Header: () => (
                        <span>
              <b>Buyer</b>
            </span>
                    ),
                    id: 'firstname',
                    filterable,
                    accessor: (d) => d.customers,
                    Cell: (props) => (
                        <span className='number'>
              {props.value == null ? (
                  <b className='text-danger'>Yet to Register</b>
              ) : props.value.firstname ? (
                  `${props.value.firstname} ${props.value.lastname}`
              ) : (
                  props.value.email
              )}
            </span> // Custom cell components!
                    ),
                },

                {
                    id: 'transaction_date', // Required because our accessor is not a string
                    Header: () => (
                        <span>
              <b>Order Date</b>
            </span>
                    ),
                    filterable,
                    accessor: 'transaction_date', // Custom value accessors!
                },
                {
                    id: 'value',
                    filterable,
                    Header: (props) => (
                        <span>
              <b>Order Status </b>
            </span>
                    ),
                    accessor: (d) => d.status.title, // String-based value accessors!
                    Cell: (props) => (
                        <div>
                            {props.value === 'Dispute' ? (
                                <span class='badge badge-danger'>Dispute</span>
                            ) : props.value === 'Disputed' ? (
                                <span class='badge badge-danger'>Disputed</span>
                            ) : props.value === 'Canceled' ? (
                                <span class='badge badge-danger'>Canceled</span>
                            ) : props.value === 'Rejected' ? (
                                <span class='badge badge-warning'>Rejected</span>
                            ) : props.value === 'Pending' ? (
                                <span class='badge badge-warning'>Pending</span>
                            ) : props.value === 'Pending II' ? (
                                <span class='badge badge-warning'>Pending II</span>
                            ) : props.value === 'Pending Buyer Response' ? (
                                <span class='badge badge-warning'>Pending Buyer Response</span>
                            ) : props.value === 'Awaiting Agreement' ? (
                                <span class='badge badge-warning'>Awaiting Agreement</span>
                            ) : (
                                <span class='badge badge-success'>{props.value}</span>
                            )}
                        </div>
                    ),
                },
                {
                    id: 'payment',
                    sortable: true,
                    filterable,
                    Header: (props) => (
                        <span>
              <b>Payment Status</b>
            </span>
                    ),
                    accessor: 'payment', // String-based value accessors!
                    className: 'text-left',
                    Cell: (props) => (
                        <div>
                            {props.value ? (
                                <span class='badge badge-success'>Paid</span>
                            ) : (
                                <span class='badge badge-danger'>Not Paid</span>
                            )}
                        </div>
                    ),
                },
                {
                    id: 'actions',
                    sortable: false,
                    filterable: false,
                    Header: (props) => (
                        <span>
              <b>Actions</b>
            </span>
                    ),
                    accessor: (d) => d,
                    Cell: (props) => (
                        <span>
              {props?.value?.status?.title === 'Dispute' && (
                  <Link
                      to={`/app/seller/dispute/raise/${props.value.id}`}
                      class='text-danger'
                  >
                      <small>Raise Dispute</small>
                      &nbsp;&nbsp;
                      <br/>
                  </Link>
              )}
                            {props?.value?.status?.title === 'Rejected' &&
                                props?.value?.seller_attempts !== 'Y' && (
                                    <a
                                        class='text-primary'
                                        style={{cursor: 'pointer'}}
                                        onClick={() =>
                                            this.onOpenModal('confirmImpedimentModal', props.value)
                                        }
                                    >
                                        <small>Confirm Impediment</small>
                                        &nbsp;&nbsp;
                                        <br/>
                                    </a>
                                )}
                            {props?.value?.status?.title === 'Disputed' && (
                                <a
                                    style={{cursor: 'pointer'}}
                                    class='text-dark'
                                    onClick={() => this.onOpenModal('cancel', props.value)}
                                >
                                    <small>Cancel Dispute</small>
                                    &nbsp;&nbsp;
                                    <br/>
                                </a>
                            )}
                            {props?.value?.status?.title !== 'Accepted' &&
                                props?.value?.status?.title !== 'Dispute' &&
                                props?.value?.status?.title !== 'Rejected' &&
                                props?.value?.status?.title !== 'Canceled' &&
                                props?.value?.status?.title !== 'Complete' &&
                                props?.value?.status?.title !== 'Disputed' && (
                                    <a
                                        style={{cursor: 'pointer'}}
                                        class='text-danger'
                                        onClick={() => this.onOpenModal('cancelOrder', props.value)}
                                    >
                                        <small>Cancel Order </small>
                                        &nbsp;&nbsp;
                                        <br/>
                                    </a>
                                )}

                            {props?.value?.status?.title !== 'Accepted' &&
                            props?.value?.status?.title !== 'Rejected' &&
                            props?.value?.status?.title !== 'Pending Buyer Response' &&
                            props?.value?.status?.title !== 'Dispute' &&
                            props?.value?.status?.title !== 'Canceled' &&
                            props?.value?.status?.title !== 'Complete' &&
                            props?.value?.status?.title !== 'Disputed' &&
                            props?.value?.payment &&
                            !(
                                props?.value?.attempts == '1' &&
                                props?.value?.seller_attempts == 'Y'
                            ) &&
                            (completeDelivery !== false && (props?.value?.delivery_type !== "2" || !props?.value?.delivery_service)) ? (
                                <a
                                    style={{cursor: 'pointer'}}
                                    class='text-success'
                                    id={i++}
                                    onClick={() => this.onOpenModal('confirmModal', props.value)}
                                >
                                    <small>Delivery Completed.</small>
                                    &nbsp;&nbsp;
                                    <br/>
                                </a>
                            ) : (
                                ''
                            )}
                            {props?.value?.status?.title !== 'Accepted' &&
                            props?.value?.status?.title !== 'Rejected' &&
                            props?.value?.status?.title !== 'Pending Buyer Response' &&
                            props?.value?.status?.title !== 'Dispute' &&
                            props?.value?.status?.title !== 'Canceled' &&
                            props?.value?.status?.title !== 'Complete' &&
                            props?.value?.status?.title !== 'Disputed' &&
                            props?.value?.payment &&
                            !(
                                props?.value?.attempts == '1' &&
                                props?.value?.seller_attempts == 'Y'
                            ) &&
                            (props?.value?.delivery_type === "2" && props?.value?.status.title === "Pending" && props?.value?.delivery_service && !props?.value?.delivery_service.shipment_code) ? (
                                <a
                                    style={{cursor: 'pointer'}}
                                    class='text-success'
                                    id={i++}
                                    onClick={() => this.onOpenModal('triggerPickupModal', props.value)}
                                >
                                    <small>Ready For Pickup.</small>
                                    &nbsp;&nbsp;
                                    <br/>
                                </a>
                            ) : (
                                ''
                            )}
                            {props?.value?.status?.title !== 'Accepted' &&
                            props?.value?.status?.title !== 'Rejected' &&
                            props?.value?.status?.title !== 'Dispute' &&
                            props?.value?.status?.title !== 'Canceled' &&
                            props?.value?.status?.title !== 'Complete' &&
                            props?.value?.status?.title !== 'Disputed' &&
                            props?.value?.payment &&
                            props?.value?.service_providers?.length !== 0 &&
                            props?.value?.sp_order_ref === 0 &&
                            completeDelivery !== false ? (
                                <a
                                    style={{cursor: 'pointer'}}
                                    class='text-primary'
                                    id={i++}
                                    onClick={() => {
                                        this.setState({
                                            payoutCurrency: props.value.payout_currency,
                                        });
                                        this.onOpenModal('assignModal', props.value);
                                    }}
                                >
                                    <small>Add Service Provider</small>
                                    &nbsp;&nbsp;
                                    <br/>
                                </a>
                            ) : (
                                ''
                            )}
            </span>
                    ), // String-based value accessors!
                },
            ];
        }

        const currentOrder = this.state.order
        let pickupAddress, deliveryAddress;

        if (currentOrder && currentOrder.delivery_type === "2" && currentOrder.delivery_service) {
            const deliveryService = currentOrder.delivery_service
            const delAddressObj = deliveryService.delivery_address
            const puAddressObj = deliveryService.pickup_address

            if (puAddressObj) {
                pickupAddress = `${puAddressObj.street}, ${puAddressObj.city}, ${puAddressObj.state}, ${puAddressObj.country}`
            }
            if (delAddressObj) {
                deliveryAddress = `${delAddressObj.street},     ${delAddressObj.city},  ${delAddressObj.state},${delAddressObj.country}`
            }
        }

        return (
            <div>
                <Modal
                    open={this.state.fullModal}
                    onClose={() => this.onCloseModal('fullModal')}
                    center
                >
                    <form>
                        <div class='modal-header'>
                            <h4 class='modal-title'>
                                <small></small>
                            </h4>
                        </div>
                        <div class='modal-body'>
                            <div class='row'></div>
                            <div class='row'>
                                <div class='col-sm-6'>
                                    <div class='form-group'>
                                        <label>Order Number</label> <br/>
                                        <input
                                            type='text'
                                            readonly='readOnly'
                                            value={formatToSixDigits(
                                                this.state.order && this.state.order.id
                                            )}
                                            class='form-control'
                                        />
                                    </div>
                                </div>
                                <div class='col-sm-6'>
                                    <div class='form-group'>
                                        <label>Amount</label> <br/>{' '}
                                        <input
                                            type='text'
                                            readonly='readOnly'
                                            value={
                                                this.state.order &&
                                                transactionHelper.toLocaleDisplay(
                                                    parseFloat(this.state.order.amount_payed / 100)
                                                )
                                            }
                                            class='form-control'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class='row'>
                                <div class='col-sm-6'>
                                    <div class='form-group'>
                                        <label>Order Date</label> <br/>{' '}
                                        <input
                                            type='text'
                                            readonly='readOnly'
                                            value={this.state.order && this.state.order.created_at}
                                            class='form-control'
                                        />
                                    </div>
                                </div>
                                <div class='col-sm-6'>
                                    <div class='form-group'>
                                        <label>Order Status</label> <br/>{' '}
                                        <input
                                            type='text'
                                            readonly='readOnly'
                                            value={this.state.order && this.state.order.status.title}
                                            class='form-control'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class='row'>
                                <div class='col-sm-6'>
                                    <div class='form-group'>
                                        <label>Buyer Name</label> <br/>{' '}
                                        <input
                                            type='text'
                                            readonly='readOnly'
                                            value={
                                                this.state.order &&
                                                this.state.order.customers &&
                                                this.state.order.customers.firstname +
                                                ' ' +
                                                this.state.order.customers.lastname
                                            }
                                            class='form-control'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class='row'>
                                <div class='col-sm-6'>
                                    <div class='form-group'>
                                        <label>Delivery Location</label> <br/>{' '}
                                        <input
                                            type='text'
                                            readonly='readOnly'
                                            value={
                                                this.state.order && this.state.order.delivery_location
                                            }
                                            class='form-control'
                                        />
                                    </div>
                                </div>
                                <div class='col-sm-6'>
                                    <div class='form-group'>
                                        <label>Delivery Date</label> <br/>{' '}
                                        <input
                                            type='text'
                                            readonly='readOnly'
                                            value={this.state.order && this.state.order.delivery_date}
                                            class='form-control'
                                        />
                                    </div>
                                </div>
                            </div>
                            {' '}
                            <br/>
                            <br/>
                        </div>
                    </form>
                </Modal>

                <Modal
                    open={this.state.cancel}
                    onClose={() => this.onCloseModal('cancel')}
                    center
                >
                    <div class='modal-header'>
                        <h4 class='modal-title'>Cancel Dispute</h4>
                    </div>
                    <div class='modal-body'>
                        <div class='row form-group'>
                            <div class='col-sm-12'>
                                {role === 'seller' ? (
                                    <p>
                                        If you cancel, the buyer will be refunded in full. Are you
                                        sure you want to cancel this dispute?
                                    </p>
                                ) : (
                                    <p>
                                        If you cancel, the seller will be credited in full. Are you
                                        sure you want to cancel this dispute?
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div class='modal-footer'>
                        <button
                            type='button'
                            data-dismiss='modal'
                            class='btn btn-primary'
                            onClick={() => this.onCloseModal('cancel')}
                        >
                            Don't Cancel
                        </button>
                        &nbsp;&nbsp;
                        <button
                            onClick={this.handleCancel}
                            disabled={canceling}
                            type='button'
                            class='btn btn-danger'
                        >
                            {canceling ? 'Canceling ...' : 'Cancel'}
                        </button>
                    </div>
                </Modal>
                {/*cancel order model starts*/}
                <Modal
                    open={this.state.cancelOrder}
                    onClose={() => this.onCloseModal('cancelOrder')}
                    center
                >
                    <div class='modal-header'>
                        <h4 class='modal-title'>Cancel Order</h4>
                    </div>
                    <div class='modal-body'>
                        <br/>
                        <div class='container'>
                            <ul>
                                <li>
                                    Please Provide a reason for cancelling this transaction and
                                    select Confirm button
                                </li>
                                <li>
                                    {' '}
                                    The other party will be given the reason for cancelling this
                                    transaction
                                </li>
                                <li> An asterisk (*) denotes a required field</li>
                            </ul>
                        </div>
                        <br/>
                        <div class='container'>
                            <div class='form-group'>
                                <div class='col-sm-10'>
                                    <label>
                                        Reason for Cancellation *{' '}
                                        <small>(Up to 435 Characters)</small>
                                    </label>
                                    <textarea
                                        maxLength={'435'}
                                        disabled={
                                            this.state.order &&
                                            this.state.order.status.title === 'Canceled'
                                        }
                                        class='form-control'
                                        name='reason'
                                        onChange={this.handleChange}
                                        value={this.state.reason}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='modal-footer'>
                        <button
                            type='button'
                            data-dismiss='modal'
                            class='btn btn-primary'
                            onClick={() => this.onCloseModal('cancelOrder')}
                        >
                            Don't Cancel
                        </button>
                        &nbsp;&nbsp;
                        <button
                            onClick={() => this.cancelTransaction()}
                            disabled={canceling2}
                            type='button'
                            class='btn btn-danger'
                        >
                            {canceling2 ? 'Canceling ...' : 'Cancel'}
                        </button>
                    </div>
                </Modal>
                {/*cancel order model ends*/}
                <Modal
                    open={this.state.confirmModal}
                    onClose={() => this.onCloseModal('confirmModal')}
                    center
                >
                    <div class='modal-header'>
                        <h4 class='modal-title'>Complete Order</h4>
                    </div>
                    <div class='modal-body'>
                        <br/>
                        <div class='container'>
                            <p>
                                Now that you have successfully delivered the item(s) to the
                                recipient, you can now inform buyer to accept your product or
                                service. But before you do, please ensure you have tracking
                                reference, signature proof of delivery with pictures and confirm
                                from the buyer that the goods or service(s) were received with
                                no issues. This will prevent the buyer from rejecting your order
                                and causing delays to your settlement. Do you wish to continue
                                this task?
                            </p>
                        </div>
                    </div>
                    <div class='modal-footer'>
                        <button
                            onClick={(e) => this.confirmModals(e)}
                            disabled={loading}
                            type='button'
                            class='btn btn-danger'
                        >
                            {loading ? 'confirming ...' : 'Yes'}
                        </button>
                        &nbsp;&nbsp;
                        <button
                            type='button'
                            data-dismiss='modal'
                            class='btn btn-primary'
                            onClick={() => this.onCloseModal('confirmModal')}
                        >
                            No
                        </button>
                    </div>
                </Modal>

                <Modal
                    open={this.state.acceptDeliveryModal}
                    onClose={() => this.onCloseModal('acceptDeliveryModal')}
                    center
                >
                    <div class='modal-header'>
                        <h4 class='modal-title'>Delivery Acceptance</h4>
                    </div>
                    <div class='modal-body'>
                        <br/>
                        <div class='container'>
                            <p>
                                When you accept the order, the seller will be credited. Are you
                                sure you want to accept this order?
                            </p>
                        </div>
                    </div>
                    <div class='modal-footer'>
                        <button
                            onClick={(e) => this.acceptDelivery(e)}
                            disabled={loading}
                            type='button'
                            class='btn btn-danger'
                        >
                            {loading ? 'confirming ...' : 'Yes'}
                        </button>
                        &nbsp;&nbsp;
                        <button
                            type='button'
                            data-dismiss='modal'
                            class='btn btn-primary'
                            onClick={() => this.onCloseModal('acceptDeliveryModal')}
                        >
                            No
                        </button>
                    </div>
                </Modal>

                <Modal
                    open={this.state.rejectDeliveryModal}
                    onClose={() => this.onCloseModal('rejectDeliveryModal')}
                    center
                >
                    <div class='modal-body'>
                        <br/>
                        <div class='container'>
                            <form onSubmit={this.rejectDelivery}>
                                <h5>
                                    <strong>Point of Delivery (PoD)</strong>
                                </h5>
                                <div class='form-group mb-3'>
                                    <label for='response'>
                                        <small>Please select response*</small>
                                    </label>
                                    <br/>
                                    <div>
                                        <div style={{float: 'left', width: '10%'}}>
                                            <input
                                                type='radio'
                                                name='reject'
                                                value='Seller has not delivered the goods or service(s)'
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div style={{float: 'left', width: '90%'}}>
                                            {' '}
                                            Seller has not delivered the goods or service(s)
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        <div style={{float: 'left', width: '10%'}}>
                                            <input
                                                type='radio'
                                                name='reject'
                                                value='Wrong item size or wrong goods or service(s) delivered'
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div style={{float: 'left', width: '90%'}}>
                                            {' '}
                                            Wrong item size or wrong goods or service(s) delivered
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        <div style={{float: 'left', width: '10%'}}>
                                            <input
                                                type='radio'
                                                name='reject'
                                                value='The goods were found damaged/ broken/ stolen'
                                                onChange={this.handleChange}
                                            />{' '}
                                        </div>
                                        <div style={{float: 'left', width: '90%'}}>
                                            The goods were found damaged/ broken/ stolen
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        <div style={{float: 'left', width: '10%'}}>
                                            <input
                                                type='radio'
                                                name='reject'
                                                value="Goods or service(s) didn't match the website or catalog description"
                                                onChange={this.handleChange}
                                            />{' '}
                                        </div>
                                        <div style={{float: 'left', width: '90%'}}>
                                            Goods or service(s) didn't match the website or catalog
                                            description
                                        </div>
                                    </div>
                                    {this.state.order?.delivery_term?.refund_option == 1 && (
                                        <>
                                            <br/>
                                            <div>
                                                <div style={{float: 'left', width: '10%'}}>
                                                    <input
                                                        type='radio'
                                                        name='reject'
                                                        value='I changed my mind (This may incur cancellation fee)'
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div style={{float: 'left', width: '90%'}}>
                                                    I changed my mind (This may incur cancellation fee)
                                                </div>
                                            </div>
                                            <br/>
                                            <div>
                                                <div style={{float: 'left', width: '10%'}}>
                                                    <input
                                                        type='radio'
                                                        name='reject'
                                                        value='I ordered the wrong thing (This may incur cancellation fee)'
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div style={{float: 'left', width: '90%'}}>
                                                    I ordered the wrong thing (This may incur cancellation
                                                    fee)
                                                </div>
                                            </div>
                                            <br/>
                                        </>
                                    )}
                                </div>
                                <div class='form-group' style={{marginTop: '3rem'}}>
                                    <input
                                        type='submit'
                                        name='button'
                                        disabled={loading}
                                        value={loading ? ' Sending...' : 'Submit'}
                                        class='btn btn-dark-blue form-control'
                                    />
                                </div>
                                <hr/>
                            </form>
                        </div>
                    </div>
                </Modal>

                <Modal
                    open={this.state.confirmImpedimentModal}
                    onClose={() => this.onCloseModal('confirmImpedimentModal')}
                    center
                >
                    <div class='modal-body'>
                        <br/>
                        <div class='container'>
                            <form data-type='pod' onSubmit={this.confirmImpediment}>
                                <h5>
                                    <strong>Point of Delivery (PoD)</strong>
                                </h5>
                                <div class='form-group'>
                                    <label for='response'>
                                        <small>
                                            Please confirm you are in possession of the item*
                                        </small>
                                    </label>

                                    <select
                                        name='response'
                                        value={this.state.response}
                                        onChange={this.handleChange}
                                        id='response'
                                        class='form-control'
                                    >
                                        <option value=''>Select Response</option>
                                        <option value='Y'>Yes (Order is ok)</option>
                                        <option value='N'>No (Order is not ok)</option>
                                        {this.state.order.attempts !== '1' && (
                                            <option value='A'>
                                                Buyer and I have agreed to another delivery attempt
                                            </option>
                                        )}
                                    </select>
                                </div>
                                <div class='form-group'>
                                    <input
                                        type='submit'
                                        name='button'
                                        disabled={loading}
                                        value={loading ? ' Sending...' : 'Submit'}
                                        class='btn btn-dark-blue form-control'
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>

                <Modal
                    open={this.state.assignModal}
                    onClose={() => this.onCloseModal('assignModal')}
                    center
                >
                    <div class='modal-header'>
                        <h4 class='modal-title'>Assign Service Provider</h4>
                    </div>
                    <div class='modal-body'>
                        <select
                            className='form-control'
                            name='sp_id'
                            onChange={this.handleChange}
                        >
                            <option value=''>Choose Service Provider</option>
                            {this.state.order &&
                                this.state.order.service_providers &&
                                this.state.order.service_providers
                                    .filter((sp) =>
                                        payoutCurrency == 'USD' ? sp.type == 2 : sp.type == 1
                                    )
                                    ?.map((sp) => (
                                        <option value={sp.id}>
                                            {sp.firstname} {sp.lastname} ({sp.email})
                                        </option>
                                    ))}
                        </select>
                        <input
                            type='number'
                            name='commission'
                            className='form-control'
                            placeholder="Enter Seller's Commission in Percentage"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div class='modal-footer'>
                        <button
                            onClick={(e) => this.AssignServiceProvider()}
                            disabled={loading}
                            type='button'
                            class='btn btn-danger'
                        >
                            {loading ? 'Assigning' : 'Assign'}
                        </button>
                        &nbsp;&nbsp;
                        <button
                            type='button'
                            data-dismiss='modal'
                            class='btn btn-primary'
                            onClick={() => this.onCloseModal('assignModal')}
                        >
                            Cancel
                        </button>
                    </div>
                </Modal>

                <Modal
                    open={this.state.triggerPickupModal}
                    onClose={() => this.onCloseModal('triggerPickupModal')}
                    center
                >
                    <div class='modal-header'>
                        <h4 class='modal-title'>Ready For Pickup</h4>
                    </div>
                    <div class='modal-body'>
                        <br/>
                        <div class='container'>
                            <p>
                                The item(s) shall be picked up from <u>{pickupAddress}</u>
                                <br/>between 9am and 5pm within 2 business
                                days and delivered to <br/> <u>{deliveryAddress} </u>.

                                <br/>
                                <br/>
                                If the addresses are correct, click <b>Proceed</b>.
                                You shall receive a link to track delivery of the item(s).

                                <br/>
                                <br/>
                                If you choose to update, please contact us at
                                support@atarapay.com
                                or call 09084631926 to update the delivery requirements
                            </p>
                        </div>
                    </div>
                    <div class='modal-footer'>
                        <button
                            onClick={(e) => this.triggerPickup(e)}
                            disabled={loading}
                            type='button'
                            class='btn btn-danger'
                        >
                            {loading ? 'confirming ...' : 'Proceed'}
                        </button>
                        &nbsp;&nbsp;
                        <button
                            type='button'
                            data-dismiss='modal'
                            class='btn btn-primary'
                            onClick={() => this.onCloseModal('triggerPickupModal')}
                        >
                            No
                        </button>
                    </div>
                </Modal>

                <div class='content-wrapper' style={{minHeight: 136}}>
                    <div class='content-header'>
                        <div class='container-fluid'>
                            <div class='row mb-2'>
                                <div class='col-sm-12'>
                                    <h1 class='m-0 text-dark'>All Orders</h1>
                                    <div class='attop'>
                                        <a
                                            href='https://www.youtube.com/watch?v=eCaLNLDzolY'
                                            className='btn btn-primary'
                                            target='_blank'
                                        >
                                            Watch: How To Accept or Reject an Order
                                        </a>
                                        <br/>
                                        <br/>
                                        {localStorage.getItem('trust_user_type') === 'seller' ? (
                                            <div>
                                                <p>
                                                    Here you will find details of all orders created via
                                                    your online website and Person to Person (P2P)
                                                    transactions.
                                                    <br/>
                                                    From here you are able to complete the delivery
                                                    process by clicking the <b>Complete Delivery</b> link
                                                    under the Actions column to the right of the table.
                                                    Also, you can cancel orders as well.
                                                </p>
                                                <p>
                                                    The <b>Add Service Provider</b> link enables a seller
                                                    to assign a service provider to a brokered
                                                    transaction. When adding the service provider, simply
                                                    choose from a list of service providers you created
                                                    from the Payout Details page and enter your commission
                                                    on the deal. This will ensure you and the service
                                                    provider receive the broker commission and product sum
                                                    respectively at payout. The product sum paid out to
                                                    the service provider is less the seller's broker
                                                    commission.
                                                </p>{' '}
                                            </div>
                                        ) : (
                                            <p>
                                                Here you will find details of all orders created via
                                                your online website and Person to Person (P2P)
                                                transactions.
                                                <br/>
                                                From here you are able to cancel orders under the
                                                Actions column to the right of the table.
                                            </p>
                                        )}
                                    </div>
                                    {' '}
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!loading ? (
                        <section class='content'>
                            <div class='container-fluid'>
                                <br/>
                                <div class='row '>
                                    <div class='col-12 col-sm-12 col-md-12'>
                                        <div class='alert animated bounceIn alert-danger d-none search-alert'>
                                            <a aria-label='close' class='close alert-close'>
                                                ×
                                            </a>
                                            <span></span>
                                        </div>
                                        <div class='card'>
                                            <div class='card-body'>
                                                <div class='row'>
                                                    <div class='col-12 col-sm-4'></div>
                                                    <div class='col-12 col-sm-4'></div>

                                                    <div class='col-12 col-sm-4'>
                                                        <div class='form-group'>
                                                            {' '}
                                                            <label>&nbsp;</label>
                                                            <div class='row'>
                                                                <div class='col-12'>
                                                                    {localStorage.getItem('trust_user_type') ===
                                                                    'customer' ||
                                                                    localStorage.getItem('trust_user_type') ===
                                                                    'marketplace' ? null : (
                                                                        <button
                                                                            class='btn btn-info btn-block form-control bulk-delivery'
                                                                            style={{
                                                                                width: 'auto',
                                                                                float: 'right',
                                                                            }}
                                                                            onClick={() => this.bulkDelivery()}
                                                                        >
                                                                            <center>Bulk Complete Delivery</center>
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class='col-12 col-sm-2'>
                                                        <div class='form-group'>
                                                            <label for='period'>Period</label>
                                                            <select
                                                                class='form-control'
                                                                name='filter'
                                                                onChange={this.handleSelect}
                                                            >
                                                                <option value='All'>All</option>
                                                                <option value='This Week'>This Week</option>
                                                                <option value='This Month'>This Month</option>
                                                                <option value='60 Days Ago'>60 Days Ago</option>
                                                                <option value='90 Days Ago'>90 Days Ago</option>
                                                                <option value='Custom'>Custom</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class='col-12 col-sm-2'>
                                                        <div class='form-group'>
                                                            <label for='filter'>Filter</label>
                                                            <input
                                                                type='text'
                                                                placeholder='Filter'
                                                                class='form-control'
                                                                value={this.state.filter}
                                                                onChange={this.handleFilter}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class='col-12 col-sm-3'>
                                                        <div class='form-group'>
                                                            <label for='from'>Date From</label>{' '}
                                                            <input
                                                                type='date'
                                                                name='from_date'
                                                                value={this.state.from_date}
                                                                disabled={this.state.notCustom}
                                                                onChange={this.handleDate}
                                                                class='form-control'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class='col-12 col-sm-3'>
                                                        <div class='form-group'>
                                                            <label for='from'>Date To</label>{' '}
                                                            <input
                                                                type='date'
                                                                name='to_date'
                                                                value={this.state.to_date}
                                                                disabled={this.state.notCustom}
                                                                onChange={this.handleDate}
                                                                class='form-control'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class='col-12 col-sm-2'>
                                                        <div class='form-group'>
                                                            {' '}
                                                            <label>&nbsp;</label>
                                                            <div class='row'>
                                                                <div class='col-12'>
                                                                    <button
                                                                        disabled={this.state.notCustom}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                data: [
                                                                                    ...this.filterDates(
                                                                                        this.state.orders
                                                                                    ),
                                                                                ],
                                                                            });
                                                                        }}
                                                                        class='btn btn-info btn-block form-control'
                                                                    >
                                                                        <center>Search</center>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div class='table-responsive'>
                                                    {this.state.data.length == 0 &&
                                                    !this.props.orderStatus ? (
                                                        <div class='d-flex justify-content-center p-10'>
                                                            wait a few seconds to get your orders
                                                            downloaded...you can refresh your browser if you
                                                            still did not get your orders after 60 seconds.
                                                        </div>
                                                    ) : this.state?.data?.length == 0 &&
                                                    this?.props?.orderStatus == 200 ? (
                                                        <div class='d-flex justify-content-center p-10'>
                                                            You have no orders yet
                                                        </div>
                                                    ) : (
                                                        <ReactTable
                                                            defaultPageSize={5}
                                                            filterable
                                                            data={this.state.data.sort(function (a, b) {
                                                                var c = new Date(a.transaction_date);
                                                                var d = new Date(b.transaction_date);
                                                                return d - c;
                                                            })}
                                                            columns={columns}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {' '}
                                <br/>
                            </div>
                        </section>
                    ) : (
                        <div className='sweet-loading'>
                            <ClipLoader
                                className={override}
                                sizeUnit={'px'}
                                size={70}
                                color={'blue'}
                                loading={loading}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Order;
