import { logisticService } from "../service.js";
import { deliveryConstants, disputeConstants } from "../constants";
import { alertActions, userActions } from "./";

const createDispatcher = (type, data, history) => {
  return (dispatch) => {
    // console.log('i worked');
    dispatch(request());
    logisticService.delivery
      .createDispatcher(type)
      .then((res) => {
        // console.log(res);
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
          dispatch(
            alertActions.alert.success(res.data.message?.replace(/_/g, " "))
          );
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout("seller", history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request() {
    return { type: deliveryConstants.delivery.ADD_DELIVERY_MAN };
  }
  function success(deliveryMen) {
    return {
      type: deliveryConstants.delivery.ADD_DELIVERY_MAN_SUCCESS,
      deliveryMen,
    };
  }
  function failure(error) {
    return { type: deliveryConstants.delivery.ADD_DELIVERY_MAN_FAILURE, error };
  }
};

const editDispatcher = (data, history) => {
  return (dispatch) => {
    // console.log('i worked');
    dispatch(request());

    logisticService.delivery
      .editDispatcher(data)
      .then((res) => {
        // console.log(res);
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
          dispatch(
            alertActions.alert.success(res.data.message?.replace(/_/g, " "))
          );
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout("seller", history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request() {
    return { type: deliveryConstants.delivery.UPDATE_DELIVERY_MAN };
  }
  function success(dispatcher) {
    return {
      type: deliveryConstants.delivery.UPDATE_DELIVERY_MAN_SUCCESS,
      dispatcher,
    };
  }
  function failure(error) {
    return { type: deliveryConstants.delivery.UPDATE_COURIER_FAILURE, error };
  }
};

const viewDispatcherType = (history) => {
  return (dispatch) => {
    // console.log('i worked');
    // dispatch(request());

    logisticService.delivery
      .viewDispatcherType()
      .then((res) => {
        // console.log(res);
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
        } else {
          dispatch(failure(res.data.message));

          dispatch(
            alertActions.alert.error(res.data.message?.replace(/\\n/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 401) {
            dispatch(userActions.user.logout("seller", history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request() {
    return { type: deliveryConstants.delivery.GET_DELIVERY_MAN_TYPE };
  }
  function success(deliveryManType) {
    return {
      type: deliveryConstants.delivery.GET_DELIVERY_MAN_TYPE_SUCCESS,
      deliveryManType,
    };
  }
  function failure(error) {
    return {
      type: deliveryConstants.delivery.GET_DELIVERY_MAN_TYPE_FAILURE,
      error,
    };
  }
};

const deleteDispatcher = (id, history) => {
  return (dispatch) => {
    // console.log('i worked');
    dispatch(request());

    logisticService.delivery
      .deleteDispatcher(id)
      .then((res) => {
        // console.log(res);
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
          dispatch(
            alertActions.alert.success(res.data.message?.replace(/_/g, " "))
          );
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 401) {
            dispatch(userActions.user.logout("seller", history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request() {
    return { type: deliveryConstants.delivery.DELETE_DELIVERY_MAN };
  }
  function success(dispatcher) {
    return {
      type: deliveryConstants.delivery.DELETE_DELIVERY_MAN_SUCCESS,
      dispatcher,
    };
  }
  function failure(error) {
    return {
      type: deliveryConstants.delivery.DELETE_DELIVERY_MAN_FAILURE,
      error,
    };
  }
};

const createCourier = (data, history) => {
  return (dispatch) => {
    // console.log('i worked');
    dispatch(request());

    logisticService.delivery
      .createCourier(data)
      .then((res) => {
        // console.log(res);
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
          dispatch(
            alertActions.alert.success(res.data.message?.replace(/_/g, " "))
          );
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/\\n/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout("seller", history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request() {
    return { type: deliveryConstants.delivery.ADD_COURIER_REQUEST };
  }
  function success(couriers) {
    return { type: deliveryConstants.delivery.ADD_COURIER_SUCCESS, couriers };
  }
  function failure(error) {
    return { type: deliveryConstants.delivery.ADD_COURIER_FAILURE, error };
  }
};

const editCourier = (data, id, history) => {
  return (dispatch) => {
    dispatch(request());
    logisticService.delivery
      .editCourier(data, id)
      .then((res) => {
        // console.log(res);
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
          dispatch(
            alertActions.alert.success(res.data.message?.replace(/_/g, " "))
          );
          dispatch(
            alertActions.alert.success(res.data.message?.replace(/_/g, " "))
          );
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/\\n/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout("seller", history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }
        console.log(err.config);
      });
  };
  function request() {
    return { type: deliveryConstants.delivery.UPDATE_COURIER_REQUEST };
  }
  function success(courier) {
    return { type: deliveryConstants.delivery.UPDATE_COURIER_SUCCESS, courier };
  }
  function failure(error) {
    return { type: deliveryConstants.delivery.UPDATE_COURIER_FAILURE, error };
  }
};

const viewCouriers = (history) => {
  return (dispatch) => {
    // console.log('i worked');
    dispatch(request());

    logisticService.delivery
      .viewCouriers()
      .then((res) => {
        // console.log(res);
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
        } else {
          dispatch(failure(res.data.message));

          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 401) {
            dispatch(userActions.user.logout("seller", history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request() {
    return { type: deliveryConstants.delivery.GET_COURIERS_REQUEST };
  }
  function success(couriers) {
    return { type: deliveryConstants.delivery.GET_COURIERS_SUCCESS, couriers };
  }
  function failure(error) {
    return { type: deliveryConstants.delivery.GET_COURIERS_FAILURE, error };
  }
};

const deleteCourier = (id, history) => {
  return (dispatch) => {
    // console.log('i worked');
    dispatch(request());

    logisticService.delivery
      .deleteCourier(id)
      .then((res) => {
        // console.log(res);
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
          dispatch(
            alertActions.alert.success(res.data.message?.replace(/_/g, " "))
          );
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message?.replace(/_/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 401) {
            dispatch(userActions.user.logout("seller", history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }
        console.log(err.config);
      });
  };
  function request() {
    return { type: deliveryConstants.delivery.DELETE_COURIER_REQUEST };
  }
  function success(courier) {
    return { type: deliveryConstants.delivery.DELETE_COURIER_SUCCESS, courier };
  }
  function failure(error) {
    return { type: deliveryConstants.delivery.DELETE_COURIER_FAILURE, error };
  }
};

export const delivery = {
  createDispatcher,
  editDispatcher,
  deleteDispatcher,
  viewDispatcherType,
  createCourier,
  editCourier,
  deleteCourier,
  viewCouriers,
};
