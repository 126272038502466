import * as yup from 'yup';

export const termSchema = yup.object().shape({
    SLA: yup
        .string("SLA  is Required")
        .required("SLA  is Required"),
    logistics_fee: yup
        .string("Logistics Fee is Required")
        .required("Logistics Fee is Required"),
    days: yup
    .string("Day is Required")
    .required("Day is Required")
        
});

export default {
    termSchema
}