import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Payment from '../views/paymentHistory';
import { orderActions } from '../actions';
// import { payoutActions } from '../actions';

const mapStateToProps = (state) =>{
    const { loading,payments, paymentLoading, orders, payment} = state.orders;
    // const paymentInfo = state.orders.payment;

    const alert  = state.alert;
    
    return {
        alert,
        loading,
        payments,
        orders,
        paymentInfo: payment,
        paymentLoading,
    };
     
     
 }
 const mapDispatchToProps = (dispatch) => ({
   
     viewPayments:(type,history)=>dispatch(orderActions.order.viewPayments(type,history)),
     getTransactionPaymentInformation: (type, data, history) => dispatch(orderActions.order.getTransactionPaymentInformation(type, data, history)),
     clearTransactionPaymentInformation: () => dispatch(orderActions.order.clearTransactionPaymentInformation()),
    dispatch
}
)
 

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Payment));