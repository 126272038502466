import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import SingleTransaction from '../views/SingleTransaction';
import { transaction } from '../constants/transaction';
import {transactionActions} from '../actions';

const mapStateToProps = (state) => {
    const alert  = state.alert;
    const { transactions, selectedTransaction } = state.transaction
    return {
        alert,
        transactions,
        selectedTransaction
    };
 }
 
 const mapDispatchToProps = (dispatch) => {
     return {
         editTransaction: (transaction, history) => dispatch(transactionActions.transaction.editTransaction(transaction, history))
     }
 }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleTransaction));