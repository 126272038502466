import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom'
import {USER_ROLE_SELLER, USER_ROLE_MARKET} from '../constants';
import Modal from "react-responsive-modal";
import moment from "moment";


class Header extends Component {
  state = {
    disabled: false,
    showReferralModal: false
  }

  getVerifiedStyle = () => {
    const {user} = this.props
    if ((user && user.role && user.role.name === USER_ROLE_SELLER || user && user.role && user.role.name === USER_ROLE_MARKET) && (user.verified !== 1 || user.support_verified !== 1 || user.seller_id === null)) {
      return {
        color: 'darkgray ',
        fontWeight: '600'
      }
    } else {
      return {
        fontWeight: '600'
      }
    }
  }

  componentWillReceiveProps(props) {
    const {user} = props
    if ((user && user.role && user.role.name === USER_ROLE_SELLER || user && user.role && user.role.name === USER_ROLE_MARKET) && (user.verified !== 1 || user.support_verified !== 1 || user.seller_id === null)) {
      this.setState({disabled: true});
    } else {
      this.setState({disabled: false});
      return {
        fontWeight: '600'
      }
    }
  }

  handleLogout = () => {
    if (this.props.user && this.props.user.role && (this.props.user.role.name === USER_ROLE_SELLER || this.props.user.role.name === USER_ROLE_MARKET))
      this.props.logout('seller', this.props.history);
    else
      this.props.logout('buyer', this.props.history);
  }

  render() {
    const {history, user} = this.props;
    const {disabled, showReferralModal} = this.state;

    let rcb = null;
    if (user) {
      rcb = user.rcb;
    }

    return (
      <>
        {
          rcb && <>

            <Modal
              open={this.state.showReferralModal}
              onClose={() => this.setState({showReferralModal: false})}
              center
              styles={{
                modal: {
                  maxWidth: '800px',
                  width: '100%',
                },
                overlay: {
                  zIndex: 1500,
                }
              }}>
              <div>
                If you share your referral
                code <strong>{user.referral_code}</strong> to <strong>{rcb.required_registrations}</strong> new users
                and
                they complete registration, you will receive a promo code that provides
                you <strong>{rcb.promo_percent}%</strong> discount off escrow fee on your
                next <strong>{rcb.promo_usage_freq}</strong> transaction(s). Valid
                until <strong>{moment(rcb.expiration_date).format('ll')}</strong>
              </div>
              <div className="mt-3">
                <strong>Referral Benefit Status:</strong> Benefit
                requirement {rcb.benefit_consumed ? "fulfilled" : "yet to be fulfilled"}. <strong>{rcb.promo_usage}</strong> promo
                code applied out of a total of <strong>{rcb.promo_usage_freq}</strong> allowed.
              </div>
            </Modal>
          </>
        }

        <nav className="main-header navbar navbar-expand bg-dark navbar-light border-bottom py-3">
          {/*<!-- Left navbar links --> */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" data-widget="pushmenu" href="#"><i className="fa fa-bars"></i></a>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <a href="https://www.atarapay.com/faq" className="nav-link"><strong><b>Help</b></strong></a>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <a href="https://www.atarapay.com/contact" className="nav-link"><strong><b>Contact Us</b></strong></a>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <a href="https://www.youtube.com/watch?v=ybsb-D1as8s&t=8s" className="nav-link"
                 target="_blank"><strong><b>Watch: How AtaraPay Escrow Works</b></strong></a>
            </li>
          </ul>

          {/*
    <!-- Right navbar links --> */}
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link onClick={e => {
                if ((user && user.role && user.role.name === USER_ROLE_SELLER || user && user.role && user.role.name === USER_ROLE_MARKET) && (user.verified !== 1 || user.support_verified !== 1 || user.seller_id === null)) {
                  e.preventDefault()
                }
              }} to="/app/transaction" className="nav-link" style={this.getVerifiedStyle()}>

                {user && user.role && user.role.name === USER_ROLE_MARKET ? null :
                  <p disabled={disabled} className="btn p2pBtn" style={this.getVerifiedStyle()}>
                    Create P2P Transaction
                  </p>
                }

              </Link>
            </li>

            <div className="d-flex flex-row">
              {
                rcb &&
                <a className="nav-link el-dropdown-link" onClick={() => this.setState({showReferralModal: true})}>
                  <i className="fa fa-gift"/>
                </a>
              }
              <ul className="nav-item dropdown" style={{paddingLeft: 0}}>
                <a className="nav-link el-dropdown-link" data-toggle="dropdown" href="#" aria-expanded="false">
                  <i className="ion-person t-ion">
                  </i>
                </a>
                <div className="dropdown-menu el-dropdown-menu dropdown-menu-right">
                <span
                  className="dropdown-item dropdown-header">Hi {((user && user.role && user.role.name === USER_ROLE_SELLER || user && user.role && user.role.name === USER_ROLE_MARKET) || user && user.role && user.role.name === USER_ROLE_MARKET) && user.seller && user.seller.business_name ? user && user.seller && user.seller.business_name : user && user.firstname + " " + user.lastname}</span>
                  <div className="dropdown-divider"></div>
                  <li onClick={() => {
                    history.push("/app/seller/profile/view")
                  }} className="dropdown-item ">
                    <i className="ion-ios-person mr-2"></i> My Profile

                  </li>
                  {user && user.role && user.role.name === USER_ROLE_SELLER ?
                    <span>
          <div className="dropdown-divider"></div>
          <li disabled={disabled} onClick={(e) => {
            if ((user && user.role && user.role.name === USER_ROLE_SELLER || user && user.role && user.role.name === USER_ROLE_MARKET) && (user.verified !== 1 || user.support_verified !== 1 || user.seller_id == null)) {
              e.preventDefault()
            } else {
              history.push("/app/settings")
            }
          }} className="dropdown-item">
            <i className="ion-ios-gear mr-2"></i> Settings
                        
          </li> </span> : null}
                  <div className="dropdown-divider"></div>
                  <li disabled={disabled} onClick={(e) => {
                    if ((user && user.role && user.role.name === USER_ROLE_SELLER || user && user.role && user.role.name === USER_ROLE_MARKET) && (user.verified !== 1 || user.support_verified !== 1 || user.seller_id == null)) {
                      window.open("https://www.atarapay.com/faq", "_blank")
                    } else {
                      window.open("https://www.atarapay.com/faq", "_blank")
                    }
                  }} className="dropdown-item">
                    <i className="ion-ios-gear mr-2"></i> FAQ

                  </li>
                  <div className="dropdown-divider"></div>
                  <li className="dropdown-item" onClick={this.handleLogout}>
                    <i className="ion-log-out mr-2"></i>Log Out

                  </li>
                  <div className="dropdown-divider"></div>

                </div>
              </ul>
            </div>

          </ul>
        </nav>

      </>
    )
  }

}

export default withRouter(Header);