import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StageOne from './StageOne';
import StageTwo from './StageTwo';
import StageThree from './StageThree';
import {
  transactionActions,
  alertActions,
  payoutActions,
  termActions,
} from '../../actions';
import transactionSchema from '../../validation/transaction';
import LOCALSTORAGE_ROLE from '../../constants';
import transactionHelpers from '../../helpers/transaction';
import 'react-phone-number-input/style.css';
import { transactionService } from '../../service.js';
import $ from 'jquery';

import swal from '@sweetalert/with-react';

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        id:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.id) ||
          null,
        type:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.type) ||
          2,
        product_name:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.product_name) ||
          '',
        category:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.category) ||
          '',
        buyer_email:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.buyer_email) ||
          '',
        buyer_phone:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.buyer_phone) ||
          '',
        seller_email:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.seller_email) ||
          '',
        seller_phone:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.seller_phone) ||
          '',
        amount_payed:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.amount_payed) ||
          0,
        quantity:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.quantity) ||
          0,
        escrow_fee_bearer:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.escrow_fee_bearer) ||
          '',
        shipping_method:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.shipping_method) ||
          0,
        shipping_cost:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.shipping_cost) ||
          0,
        max_delivery_days:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.max_delivery_days) ||
          0,
        shipping_fee_bearer:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.shipping_fee_bearer) ||
          '',
        product_desc:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.product_desc) ||
          '',
        is_additional_services:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.is_additional_services) ||
          0,
        additional_services:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.additional_services) ||
          [],
        extra:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.extra) ||
          {},
        delivery_location:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.delivery_location) ||
          '',
        currency:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.currency) ||
          'NGN',
        conversion_ratio:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.conversion_ratio) ||
          1,
        recipient:
          (this.props.selectedTransaction &&
            this.props.selectedTransaction.recipient) ||
          {},
        terms: {},
      },
      edit: false,
      stage: 1,
      other_party: null,
    };
  }

  adOns = {
    automobile: [
      {
        service: 'Title Collection',
        cost: 0,
        payment_recipient: '',
        selected: false,
      },
      {
        service: 'Lien Holder Payoff',
        cost: 0,
        payment_recipient: '',
        selected: false,
      },
    ],
    antique: [
      {
        service: 'Insurance',
        cost: 0,
        payment_recipient: '',
        selected: false,
      },
      {
        service: 'Transport Shield Protection',
        cost: 0,
        payment_recipient: '',
        selected: false,
      },
    ],
  };

  fillPhone = (name, value) => {
    console.log('Test Fill', name);
    const data = { ...this.state.data, [name]: value };
    this.setState({ data });
  };
  handleCheckUser = (role, phone_number) => {
    transactionService.transaction
      .checkUser(role, { phone_number })
      .then((res) => res.data)
      .then((res) => {
        if (res.status === 'success') {
          this.setState({ other_party: res.data });
          if (localStorage.getItem('trust_user_type') === 'customer')
            this.setState({ terms: res.data.terms });
          if (role == 'seller') {
            this.setState({
              data: { ...this.state.data, seller_email: res.data.email },
            });
          } else {
            this.setState({
              data: { ...this.state.data, buyer_email: res.data.email },
            });
          }
        } else {
          this.setState({ other_party: null });
        }
      })
      .catch((error) => {
        //kill error
        this.setState({ other_party: null });
      });
  };

  componentDidMount = () => {
    if (localStorage.getItem('trust_user_type') === 'seller')
      this.props.getTerm(this.props.history);
    this.props.viewPayout(this.props.role, this.props.history);
    if (
      this.props.selectedTransaction &&
      this.props.selectedTransaction.product_name != ''
    ) {
      this.setState({ edit: true });
      const { additional_services, category, gateway_name } =
        this.props.selectedTransaction;
      const original_list =
        (this.adOns[category] && this.adOns[category]) || [];

      let personalized_list = [];
      original_list.map((service) => {
        if (
          additional_services.find(
            (_service) =>
              _service.service == service.service && service.selected == false
          )
        ) {
          //pass
        } else {
          personalized_list = [...personalized_list, service];
        }
      });
      personalized_list = [...personalized_list, ...additional_services];

      const recipient =
        typeof this.state.data.recipient === 'string'
          ? JSON.parse(this.state.data.recipient)
          : this.state.data.recipient;

      this.setState(
        {
          data: {
            ...this.state.data,
            sellers: this.props.selectedTransaction.sellers,
            seller_phone: this.state.data.seller_phone
              ? this.state.data.seller_phone.replace('+234', '+')
              : null,
            additional_services: personalized_list,
            recipient: recipient,
            payment_method: gateway_name,
          },
          edit: true,
        },
        function () {
          console.log('component loaded', this.state);
        }
      );
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.term !== this.props.term) {
      this.setState({ terms: this.props.term });
    }
  };

  handlePriceChange = (name, value) => {
    let data = { ...this.state.data, [name]: value };
    this.setState({ data });
  };

  handlePhone = (value, name) => {
    let data = { ...this.state.data, [name]: value };
    this.setState({ data });
  };

  handleUpdateCancel = (id) => {
    this.setState({ edit: false });
    this.props.cancelTransaction();
    this.props.history.push(`/app/transaction/${id}`);
  };

  componentWillUnmount = () => {
    this.props.deSelectTransaction();
  };

  _nextStage = () => {
    let { stage } = this.state;
    stage = stage + 1 > 3 ? null : stage + 1;
    if (stage) {
      this.setState({ stage });
    }
  };
  _prevStage = () => {
    let { stage } = this.state;
    stage = stage - 1 < 1 ? null : stage - 1;
    if (stage) {
      this.setState({ stage });
    }
  };

  handleAdSelection = (e, index) => {
    const { name, value } = e.target;
    if (name == 'selected') {
      /**@todo */
    }
    const { additional_services } = this.state.data;
    additional_services[index] = {
      ...additional_services[index],
      [name]: value,
    };
    const data = { ...this.state.data, additional_services };

    this.setState({ data });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...this.state.data, [name]: value };
    if (name === 'category') {
      data = {
        ...data,
        additional_services: (this.adOns[value] && this.adOns[value]) || [],
        extra: {},
      };
    }

    if (
      data.category == 'Money Market' &&
      (name == 'conversion_ratio' || name == 'quantity')
    ) {
      data = {
        ...data,
        amount_payed: data.conversion_ratio * data.quantity,
        currency: '',
      };
    }
    this.setState({ data });
  };

  handleCheckBoxToggle = (e, index) => {
    const { additional_services } = this.state.data;
    additional_services[index] = {
      ...additional_services[index],
      selected: !additional_services[index].selected,
    };
    const data = { ...this.state.data, additional_services };
    this.setState({ data });
  };

  handleExtraChange = (e) => {
    const { name, value } = e.target;
    const extra = { ...this.state.data.extra, [name]: value };
    const data = { ...this.state.data, extra };
    this.setState({ data });
  };
  handleRecipientChange = (e) => {
    const { name, value } = e.target;
    const recipient = { ...this.state.data.recipient, [name]: value };
    const data = { ...this.state.data, recipient };
    this.setState({ data }, function () {
      //console.log('handleRecipientChange',this.state.data.recipient);
    });
  };

  handleSubmit = async () => {
    this.setState({ error: null });
    this.setState({ submitted: true, error: null });

    const details = transactionHelpers.formatTransactionData({
      ...this.state.data,
      role: this.props.role,
    });
    //console.log("Validation is Ongoing", {...details, extra: JSON.parse(details.extra)});
    const validation = await transactionSchema
      .validate({ ...details, extra: JSON.parse(details.extra) })
      .catch((error) => {
        swal('', error.errors[0], 'error');
        return this.setState({ error: '' });
      });

    if (!validation) {
      return;
    }

    const transactionDetails = {
      ...details,
      max_delivery_days:
        details.max_delivery_days !== null && details.max_delivery_days !== ''
          ? details.max_delivery_days
          : 0,
    };

    this.props.create(this.props.role, transactionDetails, this.props.history);
  };

  handleUpdateTransaction = async () => {
    const details = transactionHelpers.formatTransactionData({
      ...this.state.data,
      role: this.props.role,
    });
    const validation = await transactionSchema
      .validate(details)
      .catch((error) => {
        return this.setState({ error: error.errors[0] });
      });
    if (!validation) {
      return;
    }

    this.props.update(this.props.role, details, this.props.history);
  };
  handleCurrencyChange = (event) => {
    let Currency = event.currentTarget.getAttribute('Currency');

    let paymentMethod = '';
    let conversionRatio = 1;
    $('#optionMonnify').show();
    $('#optionPaypal').show();
    if (Currency == 'USD') {
      $('#optionMonnify').hide();
      paymentMethod = 'Paystack';
      this.getconversion();
    } else if (Currency == 'NGN') {
      $('#optionPaypal').hide();
    }

    let data = {
      ...this.state.data,
      payment_method: paymentMethod,
      currency: Currency,
      conversion_ratio: conversionRatio,
    };
    this.setState({ data });
  };
  async getconversion() {
    const resp = await fetch('https://api.atarapay.com/api/abokiExchangeRate');
    const result = await resp.json();
    let data = {
      ...this.state.data,
      conversion_ratio: result.data.USDNGN.toFixed(2),
    };
    this.setState({ data });
  }

  componentWillReceiveProps = (props) => {
    if (
      props.alert &&
      props.alert.message &&
      props.alert.type === 'alert-danger'
    ) {
      swal('Error', props.alert.message, 'error').then(() => {
        props.dispatch(alertActions.alert.clear());
      });
    } else if (
      props.alert &&
      props.alert.message &&
      props.alert.type === 'alert-success'
    ) {
      swal('Success', props.alert.message, 'success').then(() => {
        props.dispatch(alertActions.alert.clear());
        if (props.selectedTransaction) {
          props.history.push(
            `/app/transaction/${props.selectedTransaction.id}`
          );
        } else {
          props.history.push(`/app/transactions`);
        }
      });
    }
  };

  render() {
    const { data, error, edit, stage, other_party, currency, terms } =
      this.state;
    const { adding, updating, alert, role, accounts } = this.props;
    switch (stage) {
      case 1:
        return (
          <StageOne
            data={data}
            role={role}
            _nextStage={this._nextStage}
            _prevStage={this._prevStage}
            handleChange={this.handleChange}
            handleAdSelection={this.handleAdSelection}
          />
        );
      case 2:
        return (
          <StageTwo
            edit={edit}
            data={data}
            terms={terms}
            role={role}
            error={error}
            adding={adding}
            updating={updating}
            currency={currency}
            accounts={accounts}
            other_party={other_party}
            fillPhone={this.fillPhone}
            _nextStage={this._nextStage}
            _prevStage={this._prevStage}
            handlePhone={this.handlePhone}
            handleSubmit={this.handleSubmit}
            handlePriceChange={this.handlePriceChange}
            handleChange={this.handleChange}
            handleExtraChange={this.handleExtraChange}
            handleAdSelection={this.handleAdSelection}
            handleUpdateCancel={this.handleUpdateCancel}
            handleUpdateTransaction={this.handleUpdateTransaction}
            handleCheckBoxToggle={this.handleCheckBoxToggle}
            handleCheckUser={this.handleCheckUser}
            handleCurrencyChange={this.handleCurrencyChange}
            handleRecipientChange={this.handleRecipientChange}
          />
        );
      case 3:
        return (
          <StageThree
            data={data}
            _nextStage={this._nextStage}
            _prevStage={this._prevStage}
            handleChange={this.handleChange}
            handleAdSelection={this.handleAdSelection}
            handlePhone={this.handlePhone}
          />
        );
      default:
        return <div>Loading ...</div>;
    }
  }
}

const mapStateToProps = (state) => {
  const alert = state.alert;
  const { term } = state.terms;
  const { transactions, selectedTransaction, adding, updating } =
    state.transaction;
  const { accounts } = state.payout;
  return {
    alert,
    adding,
    updating,
    term,
    transactions,
    selectedTransaction,
    role: localStorage.getItem('trust_user_type'),
    accounts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    cancelTransaction: () =>
      dispatch(transactionActions.transaction.cancelEditTransaction()),
    deSelectTransaction: () =>
      dispatch(transactionActions.transaction.cancelEditTransaction()),
    create: (type, transaction, history) =>
      dispatch(
        transactionActions.transaction.createTransaction(
          type,
          transaction,
          history
        )
      ),
    update: (type, transaction, history) =>
      dispatch(
        transactionActions.transaction.updateTransaction(
          type,
          transaction,
          history
        )
      ),
    viewPayout: (type, history) =>
      dispatch(payoutActions.payout.view(type, history)),

    getTerm: (history) => dispatch(termActions.getTerm(history)),

  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Create));
