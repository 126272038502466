import swal from "@sweetalert/with-react";

export const ValidateSubmission = (details, usd_account_ids, is_terms_read) => {
  const { CashApp, Paypal, Wise } = usd_account_ids;
  let result = true;
  if (!is_terms_read && details.type !== 1) {
    swal({ title: "Please agree terms and policies", icon: "warning" });
    result = false;
  }

  if (
    !(
      (CashApp && CashApp.id && CashApp.id.length > 3) ||
      (Paypal && Paypal.id && Paypal.id.length > 3) ||
      (Wise && Wise.id && Wise.id.length > 3)
    )
  ) {
    swal({ title: "Please enter valid USD Payout detail", icon: "warning" });
    result = false;
  }

  if (
    !details.bank_account_number ||
    details.bank_account_number.length !== 10
  ) {
    swal({ title: "Bank account number is not valid", icon: "warning" });
    result = false;
  }

  if (!details.bank_account_name || details.bank_account_name.length < 4) {
    swal({ title: "Bank account name is not valid", icon: "warning" });
    result = false;
  }

  if (!details.bank_name || details.bank_name.length < 3) {
    swal({ title: "Please select Bank name", icon: "warning" });
    result = false;
  }

  return result;
};
