import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";



import Delivery from '../views/Delivery';

const mapStateToProps = (state) =>{

    const { loading,term, addresses, address, service_providers, deleting, updating } = state.terms;
    const { user } = state.users;
    const alert  = state.alert;
    return {
        term,
        addresses,
        service_providers,
        address,
        deleting,
        updating,
        alert,
        user,
        loading
    };
 }


export default withRouter(connect(mapStateToProps)(Delivery));
