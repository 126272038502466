import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import swal from '@sweetalert/with-react';

import { supportActions } from '../actions';

class SupportVerification extends Component {

    componentDidMount() {
        this.props.verify(this.props.match.params.token, this.props.match.params.answer, this.props.history);
    }

    componentWillReceiveProps(props) {
        if (props.alert && props.alert.message && props.alert.type === 'alert-danger') {
            swal("Error", props.alert.message, "error");
        }
    }

    render() {
        const { alert, confirming, success } = this.props;
        return (
            <div style={{ height: '100vh', backgroundColor: '#f7fcfc' }}>
                <nav class="navbar navbar-expand-lg fixed-top navbar-inverse">
                    <a class="navbar-brand" href="http://www.atarapay.com">
                        <img src="/dist/img/logo-header.png" alt="" className="logo-img img img-responsive" />
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </nav>
                <section>
                    <div class="row row-margin top-margin-60">
                        <div className="col-sm-3 col-md-3"></div>
                        <div className="col-sm-8 col-md-6 col-12">
                            
                                <div className="card card-margin text-center">
                                    <div style={{ textAlign: "center", fontSize: '20px' }}>Request in progress....</div>
                                </div>
                            <div class="col-sm-3 col-md-3"></div>

                        </div>
                    </div>
                </section>



            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { success, confirming } = state.register;
    const alert = state.alert;

    return {
        success,
        confirming,
        alert
    };


}
const mapDispatchToProps = (dispatch) => ({

    verify: (token, answer, history) => dispatch(supportActions.BusinessVerification(token, answer, history)),

}
)


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupportVerification));