export const settings = {
  SET_REFUNDABLE: "SET_REFUNDABLE",
  SET_REFUNDABLE_SUCCESS: "SET_REFUNDABLE_SUCCESS",
  SET_REFUNDABLE_FAILURE: "SET_REFUNDABLE_FAILURE",

  SET_PAYMENT: "SET_PAYMENT",
  SET_PAYMENT_SUCCESS: "SET_PAYMENT_SUCCESS",
  SET_PAYMENT_FAILURE: "SET_PAYMENT_FAILURE",

  SET_CARD_FEE: "SET_CARD_FEE",
  SET_CARD_FEE_SUCCESS: "SET_CARD_FEE_SUCCESS",
  SET_CARD_FEE_FAILURE: "SET_CARD_FEE_FAILURE",

  SET_SP_TRANSFER_FEE: "SET_SP_TRANSFER_FEE",
  SET_SP_TRANSFER_FEE_SUCCESS: "SET_SP_TRANSFER_FEE_SUCCESS",
  SET_SP_TRANSFER_FEE_FAILURE: " SET_SP_TRANSFER_FEE_FAILURE",

  SET_ESCROW_BEARER: "SET_ESCROW_BEARER",
  SET_ESCROW_BEARER_SUCCESS: "SET_ESCROW_BEARER_SUCCESS",
  SET_ESCROW_BEARER_FAILURE: " SET_ESCROW_BEARER_FAILURE",

  SET_BVN_VERIFICATION: "SET_BVN_VERIFICATION",
  SET_BVN_VERIFICATION_SUCCESS: "SET_BVN_VERIFICATION_SUCCESS",
  SET_BVN_VERIFICATION_FAILURE: "SET_BVN_VERIFICATION_FAILURE",

  GET_BANKS: "GET_BANKS",
  GET_BANKS_SUCCESS: "GET_BANKS_SUCCESS",
  GET_BANKS_FAILURE: "GET_BANKS_FAILURE",

  SET_CAN_CANCEL_ORDER: "SET_CAN_CANCEL_ORDER",
  SET_CAN_CANCEL_ORDER_SUCCESS: "SET_CAN_CANCEL_ORDER_SUCCESS",
  SET_CAN_CANCEL_ORDER_FAILURE: " SET_CAN_CANCEL_ORDER_FAILURE",

  SET_DELIVERY_HANDLED_BY: "SET_DELIVERY_HANDLED_BY",
  SET_DELIVERY_HANDLED_BY_SUCCESS: "SET_DELIVERY_HANDLED_BY_SUCCESS",
  SET_DELIVERY_HANDLED_BY_FAILURE: " SET_DELIVERY_HANDLED_BY_FAILURE",

};
