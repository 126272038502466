import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import ForgotPass from '../views/forgotPassword';
import { userActions } from '../actions';

const mapStateToProps = (state) =>{
    
    const { sending,success } = state.users;
    return {
        sending,
        success,
        alert : state.alert
    };
 }
 

 const mapDispatchToProps = (dispatch) => ({
     dispatch,
    sendLink:(type,data,history)=>dispatch(userActions.user.forgotPassword(type,data,history))
    
}
)
 

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ForgotPass));