import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";




import Dashboard from '../views/Dashboard';
import { userActions } from '../actions';

const mapStateToProps = (state) =>{
    
    const { loading, dashboard } = state.users;
    
    const alert  = state.alert;
    return {
      
        alert,
        dashboard,
        loading
    };
 }

 const mapDispatchToProps = (dispatch) =>({
     dispatch,
     viewDashboard: (type, history) => dispatch(userActions.user.dashboard(type, history)),
 })
 

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Dashboard));