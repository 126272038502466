import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL_ADDRESS } from '../constants';
import Modal from 'react-responsive-modal';

export const Terms = ({ open, onClose }) => (
  <Modal open={open} onClose={onClose} className='card'>
    <div className=''>
      <h1 align='center'>
        <a name='_gjdgxs'></a>
        Terms of Use.
      </h1>
      <p>
        This webpage represents the Terms of Use of our service located at{' '}
        <a href='https://atarapay.com/' target='_blank'>
          www.atarapay.com
        </a>{' '}
        and{' '}
        <a href='https://www.atara.ng/' target='_blank'>
          www.atara.ng
        </a>{' '}
        and the tools we provide you (the "Website", the “Mobile App” or the
        "Service"). The terms, "we", “us” and "our" as used in this Agreement
        refer to AtaraPay. The terms “you”, “your” and yourself in this
        Agreement refer to any natural or legal person who has signed up to use
        this website as a registered User.
      </p>
      <p>
        We refer to this Agreement, our Privacy Policy accessible at
        www.atarapay.com/privacypolicy and any other terms, rules, or guidelines
        on our Website collectively as our "Legal Terms." You explicitly and
        implicitly agree to be bound by our Legal Terms each time you access our
        Website or use our Mobile App. If you do not wish to be so bound, please
        do not use or access Website or Mobile App operated by AtaraPay or its
        partner merchants
      </p>
      <p>
        We may amend this Agreement at any time by posting the amended terms on
        our Website. We may or may not post notices on the homepage of our
        Website when such changes occur.
      </p>
      <p>
        <strong>Definitions.</strong>
      </p>
      <p>
        <strong>Alternative Recipient </strong>
        shall mean any individual or corporate entity, connected or related to
        the Buyer, that receives item(s) purchased by the Buyer at point of
        delivery using the AtaraPay application.
      </p>
      <p>
        <strong>Buyer</strong>
        shall mean any individual or corporate entity that makes payment into
        the AtaraPay escrow account using the AtaraPay payment option on Website
        or Mobile App operated by AtaraPay or its partner merchants.{' '}
      </p>
      <p>
        <strong>Maximum Delivery Days</strong> shall mean the amount of time in
        days the Buyer and Seller have agreed (at the time of initiating the
        Transaction) it will take to deliver the Item to the Buyer or the
        Buyer’s representative, failing which payment made to escrow by Buyer
        will be automatically returned to the Buyer.
      </p>
      <p>
        <strong> Maximum Accept/ Reject Days</strong> shall mean the amount of
        time in days the Buyer shall be required to accept or reject the items.
        When a delivery is made by the Seller, the delivery is completed via the
        app and notification is sent to the Buyer to accept or reject it within
        2 days (48 hours), failing which payment made from escrow will be
        automatically credited to the Seller.
      </p>
      <p>
        <strong>Dispatcher</strong> shall mean any individual or corporate
        entity, represented by the Seller at point of delivery, that delivers
        item(s) purchased by the Buyer at point of delivery.
      </p>
      <p>
        <strong>Escrow Account</strong> shall mean the bank account where the
        funds deposited by the Buyer is kept.
      </p>
      <p>
        <strong>Escrow Fees</strong> shall mean fees payable by Seller or Buyer
        to AtaraPay for providing the escrow service. The fees are 1.5% of the
        total product sum with a minimum value of ₦500 and cap of ₦2,000. For
        P2P Escrow Transactions, the minimum value is ₦500 while the cap is
        ₦50,000. Please visit https://www.atarapay.com/pricing for pricing
        details.
      </p>
      <p>
        <b>Escrow Funds</b> shall mean the funds kept in the Escrow Account.
      </p>
      <p>
        <b>Transfer Fees</b> shall mean fees payable from the Escrow Account to
        our Payment Gateway partners for enabling online payment between the
        bank accounts belonging to AtaraPay and the Users involved in the
        Transaction. The fee is ₦50 per transfer between AtaraPay’s account and
        another user’s account. Please visit https://www.atarapay.com/pricing
        for pricing details.
      </p>
      <p>
        <b>Item or Items</b> shall mean any merchandise or service that is to be
        bought or sold on Website or Mobile App operated by AtaraPay or its
        partner merchants.
      </p>
      <p>
        <b>Marketplace Operator</b> shall mean an individual or corporate entity
        that processes Transactions on behalf of an e-Commerce Marketplace that
        consists of multiple vendors or service providers.
      </p>
      <p>
        <b>Service Provider</b> shall mean an individual or corporate entity
        that offers a service on a multi-vendor e-Commerce Marketplace operated
        by the Marketplace Operator or Seller who charges a commission payable
        automatically from the escrow.
      </p>
      <p>
        <b>Partner Merchant</b> shall mean any individual or corporate entity
        that is seller on www.atara.ng marketplace or integrates AtaraPay’s
        payment option on their eCommerce portal through Application Programming
        Interface (API) to AtaraPay’s platform.
      </p>

      <p>
        <b>Person to Person (P2P) Escrow Transactions</b> shall mean escrow
        transactions created by Users via the AtaraPay website
      </p>

      <p>
        <b>eCommerce Escrow Transactions</b> shall mean escrow transactions
        created by Users via www.atara.ng or our Partner Merchants' websites.
      </p>
      <p>
        <b>Seller</b> shall mean any individual or corporate entity that sells
        Items offline or online using Website or Mobile App operated by
        AtaraPay.
      </p>

      <p>
        <b>The AtaraPay Application or App or System </b>shall all mean AtaraPay
        suites of applications comprised of hardware and software.
      </p>

      <p>
        <b>Transaction</b> shall mean the commencement of the act of buying and
        selling by the Buyer or Seller using Website or Mobile App operated by
        AtaraPay or its partner merchants. Transactions are created by User via
        www.atara.ng, our partner merchants’ ecommerce portal or via AtaraPay’s
        website.
      </p>

      <p>
        <b>User</b> shall mean either Buyer, Seller or Marketplace Operator.
      </p>

      <p>
        <b>USSD/ SMS</b> shall mean technology used by Users to create a P2P
        escrow transaction or Used by Seller, Buyer, Dispatcher and Alternative
        Recipient to indicate acceptance or rejection of items being delivered
        at point of delivery.
      </p>

      <p>
        <b>Walk-In Centres</b> shall mean a physical location where Users can
        complete Transactions and make payments using cash or POS.
      </p>

      <p>
        <b>Online Transaction Fees</b> shall mean fees payable by Buyer, Seller
        or from the Escrow Account to the payment gateway provider for enabling
        online payment on our platform. The fees for our payment gateways can be
        found here{' '}
        <a
          href='https:\/\/monnify.com\/pricing.html#:~:text=1.3%25,capped%20at%20NGN2000%20per%20transaction'
          target='_blank'
        >
          Monnify
        </a>{' '}
        and{' '}
        <a href='https:\/\/paystack.com\/pricing' target='_blank'>
          {' '}
          Paystack
        </a>
        .
      </p>
      <p>
        <strong>The AtaraPay Service Summary.</strong>
      </p>
      <p>
        The service being offered on this platform is intended to allow Buyers
        or Sellers initiate Transactions. For each Transaction initiated by a
        User, the Buyer is required to deposit funds into an Escrow Account
        which is managed by AtaraPay in partnership with trusted 3rd parties
        whom are licensed by the Central Bank of Nigeria and the National
        Insurance Commission. The Seller is thereafter notified of the deposit
        and the Seller is required to initiate delivery to the Buyer. We are
        thereafter required to release the Escrow Funds to the Seller once the
        Buyer accepts delivery.
      </p>
      <p>
        <strong>Obligations of the Buyer.</strong>
      </p>
      <p>
        The Buyer shall be required to either register directly on our website,
        via any e-Commerce website linked to our Service, via USSD/ SMS or via
        our Mobile App. The Buyer shall provide, at a minimum, the following
        information via all channels mentioned above: Contact Name, Mobile
        Number, Email Address, Password.
      </p>
      <p>
        If the Buyer initiates a Transaction directly from our website or opts
        to use AtaraPay as the mode of payment via an ecommerce website, the
        Buyer is required to deposit Escrow Funds with us which will be kept in
        the Escrow Account. The Buyer is required to accept or reject the Item
        at the time of delivery. If the Buyer accepts the item via the app, the
        AtaraPay System shall notify us of same and thereby authorizes us to
        transfer the Escrow Funds to the Seller, less Switch and Escrow Fees. If
        the Buyer does not accept or reject the item via the app within Maximum
        Acceptance/ Rejection Days defined in this Terms of Use, the AtaraPay
        System shall notify us of same and thereby authorizes us to transfer the
        Escrow Funds to the Seller, less Switch and Escrow Fees. If the Buyer
        rejects the item via the app, the AtaraPay System shall notify us of
        same and the Seller shall be required to confirm if in possession of the
        rejected item. If Seller accepts, the Escrow Funds will be refunded to
        the Buyer, less Switch and Escrow Fees. However, if the Seller rejects,
        the Escrow Funds will be kept in the Escrow Account until such a time
        that the matter is resolved between the Buyer and Seller as per our
        Dispute Resolution Policy in this Terms of Use.
      </p>
      <p>
        If Seller initiates a P2P Transaction, the Buyer is required to agree to
        the Escrow terms created by the Seller via their account on the AtaraPay
        System in order to commence the Transaction and make payment into
        escrow.
      </p>
      <p>
        <strong>Obligations of the Seller.</strong>
      </p>
      <p>
        The Seller shall be required to register on our website only and provide
        us with the following information as Individual Sellers: Contact Name,
        Mobile Number, Email Address and Password. The following additional
        information are required if seller registers as a Business Seller:
        Business Name, Business Address, Business Email Address, Year of
        Incorporation and Certification Incorporation.
      </p>
      <p>
        Once the Seller is notified that Escrow Funds have been held in the
        Escrow Account to satisfy a Transaction, Seller is required to initiate
        delivery using a delivery service that provides confirmation of delivery
        and Seller shall also provide us with the tracking reference, signature
        proof of delivery, name of person that received the items, picture of
        the physical address showing the items with longitude/ latitude and
        date/ time stamps. If the Seller does not deliver the Item within the
        Maximum Delivery Days, Seller authorizes us to return the Escrow Funds
        to the Buyer less our fees. If the Buyer returns the Item to the Seller,
        the Seller shall notify us of the receipt of the Item via the AtaraPay
        app. If the Seller informs us of the acceptance of the items via the app
        at point of delivery, Seller authorizes us to return the Escrow Funds to
        the Buyer less our fees. If the Seller informs us via the app of the
        non-acceptance of the Item, the Escrow Funds will thereafter be kept in
        the Escrow Account until such a time that the matter is resolved between
        the Buyer and Seller as per our Dispute Resolution Policy in this Terms
        of Use. IN A BUYER/SELLER TRANSACTION BOTH SELLER AND BUYER MUST AGREE
        TO THE TRANSACTION BEFORE BUYER MAKES PAYMENT.
      </p>
      <p>
        If the Buyer initiates a P2P Transaction, the Seller is required to
        agree to the Escrow terms created by the Buyer via their account on the
        AtaraPay System before Buyer makes payment.
      </p>

      <p>
        <strong>Obligations of the Marketplace Operator.</strong>
      </p>
      <p>
        The Marketplace Operator shall be required to register on our website
        and provide us with the following information: Contact Name, Mobile
        Number, Email Address, Password, Business Name, Business Address,
        Business Email Address, Year of Incorporation and Certification
        Incorporation.
      </p>
      <p>
        On successful login to our website, the Marketplace Operator shall
        ensure that its Sellers’ phone numbers are associated to its account
        from the dashboard.
      </p>

      <p>
        <strong>Our Responsibilities</strong>
      </p>
      <p>
        We are only obligated to perform those services expressly described in
        this Agreement and in the Escrow Instructions for a Transaction. In
        performing our obligations, we will not be liable to any User for theft,
        damages or losses of any items during the Transaction, or expenses,
        except those based on gross negligence or willful misconduct on our
        part. We will not incur any liability for (i) any act or failure to act
        made or omitted in good faith or (ii) any action taken or omitted in
        reliance on any instrument, including any written statement or affidavit
        provided for in this Agreement that we in good faith believe to be
        genuine, nor will we be liable or responsible for forgeries, fraud,
        impersonations, or determining the scope of any representative
        authority, provided that we believed, in good faith, that such
        forgeries, fraud, or impersonations were genuine and acted without gross
        negligence or willful misconduct. We may consult with legal counsel in
        connection with our duties under this Agreement and will be fully
        protected in any act taken, suffered, or permitted by it in good faith
        and in accordance with the advice of counsel. We are not responsible for
        determining and verifying the authority of any person acting or
        purporting to act on behalf of any party to a Transaction, including a
        User’s financial institution.
      </p>
      <p>
        <strong>Accounts and Registration.</strong>
      </p>
      <p>
        To access some features of our Website, you must register for an
        account. When you register for an account, you may be required to
        provide us with some information about yourself (such as your name,
        e-mail address, phone number, or other contact information). You agree
        that the information you provide to us is accurate, current, and
        complete, and that you will keep it up-to-date at all times. When you
        register, you will be asked to provide a password. You are solely
        responsible for maintaining the confidentiality of your account and
        password, and any password for other third-party login. You accept
        responsibility for all activities that occur under your account. If you
        have reason to believe that your account is no longer secure, you must
        immediately notify us via email at support@atarapay.com. We will not be
        liable and you may be liable for losses, damages, liability, expenses,
        and lawyers’ fees incurred by us or a third party arising from someone
        else using your account due to your conduct regardless of whether you
        have notified us of such unauthorized use. You understand and agree that
        we may require you to provide information that may be used to confirm
        your identity and help ensure the security of your account.
      </p>
      <p>
        <strong>Prohibited Transactions.</strong>
      </p>
      <p>
        Users shall not use the Services for any illegal purposes whatsoever.
      </p>

      <p>
        <strong>License and Site Access.</strong>
      </p>
      <p>
        AtaraPay grants you a limited license to access and make personal use of
        this website and not to download (other than page caching) or modify it,
        or any portion of it, except with express written consent of AtaraPay.
        This license does not include any resale or commercial use of this
        website or its contents: any collection and use of any product listings,
        descriptions, or prices: any derivative use of this website or its
        contents: any downloading or copying of account information for the
        benefit of another merchant: or any use of data mining, robots, or
        similar data gathering and extraction tools. This website or any portion
        of this website may not be reproduced, duplicated, copied, sold, resold,
        visited, or otherwise exploited for any commercial purpose without
        express written consent of AtaraPay. You agree not to bypass any
        security or other features of this website designed to control the
        manner in which the website is used, harvest or mine content from the
        website, or otherwise access or use the website in a manner inconsistent
        with individual human use. You agree not to decipher, reverse engineer,
        decompile or disassemble the website, or the software used to provide
        the website, in whole or in part, or authorize, direct, or cause a third
        party to do so. You may not frame or utilize framing techniques to
        enclose any trademark, logo, or other proprietary information (including
        images, text, page layout, or form) of AtaraPay and our associates
        without express written consent. You may not use any meta tags or any
        other "hidden text" utilizing AtaraPay’s name or trademarks without the
        express written consent of AtaraPay. Any unauthorized use terminates the
        permission or license granted by AtaraPay. You are granted a limited,
        revocable, and non-exclusive right to create a hyperlink to the home
        page of AtaraPay so long as the link does not portray AtaraPay, its
        associates, or their products or services in a false, misleading,
        derogatory, or otherwise offensive matter. You may not use any
        AtaraPay’s logo or other proprietary graphic or trademark as part of the
        link without express written permission.
      </p>
      <p>
        <strong>Limitations and changes to Service.</strong>
      </p>
      <p>
        We may change the form and functionality of our service from time to
        time without giving you prior notice. We retain the right to create
        limits on and related to use of our website at our sole discretion at
        any time with or without notice. We may also impose limits on certain
        services or aspects of those services or restrict your access to parts
        or all of the services without notice or liability. We may change,
        suspend, or discontinue any or all of the services at any time,
        including the availability of any product, feature, database.
      </p>
      <p>
        <strong>Fees</strong>
      </p>
      <p>
        {' '}
        We charge fees and expenses for services provided in connection with all
        Transactions on Website or Mobile App operated by AtaraPay or its
        partner merchants. These fees include, without limitation, third-party
        service fees that may include, Payment Gateway Transaction Fees,
        Transfer Fees and Cash Handling Fees.
      </p>
      <p>
        {' '}
        You agree that we reserve the right to change our fees from time to
        time. Any changes will be communicated to you in advance with respect to
        fees or charges related to your account. We will however not change the
        fees or charges related to a specific Transaction.{' '}
      </p>
      <p>
        <strong>Payment Methods.</strong>
      </p>
      <p>
        We accept all major payment methods, which include cash, debit/credit
        cards, bank transfer, mobile payments and USSD (Payment Method). If you
        provide a debit/credit card or other payment method accepted by us in
        connection with a Transaction, you expressly agree that we are
        authorized to charge you the fee corresponding to the terms of your
        Transaction, any other fees for additional services that you purchase,
        and any applicable taxes in connection with your use of the Services.
      </p>
      <p>
        <strong>Issues with a Transaction.</strong>
      </p>
      <p>
        If we are unable to complete a Transaction for any reason, the Seller or
        Buyer is required to cancel the transaction from the dashboard to
        prevent refund or payout due to non-response. If you are unable to
        cancel from your dashboard, please contact us via email at
        support@atarapay.com to assist with this cancellation at least 48 hours
        before the Maximum Delivery Days or Maximum Accept/ Reject Days expire.
        We will notify all Users participating in the Transaction of such
        cancellation by sending an email to the email address provided by each
        User.
      </p>
      <p>
        <strong>Rejection of Payment.</strong>
      </p>
      <p>
        You acknowledge and agree that your agreement with your financial
        institution or applicable laws may limit your ability to transmit or
        receive funds in the amount or time limits required for a Transaction.
        Accordingly, we are not responsible for delays in delivery or payments
        for Transactions due to post times, banking service times, transmission
        errors, or errors in information provided by you or other Users.
      </p>
      <p>
        <strong>Electronic Communications.</strong>
      </p>
      <p>
        When you visit our website, use our Mobile App including USSD/ SMS or
        send emails to us, you are communicating with us electronically. You
        consent to receive communications from us electronically. We will
        communicate with you by e-mail or by posting notices on our website. You
        agree that all agreements, notices, disclosures and other communications
        that we provide to you satisfy any legal requirement that such
        communications be in writing.
      </p>
      <p>
        <strong>Confidential Information.</strong>
      </p>
      <p>
        Confidential Information shall include any and all information in
        whatever form whether disclosed orally or in writing or whether eye
        readable, machine readable that is shared between the Users of Website
        or Mobile App operated by AtaraPay or its partner merchants. You agree
        to maintain Confidential Information in strict confidence, not to
        divulge the Confidential Information to any third party and in addition
        not to communicate, indicate or suggest to any third party the existence
        of the Confidential Information.
      </p>
      <p>
        <strong>Intellectual Property Ownership.</strong>
      </p>
      <p>
        Our website, and the media and materials contained on our website,
        including all intellectual property rights in the Website, are the sole
        and exclusive property of AtaraPay.
      </p>
      <p>
        <strong>Copyright.</strong>
      </p>
      <p>
        All content included on our website such as text, graphics, logos,
        buttons, icons, images, audio clips, digital downloads, data
        compilations and software is the property of AtaraPay or its content
        suppliers and protected by Copyright Laws. The compilation of all
        content on this website is the exclusive property of AtaraPay.
      </p>
      <p>
        <strong>Privacy.</strong>
      </p>
      <p>
        Please review our Privacy Policy which also governs the use of the
        Platform.
      </p>
      <p>
        <strong>Dispute Resolution</strong>
      </p>
      <p>
        In the event of any dispute, claim, question, disagreement or breach
        arising from or relating to a Transaction, Buyer or Seller (the parties)
        hereby agree to raise the dispute on the AtaraPay portal.
      </p>
      <p>
        AtaraPay Dispute Managers will mediate with the purpose of reaching an
        amicable resolution (Mediation Period). If no resolution is reached
        within three (3) days of the Mediation Period, the Parties are required
        to reach an amicable settlement by themselves (Negotiation Period). If
        the Parties have not resolved the dispute within 14 days from the end of
        the Negotiation Period, then the matter shall be resolved through
        Arbitration in accordance with the Arbitration and Conciliation Act (CAP
        A18 Laws of the Federation of Nigeria 2004) by a single arbitrator
        appointed in accordance with the said Act. The Arbitration award shall
        be final and binding upon the parties and judgment upon the award
        tendered may be entered in any court having jurisdiction. The
        Arbitration shall hold in Lagos State, Nigeria.
      </p>
      <p>
        If AtaraPay does not receive proof that the dispute has gone to
        Arbitration 14 days after the Negotiation Period, AtaraPay is authorised
        to release the funds from the Escrow account to the Buyer less Escrow,
        Inter-bank and Online Transaction fees.
      </p>
      <p>
        <strong>Indemnification.</strong>
      </p>
      <p>
        The parties to each transaction hereby agree, jointly and severally, to
        indemnify and hold us harmless against any and all third-party thefts,
        losses, claims, damages and liabilities, including reasonable costs of
        investigation, attorneys’ fees, and disbursements that may be imposed on
        us or incurred by us in connection with the performance of our duties
        under this agreement, including, but not limited to, any litigation
        arising from this agreement or involving its subject matter, unless such
        loss, liability, claim or expense shall have been determined by an
        arbitrator or a court of competent jurisdiction to be a result of our
        gross negligence or willful misconduct.
      </p>
      <p>
        <strong>Links to Third Parties..</strong>
      </p>
      <p>
        Our website may contain links to websites maintained by third parties.
        Please be aware that these links are provided for your convenience and
        reference only. We do not operate or control in any respect any
        information, software, product or services available on such websites.
        The inclusion of a link to a third-party website does not in any way
        imply an endorsement of the services or of that website, its contents,
        or its sponsoring organization.
      </p>
      <p>
        <strong>Waiver.</strong>
      </p>
      <p>
        The waiver by either you or AtaraPay of any breach of any provision of
        this Agreement does not waive any other breach. The failure of any party
        to this Agreement to insist on strict performance of any covenant or
        obligation in accordance with this Agreement will not be a waiver of
        such party’s right to demand strict compliance in the future, nor will
        the same be construed as a novation of this Agreement.
      </p>
      <p>
        <strong>Severability..</strong>
      </p>
      <p>
        If any part of this Agreement is found to be illegal, unenforceable, or
        invalid, the remaining portions of this Agreement will remain in full
        force and effect. If any material limitation or restriction on the grant
        of any license to you under this Agreement is found to be illegal,
        unenforceable, or invalid, the license will immediately terminate.
      </p>
      <p>
        <strong>Disclaimer...</strong>
      </p>
      <p>
        Your access to and use of the Services or our website is at your own
        sole risk. YOU UNDERSTAND AND AGREE THAT THE SERVICES ARE PROVIDED TO
        YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS. WITHOUT LIMITING THE
        FOREGOING, TO THE FULL EXTENT PERMITTED BY LAW, AtaraPay DISCLAIMS ALL
        WARRANTIES, EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, OR NON-INFRINGEMENT. We make no representations or
        warranties of any kind with respect to the Website or the Services,
        including any representation or warranty that the use of the Services or
        the Website will be timely, uninterrupted or error-free or operate in
        combination with any other hardware, software, system, or data; meet
        your requirements or expectations; be free from errors or that defects
        will be corrected, or; be free of viruses or other harmful components.
        We also make no representations or warranties of any kind with respect
        to any Items sold or disposed of using our Services; diligence on any
        other User or any Property is solely your responsibility. No advice or
        information, whether oral or written, obtained from us or through the
        Services, will create any warranty not expressly made herein. We are not
        responsible for any representations, warranties, or guaranties made by
        any other User of our Website or the Services. In no event will we be
        liable for any act or omission of any third party, including, but not
        limited to, your financial institution, any payment system, any
        third-party service provider, any provider of telecommunications
        services, Internet access or computer equipment or software, any mail or
        delivery service, any payment or clearing house system, or for any
        circumstances beyond our control (including but not limited to, fire,
        flood or other natural disaster, war, riot, strike, act of civil or
        military authority, equipment failure, computer virus, infiltration or
        hacking by a third party, or failure or interruption of electrical,
        telecommunications or other utility services).
      </p>
      <p>
        <strong>Choice of Law.</strong>
      </p>
      <p>
        Any disputes arising out of or related to the use of Website or Mobile
        App operated by AtaraPay or its partner merchants shall be governed by
        the Laws of Federal Republic of Nigeria without regard to conflict of
        law rules.
      </p>

      <button className='btn btn-info btn-md' onClick={onClose}>
        Close
      </button>
    </div>
  </Modal>
);

export const Privacy = ({ open, onClose }) => (
  <Modal open={open} onClose={onClose} className='card'>
    <div className=''>
      {/* <div className="row">
        <div className="col-10">
            <img src="/dist/img/logo.3fc64c3.png" alt="AtaraPay Logo" style={{opacity: 1 ,width: "200px", paddingLeft: 15}} />
        </div>
    </div> */}
      <h1 align='center'>
        <a name='_gjdgxs'></a>
        PRIVACY POLICY
      </h1>
      <p>
        AtaraPay is committed to ensuring that your privacy policy is protected.
        This privacy policy explains how we use information collected about you,
        how you can instruct us if you prefer to limit the use of that
        information and procedures we have in place to safeguard your privacy.
        “you”? means any person submitting any data to us or our agent or the
        Site.
      </p>
      <br />
      <p>
        <strong>Personal identification information</strong>
        <br />
        We may collect personal identification information from Users in a
        variety of ways, including, but not limited to, when Users visit our
        site, register on the site, and in connection with other activities,
        services, features or resources we make available on our Site. Users may
        be asked for, as appropriate, name, email address, mailing address,
        phone number, credit card information. Users may, however, visit our
        Site anonymously. We will collect personal identification information
        from Users only if they voluntarily submit such information to us. Users
        can always refuse to supply personally identification information,
        except that it may prevent them from engaging in certain Site related
        activities.
      </p>

      <p>
        {' '}
        <strong>Non-personal identification information</strong>
        <br />
        We may collect non-personal identification information about Users
        whenever they interact with our Site. Non-personal identification
        information may include the browser name, the type of computer and
        technical information about Users means of connection to our Site, such
        as the operating system and the Internet service providers utilized and
        other similar information.
      </p>

      <p>
        <strong>How we use collected information</strong>
        <br />
        AtaraPay.com may collect and use Users personal information for the
        following purposes: to provide you with the services and products you
        request or that have been; to answer questions about our services;
        billing, payment methods or use of our website; to process or collect
        payments for our services; to conduct customer surveys; and to contact
        you about the products and services that we offer
      </p>

      <p>
        <strong>How we protect your information</strong>
        <br />
        We exercise great care to protect your personal information. This
        includes, among other things, using industry standard techniques. As a
        result, while we strive to protect your personal information, we cannot
        ensure or warrant the security of any information you transmit to us or
        receive from us.
      </p>

      <p>
        <strong>Disclosure of personal information</strong>
        <br />
        We will not disclose any personal information to any third party
        (excluding our contractors to whom we may provide such information for
        the limited purpose of providing services to us and who are obligated to
        keep the information confidential), unless (1) you have authorized us to
        do so; (2) we are legally required to do so, for example, in response to
        a subpoena, court order or other legal process and/or, (3) it is
        necessary to protect our property rights related to this website. We
        also may share aggregate, non-personal information about website usage
        with unaffiliated third parties. This aggregate information does not
        contain any personal information about our users.
      </p>

      <p>
        <strong>Web browser cookies</strong>
        <br />
        Our Site may use "cookies" to enhance User experience. User's web
        browser places cookies on their hard drive for record-keeping purposes
        and sometimes to track information about them. User may choose to set
        their web browser to refuse cookies, or to alert you when cookies are
        being sent. If they do so, note that some parts of the Site may not
        function properly.
      </p>

      <p>
        <strong>Advertising </strong>
        <br />
        Ads appearing on our site may be delivered to Users by advertising
        partners, who may set cookies. These cookies allow the ad server to
        recognize your computer each time they send you an online advertisement
        to compile non personal identification information about you or others
        who use your computer. This information allows ad networks to, among
        other things, deliver targeted advertisements that they believe will be
        of most interest to you. This privacy policy does not cover the use of
        cookies by any advertisers.
      </p>

      <p>
        <strong>Policy Changes</strong>
        <br />
        By submitting data to us or our agent or using the Site, you consent to
        our use of your data in the manner set out in this Privacy Policy.
        <br />
        This Privacy Policy can change at any time to include/exclude clauses
        and atarapay.com does not bear responsibility for updating Users on the
        same.
      </p>

      <p>
        <strong>Contacting us</strong>
        <br />
        If you have any questions about this Privacy Policy, the practices of
        this site, or your dealings with this site, please contact us at:
        <br />
        <a href='http://www.atarapay.com/contact'>
          http://www.atarapay.com/contact
        </a>
      </p>

      <button className='btn btn-info btn-md' onClick={onClose}>
        Close
      </button>
    </div>
  </Modal>
);

export const OrderEmail = (props) => (
  <Modal open={props.open} onClose={props.onClose} className='card'>
    <div style={{ marginLeft: '20px', marginRight: '20px', marginTop: '40px' }}>
      <h3>Dear {props.name},</h3>
      <p>Thank you for using the AtaraPay Service.</p>
      <p>
        We have debited your card with the amount â‚¦{props.amount} and
        deposited same in the escrow account. This money will be paid to the
        Seller (less escrow and/ or card fees) once you accept that the goods or
        service(s) delivered in/ are in good condition.
      </p>
      {/*<p>
                    Find below your 6-digit code required to identify the transaction at point of delivery.
    </p>
               <p style={{fontWeight: 600, textAlign: 'center', fontSize: 18}}>
                    { props.code }
                </p>*/}

      <p>Follow these simple steps to complete your purchase:</p>

      <ol>
        <li>
          Upon successful delivery, you will get SMS and email notification to
          accept or reject the goods or service(s)
        </li>
        <li>
          To accept or reject, simply click on the link provided in the SMS or
          email notification.{' '}
          <span style={{ color: 'red', background: 'yellow' }}>
            Please do this within 48 Hours failing which seller will be paid.
          </span>
        </li>
        <li>
          If you accept the goods or service(s), your funds will be released
          from escrow directly to the seller
        </li>
        <li>
          If you reject, the seller will get a notification and is expected to
          confirm that s/he is in possession of the goods or service(s). Upon
          confirmation, you will be refunded within 24 hours
        </li>
      </ol>
      <p>
        For further clarification, please email support@atarapay.com or call us
        on 0700ATARAPAY
      </p>
      <p>Thank you again for your patronage.</p>
      <p>
        Best Regards,
        <br />
        AtaraPay Team
      </p>
      <button
        className='btn btn-danger btn-md pull-right'
        onClick={props.onClose}
      >
        Close
      </button>
    </div>
  </Modal>
);

export const OrderDeliveryEmail = (props) => (
  <Modal open={props.open} onClose={props.onClose} className='card'>
    <div style={{ marginLeft: '20px', marginRight: '20px', marginTop: '40px' }}>
      <h3>Dear {props.name},</h3>
      <p>
        You have been assigned to deliver order ID {props.code} with your
        registered phone number {props.phone}.
      </p>
      <p>At time or point of delivery, follow these 7 simple steps;</p>

      <ol>
        <li>Request for the 6-digit order code from the Buyer/ Recipient</li>
        <li>
          Click <a href={BASE_URL_ADDRESS}>{BASE_URL_ADDRESS}</a>, in the form
          that appears, enter your registered phone number, the order code
          shared and hit submit{' '}
        </li>
        <li>
          You will be prompted to enter the OTP sent to your registered phone
          number
        </li>
        <li>
          On submitting OTP, Buyer/ Recipient will get notification on their
          phone requesting to accept or reject the goods or services.
        </li>
        <li>
          If Buyer/ Recipient accepts the goods or services, then you are
          required to do nothing
        </li>
        <li>
          If Buyer/ Recipient rejects, you will get an email notification
          requesting you to confirm possession of the goods or services.
        </li>
        <li>
          If you do not confirm that you are not in possession of the goods or
          services within 24 hours of rejection, the buyerâ€™s funds will be
          automatically refunded
        </li>
      </ol>
      <p>
        For further clarification, please email support@atarapay.com or call us
        on 0700ATARAPAY
      </p>
      <p>Thank you again for your patronage.</p>
      <p>
        Best Regards,
        <br />
        AtaraPay Team
      </p>
      <button
        className='btn btn-danger btn-md pull-right'
        onClick={props.onClose}
      >
        Close
      </button>
    </div>
  </Modal>
);

export const SuccessNotification = (props) => (
  <Modal open={props.open} onClose={props.onClose} className='card'>
    <div style={{ marginLeft: '20px', marginRight: '20px', marginTop: '40px' }}>
      <p>Thank you for your payment into escrow.</p>
      <p>
        The seller has been informed of your payment and shall begin processing
        of your order.
      </p>
      <p>
        We have sent detailed instructions to your email and phone number which
        you are required to follow to accept or reject the service/ product, at
        point of delivery.
      </p>
      <p>
        You can view your order history by clicking <b>My P2P Transactions</b>{' '}
        tab on the left pane of the page.
      </p>
      <p>
        For any questions or clarifications, please send request for support by
        clicking <b>Support</b> tab on the left pane of the page.
      </p>
      <div className='pull-right'>
        <Link
          to={`/app/transaction/${props.id}`}
          className='btn btn-info btn-md mr-2'
        >
          View Transaction
        </Link>
        <button className='btn btn-danger' onClick={props.onClose}>
          Close
        </button>
      </div>
    </div>
  </Modal>
);
