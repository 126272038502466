import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Api from '../views/Api';
import { userActions } from '../actions';
// import { payoutActions } from '../actions';


const mapStateToProps = (state) =>{

    const { users, alert, loading, goingLive} = state;
    return {
        users,
        alert,
        loading,
        goingLive
    };
     
     
 }
 const mapDispatchToProps = (dispatch) => ({
     dispatch,
    view:(type,history)=>dispatch(userActions.user.viewKey(type,history)),
    gen:(type,data,history)=>dispatch(userActions.user.genKey(type,data,history)),
     goLive: (type, data, process, history) => dispatch(userActions.user.goLive(type, data, process, history))
    
}
)
 

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Api));